import {
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_BY_PHONE_START,
  SEARCH_BY_PHONE_SUCCESS,
  SEARCH_BY_PHONE_ERROR,
  SEARCH_APPROVED_BY_PHONE_START,
  SEARCH_APPROVED_BY_PHONE_SUCCESS,
  SEARCH_APPROVED_BY_PHONE_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
} from './types';

const initialState = {
  sale: null,
  sales: null,
  approvedSales: null,
  message: null,
  isLoading: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  isLoadingExport: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_SUCCESS:
      return {
        ...state,
        sales: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_APPROVED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_APPROVED_SUCCESS:
      return {
        ...state,
        approvedSales: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_APPROVED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return {
        ...state,
        sale: null,
        errorMessage: null,
        isLoading: true,
      };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        sale: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_BY_PHONE_SUCCESS:
      return {
        ...state,
        sales: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_BY_PHONE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_APPROVED_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_APPROVED_BY_PHONE_SUCCESS:
      return {
        ...state,
        approvedSales: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_APPROVED_BY_PHONE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPDATE_START:
      return { ...state, errorMessage: null, isLoadingUpdate: true };

    case UPDATE_SUCCESS:
      return {
        ...state,
        sale: payload || null,
        errorMessage: null,
        isLoadingUpdate: false,
      };

    case UPDATE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUpdate: false,
      };

    case DELETE_START:
      return { ...state, errorMessage: null, isLoadingDelete: true };

    case DELETE_SUCCESS:
      return {
        ...state,
        message: payload,
        errorMessage: null,
        isLoadingDelete: false,
      };

    case DELETE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingDelete: false,
      };

    case EXPORT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    default:
      return state;
  }
};
