/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  useHistory, useLocation, useRouteMatch, Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faPowerOff,
  faUsers,
  faUserAlt,
  faPen,
  faThLarge,
  faPlusCircle,
  faTrash,
  faBookMedical,
  faFileCsv,
  faShoppingCart,
  faBell,
  faArrowRight,
  faStore,
  faBan,
  faVideo,
  faUserNurse,
  faMoneyBillWave,
  faLock,
  faFileCircleExclamation,
  faFileCircleCheck,
  faFileSignature,
  faSeedling,
  faPeopleGroup,
  faTicket,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { logout } from '../../store/modules/auth/actions';
import Menu from '../Menu';
import MoreMenuData from '../MoreMenuData';
import logo from '../../assets/images/logo.png';
import './Sidebar.scss';

const Sidebar = ({ collapsed, toggleCollapse, userRole }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const userName = localStorage.getItem('userName');
  const profilePicture = localStorage.getItem('profilePicture');
  const collapsedClass = collapsed
    ? 'collapsed'
    : '';
  const backgroundColorClass = userRole === 'admin' || userRole === 'ea_pi' || userRole === 'regional_pi' || userRole === 'regional_fa' ? 'green'
    : userRole === 'doctor' ? 'primary'
      : userRole === 'hnl-admin' ? 'dashboardColor' : userRole === 'hfwa_user' || userRole === 'call_center' ? 'callCenterColor' : 'warning';

  const { from } = location.state || { from: { pathname: '/' } };
  const logoutUser = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      toast: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout(() => {
          history.replace(from);
        }));
        Swal.fire(
          {
            title: 'Logout!',
            text: 'You have successfully logged out',
            icon: 'success',
            timer: 2000,
            toast: true,
          },
        );
      }
    });
  };

  return (
    <aside className={`sidebar ${collapsedClass} ${backgroundColorClass}`}>
      <div className={`sidebar__header ${backgroundColorClass}`}>
        <div className={`sidebar__header__content  ${backgroundColorClass}`}>
          <div className={`sidebar__brand  ${backgroundColorClass}`}>
            <Link href="/" to="/" className="sidebar__logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="sidebar__header__icon"
            onClick={toggleCollapse}
          />
        </div>
      </div>
      <div className={`sidebar__user  ${backgroundColorClass}`}>
        <div className="sidebar__user__avatar">
          {
            profilePicture ? (
              <img src={profilePicture || logo} alt="profilePicture" className="sidebar__user__icon" />
            ) : (
              <FontAwesomeIcon
                icon={faUserAlt}
                className="sidebar__user__icon"
              />
            )
          }
        </div>
        <h6 className="sidebar__user__name">
          {userRole === 'admin' ? `Welcome ${userName}` : userRole === 'doctor' ? `Dr ${userName}` : userRole === 'hnl-admin' ? `Welcome ${userName}` : `Welcome ${userName}`}
        </h6>
        {userRole === 'doctor' ? (
          <Link href="/doctor/edit-profile" to="/doctor/edit-profile" className="sidebar__user__settings">
            <FontAwesomeIcon
              icon={faPen}
              className="sidebar__user__settings-icon"
            />
          </Link>
        ) : null}
      </div>
      <nav className={`sidebar__nav ${backgroundColorClass}`}>
        <ul className={`sidebar__links  ${backgroundColorClass}`}>
          {userRole === 'hfwa_user' ? (
            <>
              <Menu
                label="Temporary GPA Certificates"
                link={`${url}/certificates/temporary`}
                icon={faUsers}
              />
              <Menu
                label="Permanent GPA Certificates"
                link={`${url}/certificates/permanent`}
                icon={faUsers}
              />
            </>
          ) : userRole === 'ea_pi' ? (
            <>
              <Menu
                label="Pending New HPC Users"
                link={`${url}/newUser/pendingHpcRep`}
                icon={faUsers}
              />
              <Menu
                label="Digital Enrollment Dashboard"
                link={`${url}/users/dashboard`}
                icon={faThLarge}
              />
              <Menu
                label="Mcc Farmer Dashboard"
                link={`${url}/users/mccDashboard`}
                icon={faThLarge}
              />
              {/* <Menu
                label="Regional Head Approved Requests"
                link={`${url}/regionalHead/pendingHPCApproval`}
                icon={faUsers}
              /> */}
              <Menu
                label="Pending Existing User Registrations"
                link={`${url}/users/pending`}
                icon={faUsers}
              />
              <Menu
                label="Dashboard"
                link={`${url}/users/recorded`}
                icon={faThLarge}
              />
              <Menu
                label="Add New MCC/HP Representative"
                link={`${url}/mcc-reps/add`}
                icon={faPlusCircle}
              />
              <Menu
                label="Reports"
                link={`${url}/reports`}
                icon={faFileCsv}
              />
            </>
          ) : userRole === 'regional_fa' ? (
            <>
              {/* <Menu
                label="Pending New HPC Users"
                link={`${url}/newUser/pendingHpcRep`}
                icon={faUsers}
              />
              <Menu
                label="Digital Enrollment Dashboard"
                link={`${url}/users/dashboard`}
                icon={faThLarge}
              /> */}
              <Menu
                label="Mcc Farmer Dashboard"
                link={`${url}/users/mccDashboard`}
                icon={faThLarge}
              />
              <Menu
                label="Pending Existing User Registrations"
                link={`${url}/users/pending`}
                icon={faUsers}
              />
            </>
          )
            : userRole === 'admin' || userRole === 'regional_pi' ? (
              <>
                {/* <Menu
                  label="Dashboard"
                  link={`${url}/users/dashboard`}
                  icon={faThLarge}
                /> */}
                <Menu
                  label="Pending New HPC Users"
                  link={`${url}/newUser/pendingHpcRep`}
                  icon={faUsers}
                />
                <Menu
                  label="Digital Enrollment Dashboard"
                  link={`${url}/users/dashboard`}
                  icon={faThLarge}
                />
                <Menu
                  label="Mcc Farmer Dashboard"
                  link={`${url}/users/mccDashboard`}
                  icon={faThLarge}
                />
                {userRole === 'regional_pi' ? (
                  <Menu
                    label="HNL Indenting Approval"
                    link={`${url}/hnl-indenting-approval`}
                    icon={faStore}
                  />
                ) : null}
                <Menu
                  label="Pending Existing User Registrations"
                  link={`${url}/users/pending`}
                  icon={faUsers}
                />
                <Menu
                  label="Dashboard"
                  link={`${url}/users/recorded`}
                  icon={faThLarge}
                />
                {
                  userRole === 'admin' ? (
                    <MoreMenuData
                      label="GPA Certificates"
                      icon={faFileSignature}
                      data={[{
                        id: 0,
                        link: `${url}/certificates/temporary`,
                        label: 'Temporary GPA Certificates',
                        icon: faFileCircleExclamation,
                        color: '#ffb100',
                      }, {

                        id: 1,
                        link: `${url}/certificates/permanent`,
                        icon: faFileCircleCheck,
                        label: 'Permanent GPA Certificates',
                        color: '#2a2e39',

                      }]}
                    />
                  ) : null
                }

                <Menu
                  label="Pending Doctors"
                  link={`${url}/doctors/pending`}
                  icon={faUserNurse}
                />
                <Menu
                  label="Recorded Doctors"
                  link={`${url}/doctors/recorded`}
                  icon={faUserNurse}
                />
                <Menu
                  label="Appointments"
                  link={`${url}/appointments`}
                  icon={faBookMedical}
                />
                <Menu
                  label="Physical Appointments"
                  link={`${url}/physical-appointments`}
                  icon={faBookMedical}
                />
                <Menu
                  label="Products"
                  link={`${url}/products`}
                  icon={faStore}
                />
                <Menu
                  label="HNL order details"
                  link={`${url}/orders`}
                  icon={faShoppingCart}
                />
                <Menu
                  label="Farmer Loan"
                  link={`${url}/farmerLoan`}
                  icon={faMoneyBillWave}
                />
                <Menu
                  label="Pending cattle for sale"
                  link={`${url}/sales/pending`}
                  icon={faStore}
                />
                <Menu
                  label="Approved cattle for sale"
                  link={`${url}/sales/approved`}
                  icon={faStore}
                />
                {/* <Menu
                  label="Assign MCC Representative"
                  link={`${url}/mcc-reps/assign`}
                  icon={faUsers}
                /> */}
                <Menu
                  label="Add New MCC/HP Representative"
                  link={`${url}/mcc-reps/add`}
                  icon={faPlusCircle}
                />
                <Menu
                  label="Push Notification"
                  link={`${url}/notification`}
                  icon={faBell}
                />
                <Menu
                  label="Transfer MCC Representative"
                  link={`${url}/transfer-mcc`}
                  icon={faArrowRight}
                />
                <Menu
                  label="Video Placement And Creatives"
                  link={`${url}/video-placement-creatives`}
                  icon={faVideo}
                />
                <Menu
                  label="Discontinue User"
                  link={`${url}/users/discontinue`}
                  icon={faBan}
                />
                <Menu
                  label="Delete User"
                  link={`${url}/users/delete`}
                  icon={faTrash}
                />
                <Menu
                  label="Reports"
                  link={`${url}/reports`}
                  icon={faFileCsv}
                />
              </>
            ) : userRole === 'doctor'
              ? (
                <>
                  <Menu
                    label="Upcoming appointments"
                    link={`${url}/appointments/upcoming`}
                    icon={faBookMedical}
                  />
                  <Menu
                    label="Dashboard"
                    link={`${url}/appointments/past-held`}
                    icon={faThLarge}
                  />
                </>
              ) : userRole === 'hnl-admin' ? (
                <>
                  <Menu
                    label="Pending Registrations"
                    link={`${url}/users/pending`}
                    icon={faUsers}
                  />
                  <Menu
                    label="Dashboard"
                    link={`${url}/users/recorded`}
                    icon={faThLarge}
                  />
                  <Menu
                    label="HNL Indenting Approval"
                    link={`${url}/hnl-indenting-approval`}
                    icon={faStore}
                  />
                  <Menu
                    label="Appointments"
                    link={`${url}/appointments`}
                    icon={faBookMedical}
                  />
                  <Menu
                    label="Pending cattle for sale"
                    link={`${url}/sales/pending`}
                    icon={faStore}
                  />
                  <Menu
                    label="Approved cattle for sale"
                    link={`${url}/sales/approved`}
                    icon={faStore}
                  />
                  {/* <Menu
                    label="Push Notification"
                    link={`${url}/notification`}
                    icon={faBell}
                  /> */}
                  <Menu
                    label="Video Placement And Creatives"
                    link={`${url}/video-placement-creatives`}
                    icon={faVideo}
                  />
                  <Menu
                    label="Discontinue User"
                    link={`${url}/users/discontinue`}
                    icon={faBan}
                  />
                  <Menu
                    label="Delete User"
                    link={`${url}/users/delete`}
                    icon={faTrash}
                  />
                  <Menu
                    label="Reports"
                    link={`${url}/reports`}
                    icon={faFileCsv}
                  />
                </>
              )
                : userRole === 'call_center' ? (
                  <Menu
                    label="Tickets"
                    link={`${url}/Tickets`}
                    icon={faTicket}
                  />
                )
                  : (
                    <>
                      <Menu
                        label="Pending Registrations HPC"
                        link={`${url}/pendingHpcRegistrations`}
                        icon={faPeopleGroup}
                      />
                      <Menu
                        label="Pending MCC Farmer Registration"
                        link={`${url}/MccFarmerListViewPage`}
                        icon={faSeedling}
                      />
                      <Menu
                        label="Digital Enrollment Dashboard"
                        link={`${url}/users/dashboard`}
                        icon={faThLarge}
                      />
                      <Menu
                        label="HNL Indenting"
                        link={`${url}/hnl-indenting`}
                        icon={faStore}
                      />

                      {/* <Menu
                    label="Orders From Representatives"
                    link={`${url}/mcc-ordersNew`}
                    icon={faShoppingCart}
                  /> */}
                      {/* <Menu
                    label="Orders form MCC Representative"
                    link={`${url}/mcc-orders`}
                    icon={faShoppingCart}
                  /> */}
                      {/* <Menu
                    label="Order to Regional Team"
                    link={`${url}/orders`}
                    icon={faCartPlus}
                  /> */}
                    </>
                  )}
          {
                 userRole === 'admin' || userRole === 'hnl-admin' || userRole === 'ea_pi' || userRole === 'regional_fa' || userRole === 'regional_pi' || userRole === 'call_center' ? (
                   <Menu
                     label="Change Password"
                     link={`${url}/updatePassword`}
                     icon={faLock}
                   />
                 ) : null
              }

          <Menu
            label="Logout"
            button
            icon={faPowerOff}
            onClick={logoutUser}
          />
        </ul>
        {/* <div className={`sidebar__brand  ${backgroundColorClass}`}>
          <Link href="/" className="sidebar__logo">
            <img src={logo} alt="logo" />
          </Link>
        </div> */}
      </nav>
    </aside>
  );
};

export default Sidebar;
