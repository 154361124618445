/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditUserModal.scss';

const EditUserModal = ({
  show, toggle, onSubmit, values,
}) => {
  // const [farmerName, setFarmerName] = useState(values ? values.farmerName : null);
  // const [phone, setPhone] = useState(values ? values.phone.replace('+91', '') : null);
  // const [gender, setGender] = useState(values ? values.gender : 'male');
  const [age, setAge] = useState(values ? new Date(values.age) : new Date());
  const [freqError, setFreqError] = useState(null);
  const [dateError, setDate] = useState(null);
  const [qtyError, setQtyError] = useState(null);
  const userRole = localStorage?.getItem('role');
  const showHideClassName = show ? 'edit-user-modal display-block' : 'edit-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('farmerName', values.farmerName);
      setValue('phone', values.phone ? values.phone.replace('+91', '') : '');
      setValue('gender', values.gender);
      setValue('role', values.role);
      setValue('steps', values.steps);
      setAge(values ? new Date(values.age) : new Date());
    }
  }, [setValue, values]);

  const submit = ({
    farmerName,
    gender,
    role,
    steps,
  }) => {
    toggle();
    onSubmit({
      farmerName,
      gender,
      age,
      role,
      steps,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="farmerName"
                            name="farmerName"
                            placeholder="Name"
                            {...register('farmerName', { required: { value: true, message: 'Name is required' } })}
                          />
                          {errors.farmerName && <div className="form__error">{errors.farmerName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Phone number</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone number"
                            disabled
                            {...register('phone', { required: { value: true, message: 'Phone number is required' } })}
                            // value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Gender</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="gender"
                            name="gender"
                            className="form__field"
                            {...register('gender', { required: { value: true, message: 'Gender is required' } })}
                            // value={gender}
                            // onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {errors.gender && <div className="form__error">{errors.gender.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="durarion">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Age</span>
                        </div>
                        <div className="col-12">
                          <DatePicker
                            selected={age}
                            onChange={(date) => setAge(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form__field"
                          />
                          {dateError && <div className="form__error">{dateError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Type of user</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="role"
                            name="role"
                            {...register('role', { required: { value: true, message: 'Type of user is required' } })}
                          >
                            {userRole === 'admin' || userRole === 'regional_pi' || userRole === 'ea_pi' ? (
                              <>
                                <option value="farmer">Farmer</option>
                                <option value="MCC Representative">MCC Representative</option>
                                <option value="HP Representative">HP Representative</option>
                                <option value="HPC Farmer">HPC Farmer</option>
                                <option value="employee">Employee</option>
                                <option value="trader">Trader</option>
                              </>
                            ) : (
                              <>
                                <option value="hnl-employee">HNL Employee</option>
                                <option value="hnl-distributor">HNL Distributor</option>
                              </>
                            )}

                          </select>
                          {errors.role && <div className="form__error">{errors.role.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Registration step</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="steps"
                            name="steps"
                            {...register('steps')}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="complete">complete</option>
                          </select>
                          {errors.steps && <div className="form__error">{errors.steps.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditUserModal;
