/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './MultiSelectDropdown.css';

const MultiSelectDropdown = ({ options, onSelectedChange, label }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleCheckboxChange = (option) => {
    const isSelected = selectedOptions.includes(option);
    let updatedOptions;

    if (isSelected) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    // toggleDropdown();
  };
  const handleOnSelectAll = () => {
    setSelectedOptions(options);
  };
  const handleOnClearAll = () => {
    setSelectedOptions([]);
    setFilteredResults(options);
  };
  const handleRemoveOption = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== '') {
      const filteredData = options?.filter((item) => Object?.values(item)
        .join('')
        .toLowerCase()
        .includes(searchInput?.toLowerCase()));
      setFilteredResults(filteredData);
      setDropdownOpen(true);
    } else {
      setFilteredResults(options);
    }
  };
  const renderSelectedItems = () => {
    if (selectedOptions.length === 0) {
      return (
        <div className="d-flex">
          <FontAwesomeIcon icon={faSearch} color="#bebebe" size="1x" onClick={toggleDropdown} />
          <input
            type="text"
            placeholder={label}
            className="multiselect-input"
            onChange={(e) => searchItems(e.target.value)}
          />
        </div>
      );
    }

    return selectedOptions.map((option, index) => (
      <span key={index.toString()} className="selected-item">
        {option.label}
        <button
          className="remove-button"
          type="button"
          onClick={() => handleRemoveOption(option)}
        >
          <FontAwesomeIcon icon={faTimes} color="white" />
        </button>
      </span>
    ));
  };
  useEffect(() => {
    if (Array?.isArray(selectedOptions) && selectedOptions?.length > 0) {
      onSelectedChange(selectedOptions);
    } else {
      onSelectedChange([]);
    }
  }, [selectedOptions]);
  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <div className="dropdown-selected">{renderSelectedItems()}</div>
        <div className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`}>
          <FontAwesomeIcon icon={faCaretDown} color="black" />
        </div>
      </div>
      {dropdownOpen && (
        <ul className="dropdown-options">
          {searchInput.length > 1
            ? filteredResults?.map((option, index) => (
              <li
                key={index.toString()}
                className="dropdown-option"
                onClick={() => handleCheckboxChange(option)}
              >
                <label htmlFor="multSelect">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option.label}
                </label>
              </li>
            ))
            : options.map((option, index) => (
              <li
                key={index.toString()}
                className="dropdown-option"
                onClick={() => handleCheckboxChange(option)}
              >
                <label htmlFor="multSelect">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option.label}
                </label>
              </li>
            ))}
          <div className="row between">
            <button
              type="button"
              onClick={handleOnSelectAll}
              className="selectAll"
            >
              Select All
            </button>
            <button
              type="button"
              onClick={handleOnClearAll}
              className="clearAll"
            >
              Clear All
            </button>
          </div>
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
