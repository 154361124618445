/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import './ModalDashboardFilter.scss';
import moment from 'moment';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import Calendar from '../../assets/animations/Calendar.json';

const ModalForFilter = ({
  show, toggle, onSubmit, values,
}) => {
  const [fromDate, setfromDate] = useState(
    values ? new Date(values?.fromDate) : new Date(),
  );
  const [selected, setselected] = useState(null);

  const [toDate, setToDate] = useState(
    values ? new Date(values?.toDate) : new Date(),
  );
  const [showDateRange, setshowDateRange] = useState(false);
  const arrayOfOptions = [
    {
      id: 0,
      label: 'Year Till Date',
      action: moment(new Date()).startOf('year').add(3, 'month'),
    },
    {
      id: 1,
      label: 'Month Till Date',
      action: moment(new Date()).startOf('month'),
    },
    {
      id: 2,
      label: 'Date Range',
      action: true,
    },
  ];
  const [dateError, setDate] = useState(null);
  const showHideClassName = show
    ? 'edit-user-modal display-block'
    : 'edit-user-modal display-none';
  const { handleSubmit } = useForm();
  const submit = () => {
    toggle();
    onSubmit({
      fromDate,
      toDate,
    });
  };
  const handleOnSelected = (item) => {
    if (item?.id === 0 || item?.id === 1) {
      setshowDateRange(false);
      setfromDate(item?.action);
      toast.info(`${item?.label} selected`);
    } else if (item?.id === 2) {
      setfromDate(new Date());
      setshowDateRange(item?.action);
      toast.info(`${item?.label} enabled`);
    }
    setselected(item);
  };
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button
              type="button"
              className="modal-main__button"
              onClick={toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="col-8 d-flex end">
              <span className="form__label22">Filter Using Dates</span>
            </div>
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12">
                        <span className="form__label">Filter By</span>
                      </div>
                      <div className="col-12">
                        {arrayOfOptions?.map((item, index) => (
                          <label key={item.id} htmlFor={item.id}>
                            <div className="row">
                              <div className="col-1 d-flex center">
                                <input
                                  className="filterQuery"
                                  type="radio"
                                  id={item.id}
                                  checked={
                                    selected && selected.id === item.id
                                  }
                                  name={item.label}
                                  onChange={() => handleOnSelected(item)}
                                />
                              </div>
                              <div className="col-11 d-flex-col">
                                <span className="form__label14">{item.label}</span>
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {showDateRange ? (
                  <div className="col-12 col-md-6">
                    <div className="form__item">
                      <label htmlFor="durarion">
                        <div className="row middle">
                          <div className="col-12">
                            <span className="form__label">From Date</span>
                          </div>
                          <div className="col-12">
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => setfromDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="form__field"
                            />
                            {dateError && (
                              <div className="form__error">{dateError}</div>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="form__item">
                      <label htmlFor="durarion">
                        <div className="row middle">
                          <div className="col-12">
                            <span className="form__label">To Date</span>
                          </div>
                          <div className="col-12">
                            <DatePicker
                              selected={toDate}
                              onChange={(date) => setToDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="form__field"
                            />
                            {dateError && (
                              <div className="form__error">{dateError}</div>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 col-md-5">
                    <div className="form__item">
                      <div className="row middle">
                        <Lottie animationData={Calendar} loop={false} className="lottie" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button
                          type="button"
                          className="form__button"
                          onClick={handleSubmit(submit)}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModalForFilter;
