// eslint-disable-next-line import/prefer-default-export
export const arrayOfRegions = [
  'RAI',
  'VIZAG',
  'NASHIK',
  'NANDED',
  'ODISHA',
  'ONGOLE',
  'SANGVI',
  'TIRUPATI',
  'NAMAKKAL',
  'KRISHNAGIRI',
  'VIJAYAWADA',
  'HYDERABAD-1',
  'HYDERABAD-2',
];

export const status = ['approved', 'pending', 'rejected'];
