/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import {
  getApprovedDoctors, searchApprovedDoctors, exportApprovedDoctors, countDoctors,
} from '../../store/modules/doctor/actions';
import {
  selectApprovedDoctors, selectCount, selectIsLoading, selectIsLoadingCount, selectIsLoadingExport,
} from '../../store/modules/doctor/selectors';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import veterinarian from '../../assets/images/veterinarian.png';
import technician from '../../assets/images/technician.png';
import pharmacy from '../../assets/images/pharmacy.png';

import './RecordedDoctors.scss';

const RecordedDoctors = () => {
  const [query, setQuery] = useState(null);
  const columns = useMemo(
    () => [
      {
        Header: 'Doctor Name',
        accessor: 'doctorName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectApprovedDoctors(state));
  const count = useSelector((state) => selectCount(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));
  const isLoadingCount = useSelector((state) => selectIsLoadingCount(state));

  useEffect(() => {
    dispatch(getApprovedDoctors());
    dispatch(countDoctors());
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: data && data.paginate && data.paginate.length
      ? data.paginate.map((item) => (
        {
          ...item,
          phone: item?.phone?.replace('+91', ''),
          age: moment(item?.age).format('DD/MM/YYYY'),
        }
      ))
      : [],
  });

  const nextPage = (page) => {
    dispatch(getApprovedDoctors(page));
  };

  const previousPage = (page) => {
    dispatch(getApprovedDoctors(page));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchApprovedDoctors(`+91${query}`));
  };

  const exportExcell = () => {
    dispatch(exportApprovedDoctors());
  };

  return (
    <>
      <div className="recorded-doctors">
        <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <img alt="Farmer" src={veterinarian} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Veterinarian
                </span>
                <span className="summary-card__value">
                  {count ? count.veterinarian : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card info mr-8">
              <div className="summary-card__left">
                <img alt="Farmer" src={veterinarian} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Veterinary Assistant
                </span>
                <span className="summary-card__value">
                  {count ? count.veterinaryAssistant : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card success mr-8">
              <div className="summary-card__left">
                <img alt="Cow" src={technician} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  AI Technician
                </span>
                <span className="summary-card__value">
                  {count ? count.AITechnician : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card green">
              <div className="summary-card__left">
                <img alt="Cow" src={pharmacy} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Pharmacy
                </span>
                <span className="summary-card__value">
                  {count ? count.pharmacy : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              <div className="table-footer">
                <div className="row middle">
                  <div className="col-12 col-md-6">
                    {/* <p className="table-footer__title">
                      {data && data.Previous
                        ? ((data.Previous.page + 1) * data.Previous.limit - data.Previous.limit) + 1
                        : 1}
                      {' '}
                      to
                      {' '}
                      {data && data.Previous
                        ? (data.Previous.page + 1) * data.Previous.limit
                        : 10}
                      {' '}
                      of 1
                    </p> */}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="table-footer__buttons">
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => previousPage(1)}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? previousPage(data.Previous.page) : {})}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          className="table-footer__button__iclon"
                        />
                      </button>
                      <div className="table-footer__label">
                        {data && data.Next
                          ? data.Next.page - 1
                          : data && data.Previous
                            ? data.Previous.page + 1
                            : 1}
                        {/* /1 */}
                      </div>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table {...getTableProps()} className="table table-bordered">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                      <th>All Details</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        <td>
                          <Link to={`/admin/doctors/${row.original.id}`} className="table__button">View</Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal show={isLoading || isLoadingExport || isLoadingCount} />
    </>
  );
};

export default RecordedDoctors;
