import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from './types';

const initialState = {
  isAuthenticated: false,
  currentUser: null,
  role: null,
  isLoadingAuth: false,
  errorMessage: null,
  handleLottieAnimation: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_START:
      return { ...state, isLoadingAuth: true };

    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: payload || null,
        isLoadingAuth: false,
        errorMessage: null,
      };

    case AUTH_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingAuth: false,
      };

    case LOGOUT_START:
      return { ...state, isLoadingAuth: true };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: null,
        isLoadingAuth: false,
        errorMessage: null,
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingAuth: false,
      };

    case UPDATE_PROFILE_START:
      return { ...state, isLoadingAuth: true };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: payload || null,
        isLoadingAuth: false,
        errorMessage: null,
      };

    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingAuth: false,
      };
    case UPDATE_PASSWORD_START:
      return { ...state, isLoadingAuth: true, handleLottieAnimation: false };

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        currentUser: payload || null,
        isLoadingAuth: false,
        errorMessage: null,
        handleLottieAnimation: true,
      };

    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingAuth: false,
        handleLottieAnimation: false,

      };

    default:
      return state;
  }
};
