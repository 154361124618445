/* eslint-disable */
export const validateAge = (dob, minAge, maxAge) => {
  const currentDate = new Date();
  const providedDate = new Date(dob); // Convert the provided dob to a Date object
  const timeDiff = currentDate - providedDate; // Difference in milliseconds
  const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365)); // Convert milliseconds to years
  if (providedDate > currentDate) {
    return "Selected date is in the future";
  }
  if (age < minAge) {
    return `Farmer must be ${minAge} years or older to enroll`;
  }
  if (age > maxAge) {
    return `Farmer must be younger than ${maxAge} years`;
  }
  return null; // Age is valid
};
// First approach basic
// export const formatCount = (value) => {
//   if (value >= 1000) {
//     return `${(value / 1000).toFixed(1)}k`;
//   } if (value >= 100) {
//     return value;
//   } if (value >= 10) {
//     return value.toString();
//   }
//   // Add leading "0" for single-digit values
//   return `0${value.toString()}`;
// };
// second approach using find()
export const formatCount = (value) => {
  const units = [
    { magnitude: 1e7, unit: "Cr" },
    { magnitude: 1e5, unit: "L" },
    { magnitude: 1e3, unit: "k" },
  ];
  /* The line `const unitObj = units.find(({ magnitude }) => value >= magnitude);` is using the
  `find()` method to search for an object in the `units` array that satisfies the condition `value
  >= magnitude`. */
  const unitObj = units.find(({ magnitude }) => value >= magnitude);

  if (unitObj) {
    const formattedValue = (value / unitObj.magnitude).toFixed(1);
    return `${formattedValue}${unitObj.unit}`;
  }

  return value.toString().padStart(2, "0");
};
// Third approach using a forLoop
// export const formatCount = (value) => {
//   const units = [
//     { magnitude: 1e7, unit: 'Cr' },
//     { magnitude: 1e5, unit: 'L' },
//     { magnitude: 1e3, unit: 'k' },
//   ];

//   for (const unit of units) {
//     if (value >= unit.magnitude) {
//       return `${(value / unit.magnitude).toFixed(1)}${unit.unit}`;
//     }
//   }

//   return value.toString().padStart(2, '0');
// };

export const isValidIndianPhoneNumber = (phoneNumber) => {
  // Regular expressions to match common Indian phone number formats
  const patterns = [
    /^\d{10}$/,               // 10-digit mobile number
    /^(\+91|0)?[6789]\d{9}$/  // 10-digit mobile number with optional +91 or 0 prefix
  ];

  // Check if the string matches any of the patterns
  for (const pattern of patterns) {
    if (pattern.test(phoneNumber)) {
      return true;
    }
  }

  return false;
}
export const isValidName = (name) => {
  // Regular expression to match a basic name pattern
  const pattern = /^[A-Za-z\s'-]+$/;
  
  // Use the test() method to check if the string matches the pattern
  return pattern.test(name);
}

export const isNotNullUndefinedOrEmpty = (value) => {
  return value !== null && value !== undefined && value !== '';
};