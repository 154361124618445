/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  // faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import { getFollowUpAppointments, getUpcomingAppointments, searchUpcomingAppointments } from '../../store/modules/appointment/actions';
import {
  selectUpcomingAppointments, selectFolloUpAppointments, selectIsLoading,
} from '../../store/modules/appointment/selectors';
import './Upcoming.scss';

const Upcoming = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const upcoming = useSelector((state) => selectUpcomingAppointments(state));
  const followUp = useSelector((state) => selectFolloUpAppointments(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(getFollowUpAppointments());
    } else {
      dispatch(getUpcomingAppointments());
    }
  }, [tabIndex]);

  // useEffect(() => {
  //   dispatch(getUpcomingAppointments());
  // }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchUpcomingAppointments(`+91${query}`));
  };

  const nextPage = (page) => {
    dispatch(getUpcomingAppointments(page));
  };

  const previousPage = (page) => {
    dispatch(getUpcomingAppointments(page));
  };

  const nextFollowUpPage = (page) => {
    dispatch(getFollowUpAppointments(page));
  };

  const previousFollowUpPage = (page) => {
    dispatch(getFollowUpAppointments(page));
  };

  return (
    <>
      <div className="upcoming">
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                First Response
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Follow-Ups
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            <div className="row middle">
              <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div>
              {/* <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green">
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div> */}
            </div>
            <div className="row">
              {upcoming && upcoming.paginate && upcoming.paginate.length
                ? upcoming.paginate.map((appointment) => (
                  <div key={appointment.id} className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card__left">
                        <div className="card__avatar">
                          <img src={appointment.Farmer ? appointment.Farmer.profilePicture : ''} alt="user" />
                        </div>
                        <div className="card__text">
                          <div>
                            <span className="card__title">
                              Farmer Name :
                              {' '}
                            </span>
                            {appointment.Farmer ? appointment.Farmer.farmerName : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              Phone :
                              {' '}
                            </span>
                            {appointment.Farmer ? appointment.Farmer.phone.replace('+91', '') : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              Telephonic Consultancy Date :
                              {' '}
                            </span>
                            {moment(appointment.appointmentDate).format('DD/MM/YYYY')}
                          </div>
                          <div>
                            <span className="card__title">
                              Telephonic Consultancy Time :
                              {' '}
                            </span>
                            {appointment.appointmentStartTime}
                          </div>
                        </div>
                      </div>
                      <div className="card__right">
                        <Link to={`/doctor/appointments/${appointment.id}`} className="card__button">View</Link>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  null
                )}
              {!isLoading && !upcoming && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No upcoming appointments found</span>
                </div>
              </div>
              )}
            </div>
            {upcoming
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Previous}
                        onClick={() => previousPage(upcoming.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {upcoming.Next
                          ? upcoming.Next.page - 1
                          : upcoming.Previous
                            ? upcoming.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Next}
                        onClick={() => nextPage(upcoming.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            {/* <div className="row middle">
              <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green">
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div> */}
            <div className="row">
              {followUp && followUp.paginate && followUp.paginate.length
                ? followUp.paginate.map((appointment) => (
                  <div key={appointment.id} className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card__left">
                        <div className="card__avatar">
                          <img
                            src={appointment.Farmer
                              ? appointment.Farmer.profilePicture : ''}
                            alt="user"
                          />
                        </div>
                        <div className="card__text">
                          <div>
                            <span className="card__title">
                              Farmer Name :
                              {' '}
                            </span>
                            {appointment.Farmer ? appointment.Farmer.farmerName : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              Phone :
                              {' '}
                            </span>
                            {appointment.Farmer ? appointment.Farmer.phone.replace('+91', '') : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              Telephonic Consultancy Date :
                              {' '}
                            </span>
                            {moment(appointment.appointmentDate).format('DD/MM/YYYY')}
                          </div>
                          <div>
                            <span className="card__title">
                              Telephonic Consultancy Time :
                              {' '}
                            </span>
                            {appointment.appointmentStartTime}
                          </div>
                        </div>
                      </div>
                      <div className="card__right">
                        <div
                          className="card__text"
                        >
                          {/* <span
                            className="card__title"
                          >
                            Time left :
                            {' '}
                          </span>
                          {appointment.status} */}
                        </div>
                        <Link
                          to={`/doctor/appointments/${appointment.id}`}
                          className="card__button"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  null
                )}
              {!isLoading && !followUp && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No Follow-Up appointments found</span>
                </div>
              </div>
              )}
            </div>
            {followUp
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!followUp.Previous}
                        onClick={() => previousFollowUpPage(followUp.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {followUp.Next
                          ? followUp.Next.page - 1
                          : followUp.Previous
                            ? followUp.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!followUp.Next}
                        onClick={() => nextFollowUpPage(followUp.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default Upcoming;
