/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faFileExcel,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faFileUpload,
  faFilter,
  faCalendarCheck,
  faChartColumn,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import Lottie from 'lottie-react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import NotFound from '../../assets/animations/notfound.json';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  filterPermanentGPACertificatesUsingDate,
  getAllPermanentGPACertificates,
  searchPermanentGPACertificates,
  uploadFile,
  countGpaCertificates,
  exportGPACertificates,
} from '../../store/modules/certificates/actions';
import {
  selectPermanentData, selectIsLoading, selectisLoadingExport, selectCertificateCount,
} from '../../store/modules/certificates/selectors';
import withScreenRefreshDetection from '../../utils/withScreenRefreshDetection';
import Each from '../../components/Each';
import CountUp from '../../components/CountUp';
import './PermanentGPACertificates.scss';

const PermanentGPACertificates = () => {
  const [user, setUser] = useState(null);
  const [query, setQuery] = useState(null);
  const [pageNum, setpageNum] = useState(1);
  const [storePageSizeValue, setstorePageSizeValue] = useState(10);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const role = localStorage.getItem('role');
  const columns = useMemo(
    () => [
      {
        Header: 'Farmer ID',
        accessor: 'id',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Farmer Name',
        accessor: 'farmerName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'YOB',
        accessor: 'yearOfBirth',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Mobile',
        accessor: ({ mobileNumber }) => mobileNumber?.slice(-10),
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Aadhar Card No',
        accessor: 'aadhaarCardNo',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Nominee Name',
        accessor: 'nomineeName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Enrollment ID',
        accessor: 'enrollmentID',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Type',
        accessor: 'mccOrHpc',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Enrolled On',
        accessor: 'createdAt',
        Cell: ({ value }) => (value !== null ? <p>{moment(value).format('MMM-YYYY')}</p> : <p>N/A</p>),
      },
      {
        Header: 'Expiry by',
        accessor: 'createdAt',
        id: 'expiryDate', // Unique identifier for this column
        Cell: ({ value }) => (value !== null ? <p>{moment(value).endOf('year').format('MMM-YYYY')}</p> : <p>N/A</p>),
      },
      // {
      //   Header: 'GPA Download',
      //   accessor: 'gpaUrl',
      //   Cell: ({ value }) => (value !== null ? <a href={value} className="table__button">Download</a>
      //     : <p> Not Available</p>),
      // },

    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectPermanentData(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const count = useSelector((state) => selectCertificateCount(state));
  const isLoadingExport = useSelector((state) => selectisLoadingExport(state));
  useEffect(() => {
    const values = {
      pageSize: pageNum,
      limit: storePageSizeValue,
      ...(user !== null && { type: user }),
    };
    dispatch(getAllPermanentGPACertificates(values));
    const countStatus = {
      status: 'permanent',
    };
    dispatch(countGpaCertificates(countStatus));
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: data ? data.paginate.map((item) => (
      {
        ...item,
      }
    )) : [],
  });

  const nextPage = (page) => {
    setpageNum(page);
    const values = {
      pageSize: page,
      limit: storePageSizeValue,
      ...(user !== null && { type: user }),
    };
    dispatch(getAllPermanentGPACertificates(values));
  };

  const previousPage = (page) => {
    setpageNum(page);
    const values = {
      pageSize: page,
      limit: storePageSizeValue,
      ...(user !== null && { type: user }),
    };
    dispatch(getAllPermanentGPACertificates(values));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    const values = {
      phone: `${query}`,
      pageSize: pageNum,
      limit: storePageSizeValue,
    };
    dispatch(searchPermanentGPACertificates(values));
  };
  const exportExcell = () => {
    const values = {
      startDate: dates?.startDate,
      endDate: dates?.endDate,
      flag: 'permanent',
    };
    dispatch(exportGPACertificates(values));
  };
  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);
  const arrayOfUsers = ['HPC', 'MCC'];
  const handleOnPageSizeSelected = (event) => {
    setstorePageSizeValue(parseInt(event?.target?.value));
    const values = {
      pageSize: pageNum,
      limit: `${event?.target?.value}`,
      ...(user !== null && { type: user }),
    };
    dispatch(getAllPermanentGPACertificates(values));
  };
  const handleOnTypeSelected = (event) => {
    setUser(event?.target?.value);
    const values = {
      pageSize: pageNum,
      limit: storePageSizeValue,
      type: event?.target?.value,
    };
    dispatch(getAllPermanentGPACertificates(values));
  };
  const handleUploadExcelFile = async () => {
    try {
      const { value: file } = await Swal.fire({
        icon: 'info',
        title: 'Upload File',
        input: 'file',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        html: '<div><p>To download a sample Excel format, click the link below.</p><a href="https://heritage-vet-plus-server-buckets.s3.ap-south-1.amazonaws.com/sample/GPA+Data+2023+Format.xlsx">Download</a></div>',
        inputAttributes: {
          accept: '.xls, .xlsx, .csv',
          'aria-label': 'Upload your file',
        },
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose a file';
          }
        },
      });

      if (file) {
        const fileSize = file.size;
        const maxFileSize = 50 * 1024 * 1024; // 50MB limit
        if (fileSize > maxFileSize) {
          throw new Error(`File size exceeds ${maxFileSize / (1024 * 1024)}MB limit`);
        }
        if (!['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'].includes(file.type)) {
          throw new Error('Invalid file format');
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          let timerInterval;
          const fileSizeInMb = fileSize / (1024 * 1024);
          const uploadTimeInSeconds = Math.ceil(fileSizeInMb); // 1 second per 1MB
          Swal.fire({
            icon: 'success',
            title: 'Uploading your data!',
            html: `Uploading ${fileSizeInMb.toFixed(2)} MB file in <b>${uploadTimeInSeconds} seconds</b>.`,
            timer: uploadTimeInSeconds * 1000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        };
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append('file', file);
        dispatch(uploadFile(formData));
      } else {
        throw new Error('No file was uploaded.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
    }
  };
  const showFilterModal = () => {
    // Throw an error if Swal library is not available
    if (typeof Swal !== 'function') {
      throw new Error('Swal library not found. Please include it and try again.');
    }

    // Local state object to store the fromDate and toDate values
    const state = {
      fromDate: null,
      toDate: null,
    };

    const updateFilterButton = () => {
      const filterButton = Swal.getConfirmButton();
      if (filterButton) {
        filterButton.disabled = !(state.fromDate && state.toDate);
      }
    };

    const swapDatesIfNeeded = () => {
      if (state.fromDate && state.toDate && state.fromDate.getTime() > state.toDate.getTime()) {
        [state.fromDate, state.toDate] = [state.toDate, state.fromDate];
      }
    };

    const setFromDate = (newFromDate) => {
      if (newFromDate instanceof Date && !isNaN(newFromDate)) {
        state.fromDate = newFromDate;
        setDates((prevState) => ({
          ...prevState,
          startDate: moment(newFromDate).format('DD/MM/YYYY'), // or false  as you wiss
        }));
        swapDatesIfNeeded();
        updateFilterButton();
      } else {
        toast.error('Invalid value for fromDate.');
      }
    };

    const setToDate = (newToDate) => {
      if (newToDate instanceof Date && !isNaN(newToDate)) {
        state.toDate = newToDate;
        setDates((prevState) => ({
          ...prevState,
          endDate: moment(newToDate).format('DD/MM/YYYY'), // or false  as you wiss
        }));
        swapDatesIfNeeded();
        updateFilterButton();
      } else {
        toast.error('Invalid value for toDate.');
      }
    };

    const filterPermanentDate = () => {
      const values = {
        pageSize: pageNum,
        limit: storePageSizeValue,
        startDate: moment(state.fromDate).format('DD/MM/YYYY'),
        endDate: moment(state.toDate).format('DD/MM/YYYY'),
      };
      dispatch(filterPermanentGPACertificatesUsingDate(values));
    };

    Swal.fire({
      title: 'Filter Information by Date',
      html: `
      <html>
      <head>
        <style>
          body {
            background-color: #f5f5f5;
            font-family: Arial, sans-serif;
          }
          
          .date-picker {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            border-radius: 5px;
            padding: 20px;
            width: 400px;
            margin: auto;
          }
          
          .form-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          }
          
          .input-label {
            font-weight: bold;
            margin-right: 10px;
            color: #444;
          }
          
          .form-field {
            border-radius: 5px;
            border: none;
            padding: 10px;
            background-color: #f5f5f5;
            color: #666;
            font-size: 16px;
            width: 180px;
          }
          
          .form-field:focus {
            outline: none;
            border: 2px solid #0077cc;
          }
          
          .form-field::-webkit-calendar-picker-indicator {
            color: #999;
          }
        </style>
      </head>
      
      <body>
        <div class="date-picker">
          <div class="form-row">
            <div class="input-label">
              <label for="fromDate">From Date:</label>
            </div>
            <div class="input-field">
              <input type="date" id="fromDate" name="fromDate" class="form-field" />
            </div>
          </div>
          
          <div class="form-row">
            <div class="input-label">
              <label for="toDate">End Date: </label>
            </div>
            <div class="input-field">
              <input type="date" id="toDate" name="toDate" class="form-field" />
            </div>
          </div>
        </div>
      </body>
    </html>
    
      `,
      showCancelButton: true,
      confirmButtonText: 'Filter',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      willOpen: (modal) => {
        const fromInput = modal.querySelector('input[name="fromDate"]');
        const toInput = modal.querySelector('input[name="toDate"]');

        // Set the input field values based on the current state values
        fromInput.value = state.fromDate ? state.fromDate.toISOString().substring(0, 10) : null;
        toInput.value = state.toDate ? state.toDate.toISOString().substring(0, 10) : null;

        // Attach event listeners to update the state
        fromInput.addEventListener('input', () => setFromDate(new Date(fromInput.value)));
        toInput.addEventListener('input', () => setToDate(new Date(toInput.value)));
      },
      preConfirm: () => {
        if (!(state.fromDate && state.toDate)) {
          Swal.showValidationMessage('Please select both from date and End date.');
        } else {
          filterPermanentDate();
        }
      },
    });
  };
  const showSwalAlert = (values) => {
    let html = '<div style="width: 100%;height: 250px;overflow-y: auto;text-align: center;padding: 20px;"><table style="border-collapse: collapse;width:100%"><thead><tr><th style="text-align: left;padding: 8px;">Id</th><th style="text-align: left;padding: 8px;">Region Name</th><th style="text-align: left;padding: 8px;">Pending</th><th style="text-align: left;padding: 8px;">Approved</th><th style="text-align: left;padding: 8px;">Rejected</th></tr></thead><tbody>';
    if (values?.data?.regionData && values?.data?.regionData?.length > 0) {
      values.data.regionData.forEach((item, index) => {
        const bgColor = index % 2 === 0 ? '' : '#D6EEEE';
        html += `<tr style="background-color: ${bgColor};"><td style="text-align: left;padding: 8px;">${index + 1}</td><td style="text-align: left;padding: 8px;">${item.regionName || ''}</td><td style="text-align: center;padding: 8px;">${item?.pendingCount ?? '0'}</td><td style="text-align: center;padding: 8px;">${item?.completedRequestsCount ?? '0'}</td><td style="text-align: center;padding: 8px;">${item?.rejectedCount ?? '0'}</td></tr>`;
      });
    } else {
      html += '<tr><td colspan="4" style="text-align: center;padding: 8px;">No data found</td></tr>';
    }

    html += '</tbody></table></div>';

    Swal.fire({
      title: values?.label,
      html,
    });
  };
  const arrayOfStatusCards = [
    {
      id: 0,
      name: 'HPC Farmers',
      count: count?.hpcFarmersCertificate,
      color: 'warning',
      icon: faUsers,
    },
    {
      id: 1,
      name: 'MCC Farmers',
      count: count?.mccFarmersCertificate,
      color: 'green',
      icon: faUsers,
    },
    {
      id: 2,
      name: 'Proposed in current month',
      count: count?.proposedInCurrentMonth,
      color: 'danger',
      icon: faCalendarCheck,
    },
    {
      id: 3,
      name: 'Cummulative',
      count: count?.cummulativeCertificates,
      color: 'purpleColor',
      icon: faChartColumn,
    },
    {
      id: 4,
      name: 'Month Wise HPC Farmers',
      count: count?.monthlyHpcFarmers,
      color: 'tealColor',
      icon: faCalendarAlt,
    },
  ];

  const addDynamicActions = (cards, showAlertFunction) => cards.map((card) => ({
    ...card,
    action: card?.count?.regionData ? () => showAlertFunction({
      label: `Region-specific GPA Enrolled users - ${card?.name}`,
      data: card?.count,
    }) : undefined,
  }));
  const modifiedArrayWithActions = addDynamicActions(arrayOfStatusCards, showSwalAlert);

  return (
    <>
      <div className="permanent-certifcates">
        <div className="row middle">
          <Each
            of={modifiedArrayWithActions}
            render={(item, index) => (
              <div key={index} className="col-12 col-md-3">
                <div className={`summary-card ${item?.color} mr-8`} role="button" onClick={item?.action}>
                  <div className="summary-card__left">
                    <FontAwesomeIcon icon={item?.icon} className="summary-card__icon" />
                  </div>
                  <div className="summary-card__right">
                    <span className="summary-card__label">
                      {item?.name}
                    </span>

                    <span className="summary-card__value">
                      <CountUp start={0} end={item?.count?.count ?? item?.count ?? 0} duration={3} />
                    </span>
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button success" onClick={showFilterModal}>
                <FontAwesomeIcon icon={faFilter} className="button__icon" />
                Filter
              </button>
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
              <button type="button" className="button primary" onClick={handleUploadExcelFile}>
                <FontAwesomeIcon icon={faFileUpload} className="button__icon" />
                Upload
              </button>
            </div>
          </div>
        </div>
        {data && data?.paginate && data?.paginate?.length ? (
          <div className="table-container">
            <div className="row">
              <div className="col-12">
                <div className="table-footer">
                  <div className="row middle">
                    <div className="col-12 col-md-6">
                      <span className="table-footer__title">
                        {data && data.Previous
                          ? (data.Previous.page + 1) * data.Previous.limit
                          - data.Previous.limit
                          + 1
                          : 1}
                        {' '}
                        to
                        {' '}
                        {data && data.Previous
                          ? (data.Previous.page + 1) * data.Previous.limit
                          : storePageSizeValue}
                        {' '}
                        of 1
                        {' '}
                        {''}
                        <span className="form__label">Page Size : </span>
                        <select onChange={handleOnPageSizeSelected} className="dropdown-menu">
                          <Each
                            of={pageSize}
                            render={((item, index) => (
                              <option key={index.toString()} value={item} className="dropdown-menu">
                                {item}
                              </option>
                            ))}
                          />
                        </select>
                      </span>
                      <span className="table-footer__title">
                        <span className="form__label">
                          {' '}
                          {' '}
                          Choose User Type:
                          {' '}
                        </span>
                        <select onChange={handleOnTypeSelected} className="dropdown-menu">
                          <option value="" hidden>Select user type</option>
                          <Each
                            of={arrayOfUsers}
                            render={((item, index) => (
                              <option key={index.toString()} value={item} className="dropdown-menu">
                                {item}
                              </option>
                            ))}
                          />
                        </select>
                      </span>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="table-footer__buttons">
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => previousPage(1)}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? previousPage(data.Previous.page) : {})}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className="table-footer__button__iclon"
                          />
                        </button>
                        <div className="table-footer__label">
                          {data && data.Next
                            ? data.Next.page - 1
                            : data && data.Previous
                              ? data.Previous.page + 1
                              : 1}
                          {/* /1 */}
                        </div>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row?.cells?.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
        {!isLoading && !data && (
        <div className="col-12 col-lg-12 d-flex-col middle">
          <div className="notFound">
            <Lottie animationData={NotFound} loop={false} className="notFound__icon" />
            <span className="notFound__text">No Permanent GPA Certificates found</span>
          </div>
        </div>
        )}
      </div>
      <LoadingModal show={isLoading || isLoadingExport} />
    </>
  );
};

export default withScreenRefreshDetection(PermanentGPACertificates);
