import { createSelector } from 'reselect';

export const selectRaw = (state) => state.user;

export const selectUser = createSelector(
  [selectRaw],
  (user) => user.user,
);

export const selectPendingUsers = createSelector(
  [selectRaw],
  (user) => user.pending,
);

export const selectApprovedUsers = createSelector(
  [selectRaw],
  (user) => user.approved,
);

export const selectEmployees = createSelector(
  [selectRaw],
  (user) => user.employees,
);

export const selectUsers = createSelector(
  [selectPendingUsers, selectApprovedUsers],
  (pending, approved) => ({
    pending: pending || null,
    approved: approved || null,
  }),
);

export const selectCount = createSelector(
  [selectRaw],
  (user) => user.count,
);

export const selectMilkSlipsStats = createSelector(
  [selectRaw],
  (user) => user.milkSlipsStats,
);

export const selectMilkSlipsReport = createSelector(
  [selectRaw],
  (user) => user.milkSlipsReport,
);

export const selectAllUsers = createSelector(
  [selectRaw],
  (user) => user.users,
);

export const selectMccRep = createSelector(
  [selectRaw],
  (user) => user.mccRep,
);

export const selectPlant = createSelector(
  [selectRaw],
  (user) => user.plant,
);

export const selectDiscontinued = createSelector(
  [selectRaw],
  (user) => user.discontinued,
);

export const selectMessage = createSelector(
  [selectRaw],
  (user) => user.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (user) => user.isLoading,
);

export const selectIsLoadingApprove = createSelector(
  [selectRaw],
  (user) => user.isLoadingApprove,
);

export const selectIsLoadingReject = createSelector(
  [selectRaw],
  (user) => user.isLoadingReject,
);

export const selectIsLoadingExport = createSelector(
  [selectRaw],
  (user) => user.isLoadingExport,
);

export const selectIsLoadingCount = createSelector(
  [selectRaw],
  (user) => user.isLoadingCount,
);

export const selectIsLoadingEmployees = createSelector(
  [selectRaw],
  (user) => user.isLoadingEmployees,
);

export const selectIsLoadingSlips = createSelector(
  [selectRaw],
  (user) => user.isLoadingSlips,
);

export const selectIsLoadingUsers = createSelector(
  [selectRaw],
  (user) => user.isLoadingUsers,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (user) => user.errorMessage,
);

const selectors = {
  selectRaw,
  selectUser,
  selectPendingUsers,
  selectApprovedUsers,
  selectEmployees,
  selectUsers,
  selectCount,
  selectMilkSlipsStats,
  selectMilkSlipsReport,
  selectAllUsers,
  selectMccRep,
  selectPlant,
  selectDiscontinued,
  selectMessage,
  selectIsLoading,
  selectIsLoadingApprove,
  selectIsLoadingReject,
  selectIsLoadingExport,
  selectIsLoadingCount,
  selectIsLoadingEmployees,
  selectIsLoadingSlips,
  selectIsLoadingUsers,
  selectErrorMessage,
};

export default selectors;
