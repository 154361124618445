import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
// export const register = (values) => async (dispatch) => {
//   try {
//     dispatch({ type: AUTH_START });

//     const res = await Api.post('/auth/client-register', values);
//     // Api.get(
//     //   'https://maps.googleapis.com/maps/api/geocode/json?address=LosAngeles,usa',
//     // );

//     dispatch({
//       type: AUTH_SUCCESS,
//       payload: res.data,
//     });
//   } catch (error) {
//     dispatch({
//       type: AUTH_ERROR,
//       payload: ApiErrorHandler.selectMessage(error),
//     });
//   }
// };

export const loginAdmin = (values, cb) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await HttpService.post('/auth/login-admin', values);
    if (res) {
      Swal.fire(
        {
          title: 'Success!',
          text: `Greetings, ${res?.data?.admin?.adminName ?? 'User'} You have logged in successfully.`,
          icon: 'success',
          timer: 1500,
          toast: true,
        },
      );
      StoreService.setToken(res.data.session);
      localStorage.setItem('role', res.data.admin.role);
      localStorage.setItem('userName', res.data.admin.adminName);
      localStorage.setItem('regionName', res.data.admin.regionName);
      localStorage.setItem('profilePicture', res.data.admin.profilePicture);
      dispatch({
        type: AUTH_SUCCESS,
        payload: res.data.admin,
      });
      cb();
    }
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const loginDoctor = (values, cb) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });

    const res = await HttpService.post('/auth/login-doctor', values);
    StoreService.setToken(res.data.session);
    localStorage.setItem('role', 'doctor');
    localStorage.setItem('userName', res.data.doctor.doctorName);
    localStorage.setItem('specialization', res.data.doctor.specialization);
    localStorage.setItem('regionName', res.data.doctor.regionName);
    localStorage.setItem('profilePicture', res.data.doctor.profilePicture);
    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data.doctor,
    });
    cb();
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const loginPlantAdmin = (values, cb) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });

    const res = await HttpService.post('/auth/login-plant', values);

    StoreService.setToken(res.data.session);
    localStorage.setItem('role', 'plant-admin');
    localStorage.setItem('userName', res.data.plant.plantName);
    localStorage.setItem('regionName', res.data.plant.regionName);
    localStorage.setItem('profilePicture', res.data.plant.profilePicture);
    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data.plant,
    });
    cb();
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const logout = (cb) => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_START });

    StoreService.removeToken();
    localStorage.clear();
    // localStorage.removeItem('role');
    // localStorage.removeItem('userName');
    // localStorage.removeItem('specialization');
    // localStorage.removeItem('regionName');
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    cb();
  } catch (error) {
    dispatch({
      type: LOGOUT_ERROR,
      payload: ApiErrorHandler.selectMessage(error) || error,
    });
  }
};

export const updateProfile = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_START });

    const res = await HttpService.patch('/doctor/upate-doctor', values);

    localStorage.setItem('userName', res.data.doctorName);
    localStorage.setItem('specialization', res.data.specialization);
    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data,
    });
    toast.success('Profile updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updatePassword = (values, location, history) => async (dispatch) => {
  const { from } = location.state || { from: { pathname: '/' } };
  try {
    dispatch({ type: UPDATE_PASSWORD_START });
    const response = await HttpService.patch('/admin/reset-password', values);
    if (response?.status === 201) {
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: response?.data,
      });
      toast.success('Profile updated successfully');
      Swal.fire({
        title: 'Your password has been changed succesfully!',
        text: 'Please use your new password, to login again.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Logout!',
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(logout(() => {
            history.replace(from);
          }));
          Swal.fire(
            {
              title: 'Logout!',
              text: 'You have successfully logged out',
              icon: 'success',
              timer: 2000,
              toast: true,
            },
          );
        }
        if (result.isDismissed) {
          Swal.fire({
            title: 'Password has been changed!',
            text: 'Please use your new password, to login again.',
            icon: 'info',
            toast: true,
          });
        }
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
