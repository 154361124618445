/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingModal from '../../components/LoadingModal';
import {
  updateProfile,
} from '../../store/modules/auth/actions';
import {
  selectIsLoadingAuth,
} from '../../store/modules/auth/selectors';

import './Profile.scss';

const Profile = () => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem('userName');
  const specialize = localStorage.getItem('specialization');
  const [name, setName] = useState(userName);
  const [specialization, setSpecialization] = useState(specialize);
  const [nameError, setNameError] = useState(null);
  const [specializationError, setSpecializationError] = useState(null);
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const history = useHistory();

  const onChangeName = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const onChangeQualification = (event) => {
    const { value } = event.target;
    setSpecialization(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!name) {
      setNameError('Name is required');
      return;
    }
    setNameError(null);

    if (!specialization) {
      setSpecializationError('Specialization is required');
      return;
    }

    dispatch(updateProfile({
      doctorName: name,
      specialization,
    }, () => {
      history.go(0);
    }));
  };

  return (
    <>
      <div className="profile">
        <div className="d-flex middle">
          <div className="heading">
            Doctor Profile
          </div>
        </div>
        <div className="form">
          <div className="form__item">
            <label htmlFor="tabletName">
              <div className="row middle">
                <div className="col-12 col-md-3">
                  <span className="form__label">Doctor Name</span>
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form__field"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Doctor Name"
                    value={name}
                    onChange={onChangeName}
                  />
                  {nameError && <div className="form__error">{nameError}</div>}
                </div>
              </div>
            </label>
          </div>
          <div className="form__item">
            <label htmlFor="frequency">
              <div className="row middle">
                <div className="col-12 col-md-3">
                  <span className="form__label">Specialization</span>
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form__field"
                    type="text"
                    id="specialization"
                    name="specialization"
                    placeholder="Specialization"
                    value={specialization}
                    onChange={onChangeQualification}
                  />
                  {specializationError && <div className="form__error">{specializationError}</div>}
                </div>
              </div>
            </label>
          </div>
          <div className="form__item">
            <div className="row">
              <div className="col-12 d-flex-col middle">
                <button type="button" className="form__button" onClick={handleSubmit}>Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default Profile;
