import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  GET_UNIT_NAME_START,
  GET_UNIT_NAME_SUCCESS,
  GET_UNIT_NAME_ERROR,
  GET_MCC_NAME_START,
  GET_MCC_NAME_SUCCESS,
  GET_MCC_NAME_ERROR,
  GET_UNIT_MCC_NAME_START,
  GET_UNIT_MCC_NAME_SUCCESS,
  GET_UNIT_MCC_NAME_ERROR,
  GET_MORE_UNIT_MCC_NAME_START,
  GET_MORE_UNIT_MCC_NAME_SUCCESS,
  GET_MORE_UNIT_MCC_NAME_ERROR,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_START,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
  CREATE_REGION_START,
  CREATE_REGION_SUCCESS,
  CREATE_REGION_ERROR,
  EXPORT_REGION_START,
  EXPORT_REGION_SUCCESS,
  EXPORT_REGION_ERROR,
  GET_YOUTUBE_VIDEOS_START,
  GET_YOUTUBE_VIDEOS_SUCCESS,
  GET_YOUTUBE_VIDEOS_ERROR,
  ADD_YOUTUBE_VIDEO_START,
  ADD_YOUTUBE_VIDEO_SUCCESS,
  ADD_YOUTUBE_VIDEO_ERROR,
  UPDATE_YOUTUBE_VIDEO_START,
  UPDATE_YOUTUBE_VIDEO_SUCCESS,
  UPDATE_YOUTUBE_VIDEO_ERROR,
  GET_HEADING_START,
  GET_HEADING_SUCCESS,
  GET_HEADING_ERROR,
  ADD_HEADING_START,
  ADD_HEADING_SUCCESS,
  ADD_HEADING_ERROR,
  UPDATE_HEADING_START,
  UPDATE_HEADING_SUCCESS,
  UPDATE_HEADING_ERROR,
  CHECK_MCC_DETAILS_START,
  CHECK_MCC_DETAILS_SUCCESS,
  CHECK_MCC_DETAILS_ERROR,
  INSERT_MCC_DETAILS_START,
  INSERT_MCC_DETAILS_SUCCESS,
  INSERT_MCC_DETAILS_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getUnitName = (regionName) => async (dispatch) => {
  try {
    dispatch({ type: GET_UNIT_NAME_START });

    const res = await HttpService.post('/cattle/select-data', {
      regionName,
    });

    dispatch({
      type: GET_UNIT_NAME_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNIT_NAME_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMccName = (regionName) => async (dispatch) => {
  try {
    dispatch({ type: GET_MCC_NAME_START });

    const res = await HttpService.post('/cattle/select-data/', {
      regionName,
    });

    dispatch({
      type: GET_MCC_NAME_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MCC_NAME_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getUnitMccName = (pinCode, page = 1, limit = 6) => async (
  dispatch,
) => {
  try {
    dispatch({ type: GET_UNIT_MCC_NAME_START });

    const res = await HttpService.post(
      `/cattle/select-data/?page=${page}&limit=${limit}`,
      {
        attribute: 'pinCode',
        value: pinCode,
      },
    );

    dispatch({
      type: GET_UNIT_MCC_NAME_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNIT_MCC_NAME_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMoreUnitMccName = (pinCode, page = 1, limit = 6) => async (
  dispatch,
) => {
  try {
    dispatch({ type: GET_MORE_UNIT_MCC_NAME_START });

    const res = await HttpService.post(
      `/cattle/select-data/?page=${page}&limit=${limit}`,
      {
        attribute: 'pinCode',
        value: pinCode,
      },
    );

    dispatch({
      type: GET_MORE_UNIT_MCC_NAME_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MORE_UNIT_MCC_NAME_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getUnitMccNameByMccCode = (mccCode, page = 1, limit = 6) => async (
  dispatch,
) => {
  try {
    dispatch({ type: GET_UNIT_MCC_NAME_BY_MCC_CODE_START });

    const res = await HttpService.post(
      `/cattle/select-data/?page=${page}&limit=${limit}`,
      {
        attribute: 'unitCode',
        value: mccCode,
      },
    );

    dispatch({
      type: GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMoreUnitMccNameByMccCode = (mccCode, page = 1, limit = 6) => async (
  dispatch,
) => {
  try {
    dispatch({ type: GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START });

    const res = await HttpService.post(
      `/cattle/select-data/?page=${page}&limit=${limit}`,
      {
        attribute: 'unitCode',
        value: mccCode,
      },
    );

    dispatch({
      type: GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const createRegion = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_REGION_START });

    const reqConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post('/admin/create-region', values, reqConfig);

    dispatch({
      type: CREATE_REGION_SUCCESS,
      payload: res.data,
    });
    toast.success('MCC Representative added successfully');
  } catch (error) {
    dispatch({
      type: CREATE_REGION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const regionsReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_REGION_START });

    // const res = await HttpService.post('admin/export-region-unit-mccname');

    // window.open(res.data, '_blank');

    const res = await axios({
      url: `${config.apiUrl}/admin/export-region-unit-mccname`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Regions-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_REGION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_REGION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getVideos = () => async (dispatch) => {
  try {
    dispatch({ type: GET_YOUTUBE_VIDEOS_START });

    const res = await HttpService.get('/admin/view-youtube-videos/true');

    dispatch({
      type: GET_YOUTUBE_VIDEOS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_YOUTUBE_VIDEOS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const addVideo = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_YOUTUBE_VIDEO_START });

    const reqConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post('/admin/save-youtube-video', values, reqConfig);

    dispatch({
      type: ADD_YOUTUBE_VIDEO_SUCCESS,
      payload: res.data,
    });
    toast.success('Video added successfully');
  } catch (error) {
    dispatch({
      type: ADD_YOUTUBE_VIDEO_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateVideo = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_YOUTUBE_VIDEO_START });

    const reqConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post(`/admin/update-youtube-video-active/${id}`, values, reqConfig);

    dispatch({
      type: UPDATE_YOUTUBE_VIDEO_SUCCESS,
      payload: res.data,
    });
    toast.success('Video updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_YOUTUBE_VIDEO_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getHeadings = () => async (dispatch) => {
  try {
    dispatch({ type: GET_HEADING_START });

    const res = await HttpService.get('/admin/view-youtube-titles');

    dispatch({
      type: GET_HEADING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_HEADING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const addHeading = (values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_HEADING_START });

    const res = await HttpService.post('/admin/save-youtube-title', values);

    dispatch({
      type: ADD_HEADING_SUCCESS,
      payload: res.data,
    });
    toast.success('Title added successfully');
  } catch (error) {
    dispatch({
      type: ADD_HEADING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateHeading = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_HEADING_START });

    const res = await HttpService.post(`/admin/update-youtube-title/${id}`, values);

    dispatch({
      type: UPDATE_HEADING_SUCCESS,
      payload: res.data,
    });
    toast.success('Title updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_HEADING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const checkMCCDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_MCC_DETAILS_START });

    const reqConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post('/admin/check-region-unit-mcc-name', data, reqConfig);

    dispatch({
      type: CHECK_MCC_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CHECK_MCC_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const insertMCCDetails = (values) => async (dispatch) => {
  try {
    dispatch({ type: INSERT_MCC_DETAILS_START });

    const res = await HttpService.post('/admin/insert-mcc-representatives', values);

    dispatch({
      type: INSERT_MCC_DETAILS_SUCCESS,
      payload: res.data,
    });
    toast.success('MCC details added successfully');
  } catch (error) {
    dispatch({
      type: INSERT_MCC_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
