/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getOrder } from '../../store/modules/order/actions';
import {
  selectOrder,
  selectIsLoading,
} from '../../store/modules/order/selectors';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import downloadImg from '../../assets/images/download.png';

import './ViewOrder.scss';

const ViewOrder = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectOrder(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getOrder(id));
  }, []);

  return (
    <>
      {!isLoading && data
        ? (
          <div className="view-order">
            <div className="row center">
              <div className="col-12 col-md-6">
                <div className="row center">
                  <div className="col-12">
                    <div className="status-card">
                      {`Current status : ${data.status === 'waiting' ? 'Waiting to be finished' : data.status}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="status-card">
                      <div className="left">
                        User ID :
                        {' '}
                        {data.ownerId}
                      </div>
                      <div className="right">
                        Order ID :
                        {' '}
                        {data.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Plant details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 col-md-6 d-flex-col">
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Plant Number</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data.Plant ? data.Plant.phone.replace('+91', '') : ''}
                              {/* {data.Plant ? data.Plant.plateCode : ''} */}

                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Plant Name</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">{data.Plant ? data.Plant.plantName : ''}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">Delivery details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Created Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.createdAt).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Delivery Time Period</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {data.deliveryTimePeriod}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Delivery Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.deliveryDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                      {data.status === 'finished' || data.status === 'rejected' ? (
                        <div className="col-12 col-md-6">
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Date' : 'Rejected Date'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Time' : 'Rejected Time'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).format('hh:mm a')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">Total Time Taken</div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).diff(data.createdAt, 'days')}
                                {' '}
                                days
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">Order details</h4>
                  <div className="content">
                    <div className="row">
                      {data.OrderDetail ? data.OrderDetail.map((orderDetail, index) => (
                        <div key={orderDetail.id} className="col-12 col-md-6 d-flex-col mb-16">
                          <div className="prod-picture">
                            <img src={orderDetail.Product ? orderDetail.Product.prodImage[0] : ''} alt="Product" />
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Item No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {index + 1}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product ID</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodId}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.Product ? orderDetail.Product.prodName : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product Price</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodPrice}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Size</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodSize}
                              </div>
                            </div>
                          </div>
                          {orderDetail.palletType
                          && (
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Pallet Type</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.palletType}
                              </div>
                            </div>
                          </div>
                          )}
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Quantity</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.quantity}
                              </div>
                            </div>
                          </div>
                          {/* <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Sub Total</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.subTotal}
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ))
                        : null}
                      {/* <div className="col-12 d-flex-col">
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Total</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data.total}
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null}
      <LoadingModal
        show={
          isLoading
        }
      />
    </>
  );
};

export default ViewOrder;
