import React from 'react';
import Lottie from 'lottie-react';
import Error from '../../assets/animations/404.json';
import './Error404.scss';

export default function Error404() {
  return (
    <div className="container">
      <Lottie animationData={Error} loop className="lottie" />
      <span className="container__label"> Oops! Something went wrong</span>
    </div>
  );
}
