/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import './ProductModal.scss';

const ProductModal = ({
  show, toggle, onSubmit, product,
}) => {
  const [size, setSize] = useState(null);
  const [palletType, setPalletType] = useState(null);
  const [qty, setQty] = useState(1);
  const showHideClassName = show ? 'product-modal display-block' : 'product-modal display-none';

  useEffect(() => {
    if (product) {
      // setValue('size', product.Quantity[0]);
      setSize(product.Quantity[0]);
    }
  }, [product]);

  useEffect(() => {
    if (size) {
      // setValue('palletType', watchSize.Pallet[0]);
      setPalletType(size.Pallet[0]);
    }
  }, [size]);

  const onSizeChange = (value) => {
    setSize(value);
  };

  const onPalletTypeChange = (value) => {
    setPalletType(value);
  };

  const increment = () => {
    const newValue = Number.isNaN(parseInt(qty, 10)) ? 1 : parseInt(qty, 10) + 1;
    setQty(newValue);
  };

  const decrement = () => {
    const newValue = qty === 1 || Number.isNaN(parseInt(qty, 10)) ? 1 : parseInt(qty, 10) - 1;
    setQty(newValue);
  };

  const onChangeQty = (evt) => {
    const newValue = parseInt(evt.target.value, 10);
    if (Number.isNaN(newValue)) {
      setQty('');
      return;
    }
    setQty(newValue);
  };

  const submit = () => {
    if (!qty) {
      toast.error('Please, enter quantity');
      return;
    }
    toggle();
    onSubmit({
      ...product,
      quantity: qty,
      size: size.quantity,
      materialCode: palletType?.materialCode ? palletType?.materialCode : '',
      palletType: palletType
        ? palletType.palletType
        : size
          ? size.Pallet[0].palletType
          : null,
      price: palletType ? palletType.price : size ? size.Pallet[0].price : 0,
      total: palletType
        ? (palletType.price * qty).toFixed(2)
        : size
          ? (size.Pallet[0].price * qty).toFixed(2)
          : 0,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12 col-md-6 d-flex-col middle">
            <div className="product-image">
              <img src={product.prodImage[0]} alt="product" />
            </div>
            <div className="d-flex-col center pr-16 pl-16">
              <h1 className="product-name">
                {product.prodName}
              </h1>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-8">
                        <span className="form__label">Size</span>
                      </div>
                      <div className="col-12 d-flex center">
                        <div className="row center">
                          {product.Quantity.map((el) => (
                            <div key={el.id} className={`form__variant-button form__variant-button--${size && el.id === size.id ? 'active' : ''}`} role="button" tabIndex="0" onClick={() => onSizeChange(el)}>
                              {el.quantity}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {product.prodCategory === 'feed' ? (
                  <div className="col-12">
                    <div className="form__item">
                      <div className="row middle">
                        <div className="col-12 mb-8">
                          <span className="form__label">Pallet Type</span>
                        </div>
                        <div className="col-12 d-flex center">
                          <div className="row center">
                            {size
                              ? size.Pallet.map((el) => (
                                <div key={el.id} className={`form__variant-button form__variant-button--${palletType && el.id === palletType.id ? 'active' : ''}`} role="button" tabIndex="0" onClick={() => onPalletTypeChange(el)}>
                                  {el.palletType}
                                </div>
                              ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="col-12">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-8">
                        <span className="form__label">Quantity</span>
                      </div>
                      <div className="col-12 d-flex center">
                        <div className="qty__wrapper">
                          <input type="text" id="quantity" className="form__field qty__num" value={qty} min="1" aria-label="quantity" pattern="[0-9]*" name="quantity" onChange={onChangeQty} />
                          <button type="button" className="qty__adjust qty__adjust--minus" aria-label="Reduce item quantity by one" onClick={decrement}>
                            <span className="icon" aria-hidden="true">−</span>
                          </button>
                          <button type="button" className="qty__adjust qty__adjust--plus" aria-label="Increase item quantity by one" onClick={increment}>
                            <span className="icont" aria-hidden="true">+</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row center">
                      <div className="col-12 col-md-4 col-lg-2">
                        <span className="form__label">Price:</span>
                      </div>
                      <div className="col-12 col-md-8 col-lg-10">
                        {product.prodCategory === 'feed'
                          ? (
                            <span className="form__label">
                              {palletType ? qty
                                ? (palletType.price * qty).toFixed(2) : palletType.price : null}
                            </span>
                          )
                          : <span className="form__label">{size ? qty ? (size.Pallet[0].price * qty).toFixed(2) : size.Pallet[0].price : null}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={submit}>Select</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductModal;
