/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useTable } from 'react-table';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  searchContinuedUsersByPhone, searchDiscontinuedUsersByPhone,
  discontinueUser, viewDiscontinuedUsers, restoreUser,
} from '../../store/modules/user/actions';
import { selectUser, selectIsLoading, selectDiscontinued } from '../../store/modules/user/selectors';

import './DiscontinueUser.scss';

const DiscontinueUser = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const role = localStorage.getItem('role');
  const [showConfrimModal, setShowConfrimModal] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: 'MCC Representative Name',
        accessor: 'mccName',
      },
      {
        Header: 'User Name',
        accessor: 'farmerName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Total Animals',
        accessor: 'totalAnimals',
      },
      {
        Header: 'Unit Code',
        accessor: 'unitCode',
      },
      {
        Header: 'Plant Code',
        accessor: 'plateCode',
      },
      {
        Header: 'Village Name',
        accessor: 'villageName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const discontinued = useSelector((state) => selectDiscontinued(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ phone: '' });

  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(viewDiscontinuedUsers());
    }
  }, [tabIndex]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: user ? [user].map((item) => (
      {
        ...item,
        phone: item.phone.replace('+91', ''),
        age: moment(item.age).format('DD/MM/YYYY'),
        totalAnimals: item.Cattle.length,
      }
    ))
      : [],
  });

  const {
    getTableProps: getTablePropsDiscontinued,
    getTableBodyProps: getTableBodyPropsDiscontinued,
    headerGroups: headerGroupsDiscontinued,
    rows: rowsDiscontinued,
    prepareRow: prepareRowDiscontinued,
  } = useTable({
    columns,
    data: discontinued ? discontinued.paginate.map((item) => (
      {
        ...item,
        phone: item.phone.replace('+91', ''),
        age: moment(item.age).format('DD/MM/YYYY'),
        totalAnimals: item.Cattle.length,
      }
    ))
      : [],
  });

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = ({ phone }) => {
    dispatch(searchContinuedUsersByPhone(`+91${phone}`));
  };

  const discontinue = (id) => {
    dispatch(discontinueUser(id));
  };

  const searchDiscontinued = () => {
    dispatch(searchDiscontinuedUsersByPhone(`+91${query}`));
  };

  const restore = (id) => {
    dispatch(restoreUser(id));
  };

  const previousPage = (page) => {
    dispatch(viewDiscontinuedUsers(page));
  };

  const nextPage = (page) => {
    dispatch(viewDiscontinuedUsers(page));
  };

  return (
    <>
      <div className="discontinue-user">
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Discontinue
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Discontinued
              </div>
            </Tab>
          </TabList>
          <TabPanel className="discontinue-user-tab-panel">
            <div className="row middle">
              <div className="col-12 col-md-6">
                <div className="form">
                  <div className="row center">
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="phone">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Enter Phone Number</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Enter Phone Number"
                                {...register('phone', {
                                  required: {
                                    value: true,
                                    message: 'Phone number is required',
                                  },
                                  minLength: {
                                    value: 10,
                                    message: 'Phone number must not be less than 10 digits',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'Phone number must not be greater than 10 digits',
                                  },
                                  pattern: {
                                    value: /^[5-9][0-9]{9}$/,
                                    message: 'Phone number must be valid',
                                  },
                                })}
                              />
                              {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <div className="row">
                          <div className="col-12 d-flex-col middle">
                            <button type="button" className="form__button" onClick={handleSubmit(search)}>Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.filter((item) => {
                          if (role === 'hnl-admin') {
                            return item?.Header === 'User Name' || item?.Header === 'Mobile' || item?.Header === 'Gender' || item?.Header === 'Age' || item?.Header === 'Total Animals';
                          }
                          return item;
                        }).map((column) => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                        <th>All Details</th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.filter((item) => {
                            if (role === 'hnl-admin') {
                              return item.value !== null;
                            }
                            return item;
                          }).map((cell) => (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                          <td>
                            <button type="button" className="table__button" onClick={() => discontinue(row.original.id)}>Discontinue</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            <div className="row middle">
              <div className="col-12 col-md-10">
                <Search value={query} onChangeText={handleChange} onSubmit={searchDiscontinued} />
              </div>
            </div>
            <div className="table-container">
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row end">
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(discontinued.Previous.page)}
                            disabled={!discontinued || !discontinued.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {discontinued && discontinued.Next
                              ? discontinued.Next.page - 1
                              : discontinued && discontinued.Previous
                                ? discontinued.Previous.page + 1
                                : 1}
                            {/* /1 */}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (discontinued ? nextPage(discontinued.Next.page) : {})}
                            disabled={!discontinued || !discontinued.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTablePropsDiscontinued()} className="table table-bordered">
                    <thead>
                      {headerGroupsDiscontinued.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.filter((item) => {
                            if (role === 'hnl-admin') {
                              return item?.Header === 'User Name' || item?.Header === 'Mobile' || item?.Header === 'Gender' || item?.Header === 'Age' || item?.Header === 'Total Animals';
                            }
                            return item;
                          }).map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                          ))}
                          <th>All Details</th>
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyPropsDiscontinued()}>
                      {rowsDiscontinued.map((row) => {
                        prepareRowDiscontinued(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.filter((item) => {
                              if (role === 'hnl-admin') {
                                return item.value !== null;
                              }
                              return item;
                            }).map((cell) => (
                              <td
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                            <td>
                              <button type="button" className="table__button primary" onClick={() => restore(row.original.id)}>Restore</button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default DiscontinueUser;
