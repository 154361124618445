import { createSelector } from 'reselect';

export const selectRaw = (state) => state?.certficates;

export const selectCertificate = createSelector([selectRaw], (state) => state?.certficates);

export const selectTemporaryData = createSelector(
  [selectRaw],
  (certificates) => certificates?.temporaryCertificates,
);

export const selectPermanentData = createSelector(
  [selectRaw],
  (certificates) => certificates?.permanentCertificates,
);

export const selectMessage = createSelector(
  [selectRaw],
  (certificates) => certificates?.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (certificates) => certificates?.isLoading,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (certificates) => certificates?.errorMessage,
);

export const selectisLoadingExport = createSelector(
  [selectRaw],
  (certficates) => certficates?.isLoadingExport,
);

export const selectCertificateCount = createSelector(
  [selectRaw],
  (certficates) => certficates?.count,
);
const selectors = {
  selectRaw,
  selectCertificate,
  selectTemporaryData,
  selectPermanentData,
  selectMessage,
  selectIsLoading,
  selectErrorMessage,
  selectisLoadingExport,
  selectCertificateCount,
};

export default selectors;
