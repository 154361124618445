/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faMinus,
  faPlus,
  faRotateLeft,
  faRotateRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ModalForImagezoom = ({ show, toggle, imageUrl }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPanning, setIsPanning] = useState(false);
  const [panStartPos, setPanStartPos] = useState({ x: 0, y: 0 });
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });

  const modalContentRef = useRef(null);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  const handleRotateLeft = () => {
    setRotationAngle((prevAngle) => prevAngle - 90);
  };

  const handleRotateRight = () => {
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

  const handleDownload = () => {
    fetch(imageUrl.link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${imageUrl?.farmerName}-${imageUrl.type}.png`,
          ); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        toast.error(err);
      });
    //   const link = document.createElement('a');
    // link.href = imageUrl;
    // link.download = 'image.jpg';
    // link.click();
  };

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      setIsPanning(true);
      setPanStartPos({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseMove = (event) => {
    if (isPanning) {
      const offsetX = event.clientX - panStartPos.x;
      const offsetY = event.clientY - panStartPos.y;
      setPanOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const modalStyles = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 9999,
    animation: 'fadeIn 1s ease-in-out',
  };

  const modalContentStyles = {
    position: 'relative',
    width: '90%',
    maxWidth: 500,
    maxHeight: '80vh',
    margin: 'auto',
    background: '#fff',
    animation: 'twist 0.5s ease-in-out',
    borderRadius: 10,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#175fad',
    background: '#f1f6f8',
    border: 'none',
    borderBottomLeftRadius: '50%',
    padding: '0.5rem',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    outline: 'none',
    cursor: 'pointer',
    zIndex: 99999,
    transition: 'all 0.3s ease',
  };

  const imageStyles = {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 'calc(80vh - 120px)',
    objectFit: 'contain',
    transition: 'transform 0.3s ease',
    transform: `scale(${zoomLevel}) rotate(${rotationAngle}deg) translate(${panOffset.x}px, ${panOffset.y}px)`,
    cursor: isPanning ? 'grabbing' : 'grab',
  };

  const controlsStyles = {
    display: 'flex',
    position: 'absolute',
    bottom: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '1rem',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const buttonStyles = {
    background: '#333',
    color: '#fff',
    border: 'none',
    borderRadius: 30,
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 1,
    ':hover': {
      background: '#555',
    },
  };

  return (
    <div className="modal" style={modalStyles}>
      <div className="modal-content" style={modalContentStyles}>
        <div>
          <button
            type="button"
            className="close-btn"
            onClick={toggle}
            style={closeButtonStyles}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <img
          src={imageUrl.link}
          alt="zoomed-in"
          style={imageStyles}
          ref={modalContentRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        <div className="modal-controls" style={controlsStyles}>
          <button type="button" style={buttonStyles} onClick={handleZoomIn}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button type="button" style={buttonStyles} onClick={handleZoomOut}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <button type="button" style={buttonStyles} onClick={handleRotateLeft}>
            <FontAwesomeIcon icon={faRotateLeft} />
          </button>
          <button
            type="button"
            style={buttonStyles}
            onClick={handleRotateRight}
          >
            <FontAwesomeIcon icon={faRotateRight} />
          </button>
          <button type="button" style={buttonStyles} onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalForImagezoom;
