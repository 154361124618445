/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPen } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import {
  getUser,
  rejectUser,
  approveUser,
  editUser,
  editLocation,
  editCattle,
  getMilkSlipsStats,
} from '../../store/modules/user/actions';
import {
  selectUser,
  selectMilkSlipsStats,
  selectMessage,
  selectIsLoading,
  selectIsLoadingApprove,
  selectIsLoadingReject,
  selectIsLoadingSlips,
} from '../../store/modules/user/selectors';
import EditUserModal from '../../components/EditUserModal';
import EditLocationModal from '../../components/EditLocationModal';
import EditCattleModal from '../../components/EditCattleModal';
import LoadingModal from '../../components/LoadingModal';

import './ViewRegistaration.scss';

const ViewRegistaration = () => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showEditCattleModal, setShowEditCattleModal] = useState(false);
  const [catl, setCattle] = useState(null);
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectUser(state));
  const milkSlipsStats = useSelector((state) => selectMilkSlipsStats(state));
  const message = useSelector((state) => selectMessage(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingReject(state));
  const isLoadingApprove = useSelector((state) => selectIsLoadingApprove(state));
  const isLoadingSlips = useSelector((state) => selectIsLoadingSlips(state));
  const role = localStorage.getItem('role');
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUser(id));
    // dispatch(getMilkSlipsStats(id, { period: 'day', range: 6 }));
  }, [message]);

  useEffect(() => {
    const ctx = chartRef.current;
    if (!ctx) {
      return;
    }
    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          '23 Jun',
          '24 Jun',
          '25 Jun',
          '26 Jun',
          '27 Jun',
          '28 Jun',
          '29 Jun',
        ],
        // labels: milkSlipsStats && milkSlipsStats.length
        //   ? milkSlipsStats.map((milkSlipStats) => moment(milkSlipStats.date).format('DD MMM'))
        //   : null,
        datasets: [
          {
            label: '',
            data: [12, 19, 3, 5, 2, 3, 5.2],
            // data: milkSlipsStats && milkSlipsStats.length
            //   ? milkSlipsStats.map((milkSlipStats) => Number(milkSlipStats.totalQuantity))
            //   : null,
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 2,
            tension: 0.2,
          },
        ],
      },
      plugins: [
        {
          id: 'canvas_background_color',
          beforeDraw: (chart) => {
            const canvas = chart.canvas.getContext('2d');
            canvas.save();
            canvas.globalCompositeOperation = 'destination-over';
            canvas.fillStyle = '#175fad';
            canvas.fillRect(0, 0, chart.width, chart.height);
            canvas.restore();
          },
        },
      ],
      options: {
        layout: {
          padding: 20,
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
          y: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      chartInstance.destroy();
    };
  });

  const reject = () => {
    dispatch(rejectUser(id));
  };

  const approve = () => {
    dispatch(approveUser(id));
  };

  const toggleEditUserModal = () => {
    setShowEditUserModal(!showEditUserModal);
  };

  const toggleEditLocationModal = () => {
    setShowEditLocationModal(!showEditLocationModal);
  };

  const toggleEditCattleModal = () => {
    setShowEditCattleModal(!showEditCattleModal);
  };

  const submitUser = (values) => {
    const formData = new FormData();
    formData.append('farmerName', `${values.farmerName}`);
    formData.append('gender', `${values.gender || 'male'}`);
    formData.append('age', `${values.age}`);
    formData.append('role', `${values.role}`);
    formData.append('steps', `${values.steps}`);
    dispatch(editUser(id, formData));
  };

  const submitLocation = (values) => {
    const formData = new FormData();
    formData.append('pinCode', values.pinCode);
    formData.append('unitCode', values.unitCode);
    formData.append('regionName', values.regionName);
    formData.append('unitName', values.unitName);
    formData.append('mccName', values.mccName);
    formData.append('mccCode', values.mccCode);
    formData.append('mccMobile', values.mccMobile);
    formData.append('plateCode', values.plateCode);
    formData.append('plateName', values.plateName);
    formData.append('stateName', values.stateName);
    formData.append('districtName', values.districtName);
    formData.append('mendalName', values.mendalName);
    formData.append('panchayatName', values.panchayatName);
    formData.append('villageName', values.villageName);
    dispatch(editLocation(id, formData));
  };

  const submitCattle = (values) => {
    const formData = new FormData();
    formData.append('cattle', values.cattle);
    formData.append('cattleName', values.cattleName);
    formData.append('age', values.age);
    formData.append('category', values.category);
    formData.append('breed', values.breed);
    formData.append('weight', values.weight);
    formData.append('milkProduction', values.milkProduction || 0);
    formData.append('fatInMilk', values.fatInMilk || 0);
    formData.append('pregnantMonth', values.pregnantMonth || 0);
    formData.append('LactationNumber', values.lactationNo || 0);

    dispatch(editCattle(catl.id, id, formData));
  };

  return (
    <>
      {!isLoading && data ? (
        <div className="view-registration">
          {data?.status === 'waiting' ? (
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  <button
                    type="button"
                    className="button danger"
                    onClick={reject}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    onClick={approve}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row center">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="status-card">
                <div className="left">
                  User ID :
                  {' '}
                  {data.id}
                </div>
                <div className="left">
                  Total Animals :
                  {' '}
                  {data.Cattle && data.Cattle.length ? data.Cattle.length : '0'}
                </div>
                <div className="right">
                  Registration Step :
                  {' '}
                  {data.steps}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">User details</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 d-flex end">
                          <FontAwesomeIcon
                            icon={faPen}
                            className="edit-icon"
                            onClick={toggleEditUserModal}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="picture">
                            <img src={data.profilePicture} alt="User" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex-col">
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Name</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data.farmerName}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Phone Number</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.phone
                                  ? data?.phone?.replace('+91', '')
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Gender</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.gender}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Age</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {moment().diff(data?.age, 'years')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Date of Birth
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {moment(data?.age).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Type of User</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">{data?.role}</div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Vet Plus ID</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.vetPlusId ? data?.vetPlusId : 'N/A'}
                              </div>
                            </div>
                          </div>
                          {role === 'hnl-admin' ? (
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">
                                  Employee Id
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">
                                  {data?.hnlId}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex">
                      <div className="row w-100 m-h-100">
                        {role === 'admin' || role === 'regional_pi' ? (
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div>
                        ) : null}
                        <div className="col-12 col-md-6">
                          <div>
                            {role === 'admin' || role === 'regional_pi' ? (
                              <>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Pin Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.pinCode
                                        ? data?.pinCode
                                        : data?.role === 'trader'
                                          ? 'N/A For Trader Module'
                                          : 'N?A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Region Name
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.regionName}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      MCC/HP Unit Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.unitCode ? data?.unitCode : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      MCC/HP Unit Name
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.unitName ? data?.unitName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      MCC/HP REP Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.mccCode ? data?.mccCode : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      MCC/HP REP Name
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.mccName ? data?.mccName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      MCC/HP REP Mobile No
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.mccMobile ? data.mccMobile : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {role === 'admin' || role === 'regional_pi' ? (
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant No</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.plateCode ? data?.plateCode : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.plateName ? data?.plateName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">State</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.stateName ? data?.stateName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.districtName ? data?.districtName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Mandal</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.mendalName ? data?.mendalName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Panchayat</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.panchayatName ? data?.panchayatName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Village</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data.villageName ? data.villageName : data?.role === 'trader' ? 'N/A For Trader Module' : 'N/A'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Farm Milking Details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="milking-sort">
                              <span>Last 7 days</span>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex end">
                            <Link to={`/admin/users/${data.id}/milking`} className="button">
                              Detailed Report
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="button__icon"
                                onClick={toggleEditUserModal}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="milking-chart">
                          <div className="milking-chart__row">
                            <div className="milking-chart__y-axis">
                              <span>
                                Liters
                              </span>
                            </div>
                            <div className="milking-chart__chart">
                              <canvas ref={chartRef} id="milkingChart" width="100%" />
                            </div>
                          </div>
                          <div className="milking-chart__x-axis">
                            <span>
                              Timeline
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            {data?.Cattle?.length > 0 && data?.Cattle ? (
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Cattle details</h4>
                  {data?.Cattle && data?.Cattle?.length
                    ? data?.Cattle.map((cattle) => (
                      <div className="content">
                        <div className="row">
                          <div className="col-12 d-flex between">
                            <div className="picture">
                              <img src={cattle.profilePicture} alt="Cattle" />
                            </div>
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={() => {
                                setCattle(cattle);
                                toggleEditCattleModal();
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">Cattle</div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {cattle.cattle}
                                </div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">Name</div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {cattle.cattleName}
                                </div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">Age</div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {moment().diff(cattle.age, 'years')}
                                </div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">
                                  Cattle Date of Birth
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {moment(cattle.age).format('DD/MM/YYYY')}
                                </div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">
                                  Cattle Stage
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {cattle.category}
                                </div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-5">
                                <div className="content__title">Breed</div>
                              </div>
                              <div className="col-6">
                                <div className="content__text">
                                  {cattle.breed}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-7">
                                <div className="content__title">Weight</div>
                              </div>
                              <div className="col-4">
                                <div className="content__text">
                                  {cattle.weight}
                                  {' '}
                                  kg
                                </div>
                              </div>
                            </div>
                            {cattle.Milking && cattle.Milking.length ? (
                              <>
                                <div className="row between">
                                  <div className="col-7">
                                    <div className="content__title">
                                      Milk Production
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="content__text">
                                      {cattle.category === 'milking'
                                        || cattle.category === 'dry'
                                        ? cattle.Milking[0].milkProduction
                                        : 0}
                                      {' '}
                                      L
                                    </div>
                                  </div>
                                </div>
                                <div className="row between">
                                  <div className="col-7">
                                    <div className="content__title">
                                      Fat In Milk
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="content__text">
                                      {cattle.category === 'milking'
                                        || cattle.category === 'dry'
                                        ? cattle.Milking[0].fatInMilk
                                        : 0}
                                      {' '}
                                      %
                                    </div>
                                  </div>
                                </div>
                                <div className="row between">
                                  <div className="col-7">
                                    <div className="content__title">
                                      No. of month pregnant
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="content__text">
                                      {cattle.category === 'milking'
                                        || cattle.category === 'dry'
                                        ? cattle.Milking[0].pregnantMonth
                                        : 0}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between">
                                  <div className="col-7">
                                    <div className="content__title">
                                      Lactation No
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="content__text">
                                      {cattle.category === 'milking'
                                        || cattle.category === 'dry'
                                        ? cattle.Milking[0].LactationNumber
                                        : 0}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <EditUserModal
        show={showEditUserModal}
        toggle={toggleEditUserModal}
        onSubmit={submitUser}
        values={data}
      />
      <EditLocationModal
        show={showEditLocationModal}
        toggle={toggleEditLocationModal}
        onSubmit={submitLocation}
        values={data}
      />
      <EditCattleModal
        show={showEditCattleModal}
        toggle={toggleEditCattleModal}
        onSubmit={submitCattle}
        values={catl}
      />
      <LoadingModal
        show={
          isLoading || isLoadingReject || isLoadingApprove || isLoadingSlips
        }
      />
    </>
  );
};

export default ViewRegistaration;
