import { toast } from 'react-toastify';
import {
  GET_LOAN_UPCOMING_START,
  GET_LOAN_UPCOMING_SUCCESS,
  GET_LOAN_UPCOMING_ERROR,
  GET_LOAN_PAST_HELD_START,
  GET_LOAN_PAST_HELD_SUCCESS,
  GET_LOAN_PAST_HELD_ERROR,
  GET_LOAN_SINGLE_START,
  GET_LOAN_SINGLE_SUCCESS,
  GET_LOAN_SINGLE_ERROR,
  FINISH_LOAN_START,
  FINISH_LOAN_SUCCESS,
  FINISH_LOAN_ERROR,
  REJECT_LOAN_START,
  REJECT_LOAN_SUCCESS,
  REJECT_LOAN_ERROR,
  GET_ALL_COUNT_START,
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';

export const getAllUpcomingLoanRequest = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOAN_UPCOMING_START });

    const res = await HttpService.get(`/admin/loan-requests?status=Pending&page=${page}&limit=${limit}`);

    dispatch({
      type: GET_LOAN_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOAN_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllApprovedAndRejectedStatus = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOAN_PAST_HELD_START });

    const res = await HttpService.get(`/admin/loan-requests?status=Approved,Rejected&page=${page}&limit=${limit}`);

    dispatch({
      type: GET_LOAN_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOAN_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getUserDetailsById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOAN_SINGLE_START });

    const res = await HttpService.get(`/admin/loan-request/${id}`);

    dispatch({
      type: GET_LOAN_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOAN_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const ApproveLoanRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: FINISH_LOAN_START });

    const res = await HttpService.patch('/admin/loan-request', { id: `${id}`, status: true });

    dispatch({
      type: FINISH_LOAN_SUCCESS,
      payload: res.data,
    });

    toast.success('Loan Request Approved successfully');
  } catch (error) {
    dispatch({
      type: FINISH_LOAN_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const rejectLoanRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_LOAN_START });

    const res = await HttpService.patch('/admin/loan-request', { id: `${id}`, status: false });
    dispatch({
      type: REJECT_LOAN_SUCCESS,
      payload: res.data,
    });
    toast.success('Loan rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_LOAN_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllCountForFarmerLoan = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COUNT_START });

    const response = await HttpService.get('/admin/loan-requests-summary');
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COUNT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
