/* eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import logoImg from '../../assets/images/logo.png';

import './NotificationModal.scss';

const NotificationModal = ({
  show, toggle, notification, action,
}) => {
  const showHideClassName = show ? 'notification-modal display-block' : 'notification-modal display-none';
  const onDelete = () => {
    action();
    toggle();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Notification Title</span>
                      </div>
                      <div className="col-12">
                        <span className="form__text">{notification?.notificationTitle}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Notification Image</span>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="picture">
                          <img src={notification?.notificationImage} alt="Notification" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-16 mb-8">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Notification Text/Body</span>
                      </div>
                      <div className="col-12">
                        <span className="form__text">{notification?.notificationBody}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-16 mb-8">
                  <span className="form__label">Schedule</span>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row center">
                      <div className="col-12 mb-4">
                        <span className="form__label">Send to eligible users</span>
                      </div>
                      <div className="col-12 col-md-4">
                        <span className="form__text">Daily</span>
                      </div>
                      <div className="col-12 col-md-6 d-flex">
                        <span className="form__label ml-16 mr-16">at</span>
                        <span className="form__text">{notification?.time}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Start</span>
                      </div>
                      <div className="col-12">
                        <span className="form__text">{moment(notification?.startDate).format('DD/MM/YYYY')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12">
                        <span className="form__label mb-4">End</span>
                      </div>
                      <div className="col-12">
                        <span className="form__text">{moment(notification?.endDate).format('DD/MM/YYYY')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex middle mt-16">
                  <div className="form__item">
                    <button type="button" className="button danger" onClick={onDelete}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotificationModal;
