/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  getOHSValueForOrders,
  getBatchNoForIndividualItems,
} from '../../store/modules/order/actions';
import './EditItemModalNew.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOHSValue,
  selectedBatchNo,
} from '../../store/modules/order/selectors';

const EditItemModalNew = ({
  show, toggle, onSubmit, item,
}) => {
  const dispatch = useDispatch();
  const [size, setSize] = useState(null);
  const ohsValue = useSelector((state) => selectOHSValue(state));
  const getBatchNoFromApi = useSelector((state) => selectedBatchNo(state));
  const [palletType, setPalletType] = useState(null);
  const [batchNo, setbatchNo] = useState(null);
  const [checked, setchecked] = useState(true);
  const [remarks, setRemarks] = useState(null);
  const [qty, setQty] = useState(1);
  const handleChange = () => {
    setchecked(!checked);
  };
  const handleOnremarksFilled = (e) => {
    setRemarks(e);
  };
  const showHideClassName = show
    ? 'edit-item-modal display-block'
    : 'edit-item-modal display-none';

  useEffect(() => {
    if (item) {
      setSize(
        item.Product?.Quantity.find((el) => el.quantity === item.prodSize),
      );
      setPalletType(item.palletType);
      setQty(item.quantity);
    }
  }, [item]);

  useEffect(() => {
    if (size) {
      setPalletType(
        size.Pallet.find((el) => el.palletType === item.palletType)
          || size.Pallet[0],
      );
    }
  }, [size]);
  const getAllBatchNo = (mc) => {
    const mCode = mc?.Pallet[0].materialCode;
    dispatch(getBatchNoForIndividualItems(mCode));
  };
  const getPalletBatchNo = (mc) => {
    const val = mc?.materialCode;
    dispatch(getBatchNoForIndividualItems(val));
  };
  const onSizeChange = (value) => {
    setSize(value);
    getAllBatchNo(value);
  };

  const onPalletTypeChange = (value) => {
    setPalletType(value);
    getPalletBatchNo(value);
  };

  const increment = () => {
    const newValue = Number.isNaN(parseInt(qty, 10))
      ? 1
      : parseInt(qty, 10) + 1;
    setQty(newValue);
  };

  const decrement = () => {
    const newValue = qty === 1 || Number.isNaN(parseInt(qty, 10)) ? 1 : parseInt(qty, 10) - 1;
    setQty(newValue);
  };

  const onChangeQty = (evt) => {
    const newValue = parseInt(evt.target.value, 10);
    if (Number.isNaN(newValue)) {
      setQty('');
      return;
    }
    setQty(newValue);
  };

  const submit = () => {
    if (!qty) {
      toast.error('Please, enter quantity');
      return;
    }
    if (!batchNo && !item?.batches?.length > 0) {
      toast.error(
        item?.batches?.length > 0
          ? 'Please select batchNo from the drop down'
          : 'There are no batch no.',
      );
      return;
    }
    toggle();
    onSubmit({
      ...item,
      quantity: qty,
      size: size.quantity,
      palletType: palletType
        ? palletType.palletType
        : size
          ? size.Pallet[0].palletType
          : null,
      price: palletType ? palletType.price : size ? size.Pallet[0].price : 0,
      recovery: checked,
      materialCode:
        palletType?.materialCode
        ?? size?.Pallet?.[0]?.materialCode
        ?? item?.materialCode,
      batchNo,
      remarks,
      sloc: ohsValue?.sloc,
      total: palletType
        ? (palletType.price * qty).toFixed(2)
        : size
          ? (size.Pallet[0].price * qty).toFixed(2)
          : 0,
    });
  };

  const handleOnBatchesSelected = (e) => {
    const data = {
      materialCode: item?.materialCode,
      batchNo: e,
    };
    setbatchNo(e);
    dispatch(getOHSValueForOrders(data));
  };
  const fetchApidetails = () => {
    if (palletType?.materialCode) {
      return dispatch(getBatchNoForIndividualItems(palletType?.materialCode));
    }
    if (size?.Pallet[0]?.materialCode) {
      return dispatch(
        getBatchNoForIndividualItems(size?.Pallet[0].materialCode),
      );
    }
    if (item?.materialCode) {
      return dispatch(getBatchNoForIndividualItems(item?.materialCode));
    }
    return null;
  };

  useEffect(() => {
    fetchApidetails();
  }, [dispatch]);

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button
              type="button"
              className="modal-main__button"
              onClick={toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12 col-md-6 d-flex-col middle">
            <div className="product-image">
              <img src={item.Product?.prodImage[0]} alt="product" />
            </div>
            <div className="d-flex-col center pr-16 pl-16">
              <h1 className="product-name">{item.Product?.prodName}</h1>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-8">
                        <span className="form__label">Size</span>
                      </div>
                      <div className="col-12 d-flex center">
                        <div className="row center">
                          {item?.Product?.Quantity?.length > 0
                            ? item.Product.Quantity.map((el) => (
                              <div
                                key={el.id}
                                className={`form__variant-button form__variant-button--${
                                  size && el.id === size.id ? 'active' : ''
                                }`}
                                role="button"
                                tabIndex="0"
                                onClick={() => onSizeChange(el)}
                              >
                                {el.quantity}
                              </div>
                            ))
                            : null}
                        </div>
                      </div>
                    </div>
                    {item?.materialCode && (
                      <div className="row between d-flex">
                        <div className="row between">
                          <div className="d-flex">
                            <div className="content__title">
                              Material Code :
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="content__text">
                              {palletType?.materialCode
                                ?? size?.Pallet?.[0]?.materialCode}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {ohsValue && (
                      <div className="row between d-flex">
                        <div>
                          <div className="row between">
                            <div className="d-flex">
                              <div className="content__title">Ohs :</div>
                            </div>
                            <div className="d-flex">
                              <div className="content__text">
                                {ohsValue?.ohs}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="d-flex">
                              <div className="content__title">Sloc :</div>
                            </div>
                            <div className="d-flex">
                              <div className="content__text">
                                {ohsValue?.sloc}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="row between">
                            <div className="d-flex">
                              <div className="content__title">UOM :</div>
                            </div>
                            <div className="d-flex">
                              <div className="content__text">
                                {ohsValue?.uom}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="d-flex">
                              <div className="content__title">Value :</div>
                            </div>
                            <div className="d-flex">
                              <div className="content__text">
                                {ohsValue?.rate}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {item?.batches?.length > 0 ? ( */}
                    <div className="row middle">
                      <div className="col-12 mb-8">
                        <span className="form__label">Batches</span>
                      </div>
                      <div className="col-12 d-flex center">
                        <div className="row center">
                          <select
                            id="batchNo"
                            name="batchNo"
                            className="form__field"
                            value={batchNo}
                            onChange={(e) => handleOnBatchesSelected(e?.target?.value)}
                          >
                            <option value="" hidden>
                              {item?.batches?.length > 0
                                ? 'Choose Here'
                                : 'N/A'}
                            </option>

                            {getBatchNoFromApi?.length > 0
                              && getBatchNoFromApi.map((i, index) => (
                                <option key={index} value={i?.batchNo}>
                                  {i?.batchNo}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* ) : (
                      <div />
                    )} */}
                  </div>
                </div>
                {item.Product?.prodCategory === 'feed' ? (
                  <div className="d-flex col-12">
                    <div className="form__item">
                      <div className="row middle">
                        <div className="col-12 mb-8">
                          <span className="form__label">Pallet Type</span>
                        </div>
                        <div className="col-12 d-flex center">
                          <div className="row center">
                            {size?.Pallet?.length >= 0
                              ? size.Pallet.map((el) => (
                                <div
                                  key={el.id}
                                  className={`form__variant-button form__variant-button--${
                                    palletType && el.id === palletType.id
                                      ? 'active'
                                      : ''
                                  }`}
                                  role="button"
                                  tabIndex="0"
                                  onClick={() => onPalletTypeChange(el)}
                                >
                                  {el.palletType}
                                </div>
                              ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="col-12">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-8">
                        <span className="form__label">Quantity</span>
                      </div>
                      <div className="col-12 d-flex center">
                        <div className="qty__wrapper">
                          <input
                            type="text"
                            id="quantity"
                            className="form__field qty__num"
                            value={qty}
                            min="1"
                            aria-label="quantity"
                            pattern="[0-9]*"
                            name="quantity"
                            onChange={onChangeQty}
                          />
                          <button
                            type="button"
                            className="qty__adjust qty__adjust--minus"
                            aria-label="Reduce item quantity by one"
                            onClick={decrement}
                          >
                            <span className="icon" aria-hidden="true">
                              −
                            </span>
                          </button>
                          <button
                            type="button"
                            className="qty__adjust qty__adjust--plus"
                            aria-label="Increase item quantity by one"
                            onClick={increment}
                          >
                            <span className="icont" aria-hidden="true">
                              +
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row center">
                      <div className="col-12 col-md-4 col-lg-2">
                        <span className="form__label">Price:</span>
                      </div>
                      <div className="col-12 col-md-8 col-lg-10">
                        {item.Product?.prodCategory === 'feed' ? (
                          <span className="form__label">
                            {palletType
                              ? qty
                                ? (palletType.price * qty).toFixed(2)
                                : palletType.price
                              : null}
                          </span>
                        ) : (
                          <span className="form__label">
                            {size
                              ? qty
                                ? (size.Pallet[0].price * qty).toFixed(2)
                                : size.Pallet[0].price
                              : null}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label className="form__label">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleChange}
                    />
                    Recovery
                  </label>
                </div>
                <div className="form__item">
                  <div className="col-12">
                    {!checked && (
                      <input
                        className="form__fieldBig"
                        type="text"
                        id="remarks"
                        name="remarks"
                        value={remarks}
                        placeholder="remarks"
                        onChange={(e) => handleOnremarksFilled(e?.target?.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button
                          type="button"
                          className="form__button"
                          onClick={submit}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditItemModalNew;
