/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Lottie from 'lottie-react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePassword } from '../../store/modules/auth/actions';
import {
  selectIsLoadingAuth,
  selectErrorMessage,
} from '../../store/modules/auth/selectors';
import Spinner from '../../components/Spinner';
import passwordLock from '../../assets/animations/passwordLock.json';
import success from '../../assets/animations/success.json';
import caution from '../../assets/animations/caution.json';
import './UpdatePassword.scss';

const UpdatePassword = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const handleAnimation = useSelector(
    (state) => state.auth.handleLottieAnimation,
  );
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = ({ oldPassword, newPassword }) => {
    const credentials = {
      oldPassword,
      newPassword,
    };
    dispatch(updatePassword(credentials, location, history));
  };
  const result = errors?.oldPassword || errors?.newPassword || errorMessage
    ? caution
    : handleAnimation
      ? success
      : passwordLock;

  return (
    <div className="password-container">
      <div className="password-form">
        <div className="logo">
          <Lottie animationData={result} loop={false} className="logo__img" />
        </div>
        <div className="main">
          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="form__item">
              <label htmlFor="oldPassword">
                <input
                  className={`form__field ${
                    errors?.oldPassword || errorMessage ? 'error' : ''
                  }`}
                  type={showPassword ? 'text' : 'password'}
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Enter your old password"
                  {...register('oldPassword', {
                    required: {
                      value: true,
                      message: '*Old Password is required',
                    },
                  })}
                />
              </label>
              {errors?.oldPassword && (
                <div className="form__error">
                  {errors?.oldPassword?.message}
                </div>
              )}
            </div>
            <div className="form__item">
              <label htmlFor="password">
                <input
                  className={`form__field ${
                    errors?.newPassword || errorMessage ? 'error' : ''
                  }`}
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter your new password"
                  {...register('newPassword', {
                    required: {
                      value: true,
                      message: '*New Password is required',
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        '*Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                    },
                  })}
                />
              </label>
              {errors?.newPassword && (
                <div className="form__error">
                  {errors?.newPassword?.message}
                </div>
              )}
            </div>
            <div className="form__item">
              {errorMessage && (
                <div className="form__error">{`*${errorMessage}`}</div>
              )}
            </div>
            <div className="form__row">
              <input
                value={showPassword}
                type="checkbox"
                onChange={togglePasswordVisibility}
              />
              <span className="form__row">Show Password</span>
            </div>
            <div className="form__item">
              {isLoading ? (
                <Spinner />
              ) : (
                <input className="form__button" type="submit" value="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
