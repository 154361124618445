/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  deleteDoctor, deleteUser, searchDoctorsByPhone, searchUsersByPhone,
} from '../../store/modules/user/actions';
import { selectUser, selectIsLoading } from '../../store/modules/user/selectors';
import ConfirmModal from '../../components/ConfirmModal';
import LoadingModal from '../../components/LoadingModal';

import './DeleteUser.scss';

const DeleteUser = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm({ app: 'VET+App', phone: '' });

  const selectedApp = watch('app');

  const columns = useMemo(
    () => (selectedApp === 'VET+App' ? [
      {
        Header: 'MCC Representative Name',
        accessor: 'mccName',
      },
      {
        Header: 'User Name',
        accessor: 'farmerName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Total Animals',
        accessor: 'totalAnimals',
      },
      {
        Header: 'Unit Code',
        accessor: 'unitCode',
      },
      {
        Header: 'Plant Code',
        accessor: 'plateCode',
      },
      {
        Header: 'Village Name',
        accessor: 'villageName',
      },
    ] : [
      {
        Header: 'Doctor Name',
        accessor: 'doctorName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
    ]),
    [selectedApp],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:
    user
      ? [user].map((item) => (user.role === 'Farmer' ? (
        {
          ...item,
          phone: item.phone.replace('+91', ''),
          age: moment(item.age).format('DD/MM/YYYY'),
          totalAnimals: item.Cattle.length,
        }
      ) : (
        {
          ...item,
          phone: item.phone.replace('+91', ''),
          age: moment(item.age).format('DD/MM/YYYY'),
        }
      )))
      : [],
  });

  const search = ({ app, phone }) => {
    if (app === 'VETServicesApp') {
      dispatch(searchDoctorsByPhone(`+91${phone}`));
    } else {
      dispatch(searchUsersByPhone(`+91${phone}`));
    }
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const killUser = () => {
    if (selectedApp === 'VETServicesApp') {
      dispatch(deleteDoctor(user.phone));
    } else {
      dispatch(deleteUser(user.phone));
    }
  };

  return (
    <>
      <div className="delete-user">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Delete User
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-6">
            <div className="form">
              <div className="row center">
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="app">
                      <div className="row middle">
                        <div className="col-12 col-md-6">
                          <span className="form__label">Choose the application</span>
                        </div>
                        <div className="col-12 col-md-6">
                          <select
                            className="form__field"
                            id="app"
                            name="app"
                            {...register('app', {
                              required: {
                                value: true,
                                message: 'Choose the application',
                              },
                            })}
                          >
                            <option value="VET+App">VET+ App</option>
                            <option value="VETServicesApp">VET Services App</option>
                          </select>
                          {errors.app && <div className="form__error">{errors.app.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="phone">
                      <div className="row middle">
                        <div className="col-12 col-md-6">
                          <span className="form__label">Enter Phone Number</span>
                        </div>
                        <div className="col-12 col-md-6">
                          <input
                            className="form__field"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone Number"
                            {...register('phone', {
                              required: {
                                value: true,
                                message: 'Phone number is required',
                              },
                              minLength: {
                                value: 10,
                                message: 'Phone number must not be less than 10 digits',
                              },
                              maxLength: {
                                value: 10,
                                message: 'Phone number must not be greater than 10 digits',
                              },
                              pattern: {
                                value: /^[5-9][0-9]{9}$/,
                                message: 'Phone number must be valid',
                              },
                            })}
                          />
                          {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(search)}>Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="table-container"> */}
        <div className="row">
          <div className="col-12">
            <table {...getTableProps()} className="table table-bordered">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                    <th>All Details</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                      <td>
                        <button type="button" className="table__button" onClick={toggleConfirmModal}>Delete</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </div>
      <ConfirmModal
        show={showConfirmModal}
        toggle={toggleConfirmModal}
        onConfirm={killUser}
        title="Confirm"
        text="Are you sure you want to delete this user?"
      />
      <LoadingModal show={isLoading} />
    </>
  );
};

export default DeleteUser;
