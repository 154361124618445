/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner';
import { getEmployees } from '../../store/modules/user/actions';
import {
  selectIsLoading,
  selectEmployees,
  selectIsLoadingEmployees,
} from '../../store/modules/user/selectors';

import './EmployeeModal.scss';

const EmployeeModal = ({
  show, toggle, onSubmit,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectError, setSelectError] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingEmployees(state));
  const employees = useSelector((state) => selectEmployees(state));
  const showHideClassName = show ? 'employee-modal display-block' : 'employee-modal display-none';

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const nextPage = () => {
    dispatch(getEmployees(employees.Next.page));
  };

  const previousPage = () => {
    dispatch(getEmployees(employees.Previous.page));
  };

  const onSelect = (value) => {
    setSelected(value);
  };

  const submit = (evt) => {
    evt.preventDefault();
    if (!selected) {
      setSelectError('Please, select an item');
      return;
    }
    setSelectError(null);

    toggle();
    onSubmit(selected);
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 d-flex middle">
                  <span className="form__label">Select Employee</span>
                </div>
                <div className="col-12">
                  {isLoading ? <Spinner />
                    : employees && employees.paginate.length
                      ? (
                        <div className="table">
                          <div className="row pt-8 pb-8 pl-8 pr-8 divider">
                            <div className="col-1 d-flex center" />
                            <div className="col-11">
                              <span className="form__label">Employee Name</span>
                            </div>
                          </div>
                          {/* {unitMccNames.paginate.map((item) => ( */}
                          <div className="form__item divider">
                            <label htmlFor="unitMccName">
                              <div className="row">
                                <div className="col-1 d-flex center">
                                  <input
                                    className="form__check"
                                    type="radio"
                                    id="unitMccName"
                                    name="unitMccName"
                                  />
                                </div>
                                <div className="col-11 d-flex-col">
                                  <span className="label">Arjun</span>
                                </div>
                                {/* <div className="col-11 col-md-5 d-flex-col pl-8">
                                  <span className="label">{item.mccName}</span>
                                </div> */}
                              </div>
                            </label>
                          </div>
                          <div className="form__item divider">
                            <label htmlFor="unitMccName">
                              <div className="row">
                                <div className="col-1 d-flex center">
                                  <input
                                    className="form__check"
                                    type="radio"
                                    id="unitMccName"
                                    name="unitMccName"
                                  />
                                </div>
                                <div className="col-11 d-flex-col">
                                  <span className="label">Josue</span>
                                </div>
                                {/* <div className="col-11 col-md-5 d-flex-col pl-8">
                                  <span className="label">{item.mccName}</span>
                                </div> */}
                              </div>
                            </label>
                          </div>
                          <div className="form__item divider">
                            <label htmlFor="unitMccName">
                              <div className="row">
                                <div className="col-1 d-flex center">
                                  <input
                                    className="form__check"
                                    type="radio"
                                    id="unitMccName"
                                    name="unitMccName"
                                  />
                                </div>
                                <div className="col-11 d-flex-col">
                                  <span className="label">Paras</span>
                                </div>
                                {/* <div className="col-11 col-md-5 d-flex-col pl-8">
                                  <span className="label">{item.mccName}</span>
                                </div> */}
                              </div>
                            </label>
                          </div>
                          {/* ))} */}
                          <div className="form__item">
                            <div className="row">
                              <div className="col-6 d-flex-col middle">
                                <button
                                  type="button"
                                  className="load-more-button"
                                  disabled={!employees.Previous}
                                  onClick={previousPage}
                                >
                                  Prev
                                </button>
                              </div>
                              <div className="col-6 d-flex-col middle">
                                <button
                                  type="button"
                                  className="load-more-button"
                                  disabled={!employees.Next}
                                  onClick={nextPage}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : null}
                </div>
                {employees ? (
                  <div className="col-12">
                    <div className="form__item">
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          {selectError && <div className="form__error">{selectError}</div>}
                          <button type="button" className="form__button" onClick={submit}>Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmployeeModal;
