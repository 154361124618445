const prefix = 'DOCTOR';

export const GET_PENDING_START = `${prefix}_GET_PENDING_START`;
export const GET_PENDING_SUCCESS = `${prefix}_GET_PENDING_SUCCESS`;
export const GET_PENDING_ERROR = `${prefix}_GET_PENDING_ERROR`;

export const GET_APPROVED_START = `${prefix}_GET_APPROVED_START`;
export const GET_APPROVED_SUCCESS = `${prefix}_GET_APPROVED_SUCCESS`;
export const GET_APPROVED_ERROR = `${prefix}_GET_APPROVED_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const SEARCH_PENDING_START = `${prefix}_SEARCH_PENDING_START`;
export const SEARCH_PENDING_SUCCESS = `${prefix}_SEARCH_PENDING_SUCCESS`;
export const SEARCH_PENDING_ERROR = `${prefix}_SEARCH_PENDING_ERROR`;

export const SEARCH_APPROVED_START = `${prefix}_SEARCH_APPROVED_START`;
export const SEARCH_APPROVED_SUCCESS = `${prefix}_SEARCH_APPROVED_SUCCESS`;
export const SEARCH_APPROVED_ERROR = `${prefix}_SEARCH_APPROVED_ERROR`;

export const APPROVE_START = `${prefix}_APPROVE_START`;
export const APPROVE_SUCCESS = `${prefix}_APPROVE_SUCCESS`;
export const APPROVE_ERROR = `${prefix}_APPROVE_ERROR`;

export const REJECT_START = `${prefix}_REJECT_START`;
export const REJECT_SUCCESS = `${prefix}_REJECT_SUCCESS`;
export const REJECT_ERROR = `${prefix}_REJECT_ERROR`;

export const UPDATE_START = `${prefix}_UPDATE_START`;
export const UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;
export const UPDATE_ERROR = `${prefix}_UPDATE_ERROR`;

export const GET_LOCATION_START = `${prefix}_GET_LOCATION_START`;
export const GET_LOCATION_SUCCESS = `${prefix}_GET_LOCATION_SUCCESS`;
export const GET_LOCATION_ERROR = `${prefix}_GET_LOCATION_ERROR`;

export const ADD_LOCATION_START = `${prefix}_ADD_LOCATION_START`;
export const ADD_LOCATION_SUCCESS = `${prefix}_ADD_LOCATION_SUCCESS`;
export const ADD_LOCATION_ERROR = `${prefix}_ADD_LOCATION_ERROR`;

export const DELETE_LOCATION_START = `${prefix}_DELETE_LOCATION_START`;
export const DELETE_LOCATION_SUCCESS = `${prefix}_DELETE_LOCATION_SUCCESS`;
export const DELETE_LOCATION_ERROR = `${prefix}_DELETE_LOCATION_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;

export const COUNT_START = `${prefix}_COUNT_START`;
export const COUNT_SUCCESS = `${prefix}_COUNT_SUCCESS`;
export const COUNT_ERROR = `${prefix}_COUNT_ERROR`;

export const SEARCH_BY_PHONE_START = `${prefix}_SEARCH_BY_PHONE_START`;
export const SEARCH_BY_PHONE_SUCCESS = `${prefix}_SEARCH_BY_PHONE_SUCCESS`;
export const SEARCH_BY_PHONE_ERROR = `${prefix}_SEARCH_BY_PHONE_ERROR`;

export const DELETE_START = `${prefix}_DELETE_START`;
export const DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;
export const DELETE_ERROR = `${prefix}_DELETE_ERROR`;

export const SEARCH_MCC_REP_BY_MCC_CODE_START = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_START`;
export const SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS`;
export const SEARCH_MCC_REP_BY_MCC_CODE_ERROR = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_ERROR`;
