const prefix = 'DATA';

export const GET_UNIT_NAME_START = `${prefix}_GET_UNIT_NAME_START`;
export const GET_UNIT_NAME_SUCCESS = `${prefix}_GET_UNIT_NAME_SUCCESS`;
export const GET_UNIT_NAME_ERROR = `${prefix}_GET_UNIT_NAME_ERROR`;

export const GET_MCC_NAME_START = `${prefix}_GET_MCC_NAME_START`;
export const GET_MCC_NAME_SUCCESS = `${prefix}_GET_MCC_NAME_SUCCESS`;
export const GET_MCC_NAME_ERROR = `${prefix}_GET_MCC_NAME_ERROR`;

export const GET_UNIT_MCC_NAME_START = `${prefix}_GET_UNIT_MCC_NAME_START`;
export const GET_UNIT_MCC_NAME_SUCCESS = `${prefix}_GET_UNIT_MCC_NAME_SUCCESS`;
export const GET_UNIT_MCC_NAME_ERROR = `${prefix}_GET_UNIT_MCC_NAME_ERROR`;

export const GET_MORE_UNIT_MCC_NAME_START = `${prefix}_GET_MORE_UNIT_MCC_NAME_START`;
export const GET_MORE_UNIT_MCC_NAME_SUCCESS = `${prefix}_GET_MORE_UNIT_MCC_NAME_SUCCESS`;
export const GET_MORE_UNIT_MCC_NAME_ERROR = `${prefix}_GET_MORE_UNIT_MCC_NAME_ERROR`;

export const GET_UNIT_MCC_NAME_BY_MCC_CODE_START = `${prefix}_GET_UNIT_MCC_NAME_BY_MCC_CODE_START`;
export const GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS = `${prefix}_GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS`;
export const GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR = `${prefix}_GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR`;

export const GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START = `${prefix}_GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START`;
export const GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS = `${prefix}_GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS`;
export const GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR = `${prefix}_GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR`;

export const CREATE_REGION_START = `${prefix}_CREATE_REGION_START`;
export const CREATE_REGION_SUCCESS = `${prefix}_CREATE_REGION_SUCCESS`;
export const CREATE_REGION_ERROR = `${prefix}_CREATE_REGION_ERROR`;

export const EXPORT_REGION_START = `${prefix}_EXPORT_REGION_START`;
export const EXPORT_REGION_SUCCESS = `${prefix}_EXPORT_REGION_SUCCESS`;
export const EXPORT_REGION_ERROR = `${prefix}_EXPORT_REGION_ERROR`;

export const GET_YOUTUBE_VIDEOS_START = `${prefix}_GET_YOUTUBE_VIDEOS_START`;
export const GET_YOUTUBE_VIDEOS_SUCCESS = `${prefix}_GET_YOUTUBE_VIDEOS_SUCCESS`;
export const GET_YOUTUBE_VIDEOS_ERROR = `${prefix}_GET_YOUTUBE_VIDEOS_ERROR`;

export const ADD_YOUTUBE_VIDEO_START = `${prefix}_ADD_YOUTUBE_VIDEO_START`;
export const ADD_YOUTUBE_VIDEO_SUCCESS = `${prefix}_ADD_YOUTUBE_VIDEO_SUCCESS`;
export const ADD_YOUTUBE_VIDEO_ERROR = `${prefix}_ADD_YOUTUBE_VIDEO_ERROR`;

export const UPDATE_YOUTUBE_VIDEO_START = `${prefix}_UPDATE_YOUTUBE_VIDEO_START`;
export const UPDATE_YOUTUBE_VIDEO_SUCCESS = `${prefix}_UPDATE_YOUTUBE_VIDEO_SUCCESS`;
export const UPDATE_YOUTUBE_VIDEO_ERROR = `${prefix}_UPDATE_YOUTUBE_VIDEO_ERROR`;

export const GET_HEADING_START = `${prefix}_GET_HEADING_START`;
export const GET_HEADING_SUCCESS = `${prefix}_GET_HEADING_SUCCESS`;
export const GET_HEADING_ERROR = `${prefix}_GET_HEADING_ERROR`;

export const ADD_HEADING_START = `${prefix}_ADD_HEADING_START`;
export const ADD_HEADING_SUCCESS = `${prefix}_ADD_HEADING_SUCCESS`;
export const ADD_HEADING_ERROR = `${prefix}_ADD_HEADING_ERROR`;

export const UPDATE_HEADING_START = `${prefix}_UPDATE_HEADING_START`;
export const UPDATE_HEADING_SUCCESS = `${prefix}_UPDATE_HEADING_SUCCESS`;
export const UPDATE_HEADING_ERROR = `${prefix}_UPDATE_HEADING_ERROR`;

export const CHECK_MCC_DETAILS_START = `${prefix}_CHECK_MCC_DETAILS_START`;
export const CHECK_MCC_DETAILS_SUCCESS = `${prefix}_CHECK_MCC_DETAILS_SUCCESS`;
export const CHECK_MCC_DETAILS_ERROR = `${prefix}_CHECK_MCC_DETAILS_ERROR`;

export const INSERT_MCC_DETAILS_START = `${prefix}_INSERT_MCC_DETAILS_START`;
export const INSERT_MCC_DETAILS_SUCCESS = `${prefix}_INSERT_MCC_DETAILS_SUCCESS`;
export const INSERT_MCC_DETAILS_ERROR = `${prefix}_INSERT_MCC_DETAILS_ERROR`;
