const prefix = 'MARKET';

export const GET_ALL_START = `${prefix}_GET_ALL_START`;
export const GET_ALL_SUCCESS = `${prefix}_GET_ALL_SUCCESS`;
export const GET_ALL_ERROR = `${prefix}_GET_ALL_ERROR`;

export const GET_APPROVED_START = `${prefix}_GET_APPROVED_START`;
export const GET_APPROVED_SUCCESS = `${prefix}_GET_APPROVED_SUCCESS`;
export const GET_APPROVED_ERROR = `${prefix}_GET_APPROVED_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const SEARCH_BY_PHONE_START = `${prefix}_SEARCH_BY_PHONE_START`;
export const SEARCH_BY_PHONE_SUCCESS = `${prefix}_SEARCH_BY_PHONE_SUCCESS`;
export const SEARCH_BY_PHONE_ERROR = `${prefix}_SEARCH_BY_PHONE_ERROR`;

export const SEARCH_APPROVED_BY_PHONE_START = `${prefix}_SEARCH_APPROVED_BY_PHONE_START`;
export const SEARCH_APPROVED_BY_PHONE_SUCCESS = `${prefix}_SEARCH_APPROVED_BY_PHONE_SUCCESS`;
export const SEARCH_APPROVED_BY_PHONE_ERROR = `${prefix}_SEARCH_APPROVED_BY_PHONE_ERROR`;

export const UPDATE_START = `${prefix}_UPDATE_START`;
export const UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;
export const UPDATE_ERROR = `${prefix}_UPDATE_ERROR`;

export const DELETE_START = `${prefix}_DELETE_START`;
export const DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;
export const DELETE_ERROR = `${prefix}_DELETE_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;
