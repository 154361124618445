import {
  RAISE_TICKET_START,
  RAISE_TICKET_SUCCESS,
  RAISE_TICKET_ERROR,
  GET_RAISE_TICKET_LIST_START,
  GET_RAISE_TICKET_LIST_SUCCESS,
  GET_RAISE_TICKET_LIST_ERROR,
  UPDATE_RAISE_TICKET_LIST_START,
  UPDATE_RAISE_TICKET_LIST_SUCCESS,
  UPDATE_RAISE_TICKET_LIST_ERROR,
  AUTO_POPULATE_TICKET_DETAILS_START,
  AUTO_POPULATE_TICKET_DETAILS_SUCCESS,
  AUTO_POPULATE_TICKET_DETAILS_ERROR,
  STORE_TICKET_DETAILS,
  CLEAR_TICKET_DETAILS,
} from './types';

const initialState = {
  isLoading: false,
  getTicketList: null,
  getTicketById: null,
  postATicketToAPI: null,
  errorMessage: null,
  storedTicketDetails: {},
  autoPopulate: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RAISE_TICKET_LIST_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_RAISE_TICKET_LIST_SUCCESS:
      return {
        ...state,
        getTicketList: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_RAISE_TICKET_LIST_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case RAISE_TICKET_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case RAISE_TICKET_SUCCESS:
      return {
        ...state,
        postATicketToAPI: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case RAISE_TICKET_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case UPDATE_RAISE_TICKET_LIST_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case UPDATE_RAISE_TICKET_LIST_SUCCESS:
      return {
        ...state,
        getTicketById: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case UPDATE_RAISE_TICKET_LIST_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case AUTO_POPULATE_TICKET_DETAILS_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case AUTO_POPULATE_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        autoPopulate: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case AUTO_POPULATE_TICKET_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case STORE_TICKET_DETAILS:
      return {
        ...state,
        storedTicketDetails: {
          ...state.storedTicketDetails,
          ...payload,
        },
      };
    case CLEAR_TICKET_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
};
