/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faUsers,
  faChevronRight,
  faChevronLeft,
  faRecycle,
  faDownload,
  faSearch,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faUser,
  faPaw,
  faShoppingCart,
  faFilter,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import Swal from 'sweetalert2';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  getPendingUsers, searchPendingUsers, exportPendingUsers, countUsers,
} from '../../store/modules/user/actions';
import {
  selectPendingUsers, selectCount, selectIsLoading, selectIsLoadingExport, selectIsLoadingCount,
} from '../../store/modules/user/selectors';
import farmer from '../../assets/svg/farmer.svg';
import cow from '../../assets/images/cow.png';
import ModalDashboardFilter from '../../components/ModalDashboardFilter';
import CountUp from '../../components/CountUp';
import withScreenRefreshDetection from '../../utils/withScreenRefreshDetection';
import './PendingRegistration.scss';

const PendingRegistration = (props) => {
  const [query, setQuery] = useState(null);
  const [showModalForFilter, setshowModalForFilter] = useState(false);
  const [storePageSizeValue, setstorePageSizeValue] = useState(10);
  const columns = useMemo(
    () => [
      {
        Header: 'Assigned To',
        accessor: 'assignedTo',
      },
      {
        Header: 'Farmer Name',
        accessor: 'farmerName',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Total Animals',
        accessor: 'totalAnimals',
      },
      {
        Header: 'Unit Code',
        accessor: 'unitCode',
      },
      {
        Header: 'Plant Code',
        accessor: 'plantCode',
      },
      {
        Header: 'Village Name',
        accessor: 'villageName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectPendingUsers(state));
  const count = useSelector((state) => selectCount(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));
  const isLoadingCount = useSelector((state) => selectIsLoadingCount(state));
  const role = localStorage.getItem('role');
  const filterApplied = localStorage.getItem('filterAppliedForPendingRegistration');
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable({
  //   columns,
  //   data: data && data.paginate && data.paginate.length ? data : [],
  // });

  const fetchDetailsForPendingRegistrations = () => {
    const values = {
      status: 'waiting',
      page: 1,
      limit: storePageSizeValue,
    };
    dispatch(getPendingUsers(values));
    dispatch(countUsers());
  };

  useEffect(() => {
    if (filterApplied) {
      Swal.fire({
        icon: 'info',
        title: 'Filter applied, do you want to continue anyway ?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((res) => {
        if (res?.isDismissed) {
          localStorage.removeItem('filterAppliedForPendingRegistration');
          fetchDetailsForPendingRegistrations();
        }
      });
    } else {
      fetchDetailsForPendingRegistrations();
    }
  }, []);
  useEffect(() => {
    if (props?.isRefreshed) {
      localStorage.removeItem('filterAppliedForPendingRegistration');
    }
  }, [props?.isRefreshed]);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchPendingUsers(`+91${query}`));
  };

  const nextPage = (page) => {
    const values = {
      status: 'waiting',
      page,
      limit: storePageSizeValue,
    };
    dispatch(getPendingUsers(values));
  };

  const previousPage = (page) => {
    const values = {
      status: 'waiting',
      page,
      limit: storePageSizeValue,
    };
    dispatch(getPendingUsers(values));
  };

  const exportExcell = () => {
    dispatch(exportPendingUsers());
  };
  const showSwalAlert = () => {
    let html = '<div style="width: 100%;height: 250px;overflow-y: auto;text-align: center;padding: 20px;"><table style="border-collapse: collapse;width:100%"><thead><tr><th style="text-align: left;padding: 8px;">Id</th><th style="text-align: left;padding: 8px;">Region Name</th><th style="text-align: left;padding: 8px;">Pending</th><th style="text-align: left;padding: 8px;">Approved / Rejected</th></tr></thead><tbody>';

    count.countedDigitalRegistrations.regionData.forEach((item, index) => {
      const bgColor = index % 2 === 0 ? '' : '#D6EEEE'; // calculate background color
      const regionName = item?.regionName || ''; // null check unnecessary

      html += `<tr style="background-color: ${bgColor};"><td style="text-align: left;padding: 8px;">${
        index + 1
      }</td><td style="text-align: left;padding: 8px;">${regionName}</td><td style="text-align: center;padding: 8px;">${
        item?.pendingCount
      }</td><td style="text-align: center;padding: 8px;">${item?.completedRequestsCount}</td></tr>`;
    });

    html += '</tbody></table>';
    Swal.fire({
      title: 'Region-specific number of digital farmers enrolled',
      html,
    });
  };
  const showVplCountSwal = () => {
    let html = '<div style="width: 100%;height: 250px;overflow-y: auto;text-align: center;padding: 20px;"><table style="border-collapse: collapse;width:100%"><thead><tr><th style="text-align: left;padding: 8px;">Id</th><th style="text-align: left;padding: 8px;">Region Name</th><th style="text-align: left;padding: 8px;">Pending</th><th style="text-align: left;padding: 8px;">Approved / Rejected</th></tr></thead><tbody>';

    count.countedIndents.regionData.forEach((item, index) => {
      const bgColor = index % 2 === 0 ? '' : '#D6EEEE'; // calculate background color
      const regionName = item?.regionName || ''; // null check unnecessary

      html += `<tr style="background-color: ${bgColor};"><td style="text-align: left;padding: 8px;">${
        index + 1
      }</td><td style="text-align: left;padding: 8px;">${regionName}</td><td style="text-align: center;padding: 8px;">${
        item?.pendingCount
      }</td><td style="text-align: center;padding: 8px;">${item?.completedRequestsCount}</td></tr>`;
    });

    html += '</tbody></table>';
    Swal.fire({
      title: 'VPL Store Indents Region Wise',
      html,
    });
  };

  const handleOnFilter = () => {
    setshowModalForFilter(!showModalForFilter);
  };
  const submitFilter = (value) => {
    const values = {
      status: 'waiting',
      page: 1,
      limit: storePageSizeValue,
      fromDate: moment(value?.fromDate).format('DD/MM/YYYY'),
      toDate: moment(value?.toDate).format('DD/MM/YYYY'),
    };
    dispatch(countUsers(values));
    dispatch(getPendingUsers(values));
    localStorage.setItem('filterAppliedForPendingRegistration', true);
  };
  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);
  const handleOnPageSizeSelected = (event) => {
    setstorePageSizeValue(event?.target?.value);
    const values = {
      page: 1,
      status: 'waiting',
      limit: `${event?.target?.value}`,
    };
    dispatch(getPendingUsers(values));
  };
  return (
    <>
      <div className="pending-registration">
        {
          filterApplied && (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon
              icon={faWarning}
              style={{ marginRight: 5 }}
            />
            In order to dynamically remove the filter that has been applied, please refresh the page.
          </div>
          )
        }
        <div className="row middle">
          {/* <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faUsers} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  {role === 'hnl-admin' ? 'HNL Employees' : 'Employees'}
                </span>
                {
                  role === 'hnl-admin' ? (
                    <span className="summary-card__value">
                      {count ? count?.countedHnlEmployees : 0}
                    </span>
                  ) : (
                    <span className="summary-card__value">
                      {count ? count?.countedEmployees : 0}
                    </span>
                  )
                }
              </div>
            </div>
          </div> */}
          {
            role === 'hnl-admin' ? (
              <div className="col-12 col-md-3">
                <div className="summary-card primary mr-8">
                  <div className="summary-card__left">
                    <FontAwesomeIcon icon={faUsers} className="summary-card__icon" />
                  </div>
                  <div className="summary-card__right">
                    <span className="summary-card__label">
                      HNL Employees
                    </span>
                    <span className="summary-card__value">
                      {count ? <CountUp start={0} end={count?.countedHnlEmployees} duration={3} /> : 0}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 col-md-3">
                <div className="summary-card purpleColor mr-8" onClick={showSwalAlert}>
                  <div className="summary-card__left">
                    <FontAwesomeIcon icon={faUsers} className="summary-card__icon" />
                  </div>
                  <div className="summary-card__right">
                    <span className="summary-card__label">
                      Digital Farmer Enrollments
                    </span>
                    <span className="summary-card__value">
                      {count ? <CountUp start={0} end={count?.countedDigitalRegistrations?.count} duration={3} /> : 0}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
          {/* {
            count?.countedIndents && (
              <div className="col-12 col-md-3" onClick={showVplCountSwal}>
                <div className="summary-card warning mr-8">
                  <div className="summary-card__left">
                    <FontAwesomeIcon icon={faShoppingCart} className="summary-card__icon" />
                  </div>
                  <div className="summary-card__right">
                    <span className="summary-card__label">
                      VPL Orders Count
                    </span>
                    <span className="summary-card__value">
                      {count ? <CountUp start={0} end={count?.countedIndents?.count} duration={3} /> : 0}
                    </span>
                  </div>
                </div>
              </div>
            )
          } */}
          <div className="col-12 col-md-3">
            <div className="summary-card info mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faUsers} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  {role === 'hnl-admin' ? 'HNL Distributors' : 'MCC Representatives'}
                </span>
                {
                  role === 'hnl-admin' ? (
                    <span className="summary-card__value">
                      {count ? <CountUp start={0} end={count?.countedHnlEmployees} duration={3} /> : 0}
                    </span>
                  ) : (
                    <span className="summary-card__value">
                      {count ? <CountUp start={0} end={count?.countedRepresentatives} duration={3} /> : 0}
                    </span>
                  )
                }
              </div>
            </div>
          </div>
          {role === 'admin' || role === 'regional_pi' ? (
            <>
              <div className="col-12 col-md-3">
                <div className="farmer-card success mr-8">
                  <div className="farmer-card__center">
                    <span className="farmer-card__label">
                      HPC Farmers
                    </span>
                    <span className="farmer-card__value">
                      {count ? <CountUp start={0} end={count?.countedFarmers?.hpcFarmers} duration={3} /> : 0}
                    </span>
                  </div>
                  <div className="farmer-card__center">
                    <span className="farmer-card__label">
                      MCC Farmers
                    </span>
                    <span className="farmer-card__value">
                      {count ? <CountUp start={0} end={count?.countedFarmers?.mccFarmers} duration={3} /> : 0}
                    </span>
                  </div>
                  <div className="farmer-card__center">
                    <span className="farmer-card__label">
                      Total Farmers
                    </span>
                    <span className="farmer-card__value">
                      {count ? <CountUp start={0} end={count?.countedFarmers?.hpcFarmers + count?.countedFarmers?.mccFarmers} duration={3} /> : 0}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="col-12 col-md-3">
            <div className="summary-card green">
              <div className="summary-card__left">
                {/* <FontAwesomeIcon icon={faPaw} className="summary-card__icon" /> */}
                <img alt="Cow" src={cow} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Cattle
                </span>
                <span className="summary-card__value">
                  {count ? <CountUp start={0} end={count?.countedCattles} duration={3} /> : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button primary" onClick={handleOnFilter}>
                <FontAwesomeIcon icon={faFilter} className="button__icon" />
                Filter
              </button>
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="row middle">
            <div className="col-12 col-md-6">
              <span className="table-footer__title">
                {data && data.Previous
                  ? (data.Previous.page + 1) * data.Previous.limit
                          - data.Previous.limit
                          + 1
                  : 1}
                {' '}
                to
                {' '}
                {data && data.Previous
                  ? (data.Previous.page + 1) * data.Previous.limit
                  : storePageSizeValue}
                {' '}
                of 1
                {' '}
                {''}
              </span>
            </div>
            <div className="col-12 col-md-6">
              <div className="table-footer__buttons">
                <div className="rowWithFlex">
                  Limit:
                  <select
                    style={{ marginLeft: '5px' }}
                    className="form__field"
                    id="role"
                    onChange={handleOnPageSizeSelected}
                    name="role"
                  >
                    {pageSize?.map((item, index) => (
                      <option key={index.toString()} value={item}>{item}</option>
                    ))}
                  </select>
                </div>

                <button
                  type="button"
                  className="table-footer__button"
                  onClick={() => previousPage(1)}
                  disabled={!data || !data.Previous}
                >
                  <FontAwesomeIcon
                    icon={faAngleDoubleLeft}
                    className="table-footer__button__icon"
                  />
                </button>
                <button
                  type="button"
                  className="table-footer__button"
                  onClick={() => (data ? previousPage(data.Previous.page) : {})}
                  disabled={!data || !data.Previous}
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="table-footer__button__iclon"
                  />
                </button>
                <div className="table-footer__label">
                  {data && data.Next
                    ? data.Next.page - 1
                    : data && data.Previous
                      ? data.Previous.page + 1
                      : 1}
                  {/* /1 */}
                </div>
                <button
                  type="button"
                  className="table-footer__button"
                  onClick={() => (data ? nextPage(data.Next.page) : {})}
                  disabled={!data || !data.Next}
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="table-footer__button__icon"
                  />
                </button>
                <button
                  type="button"
                  className="table-footer__button"
                  onClick={() => (data ? nextPage(data.Next.page) : {})}
                  disabled={!data || !data.Next}
                >
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    className="table-footer__button__icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="row center">
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-auto">
                        <span className="form__label">Filter By:</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form__item">
                    <label htmlFor="role">
                      <div className="row middle">
                        <div className="col-12 col-md-4">
                          <span className="form__label">User Type</span>
                        </div>
                        <div className="col-12 col-md-8">
                          <select
                            className="form__field"
                            id="role"
                            name="role"
                          >
                            <option value="farmer">All</option>
                            <option value="farmer">Farmer</option>
                            <option value="MCC Representative">MCC Representative</option>
                            <option value="employee">Employee</option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <label htmlFor="unitCode">
                      <div className="row middle">
                        <div className="col-12 col-md-4">
                          <span className="form__label">Unit Code</span>
                        </div>
                        <div className="col-12 col-md-8">
                          <input
                            className="form__field"
                            type="text"
                            id="unitCode"
                            name="unitCode"
                            placeholder="Unit Code"
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <label htmlFor="plantCode">
                      <div className="row middle">
                        <div className="col-12 col-md-4">
                          <span className="form__label">Plant Code</span>
                        </div>
                        <div className="col-12 col-md-8">
                          <input
                            className="form__field"
                            type="text"
                            id="plantCode"
                            name="plantCode"
                            placeholder="Plant Code"
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="table-container">
          <div className="row">
            <div className="col-12">
              <div className="table-header">
                <div className="row middle mb-8">
                  <div className="col-12 col-md-6">
                    <p className="table-header__title">
                      Pending Registration
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="table-header__buttons">
                      <button type="button" className="table-header__button mr-8">
                        <FontAwesomeIcon
                          icon={faRecycle}
                          className="table-header__button__icon"
                        />
                        refresh
                      </button>
                      <button type="button" className="table-header__button" onClick={exportExcell}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="table-header__button__icon"
                        />
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row center between">
                  <div className="col-12 col-md-6">
                    <div className="form__item p-0 mb-16">
                      <label htmlFor="role">
                        <div className="row">
                          <div className="col-4 col-md-2">
                            <span className="form__label">Show</span>
                          </div>
                          <div className="col-4 col-md-2">
                            <select
                              className="form__field"
                              id="role"
                              name="role"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="col-4 col-md-2">
                            <span className="form__label ml-8">Entries</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="search">
                      <label htmlFor="search">
                        <input
                          className="search__field"
                          type="text"
                          id="search"
                          name="search"
                          placeholder="Search"
                          onChange={handleChange}
                        />
                        <button type="button" className="search__button"
                        onClick={search} disabled={!query}>
                          <FontAwesomeIcon icon={faSearch} className="search__button__icon" />
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Assigned To</th>
                    <th>Farmer Name</th>
                    <th>Mobile</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Total Animals</th>
                    <th>Unit Code</th>
                    <th>Plant Code</th>
                    <th>Village Name</th>
                    <th>All Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Assigned To</td>
                    <td>Farmer Name</td>
                    <td>Mobile</td>
                    <td>Gender</td>
                    <td>Age</td>
                    <td>Total Animals</td>
                    <td>Unit Code</td>
                    <td>Plant Code</td>
                    <td>Village Name</td>
                    <td>
                      <button type="button" className="table__button">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <div className="table-footer">
                <div className="row middle">
                  <div className="col-12 col-md-6">
                    <p className="table-footer__title">
                      1 to 1 of 1
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="table-footer__buttons">
                      <button
                        type="button"
                        className="table-footer__button"
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                      >
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          className="table-footer__button__iclon"
                        />
                      </button>
                      <div className="table-footer__label">
                        1/1
                      </div>
                      <button
                        type="button"
                        className="table-footer__button"
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          {data && data.paginate && data.paginate.length
            ? data.paginate.map((user) => (
              <div key={user.id} className="col-12 col-lg-12">
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img src={user.profilePicture ?? farmer} alt="user" />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Name :
                          {' '}
                        </span>
                        {user.farmerName ?? 'N/A'}
                      </div>
                      <div>
                        <span className="card__title">
                          Phone Number :
                          {' '}
                        </span>
                        {user.phone.replace('+91', '') ?? 'N/A'}
                      </div>
                      <div>
                        <span className="card__title">
                          Registration Date :
                          {' '}
                        </span>
                        {moment(user.createdAt).format('DD/MM/YYYY') ?? 'N/A'}
                      </div>
                      <div>
                        <span className="card__title">
                          Registration Time :
                          {' '}
                        </span>
                        {moment(user.createdAt).format('hh:mm a') ?? 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <Link to={`/admin/users/${user.id}`} className="card__button">View</Link>
                  </div>
                </div>
              </div>
            ))
            : (
              null
            )}
          {!isLoading && !data && (
          <div className="col-12 col-lg-12 d-flex-col middle">
            <div className="notFound">
              <FontAwesomeIcon icon={faUsers} className="notFound__icon" />
              <span className="notFound__text">No pending registrations found</span>
            </div>
          </div>
          )}
        </div>
        {data
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Previous}
                    onClick={() => previousPage(data.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {data.Next
                      ? data.Next.page - 1
                      : data.Previous
                        ? data.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Next}
                    onClick={() => nextPage(data.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
      <LoadingModal show={isLoading || isLoadingExport || isLoadingCount} />
      {showModalForFilter ? (
        <ModalDashboardFilter
          show={showModalForFilter}
          toggle={handleOnFilter}
          onSubmit={submitFilter}
        />
      ) : null}
    </>
  );
};

export default withScreenRefreshDetection(PendingRegistration);
