/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {
  updateMccFarmersDetailsByTypeForPlant,
  getMccFarmersDetailsById,
  handleMccFarmerApprovalByPlantIC,
  handleMccFarmerRejectionByPlantIC,
  getSignatureData,
  deleteSignatureById,
  postSignatureDataInPlantSide,
} from '../../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectSingleUserData,
  selectIsLoadingApprovalPlant,
  selectIsLoadingRejectionPlant,
  selectSignatureArray,
  selectIpAddress,
} from '../../../store/modules/newUser/selectors';
import EditNewUserModal from './components/UserModalUpdate';
import EditBankDetailsModal from '../../../components/EditBankDetailsModal';
import DigitalSignatureModal from '../../../components/DigitalSingatureModal';
import SignaturesModal from '../../../components/SignaturesModal';
import EditGpaModal from './components/UserGpaUpdate';
import LoadingModal from '../../../components/LoadingModal';
import ModalForImageZoom from '../../../components/ModalForImageZoom';
import '../mccFarmerListViewPage.scss';

const MccFarmerEditPage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectSingleUserData(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingApprovalPlant(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingRejectionPlant(state));
  const ipAddress = useSelector((state) => selectIpAddress(state));
  const signatureArray = useSelector((state) => selectSignatureArray(state));
  const role = localStorage.getItem('role');
  const { id } = useParams();
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDigitalSignatureModal, setshowDigitalSignatureModal] = useState(
    false,
  );
  const [showBankDetailsModal, setshowBankDetailsModal] = useState(false);
  const [showSignatureModal, setshowSignatureModal] = useState(false);
  const [showModalForGPAUsers, setshowModalForGPAUsers] = useState(false);
  const [maskAadhar, setmaskAadhar] = useState(false);
  const [showImageModal, setshowImageModal] = useState(false);
  const [selectedImageValues, setselectedImageValues] = useState(null);
  const [ip, setIp] = useState('IP Address not available');

  const fetchMccFarmerDetails = () => {
    const dataTobeSent = {
      id,
    };
    dispatch(getMccFarmersDetailsById(dataTobeSent));
  };

  useEffect(() => {
    fetchMccFarmerDetails();
  }, [dispatch]);

  const handleOnImagepressed = (img) => {
    setshowImageModal(!showImageModal);
    setselectedImageValues(img);
  };
  const toggleBankDetailsModal = () => {
    setshowBankDetailsModal(!showBankDetailsModal);
  };
  const handleIpAddress = () => {
    ipAddress?.then((res) => {
      setIp(res ? res?.ip : 'IP Not fetched');
    });
  };
  useEffect(() => {
    handleIpAddress();
  }, []);
  const handleOnSignatureArrayDataCalled = () => {
    setshowSignatureModal(!showSignatureModal);
    const values = {
      type: 'plant',
    };
    dispatch(getSignatureData(values));
  };

  const handleOnDigitalSignatureModalCalled = () => {
    setshowDigitalSignatureModal(!showDigitalSignatureModal);
  };
  const handleCallBack = (response) => {
    if (response) {
      fetchMccFarmerDetails();
    }
  };
  const submitBankDetails = (values) => {
    const bankDetails = {
      bankName: values?.bankName,
      bankAccountNumber: values?.accountNumber,
      ifscCode: values?.ifscCode,
      branchName: values?.branchName,
      phone: data?.phone,
    };

    dispatch(updateMccFarmersDetailsByTypeForPlant(bankDetails, handleCallBack));
  };
  const handleOnValueSelectedFromSignatureModal = (val) => {
    const approvedData = {
      phone: data?.phone,
      status: 'approved',
      farmerStatus: 'Active',
      approved_plant_ic: 'approved',
      plant_ic_sign: val?.signatureUrl,
      plant_ic_sign_date: new Date().toISOString(),
    };
    dispatch(handleMccFarmerApprovalByPlantIC(approvedData, fetchMccFarmerDetails));
  };
  const handleOnDeleteSignatureCalled = (value) => {
    const valuesToBepassed = {
      id: value?.id,
      type: 'plant',
    };
    dispatch(deleteSignatureById(valuesToBepassed));
  };

  const handleOnSignatureFetchedFromCanvasModal = (val) => {
    const values = {
      signature: val,
    };
    if (val !== null) {
      dispatch(postSignatureDataInPlantSide(values));
      handleOnSignatureArrayDataCalled();
    }
  };
  const reject = () => {
    Swal.fire({
      title: 'Enter your reason for rejection',
      input: 'textarea',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          const rejectData = {};
          rejectData.phone = data?.phone;
          rejectData.status = 'rejected';
          rejectData.farmerStatus = 'Inactive';
          rejectData.approved_plant_ic = 'rejected';
          rejectData.comment = result?.value;
          dispatch(handleMccFarmerRejectionByPlantIC(rejectData, fetchMccFarmerDetails));
        } else {
          Swal.fire({
            text: 'Please specify a reason for Inactivating',
            icon: 'error',
            toast: 'true',
            timer: 2000,
          });
        }
      }
    });
  };

  const approve = () => {
    handleOnSignatureArrayDataCalled();
  };

  const toggleEditUserModal = () => {
    setShowEditUserModal(!showEditUserModal);
  };

  const toggleGpaModal = () => {
    setshowModalForGPAUsers(!showModalForGPAUsers);
  };

  const submitUser = (values) => {
    const userDetailsThatNeedToBeUpdated = {
      farmerName: values?.farmerName,
      gender: values?.gender,
      dob: values?.dob,
      aadhaarNumber: values?.aadharNumber,
      phone: data?.phone,
    };
    dispatch(
      updateMccFarmersDetailsByTypeForPlant(userDetailsThatNeedToBeUpdated),
    );
  };

  const submitGpaDetails = (values) => {
    const repUpdateData = {
      nomineeRelationship: values?.nomineeRelationship,
      nomineeName: values?.nomineeName,
      phone: data?.phone,
    };
    dispatch(updateMccFarmersDetailsByTypeForPlant(repUpdateData));
  };
  const aadharNumberTobeShown = data?.aadhaarNumber?.replace(
    /\d(?=\d{4})/g,
    '✱',
  );
  const toggleMaskingOfAadar = () => {
    setmaskAadhar(!maskAadhar);
  };
  return (
    <>
      {!isLoading && data ? (
        <div className="view-registration">
          <div className="col-12 col-md-6">
            <div className="row center">
              <div className="col-12">
                <div className="status-card">
                  {`Current status : ${data?.farmerStatus ?? 'Old User'}`}
                </div>
              </div>
            </div>
          </div>
          {data?.status === 'pending' ? (
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  <button
                    type="button"
                    className="button danger"
                    onClick={reject}
                  >
                    Inactive
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    onClick={approve}
                  >
                    Activate
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">User details</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {data?.farmerStatus === 'Inactive' ? (
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="edit-icon"
                              onClick={toggleEditUserModal}
                            />
                          </div>
                        ) : null}
                        <div className="col-12 col-lg-6 d-flex-col">
                          <div className="row right">
                            {data?.aadhaarImgUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-left">
                                  <h4 className="title">Aadhar Card Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'Aadhar Card',
                                      link: `${data?.aadhaarImgUrl}?${new Date().getTime()}`,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data?.aadhaarImgUrl
                                          ? `${data?.aadhaarImgUrl}?${new Date().getTime()}`
                                          : null
                                      }
                                      alt="1"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.nomineeImgUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Nominee Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'NOMINEE IMAGE',
                                      link: `${data?.nomineeImgUrl}?${new Date().getTime()}`,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data?.nomineeImgUrl
                                          ? `${data?.nomineeImgUrl}?${new Date().getTime()}`
                                          : null
                                      }
                                      alt="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.bankPassBookImgUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Bank Passbook Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'BANK PASSBOOK IMAGE',
                                      link: data?.bankPassBookImgUrl,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data?.bankPassBookImgUrl
                                          ? `${data?.bankPassBookImgUrl}?${new Date().getTime()}`
                                          : null
                                      }
                                      alt="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex-col">
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Name</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data?.farmerName}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Phone Number</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data?.phone
                                  ? data?.phone?.replace('+91', '')
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Gender</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data?.gender ? data?.gender : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Date of Birth
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {moment(data?.dob).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          {data?.aadhaarNumber && (
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Aadhar Number
                              </div>
                            </div>
                            <div
                              className="col-8"
                              onClick={() => toggleMaskingOfAadar()}
                            >
                              <div className="content__text">
                                {maskAadhar
                                  ? data?.aadhaarNumber
                                  : aadharNumberTobeShown}
                              </div>
                            </div>
                          </div>
                          )}
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Plant Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.plantCode ? data?.plantCode : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Mcc Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.unitCode ? data?.unitCode : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Mcc Rep Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.mccCode ? data?.mccCode : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Role</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">MCC Farmer</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 d-flex">
                      <div className="row w-100 m-h-100">
                        {data?.preparedByPlantIC === 'pending' ? (
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div>
                        ) : null}
                        <div className="col-12 col-md-6">
                          <div>
                            {role === 'plant-admin' ? (
                              <>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      No Of Cows
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.noOfCows ? data?.noOfCows : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Cow Milk Quantity
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.cowMilkquantity
                                        ? data?.cowMilkquantity
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      No of Buffalo
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.noOfBuffalo
                                        ? data?.noOfBuffalo
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Buffalo Milk Quantity
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.buffaloMilkQuantity
                                        ? data?.buffaloMilkQuantity
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Farmer Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.farmerCode
                                        ? data?.farmerCode
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Vendor Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.vendorCode
                                        ? data?.vendorCode
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {role === 'plant-admin' ? (
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">
                                  House No/ Door No
                                </div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.hNoDno ? data?.hNoDno : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.district ? data?.district : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Mandal</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.mandal ? data?.mandal : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Village</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.village ? data?.village : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Pin Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.pinCode ? data?.pinCode : 'N/A'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">
                  Nominee details
                  {data?.farmerStatus === 'Inactive' ? (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="edit-icon"
                      onClick={() => {
                        toggleGpaModal();
                      }}
                    />
                  ) : null}
                </h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Nominee Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.nomineeName ? data?.nomineeName : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">
                            Nominee Relationship
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.nomineeRelationship
                              ? data.nomineeRelationship
                              : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Enrollment Id</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.enrolmentId ? data?.enrolmentId : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">
                  Bank details
                  {data?.farmerStatus === 'Inactive' ? (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="edit-icon"
                      onClick={
                        toggleBankDetailsModal
                      }
                    />
                  ) : null}
                </h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Account Number</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.bankAccountNumber ? data?.bankAccountNumber : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">
                            IFSC Code
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.ifscCode
                              ? data.ifscCode
                              : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Bank Name</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.bankName ? data?.bankName : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Branch Name</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.branchName ? data?.branchName : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">Digital Signature</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="col-1 col-md-6 d-flex-col justify-content-center">
                        {data?.plant_ic_sign ? (
                          <img
                            src={data?.plant_ic_sign}
                            alt="signature"
                            className="col-md-6 d-flex"
                          />
                        ) : (
                          <div className="content__text">N/A</div>
                        )}
                        <div className="content__mtitle">Plant Incharge</div>
                        {data?.plant_ic_sign_date ? (
                          <div className="content__miniTitle">
                            {`Signed on ${moment(
                              data?.plant_ic_sign_date,
                            ).format('DD MMM, YYYY, hh:mm:a')}`}
                          </div>
                        ) : (
                          <div className="content__miniTitle">
                            Not yet signed
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showEditUserModal ? (
        <EditNewUserModal
          show={showEditUserModal}
          toggle={toggleEditUserModal}
          onSubmit={submitUser}
          values={data}
        />
      ) : null}
      {showModalForGPAUsers ? (
        <EditGpaModal
          show={showModalForGPAUsers}
          toggle={toggleGpaModal}
          onSubmit={submitGpaDetails}
          values={data}
        />
      ) : null}

      {showImageModal ? (
        <ModalForImageZoom
          show={showImageModal}
          toggle={handleOnImagepressed}
          imageUrl={selectedImageValues}
        />
      ) : null}
      {showDigitalSignatureModal ? (
        <DigitalSignatureModal
          show={showDigitalSignatureModal}
          toggle={handleOnDigitalSignatureModalCalled}
          onSubmit={handleOnSignatureFetchedFromCanvasModal}
        />
      ) : null}
      {showSignatureModal ? (
        <SignaturesModal
          signatures={signatureArray}
          show={showSignatureModal}
          isLoading={isLoading}
          onSubmit={handleOnValueSelectedFromSignatureModal}
          toggle={handleOnSignatureArrayDataCalled}
          onDelete={handleOnDeleteSignatureCalled}
          showSignatureCanvas={handleOnDigitalSignatureModalCalled}
        />
      ) : null}
      {showBankDetailsModal ? (
        <EditBankDetailsModal
          show={showBankDetailsModal}
          toggle={toggleBankDetailsModal}
          onSubmit={submitBankDetails}
          values={data}
        />
      ) : null}
      <LoadingModal show={isLoading || isLoadingFinish || isLoadingReject} />
    </>
  );
};

export default MccFarmerEditPage;
