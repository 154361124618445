import React, { useState, useEffect } from 'react';

const CountUp = ({ start, end, duration }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let frame;
    const increment = Math.ceil((end - start) / (duration * 60));

    const updateCount = () => {
      setCount((prevCount) => {
        if (prevCount + increment <= end) {
          return prevCount + increment;
        }
        return end;
      });
    };

    const animateCount = () => {
      frame = requestAnimationFrame(animateCount);
      updateCount();
    };

    animateCount();

    return () => cancelAnimationFrame(frame);
  }, [start, end, duration]);

  return <>{count}</>;
};

export default CountUp;
