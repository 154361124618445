/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { validateAge } from '../Validators';
import 'react-datepicker/dist/react-datepicker.css';
import './EditNewUserModal.scss';

const EditUserModal = ({
  show, toggle, onSubmit, values,
}) => {
  const dateParts = values && values?.dob !== null
    ? values?.dob?.split('/')
    : new Date();
  const dateObject = values && values?.dob !== null
    ? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    : '';
  const [dob, setdob] = useState(values ? new Date(values?.dob) : new Date(moment().subtract(18, 'years')));
  const [freqError, setFreqError] = useState(null);
  const [dateError, setDate] = useState(null);
  const [qtyError, setQtyError] = useState(null);
  const userRole = localStorage?.getItem('role');
  const showHideClassName = show ? 'edit-user-modal display-block' : 'edit-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('farmerName', values.farmerName);
      setValue('phone', values.phone ? values.phone.replace('+91', '') : '');
      setValue('gender', values.gender);
      setValue('role', values.role);
      // setValue('hpcCode', values?.hpcCode);
      setValue('aadharNumber', values?.aadharNumber);
      setdob(values ? new Date(values?.dob) : new Date(
        moment().subtract(18, 'years'),
      ));
    }
  }, [setValue, values]);

  const submit = ({
    farmerName,
    gender,
    aadharNumber,
    phone,
  }) => {
    toggle();
    const validationMessage = validateAge(dob, 18, 108);
    if (validationMessage) {
      Swal.fire({
        icon: 'warning',
        toast: true,
        title: validationMessage,
      });
    } else {
      onSubmit({
        farmerName,
        gender,
        dob,
        aadharNumber,
        phone,
      });
    }
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="farmerName"
                            name="farmerName"
                            placeholder="Name"
                            {...register('farmerName', { required: { value: true, message: 'Name is required' } })}
                          />
                          {errors.farmerName && <div className="form__error">{errors.farmerName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Phone number</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="phone"
                            name="phone"
                            maxLength={10}
                            disabled
                            placeholder="Phone number"
                            {...register('phone', {
                              required: { value: true, message: 'Phone number is required' },
                              minLength: {
                                value: 10,
                                message:
                                'Phone Number must not be less than 10 digits',
                              },
                              maxLength: {
                                value: 10,
                                message:
                                'Phone Number must not be greater than 10 digits',
                              },
                            })}
                            // value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">HPC Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="hpcCode"
                            name="hpcCode"
                            placeholder="HPC Code"
                            {...register('hpcCode', { required: { value: true, message: 'HPC Code  is required' } })}
                          />
                          {errors.hpcCode && <div className="form__error">{errors.hpcCode.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div> */}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Gender</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="gender"
                            name="gender"
                            className="form__field"
                            {...register('gender', { required: { value: true, message: 'Gender is required' } })}
                            // value={gender}
                            // onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                          </select>
                          {errors.gender && <div className="form__error">{errors.gender.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="durarion">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Date of Birth</span>
                        </div>
                        <div className="col-12">
                          <DatePicker
                            selected={dob}
                            onChange={(date) => setdob(date)}
                            // minDate={new Date(moment().subtract(150, 'years'))}
                            // maxDate={new Date(moment().subtract(18, 'years'))}
                            dateFormat="dd/MM/yyyy"
                            className="form__field"
                          />
                          {dateError && <div className="form__error">{dateError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Aadhar Number</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="aadharNumber"
                            name="aadharNumber"
                            maxLength={12}
                            placeholder="Aadhar Number"
                            {...register('aadharNumber', {
                              required: { value: true, message: 'Aadhar is required' },
                              // minLength: {
                              //   value: 12,
                              //   message:
                              //     'Aadhar Number must not be less than 12 digits',
                              // },
                              // maxLength: {
                              //   value: 12,
                              //   message:
                              //     'Aadhar Number must not be greater than 12 digits',
                              // },
                            })}
                          />
                          {errors.aadharNumber && <div className="form__error">{errors.aadharNumber.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditUserModal;
