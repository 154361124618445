/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

const hocNetworkDetector = (WrappedComponent) => (props) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const isMobileDevice = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const handleOnlineStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    // Remove event listeners before adding new ones
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [handleOnlineStatusChange]);

  return (
    <WrappedComponent
      isOnline={isOnline}
      isMobileDevice={isMobileDevice}
      {...props}
    />
  );
};

export default hocNetworkDetector;
