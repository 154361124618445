/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './EditSearchModal.scss';
import { useForm } from 'react-hook-form';

const EditSearchModalComponent = (props) => {
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const showHideClassName = props?.show
    ? 'edit-search-modal display-block'
    : 'edit-search-modal display-none';

  const handleOnSelected = (e) => {
    setSelected(e);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = ({ hpcCode }) => {
    props?.onSubmit({
      hpcCode,
    });
  };

  const handleOnSubmitted = () => {
    props?.handleOnItemSelected(selected);
  };

  // Slice the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props?.searchedData?.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math?.ceil(props?.searchedData?.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button
              type="button"
              className="modal-main__button"
              onClick={props?.toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="hpcCode">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Enter HPC code</span>
                        </div>
                        <div className="col-8 col-md-10">
                          <input
                            className="form__field"
                            type="text"
                            id="hpcCode"
                            maxLength={4}
                            name="hpcCode"
                            placeholder="Enter HPC code"
                            {...register('hpcCode', {
                              required: {
                                value: true,
                                message: 'HPC code is required',
                              },
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'HPC code must contain only numbers',
                              },
                              minLength: {
                                value: 3,
                                message:
                                  'HPC code must not be less than 3 digits',
                              },
                              maxLength: {
                                value: 4,
                                message:
                                  'HPC code must not be greater than 4 digits',
                              },
                            })}
                          />
                          {errors.hpcCode && (
                            <div className="form__error">
                              {errors.hpcCode.message}
                            </div>
                          )}
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="row">
                            <div className="col-12 d-flex-col middle">
                              <button
                                type="button"
                                className="form__button"
                                onClick={handleSubmit(submit)}
                              >
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="form__button__icon"
                                />
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {props?.searchedData && props?.searchedData?.length > 0 ? (
                    <div className="table">
                      <div className="row divider">
                        <div className="col-1" />
                        <div className="col-5 pr-8">
                          <span className="form__label">HPC Code</span>
                        </div>
                        <div className="col-5 pl-8">
                          <span className="form__label">HPC Name</span>
                        </div>
                      </div>
                      {currentItems?.map((item) => (
                        <div className="form__item divider" key={item.id}>
                          <label htmlFor="hpcCode">
                            <div className="row">
                              <div className="col-1 d-flex center">
                                <input
                                  className="form__check"
                                  type="checkbox"
                                  id="hpcCode"
                                  name="hpcCode"
                                  checked={selected && selected?.id === item?.id}
                                  onChange={() => handleOnSelected(item)}
                                />
                              </div>
                              <div className="col-10 col-md-5 d-flex-col pr-8 divider-right">
                                <span className="label">{item.hpcCode}</span>
                              </div>
                              <div className="col-11 col-md-5 d-flex-col pl-8">
                                <span className="label">{item.hpcName}</span>
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                      <div className="row center">
                        <div className="pagination-container">
                          <div className="pagination-label">Page: </div>
                          <ul className="pagination">
                            {Array.from({ length: totalPages }).map((_, index) => (
                              <li
                                key={index}
                                className={`page-item ${
                                  index + 1 === currentPage ? 'active' : ''
                                }`}
                              >
                                <button
                                  type="button"
                                  className="page-link"
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : <span className="form__searchError">No Records Found....</span>}
                </div>
                {props?.searchedData?.length > 0 ? (
                  <div className="col-12">
                    <div className="form__item">
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          <button type="button" className="form__button" onClick={() => handleOnSubmitted()}>Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditSearchModalComponent;
