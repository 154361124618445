/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faMoneyBill,
  faFilter,
  faCheckCircle,
  faWarning,
  // faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Swal from 'sweetalert2';
import { formatCount, isValidIndianPhoneNumber, isValidName } from '../../components/Validators';
import CountUp from '../../components/CountUp';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import {
  getAllNewUserPendingPlantSideRequestDetailsByType, getAllNewUserCompletedPlantSideRequestDetailsByType, searchNewUserPendingPlantSideRequestDetailsByType, searchAllNewUserCompletedPlantSideRequestDetailsByType, filterPlantSideNewUsersPendingrequests, filterPlantSideNewUsersCompletedrequests,
  getcountDigitalRegistraion,
} from '../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectPendingUserPlantSide,
  selectCompletedUserPlantSide,
  selectDigitalRegistrationCount,
} from '../../store/modules/newUser/selectors';
import ModalForFilter from '../../components/ModalForFilter';
import './PendingHpcRegistrationApprovalForPlantIc.scss';
import withScreenRefreshDetection from '../../utils/withScreenRefreshDetection';

const PendingRegistrationApprovalForPlantIc = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const [showModalForFilter, setshowModalForFilter] = useState(false);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const dispatch = useDispatch();
  const upcoming = useSelector((state) => selectPendingUserPlantSide(state));
  const pastHeld = useSelector((state) => selectCompletedUserPlantSide(state));
  const count = useSelector((state) => selectDigitalRegistrationCount(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const filterAppliedForWaitingUsers = localStorage.getItem(
    'isFilterAppliedForWaitingUsers',
  );
  const filterAppliedForCompletedUsers = localStorage.getItem(
    'isFilterAppliedForCompletedUsers',
  );
  const values = {
    page: 1,
    limit: 10,
  };

  const fetchDetailsBasedOnStates = (state) => {
    if (tabIndex === 1) {
      dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(values));
    } else {
      dispatch(getAllNewUserPendingPlantSideRequestDetailsByType());
    }
  };
  const handleConfirmPendingUsers = () => {
    localStorage.removeItem('isFilterAppliedForWaitingUsers');
    fetchDetailsBasedOnStates();
  };

  const handleConfirmCompletedUsers = () => {
    localStorage.removeItem('isFilterAppliedForCompletedUsers');
    fetchDetailsBasedOnStates();
  };
  useEffect(() => {
    dispatch(getcountDigitalRegistraion('plant'));
  }, [dispatch]);

  useEffect(() => {
    if (tabIndex === 0 && filterAppliedForWaitingUsers) {
      Swal.fire({
        icon: 'warning',
        title:
          'Filter applied for pending users, do you want to continue anyway?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((result) => {
        if (result.isDismissed) {
          handleConfirmPendingUsers();
        }
      });
    } else if (tabIndex === 1 && filterAppliedForCompletedUsers) {
      Swal.fire({
        icon: 'warning',
        title:
          'Filter applied for completed users, do you want to continue anyway?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((result) => {
        if (result.isDismissed) {
          handleConfirmCompletedUsers();
        }
      });
    } else {
      fetchDetailsBasedOnStates();
    }
  }, [tabIndex, filterAppliedForWaitingUsers, filterAppliedForCompletedUsers]);
  useEffect(() => {
    if (props?.isRefreshed) {
      localStorage.removeItem('filterAppliedForCompletedUsers');
      localStorage.removeItem('isFilterAppliedForWaitingUsers');
    }
  }, [props?.isRefreshed]);
  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const nextUpcomingPage = (page) => {
    dispatch(getAllNewUserPendingPlantSideRequestDetailsByType(page));
  };

  const previousUpcomingPage = (page) => {
    dispatch(getAllNewUserPendingPlantSideRequestDetailsByType(page));
  };

  const nextPastHeldPage = (page) => {
    const nextPage = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(nextPage));
  };

  const previousPastHeldPage = (page) => {
    const previousPage = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(previousPage));
  };

  const handleOnSearch = async () => {
    let valueSearch;
    Swal.fire({
      toast: true,
      icon: 'warning',
      title: 'Are you in search of a vendor code?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res?.isConfirmed) {
        valueSearch = {
          vendorCode: query,
          page: 1,
          limit: 10,
        };
      } else if (isValidIndianPhoneNumber(query)) {
        valueSearch = {
          phone: query,
          page: 1,
          limit: 10,
        };
      } else if (isValidName(query)) {
        valueSearch = {
          farmerName: query,
          page: 1,
          limit: 10,
        };
      }
      if (tabIndex === 0) {
        dispatch(searchNewUserPendingPlantSideRequestDetailsByType(valueSearch));
      } else {
        dispatch(searchAllNewUserCompletedPlantSideRequestDetailsByType(valueSearch));
      }
    });
  };
  const handleOnFilter = () => {
    setshowModalForFilter(!showModalForFilter);
  };

  const submitFilter = (e) => {
    const filterValues = {
      hpcCode: e?.hpcCode,
      fromDate: moment(e?.fromDate).format('DD/MM/YYYY'),
      page: 1,
      limit: 10,
      toDate: moment(e?.toDate).format('DD/MM/YYYY'),
    };
    if (tabIndex === 0) {
      dispatch(filterPlantSideNewUsersPendingrequests(filterValues));
      localStorage.setItem('isFilterAppliedForWaitingUsers', true);
    } else {
      dispatch(filterPlantSideNewUsersCompletedrequests(filterValues));
      localStorage.setItem('isFilterAppliedForCompletedUsers', true);
    }
  };
  const handleOnBackPress = () => {
    if (tabIndex === 0) {
      dispatch(getAllNewUserPendingPlantSideRequestDetailsByType());
    }
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(values));
  };
  return (
    <>
      <div className="admin-orders">
        {tabIndex === 0 && filterAppliedForWaitingUsers ? (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }} />
            In order to dynamically remove the filter that has been applied to pending users, please refresh the page.
          </div>
        ) : tabIndex === 1 && filterAppliedForCompletedUsers ? (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }} />
            In order to dynamically remove the filter that has been applied to completed users, please refresh the page.
          </div>
        ) : null}
        {/* <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card warning mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Pending Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.pendingLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card green mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Finished Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.finsihedLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card danger mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Rejected Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.rejectedLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Total Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.totalLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title" onClick={handleOnBackPress}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                />

                {' '}
                Pending HPC Registrations
              </p>
            </div>
          </div>
        </div>

        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Waiting for Approval
                {
                  count?.countedDigitalRegistrations?.pendingCount > 0 ? (
                    <span className="count-ui danger">
                      { formatCount(count?.countedDigitalRegistrations?.pendingCount)}
                      {/* <CountUp start={0} end={formatCount(count?.countedDigitalRegistrations?.pendingCount)} duration={3} /> */}
                    </span>
                  ) : null
                }
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Completed
                {count?.countedDigitalRegistrations?.approvedCount > 0 ? (
                  <span className="count-ui">
                    { formatCount(count?.countedDigitalRegistrations?.approvedCount)}
                    {/* <CountUp start={0} end={formatCount(count?.countedDigitalRegistrations?.approvedCount)} duration={3} /> */}
                  </span>
                ) : null}
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            {/* <div className="row middle">
              <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green">
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div> */}
            <div className="row middle">
              <div className="col-12 col-md-10">
                <Search
                  value={query}
                  label="Find Farmers By Name, Vendor Code, or Phone"
                  onChangeText={handleChange}
                  onSubmit={handleOnSearch}
                />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button
                    type="button"
                    className="button green"
                    onClick={handleOnFilter}
                  >
                    <FontAwesomeIcon icon={faFilter} className="button__icon" />
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {upcoming && upcoming.paginate && upcoming.paginate.length
                ? upcoming.paginate.map((item) => (
                  <>
                    {
                  item?.equalId === null && item?.isEqualGenerated === false ? (
                    <div className="col-12 col-lg-12">
                      <div className="card">
                        <div className="card__left">
                          <div className="card__avatar">
                            {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                          </div>
                          <div className="row between col-9">
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  Farmer Name :
                                  {' '}
                                </span>
                                {item ? item?.farmerName : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Registration ID :
                                  {' '}
                                </span>
                                {item ? item?.id : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Phone:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.phone : ''}

                              </div>
                              <div>
                                <span className="card__title">
                                  Role:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                              </div>
                            </div>
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  User Created At :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Plant Code :
                                  {' '}
                                </span>
                                {item?.plantCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  HPC Code :
                                  {' '}
                                </span>
                                {item?.hpcCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  User Created Time :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('hh:mm a')}
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="card__right">
                          {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCheckCircle} />

                            </div>
                            )
                          }

                          <Link to={`/plant-admin/pendingHpcRegistrations/${item?.id}`} className="card__button">View</Link>
                        </div>
                      </div>
                    </div>
                  ) : item?.equalId && item?.isEqualGenerated ? (
                    <div className="col-12 col-lg-12">
                      <div className="card">
                        <div className="card__left">
                          <div className="card__avatar">
                            {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                          </div>
                          <div className="row between col-9">
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  Farmer Name :
                                  {' '}
                                </span>
                                {item ? item?.farmerName : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Registration ID :
                                  {' '}
                                </span>
                                {item ? item?.id : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Phone:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.phone : ''}

                              </div>
                              <div>
                                <span className="card__title">
                                  Role:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                              </div>
                            </div>
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  User Created At :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Plant Code :
                                  {' '}
                                </span>
                                {item?.plantCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  HPC Code :
                                  {' '}
                                </span>
                                {item?.hpcCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  User Created Time :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('hh:mm a')}
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="card__right">
                          {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCheckCircle} />

                            </div>
                            )
                          }
                          <Link to={`/plant-admin/pendingHpcRegistrations/${item?.id}`} className="card__button">View</Link>
                        </div>
                      </div>
                    </div>
                  ) : null
                 }
                  </>
                ))
                : (
                  null
                )}
              {!isLoading && !upcoming && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No upcoming User Request found</span>
                </div>
              </div>
              )}
            </div>
            {upcoming
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Previous}
                        onClick={() => previousUpcomingPage(upcoming.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {upcoming.Next
                          ? upcoming.Next.page - 1
                          : upcoming.Previous
                            ? upcoming.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Next}
                        onClick={() => nextUpcomingPage(upcoming.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            <div className="row middle">
              <div className="col-12 col-md-10">
                <Search
                  value={query}
                  onChangeText={handleChange}
                  onSubmit={handleOnSearch}
                />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button
                    type="button"
                    className="button green"
                    onClick={handleOnFilter}
                  >
                    <FontAwesomeIcon icon={faFilter} className="button__icon" />
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {pastHeld && pastHeld.paginate && pastHeld.paginate.length
                ? pastHeld.paginate.map((item) => (
                  <>
                    {
                  item?.equalId === null && !item?.isEqualGenerated ? (
                    <div className="col-12 col-lg-12">
                      <div className="card">
                        <div className="card__left">
                          <div className="card__avatar">
                            {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                          </div>
                          <div className="row between col-9">
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  Farmer Name :
                                  {' '}
                                </span>
                                {item ? item?.farmerName : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Registration ID :
                                  {' '}
                                </span>
                                {item ? item?.id : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Phone:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.phone : ''}

                              </div>
                              <div>
                                <span className="card__title">
                                  Role:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                              </div>
                              {
                              item?.status === 'rejected' && item?.comment && (
                                <div>
                                  <span className="card__title">
                                    Reason:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  <span className="card__text--danger">
                                    {item ? item?.comment : ''}
                                  </span>

                                </div>
                              )
                            }

                            </div>
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  User Created At :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Plant Code :
                                  {' '}
                                </span>
                                {item?.plantCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  HPC Code :
                                  {' '}
                                </span>
                                {item?.hpcCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  User Created Time :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('hh:mm a')}
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="card__right">
                          <div
                            className={`card__text--${item?.status === 'approved' ? 'success'
                              : item?.status === 'pending' ? 'warning' : item?.status === 'rejected' ? 'danger' : ''}`}
                          >
                            <span
                              className="card__title"
                            >
                              Status :
                              {' '}
                            </span>
                            {item?.status?.toUpperCase()}
                          </div>
                          {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCheckCircle} />

                            </div>
                            )
                          }
                          <Link
                            to={`/plant-admin/pendingHpcRegistrations/${item.id}`}
                            className="card__button"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : item?.equalId && item?.isEqualGenerated ? (
                    <div className="col-12 col-lg-12">
                      <div className="card">
                        <div className="card__left">
                          <div className="card__avatar">
                            {
                          item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                          ) : <img src={logoImg} alt="user" />

                        }
                          </div>
                          <div className="row between col-9">
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  Farmer Name :
                                  {' '}
                                </span>
                                {item ? item?.farmerName : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Registration ID :
                                  {' '}
                                </span>
                                {item ? item?.id : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Phone:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.phone : ''}

                              </div>
                              <div>
                                <span className="card__title">
                                  Role:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                              </div>
                              {
                            item?.status === 'rejected' && item?.comment && (
                              <div>
                                <span className="card__title">
                                  Reason:
                                  {' '}
                                </span>
                                {/* {item?.id ? item?.id : ''} */}
                                <span className="card__text--danger">
                                  {item ? item?.comment : ''}
                                </span>

                              </div>
                            )
                          }

                            </div>
                            <div className="card__text">
                              <div>
                                <span className="card__title">
                                  User Created At :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Plant Code :
                                  {' '}
                                </span>
                                {item?.plantCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  HPC Code :
                                  {' '}
                                </span>
                                {item?.hpcCode ?? 'N/A'}
                              </div>
                              <div>
                                <span className="card__title">
                                  User Created Time :
                                  {' '}
                                </span>
                                {moment(item?.createdAt).format('hh:mm a')}
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="card__right">
                          <div
                            className={`card__text--${item?.status === 'approved' ? 'success'
                              : item?.status === 'pending' ? 'warning' : item?.status === 'rejected' ? 'danger' : ''}`}
                          >
                            <span
                              className="card__title"
                            >
                              Status :
                              {' '}
                            </span>
                            {item?.status?.toUpperCase()}
                          </div>
                          {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCheckCircle} />

                            </div>
                            )
                          }
                          <Link
                            to={`/plant-admin/pendingHpcRegistrations/${item.id}`}
                            className="card__button"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null
                 }
                  </>
                ))
                : (
                  null
                )}
              {!isLoading && !pastHeld && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No past held users found</span>
                </div>
              </div>
              )}
            </div>
            {pastHeld
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Previous}
                        onClick={() => previousPastHeldPage(pastHeld.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {pastHeld.Next
                          ? pastHeld.Next.page - 1
                          : pastHeld.Previous
                            ? pastHeld.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Next}
                        onClick={() => nextPastHeldPage(pastHeld.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
        {showModalForFilter ? (
          <ModalForFilter
            show={showModalForFilter}
            toggle={handleOnFilter}
            onSubmit={submitFilter}
            // values={data}
          />
        ) : null}
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default withScreenRefreshDetection(PendingRegistrationApprovalForPlantIc);
