import {
  SEND_START,
  SEND_SUCCESS,
  SEND_ERROR,
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
} from './types';

const initialState = {
  notification: null,
  notifications: null,
  message: null,
  isLoading: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEND_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEND_SUCCESS:
      return {
        ...state,
        message: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEND_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_ALL_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_SUCCESS:
      return {
        ...state,
        notifications: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case DELETE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case DELETE_SUCCESS:
      return {
        ...state,
        message: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case DELETE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    default:
      return state;
  }
};
