/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Landing from '../pages/Landing';
import AdminLogin from '../pages/AdminLogin';
import DoctorLogin from '../pages/DoctorLogin';
import PlantAdminLogin from '../pages/PlantAdminLogin';
import DoctorSignup from '../pages/DoctorSignup';
import ServiceSignup from '../pages/ServiceSignup';
import AdminDashboardLayout from '../layouts/AdminDashboard';
import DoctorDashboardLayout from '../layouts/DoctorDashboard';
import PlantAdminDashboardLayout from '../layouts/PlantAdminDashboard';
// import Upcoming from '../pages/Upcoming';
// import PastHeld from '../pages/PastHeld';
import Error404 from '../pages/Error404';
import Error403 from '../pages/Error403';

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Landing />
    </Route>
    <PublicRoute exact path="/auth/admin">
      <AdminLogin />
    </PublicRoute>
    <PublicRoute exact path="/auth/doctor">
      <DoctorLogin />
    </PublicRoute>
    <PublicRoute exact path="/auth/plant-admin">
      <PlantAdminLogin />
    </PublicRoute>
    <PublicRoute exact path="/auth/doctor-enrollment">
      <DoctorSignup />
    </PublicRoute>
    <PublicRoute exact path="/auth/service-enrollment">
      <ServiceSignup />
    </PublicRoute>
    <PrivateRoute path="/admin">
      <AdminDashboardLayout />
    </PrivateRoute>
    <PrivateRoute path="/doctor">
      <DoctorDashboardLayout />
    </PrivateRoute>
    <Route path="/plant-admin">
      <PlantAdminDashboardLayout />
    </Route>
    <Route exact path="/403" render={() => <Error403 />} />
    <Route exact render={() => <Error404 />} />
  </Switch>
);

export default Routes;
