/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useTable } from 'react-table';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getProducts } from '../../store/modules/product/actions';
import { selectProducts, selectIsLoading as selectIsLoadingProduct } from '../../store/modules/product/selectors';
import { createOrder, getOrders, exportOrders } from '../../store/modules/order/actions';
import { selectOrders, selectIsLoading, selectIsLoadingExport } from '../../store/modules/order/selectors';
import ProductModal from '../../components/ProductModal';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';

import './CreateOrder.scss';

const CreateOrder = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selected, setSelected] = useState(null);
  const [cart, setCart] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectError, setSelectError] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'prodName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const orders = useSelector((state) => selectOrders(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const products = useSelector((state) => selectProducts(state));
  const isLoadingProduct = useSelector((state) => selectIsLoadingProduct(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));

  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm({ delivery: '7' });
  const watchDelivery = watch('delivery');

  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(getOrders());
    } else {
      dispatch(getProducts());
    }
  }, [tabIndex]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: products && products.paginate ? products.paginate : [],
  });

  const nextPage = (page) => {
    dispatch(getProducts(page));
  };

  const previousPage = (page) => {
    dispatch(getProducts(page));
  };

  const nextOrderPage = (page) => {
    dispatch(getOrders(page));
  };

  const previousOrderPage = (page) => {
    dispatch(getOrders(page));
  };

  const toggleProductModal = () => {
    setShowProductModal(!showProductModal);
  };

  const onSelect = (value) => {
    setSelected(value);
    const selectedItem = cart.find((item) => item.id === value.id);
    if (selectedItem) {
      setCart((prevState) => [
        ...prevState.filter((item) => item.id !== value.id),
      ]);
      return;
    }
    toggleProductModal();
  };

  const onCart = (value) => {
    setCart((prevState) => [...prevState, value]);
  };

  const placeOrder = ({ delivery }) => {
    if (!cart.length || !delivery) {
      toast.error('Please, select a product to place an order');
      return;
    }

    const order = {
      items: cart.map((item) => ({
        prodId: item.id,
        prodPrice: item.price,
        quantity: `${item.quantity}`,
        prodSize: item.size,
        palletType: item.palletType,
        subTotal: `${item.total}`,
      })),
      deliveryTimePeriod: `${delivery} days`,
      deliveryDate: moment().add(watchDelivery, 'days'),
      total: cart.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2),
      userType: 'plant',
    };

    dispatch(createOrder(order, () => setCart([])));
  };

  const reportOrders = () => {
    dispatch(exportOrders());
  };

  return (
    <>
      <div className="create-order">
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Place order
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Past Held
              </div>
            </Tab>
          </TabList>
          <TabPanel className="order-tab-panel">
            <div className="row">
              <div className="col-12">
                <div className="heading">
                  <p className="heading__title">
                    Select Product and Quantity
                  </p>
                </div>
              </div>
            </div>
            <div className="table-container">
              <div className="row">
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          <th>#</th>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                          ))}
                          <th>Size</th>
                          <th>Pallet Type</th>
                          <th>Quantity</th>
                          <th>Product Price/Unit</th>
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            <td>
                              <div className="form__item p-0">
                                <div className="row middle">
                                  <div className="col-12">
                                    <input
                                      className="form__check"
                                      type="checkbox"
                                      id={row.original.id}
                                    // name="selected"
                                      checked={
                                      !!cart.find((el) => el.id === row.original.id)
                                    }
                                      onChange={() => onSelect(row.original)}
                                    />
                                    {errors.phone && <div className="form__error">{errors.quantity.message}</div>}
                                  </div>
                                </div>
                              </div>
                            </td>
                            {row.cells.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                            <td>
                              <div className="form__item p-0 m-w-100px">
                                <label htmlFor="size">
                                  <div className="row middle">
                                    <div className="col-12">
                                      <select
                                        className="form__field"
                                        disabled
                                        readOnly
                                      >
                                        {cart.find((el) => el.id === row.original.id)
                                          ? (
                                            <option>
                                              {cart.find((el) => el.id === row.original.id).size}
                                            </option>
                                          )
                                          : row.original.Quantity.map((el) => (
                                            <option key={el.id}>{el.quantity}</option>))}
                                      </select>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="form__item p-0 m-w-100px">
                                <label htmlFor="size">
                                  <div className="row middle">
                                    <div className="col-12">
                                      <select
                                        className="form__field"
                                        disabled
                                        readOnly
                                      >
                                        {cart.find((el) => el.id === row.original.id)
                                          ? (
                                            <option>
                                              {cart.find(
                                                (el) => el.id === row.original.id,
                                              ).palletType}
                                            </option>
                                          )
                                          : row.original.Quantity[0].Pallet.map((el) => (
                                            <option key={el.id}>{el.palletType || '-'}</option>))}
                                      </select>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="form__item p-0 m-w-100px">
                                <label htmlFor="quantity">
                                  <div className="row middle">
                                    <div className="col-12">
                                      <input
                                        className="form__field"
                                        type="number"
                                        disabled
                                        readOnly
                                        value={cart.find((el) => el.id === row.original.id)
                                          ? cart.find((el) => el.id === row.original.id).quantity
                                          : 0}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </td>
                            <td>
                              {cart.find((el) => el.id === row.original.id)
                                ? cart.find((el) => el.id === row.original.id).price
                                : row.original.Quantity[0].Pallet[0].price}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (products ? previousPage(products.Previous.page) : {})}
                            disabled={!products || !products.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {products && products.Next
                              ? products.Next.page - 1
                              : products && products.Previous
                                ? products.Previous.page + 1
                                : 1}
                            {/* /1 */}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (products ? nextPage(products.Next.page) : {})}
                            disabled={!products || !products.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="form__label">Order Details</span>
              </div>
              <div className="col-12">
                <div className="form">
                  <div className="row">
                    <div className="col-12">
                      <div className="form__item">
                        <div className="row">
                          <div className="col-12 col-md-2">
                            <span className="form__label">Total Price:</span>
                          </div>
                          <div className="col-12 col-md-4">
                            <span className="form__label">
                              {cart.length
                                ? cart.reduce((
                                  acc, item,
                                ) => acc + item.quantity * item.price, 0).toFixed(2)
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="delivery">
                          <div className="row">
                            <div className="col-12 col-md-2">
                              <span className="form__label">Delivery By:</span>
                            </div>
                            <div className="col-12 col-md-4">
                              <select
                                className="form__field"
                                id="delivery"
                                name="delivery"
                                placeholder="Delivery By"
                                {...register('delivery', {
                                  required: {
                                    value: true,
                                    message: 'Delivery By is required',
                                  },
                                })}
                              >
                                <option value="7">7 Days</option>
                                <option value="15">15 Days</option>
                                <option value="30">30 Days</option>
                              </select>
                              {errors.delivery && <div className="form__error">{errors.delivery.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <div className="row">
                          <div className="col-12 col-md-2">
                            <span className="form__label">Delivery Date:</span>
                          </div>
                          <div className="col-12 col-md-4">
                            <span className="form__label">{moment().add(watchDelivery || '7', 'days').format('DD/MM/YYYY')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <div className="row">
                          <div className="col-12 d-flex-col middle">
                            <button type="button" className="form__button" onClick={handleSubmit(placeOrder)}>Order</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="order-tab-panel">
            <div className="row end">
              {/* <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div> */}
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green" onClick={reportOrders}>
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {orders && orders.paginate && orders.paginate.length
                ? orders.paginate.map((order) => (
                  <div className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card__left">
                        <div className="card__avatar">
                          <img src={logoImg} alt="order" />
                        </div>
                        <div className="card__text">
                          <div className="row">
                            <div className="col-12 col-lg-4">
                              <div>
                                <span className="card__title">
                                  Order ID :
                                  {' '}
                                </span>
                                {order.id}
                              </div>
                              <div>
                                <span className="card__title">
                                  Order Date :
                                  {' '}
                                </span>
                                {moment(order.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Order Time :
                                  {' '}
                                </span>
                                {moment(order.createdAt).format('hh:mm a')}
                              </div>
                            </div>
                            {order.status === 'finished' || order.status === 'rejected' ? (
                              <div className="col-12 col-lg-4">
                                <div>
                                  <span className="card__title">
                                    {order.status === 'finished' ? 'Finished Date' : 'Rejected Date'}
                                    {' '}
                                    :
                                    {' '}
                                  </span>
                                  {moment(order.finishedDate).format('DD/MM/YYYY')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    {order.status === 'finished' ? 'Finished Time' : 'Rejected Time'}
                                    {' '}
                                    :
                                    {' '}
                                  </span>
                                  {moment(order.finishedDate).format('hh:mm a')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Total Time Taken :
                                    {' '}
                                  </span>
                                  {moment(order.finishedDate).diff(order.createdAt, 'days')}
                                  {' '}
                                  days
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="card__right">
                        <div
                          className={`mb-8 card__text--${order.status === 'finished' ? 'primary'
                            : order.status === 'rejected' ? 'danger' : 'warning'}`}
                        >
                          <span
                            className="card__title"
                          >
                            Status :
                            {' '}
                          </span>
                          {order.status === 'finished' ? 'approved' : order.status}
                        </div>
                        <Link
                          to={`/plant-admin/orders/${order.id}`}
                          className="card__button"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  null
                )}
              {!isLoading && !orders && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No orders found</span>
                </div>
              </div>
              )}
            </div>
            {orders
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!orders.Previous}
                        onClick={() => previousOrderPage(orders.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {orders.Next
                          ? orders.Next.page - 1
                          : orders.Previous
                            ? orders.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!orders.Next}
                        onClick={() => nextOrderPage(orders.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      {showProductModal && (
      <ProductModal
        show={showProductModal}
        toggle={toggleProductModal}
        onSubmit={onCart}
        product={selected}
      />
      )}
      <LoadingModal show={isLoading || isLoadingProduct || isLoadingExport} />
    </>
  );
};

export default CreateOrder;
