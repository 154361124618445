import { createSelector } from 'reselect';

export const selectRaw = (state) => state.market;

export const selectSale = createSelector([selectRaw], (market) => market.sale);

export const selectSales = createSelector(
  [selectRaw],
  (market) => market.sales,
);

export const selectApprovedSales = createSelector(
  [selectRaw],
  (market) => market.approvedSales,
);

export const selectMessage = createSelector(
  [selectRaw],
  (market) => market.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (market) => market.isLoading,
);

export const selectIsLoadingUpdate = createSelector(
  [selectRaw],
  (market) => market.isLoadingUpdate,
);

export const selectIsLoadingDelete = createSelector(
  [selectRaw],
  (market) => market.isLoadingDelete,
);

export const selectIsLoadingExport = createSelector(
  [selectRaw],
  (market) => market.isLoadingExport,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (market) => market.errorMessage,
);

const selectors = {
  selectRaw,
  selectSale,
  selectSales,
  selectApprovedSales,
  selectMessage,
  selectIsLoading,
  selectIsLoadingDelete,
  selectErrorMessage,
};

export default selectors;
