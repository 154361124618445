/* eslint-disable max-len */
import dotenv from 'dotenv';

dotenv.config();

const isProduction = process.env.NODE_ENV === 'production';

const config = {
  apiUrl: isProduction ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL,
  doctorApiUrl: isProduction ? process.env.REACT_APP_PROD_DOC_URL : process.env.REACT_APP_DEV_DOC_URL,
  evmUrl: process.env.REACT_APP_EVM_URL,
  ipV4Url: process.env.REACT_APP_IPV4,
  ip64Url: process.env.REACT_APP_IP64,
};

export default config;
