/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';

const withScreenRefreshDetection = (WrappedComponent) => (props) => {
  const isRefreshedRef = useRef(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const handleBeforeUnload = (event) => {
    if (!isRefreshedRef.current) {
      event.preventDefault();
      event.returnValue = '';
      setIsRefreshed(true); // Update the isRefreshed state here
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    isRefreshedRef.current = isRefreshed;
  }, [isRefreshed]);

  return <WrappedComponent {...props} isRefreshed={isRefreshed} />;
};

export default withScreenRefreshDetection;
