const prefix = 'APPOINTMENT';

export const GET_UPCOMING_START = `${prefix}_GET_UPCOMING_START`;
export const GET_UPCOMING_SUCCESS = `${prefix}_GET_UPCOMING_SUCCESS`;
export const GET_UPCOMING_ERROR = `${prefix}_GET_UPCOMING_ERROR`;

export const GET_PAST_HELD_START = `${prefix}_GET_PAST_HELD_START`;
export const GET_PAST_HELD_SUCCESS = `${prefix}_GET_PAST_HELD_SUCCESS`;
export const GET_PAST_HELD_ERROR = `${prefix}_GET_PAST_HELD_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const SEARCH_UPCOMING_START = `${prefix}_SEARCH_UPCOMING_START`;
export const SEARCH_UPCOMING_SUCCESS = `${prefix}_SEARCH_UPCOMING_SUCCESS`;
export const SEARCH_UPCOMING_ERROR = `${prefix}_SEARCH_UPCOMING_ERROR`;

export const SEARCH_PAST_HELD_START = `${prefix}_SEARCH_PAST_HELD_START`;
export const SEARCH_PAST_HELD_SUCCESS = `${prefix}_SEARCH_PAST_HELD_SUCCESS`;
export const SEARCH_PAST_HELD_ERROR = `${prefix}_SEARCH_PAST_HELD_ERROR`;

export const FINISH_START = `${prefix}_FINISH_START`;
export const FINISH_SUCCESS = `${prefix}_FINISH_SUCCESS`;
export const FINISH_ERROR = `${prefix}_FINISH_ERROR`;

export const REJECT_START = `${prefix}_REJECT_START`;
export const REJECT_SUCCESS = `${prefix}_REJECT_SUCCESS`;
export const REJECT_ERROR = `${prefix}_REJECT_ERROR`;

export const UPLOAD_PRESCRIPTION_START = `${prefix}_UPLOAD_PRESCRIPTION_START`;
export const UPLOAD_PRESCRIPTION_SUCCESS = `${prefix}_UPLOAD_PRESCRIPTION_SUCCESS`;
export const UPLOAD_PRESCRIPTION_ERROR = `${prefix}_UPLOAD_PRESCRIPTION_ERROR`;

export const GET_FOLLOW_UP_START = `${prefix}_GET_FOLLOW_UP_START`;
export const GET_FOLLOW_UP_SUCCESS = `${prefix}_GET_FOLLOW_UP_SUCCESS`;
export const GET_FOLLOW_UP_ERROR = `${prefix}_GET_FOLLOW_UP_ERROR`;

export const SET_FOLLOW_UP_START = `${prefix}_SET_FOLLOW_UP_START`;
export const SET_FOLLOW_UP_SUCCESS = `${prefix}_SET_FOLLOW_UP_SUCCESS`;
export const SET_FOLLOW_UP_ERROR = `${prefix}_SET_FOLLOW_UP_ERROR`;

export const GET_ALL_COUNT_START = `${prefix}_GET_ALL_COUNT_START`;
export const GET_ALL_COUNT_SUCCESS = `${prefix}_GET_ALL_COUNT_SUCCESS`;
export const GET_ALL_COUNT_ERROR = `${prefix}_GET_ALL_COUNT_ERROR`;

export const GET_ALL_COUNT_PHYSICAL_START = `${prefix}_GET_ALL_COUNT_PHYSICAL_START`;
export const GET_ALL_COUNT_PHYSICAL_SUCCESS = `${prefix}_GET_ALL_COUNT_PHYSICAL_SUCCESS`;
export const GET_ALL_COUNT_PHYSICAL_ERROR = `${prefix}_GET_ALL_COUNT_PHYSICAL_ERROR`;
