/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faMoneyBill,
  faFilter,
  faCheckCircle,
  faFileExcel,
  faWarning,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Swal from 'sweetalert2';
import { formatCount, isValidIndianPhoneNumber, isValidName } from '../../components/Validators';
import CountUp from '../../components/CountUp';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import {
  getAllNewUserPendingRequestDetailsByType,
  getAllNewUserCompletedRequestDetailsByType,
  searchByPhonePendingNewUsers,
  searchByPhoneCompletedNewUsers,
  filterAllNewUserCompletedRequestDetailsByType,
  filterAllNewUserPendingRequestDetailsByType,
  exportExcelForPending,
  exportExcelForCompleted,
  getcountDigitalRegistraion,
  bulkUploadEapi,
} from '../../store/modules/newUser/actions';
import {
  selectCompletedUser,
  selectIsLoading,
  selectPendingUser,
  selectDigitalRegistrationCount,
} from '../../store/modules/newUser/selectors';
import ModalForFilter from '../../components/ModalForFilter';
import withScreenRefreshDetection from '../../utils/withScreenRefreshDetection';
import './PendingHpcRepRegistration.scss';

const pendingHpcRegistration = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const upcoming = useSelector((state) => selectPendingUser(state));
  const pastHeld = useSelector((state) => selectCompletedUser(state));
  const count = useSelector((state) => selectDigitalRegistrationCount(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const filterAppliedForWaitingUsers = localStorage.getItem(
    'isFilterAppliedForWaitingUsers',
  );
  const filterAppliedForCompletedUsers = localStorage.getItem(
    'isFilterAppliedForCompletedUsers',
  );

  const values = {
    page: 1,
    limit: 10,
  };

  const fetchDetailsBasedOnStates = () => {
    if (tabIndex === 0) {
      dispatch(getAllNewUserPendingRequestDetailsByType(values));
    } else if (tabIndex === 1) {
      dispatch(getAllNewUserCompletedRequestDetailsByType(values));
    }
  };

  const handleConfirmPendingUsers = () => {
    localStorage.removeItem('isFilterAppliedForWaitingUsers');
    fetchDetailsBasedOnStates();
  };

  const handleConfirmCompletedUsers = () => {
    localStorage.removeItem('isFilterAppliedForCompletedUsers');
    fetchDetailsBasedOnStates();
  };
  const handleRefresh = (response) => {
    if (response) {
      dispatch(getAllNewUserPendingRequestDetailsByType(values));
      dispatch(getAllNewUserCompletedRequestDetailsByType(values));
      dispatch(getcountDigitalRegistraion('Admin'));
    }
  };
  useEffect(() => {
    dispatch(getcountDigitalRegistraion('Admin'));
  }, [dispatch]);

  useEffect(() => {
    if (tabIndex === 0 && filterAppliedForWaitingUsers) {
      Swal.fire({
        icon: 'warning',
        title:
          'Filter applied for pending users, do you want to continue anyway?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((result) => {
        if (result.isDismissed) {
          handleConfirmPendingUsers();
        }
      });
    } else if (tabIndex === 1 && filterAppliedForCompletedUsers) {
      Swal.fire({
        icon: 'warning',
        title:
          'Filter applied for completed users, do you want to continue anyway?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((result) => {
        if (result.isDismissed) {
          handleConfirmCompletedUsers();
        }
      });
    } else {
      fetchDetailsBasedOnStates();
    }
  }, [tabIndex, filterAppliedForWaitingUsers, filterAppliedForCompletedUsers]);

  useEffect(() => {
    if (props?.isRefreshed) {
      localStorage.removeItem('filterAppliedForCompletedUsers');
      localStorage.removeItem('isFilterAppliedForWaitingUsers');
    }
  }, [props?.isRefreshed]);
  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };
  const handleUploadExcelFile = async () => {
    try {
      const { value: file } = await Swal.fire({
        icon: 'info',
        title: 'Upload File',
        input: 'file',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        html: '<div><p>To download a sample Excel format, click the link below.</p><a href="https://heritage-vet-plus-server-buckets.s3.ap-south-1.amazonaws.com/images/assets/eapi-bulkupload.xlsx">Download</a></div>',
        inputAttributes: {
          accept: '.xls, .xlsx, .csv',
          'aria-label': 'Upload your file',
        },
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose a file';
          }
        },
      });

      if (file) {
        const fileSize = file.size;
        const maxFileSize = 50 * 1024 * 1024; // 50MB limit
        if (fileSize > maxFileSize) {
          throw new Error(`File size exceeds ${maxFileSize / (1024 * 1024)}MB limit`);
        }
        if (!['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'].includes(file.type)) {
          throw new Error('Invalid file format');
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          let timerInterval;
          const fileSizeInMb = fileSize / (1024 * 1024);
          const uploadTimeInSeconds = Math.ceil(fileSizeInMb); // 1 second per 1MB
          Swal.fire({
            icon: 'success',
            toast: true,
            title: 'Uploading your data!',
            html: `Uploading ${fileSizeInMb.toFixed(2)} MB file in <b>${uploadTimeInSeconds} seconds</b>.`,
            timer: uploadTimeInSeconds * 1000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        };
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append('file', file);
        dispatch(bulkUploadEapi(formData, handleRefresh));
      } else {
        throw new Error('No file was uploaded.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        toast: true,
        text: error.message,
      });
    }
  };
  const nextUpcomingPage = (page) => {
    const nextPageValue = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserPendingRequestDetailsByType(nextPageValue));
  };
  const handleOnExportExcelRecords = () => {
    if (tabIndex === 0) {
      dispatch(exportExcelForPending('pending'));
    } else {
      dispatch(exportExcelForCompleted('completed'));
    }
  };
  const previousUpcomingPage = (page) => {
    const previousUpcomingPageValue = {
      page,
      limit: 10,
    };
    dispatch(
      getAllNewUserPendingRequestDetailsByType(previousUpcomingPageValue),
    );
  };

  const nextPastHeldPage = (page) => {
    const nextPageValue = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedRequestDetailsByType(nextPageValue));
  };

  const previousPastHeldPage = (page) => {
    const nextPageValue = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedRequestDetailsByType(nextPageValue));
  };
  const handleOnSearch = async () => {
    let searchValues;
    Swal.fire({
      toast: true,
      icon: 'warning',
      title: 'Are you in search of a vendor code?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res?.isConfirmed) {
        searchValues = {
          vendorCode: query,
          page: 1,
          limit: 10,
        };
      } else if (isValidIndianPhoneNumber(query)) {
        searchValues = {
          phone: query,
          page: 1,
          limit: 10,
        };
      } else if (isValidName(query)) {
        searchValues = {
          farmerName: query,
          page: 1,
          limit: 10,
        };
      }
      if (tabIndex === 0) {
        setTabIndex(0);
        dispatch(searchByPhonePendingNewUsers(searchValues));
      } else {
        setTabIndex(1);
        dispatch(searchByPhoneCompletedNewUsers(searchValues));
      }
    });
  };

  const handleOnFilter = () => {
    setshowFilterModal(!showFilterModal);
  };

  const submitFilter = (e) => {
    const filterValues = {
      page: 1,
      limit: 10,
      fromDate: moment(e.fromDate).format('DD/MM/YYYY'),
      plantCode: e?.plantCode === 'Choose here' ? null : e?.plantCode,
      hpcCode: e?.hpcCode === 'Choose here' ? null : e?.hpcCode,
      toDate: moment(e.toDate).format('DD/MM/YYYY'),
    };
    if (tabIndex === 0) {
      dispatch(filterAllNewUserPendingRequestDetailsByType(filterValues));
      localStorage.setItem('isFilterAppliedForWaitingUsers', true);
    } else {
      dispatch(filterAllNewUserCompletedRequestDetailsByType(filterValues));
      localStorage.setItem('isFilterAppliedForCompletedUsers', true);
    }
  };
  const handleOnBackPress = () => {
    if (tabIndex === 0) {
      dispatch(getAllNewUserPendingRequestDetailsByType(values));
    } else if (tabIndex === 1) {
      dispatch(getAllNewUserCompletedRequestDetailsByType(values));
    }
  };

  return (
    <>
      <div className="admin-orders">
        {tabIndex === 0 && filterAppliedForWaitingUsers ? (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }} />
            In order to dynamically remove the filter that has been applied to pending users, please refresh the page.
          </div>
        ) : tabIndex === 1 && filterAppliedForCompletedUsers ? (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }} />
            In order to dynamically remove the filter that has been applied to completed users, please refresh the page.
          </div>
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="heading" onClick={handleOnBackPress}>
              <p className="heading__title">
                <FontAwesomeIcon icon={faChevronLeft} />
                {' '}
                Pending New User
                Registrations
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search
              value={query}
              label="Find Farmers By Name, Vendor Code, or Phone"
              onChangeText={handleChange}
              onSubmit={handleOnSearch}
            />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button
                type="button"
                className="button primary"
                onClick={handleOnFilter}
              >
                <FontAwesomeIcon icon={faFilter} className="button__icon" />
                Filter
              </button>
              <button
                type="button"
                className="button green"
                onClick={handleOnExportExcelRecords}
              >
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
              {
                role === 'ea_pi' ? (
                  <button
                    type="button"
                    className="button danger"
                    onClick={handleUploadExcelFile}
                  >
                    <FontAwesomeIcon icon={faFileUpload} className="button__icon" />
                    Upload
                  </button>
                ) : null
              }

            </div>
          </div>
        </div>
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Waiting for Approval
                {
                  count?.countedDigitalRegistrations?.pendingCount > 0 ? (
                    <span className="count-ui danger">
                      {formatCount(count?.countedDigitalRegistrations?.pendingCount)}
                      {/* <CountUp start={0} end={formatCount(count?.countedDigitalRegistrations?.pendingCount)} duration={3} /> */}
                    </span>
                  ) : null
                }

              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Completed
                {count?.countedDigitalRegistrations?.approvedCount > 0 ? (
                  <span className="count-ui">
                    {formatCount(count?.countedDigitalRegistrations?.approvedCount)}
                    {/* <CountUp start={0} end={formatCount(count?.countedDigitalRegistrations?.approvedCount)} duration={3} /> */}
                  </span>
                ) : null}

              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            {/* <div className="row middle">
              <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green">
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div> */}
            <div className="row">
              {upcoming && upcoming.paginate && upcoming.paginate.length
                ? upcoming.paginate.map((item) => (
                  <>
                    {item?.equalId === null
                      && item?.isEqualGenerated === false ? (
                        <div className="col-12 col-lg-12">
                          <div className="card">
                            <div className="card__left">
                              <div className="card__avatar">
                                {item?.profilePicture ? (
                                  <img src={item?.profilePicture} alt="order" />
                                ) : (
                                  <img src={logoImg} alt="user" />
                                )}
                              </div>
                              <div className="row between col-9">
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      Farmer Name :
                                      {' '}
                                    </span>
                                    {item ? item?.farmerName : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Registration ID :
                                      {' '}
                                    </span>
                                    {item ? item?.id : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Phone: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item ? item?.phone : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Role: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item
                                      ? item?.mccType === 'hpc_farmer'
                                        ? 'HPC Farmer'
                                        : 'HPC Representative'
                                      : ''}
                                  </div>
                                </div>
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      User Created At :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Plant Code :
                                      {' '}
                                    </span>
                                    {item?.plantCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      HPC Code :
                                      {' '}
                                    </span>
                                    {item?.hpcCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      User Created Time :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format('hh:mm a')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card__right">
                              {item?.isEqualGenerated === true && (
                                <div className="card__text--success">
                                  <span className="card__title">
                                    E-KYC DONE
                                    {' '}
                                  </span>
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                              )}
                              {role === 'admin'
                              || role === 'ea_pi'
                              || role === 'regional_pi'
                              || role === 'regional_fa' ? (
                                <Link
                                  to={`/admin/newUser/${item?.id}`}
                                  className="card__button"
                                >
                                  View
                                </Link>
                                ) : (
                                  <Link
                                    to={`/plant-admin/pendingHpcRegistrations/${item?.id}`}
                                    className="card__button"
                                  >
                                    View
                                  </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : item?.equalId && item?.isEqualGenerated ? (
                        <div className="col-12 col-lg-12">
                          <div className="card">
                            <div className="card__left">
                              <div className="card__avatar">
                                {item?.profilePicture ? (
                                  <img src={item?.profilePicture} alt="order" />
                                ) : (
                                  <img src={logoImg} alt="user" />
                                )}
                              </div>
                              <div className="row between col-9">
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      Farmer Name :
                                      {' '}
                                    </span>
                                    {item ? item?.farmerName : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Registration ID :
                                      {' '}
                                    </span>
                                    {item ? item?.id : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Phone: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item ? item?.phone : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Role: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item
                                      ? item?.mccType === 'hpc_farmer'
                                        ? 'HPC Farmer'
                                        : 'HPC Representative'
                                      : ''}
                                  </div>
                                </div>
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      User Created At :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Plant Code :
                                      {' '}
                                    </span>
                                    {item?.plantCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      HPC Code :
                                      {' '}
                                    </span>
                                    {item?.hpcCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      User Created Time :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format('hh:mm a')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card__right">
                              {item?.isEqualGenerated === true && (
                                <div className="card__text--success">
                                  <span className="card__title">
                                    E-KYC DONE
                                    {' '}
                                  </span>
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                              )}
                              {role === 'admin'
                              || role === 'ea_pi'
                              || role === 'regional_pi'
                              || role === 'regional_fa' ? (
                                <Link
                                  to={`/admin/newUser/${item?.id}`}
                                  className="card__button"
                                >
                                  View
                                </Link>
                                ) : (
                                  <Link
                                    to={`/plant-admin/pendingHpcRegistrations/${item?.id}`}
                                    className="card__button"
                                  >
                                    View
                                  </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                  </>
                ))
                : null}
              {!isLoading && !upcoming && (
                <div className="col-12 col-lg-12 d-flex-col middle">
                  <div className="notFound">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="notFound__icon"
                    />
                    <span className="notFound__text">
                      No upcoming user Request found
                    </span>
                  </div>
                </div>
              )}
            </div>
            {upcoming ? (
              <div className="row">
                <div className="col-12 col-lg-12 d-flex-col middle">
                  <div className="pagination">
                    <button
                      type="button"
                      className="pagination__btn"
                      disabled={!upcoming.Previous}
                      onClick={() => previousUpcomingPage(upcoming.Previous.page)}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="pagination__btn__icon"
                      />
                    </button>
                    <span className="pagination__page">
                      {upcoming.Next
                        ? upcoming.Next.page - 1
                        : upcoming.Previous
                          ? upcoming.Previous.page + 1
                          : 1}
                    </span>
                    <button
                      type="button"
                      className="pagination__btn"
                      disabled={!upcoming.Next}
                      onClick={() => nextUpcomingPage(upcoming.Next.page)}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="pagination__btn__icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            {/* <div className="row middle">
              <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div>
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green">
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div> */}
            <div className="row">
              {pastHeld && pastHeld.paginate && pastHeld.paginate.length
                ? pastHeld.paginate.map((item) => (
                  <>
                    {item?.equalId === null
                      && item?.isEqualGenerated === false ? (
                        <div className="col-12 col-lg-12">
                          <div className="card">
                            <div className="card__left">
                              <div className="card__avatar">
                                {item?.profilePicture ? (
                                  <img src={item?.profilePicture} alt="order" />
                                ) : (
                                  <img src={logoImg} alt="user" />
                                )}
                              </div>
                              <div className="row between col-9">
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      Farmer Name :
                                      {' '}
                                    </span>
                                    {item ? item?.farmerName : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Registration ID :
                                      {' '}
                                    </span>
                                    {item ? item?.id : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Phone: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item ? item?.phone : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Role: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item
                                      ? item?.mccType === 'hpc_farmer'
                                        ? 'HPC Farmer'
                                        : 'HPC Representative'
                                      : ''}
                                  </div>
                                  {item?.status === 'rejected'
                                    && item?.comment && (
                                      <div>
                                        <span className="card__title">
                                          Reason:
                                          {' '}
                                        </span>
                                        {/* {item?.id ? item?.id : ''} */}
                                        <span className="card__text--danger">
                                          {item ? item?.comment : ''}
                                        </span>
                                      </div>
                                  )}
                                </div>
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      User Created At :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Plant Code :
                                      {' '}
                                    </span>
                                    {item?.plantCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      HPC Code :
                                      {' '}
                                    </span>
                                    {item?.hpcCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      User Created Time :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format('hh:mm a')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card__right">
                              <div
                                className={`card__text--${
                                  item?.status === 'approved'
                                    ? 'success'
                                    : item?.status === 'pending'
                                      ? 'warning'
                                      : item?.status === 'rejected'
                                        ? 'danger'
                                        : ''
                                }`}
                              >
                                <span className="card__title">Status : </span>
                                {item?.status?.toUpperCase()}
                              </div>
                              {item?.isEqualGenerated === true && (
                                <div className="card__text--success">
                                  <span className="card__title">
                                    E-KYC DONE
                                    {' '}
                                  </span>
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                              )}
                              {item?.isEqualGenerated === true && (
                                <div className="card__text--success">
                                  <span className="card__title">
                                    E-KYC DONE
                                    {' '}
                                  </span>
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                              )}
                              {role === 'admin'
                              || role === 'ea_pi'
                              || role === 'regional_pi'
                              || role === 'regional_fa' ? (
                                <Link
                                  to={`/admin/newUser/${item?.id}`}
                                  className="card__button"
                                >
                                  View
                                </Link>
                                ) : (
                                  <Link
                                    to={`/plant-admin/pendingHpcRegistrations/${item?.id}`}
                                    className="card__button"
                                  >
                                    View
                                  </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : item?.equalId && item?.isEqualGenerated ? (
                        <div className="col-12 col-lg-12">
                          <div className="card">
                            <div className="card__left">
                              <div className="card__avatar">
                                {item?.profilePicture ? (
                                  <img src={item?.profilePicture} alt="order" />
                                ) : (
                                  <img src={logoImg} alt="user" />
                                )}
                              </div>
                              <div className="row between col-9">
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      Farmer Name :
                                      {' '}
                                    </span>
                                    {item ? item?.farmerName : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Registration ID :
                                      {' '}
                                    </span>
                                    {item ? item?.id : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Phone: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item ? item?.phone : ''}
                                  </div>
                                  <div>
                                    <span className="card__title">Role: </span>
                                    {/* {item?.id ? item?.id : ''} */}
                                    {item
                                      ? item?.mccType === 'hpc_farmer'
                                        ? 'HPC Farmer'
                                        : 'HPC Representative'
                                      : ''}
                                  </div>
                                  {item?.status === 'rejected'
                                    && item?.comment && (
                                      <div>
                                        <span className="card__title">
                                          Reason:
                                          {' '}
                                        </span>
                                        {/* {item?.id ? item?.id : ''} */}
                                        <span className="card__text--danger">
                                          {item ? item?.comment : ''}
                                        </span>
                                      </div>
                                  )}
                                </div>
                                <div className="card__text">
                                  <div>
                                    <span className="card__title">
                                      User Created At :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      Plant Code :
                                      {' '}
                                    </span>
                                    {item?.plantCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      HPC Code :
                                      {' '}
                                    </span>
                                    {item?.hpcCode ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className="card__title">
                                      User Created Time :
                                      {' '}
                                    </span>
                                    {moment(item?.createdAt).format('hh:mm a')}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card__right">
                              <div
                                className={`card__text--${
                                  item?.status === 'approved'
                                    ? 'success'
                                    : item?.status === 'pending'
                                      ? 'warning'
                                      : item?.status === 'rejected'
                                        ? 'danger'
                                        : ''
                                }`}
                              >
                                <span className="card__title">Status : </span>
                                {item?.status?.toUpperCase()}
                              </div>
                              {item?.isEqualGenerated === true && (
                                <div className="card__text--success">
                                  <span className="card__title">
                                    E-KYC DONE
                                    {' '}
                                  </span>
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                              )}
                              {role === 'admin'
                              || role === 'ea_pi'
                              || role === 'regional_pi'
                              || role === 'regional_fa' ? (
                                <Link
                                  to={`/admin/newUser/${item?.id}`}
                                  className="card__button"
                                >
                                  View
                                </Link>
                                ) : (
                                  <Link
                                    to={`/plant-admin/pendingHpcRegistrations/${item?.id}`}
                                    className="card__button"
                                  >
                                    View
                                  </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                  </>
                ))
                : null}
              {!isLoading && !pastHeld && (
                <div className="col-12 col-lg-12 d-flex-col middle">
                  <div className="notFound">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="notFound__icon"
                    />
                    <span className="notFound__text">
                      No past held users found
                    </span>
                  </div>
                </div>
              )}
            </div>
            {pastHeld ? (
              <div className="row">
                <div className="col-12 col-lg-12 d-flex-col middle">
                  <div className="pagination">
                    <button
                      type="button"
                      className="pagination__btn"
                      disabled={!pastHeld.Previous}
                      onClick={() => previousPastHeldPage(pastHeld.Previous.page)}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="pagination__btn__icon"
                      />
                    </button>
                    <span className="pagination__page">
                      {pastHeld.Next
                        ? pastHeld.Next.page - 1
                        : pastHeld.Previous
                          ? pastHeld.Previous.page + 1
                          : 1}
                    </span>
                    <button
                      type="button"
                      className="pagination__btn"
                      disabled={!pastHeld.Next}
                      onClick={() => nextPastHeldPage(pastHeld.Next.page)}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="pagination__btn__icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </TabPanel>
        </Tabs>
        {showFilterModal ? (
          <ModalForFilter
            show={showFilterModal}
            toggle={handleOnFilter}
            onSubmit={submitFilter}
            // values={data}
          />
        ) : null}
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default withScreenRefreshDetection(pendingHpcRegistration);
