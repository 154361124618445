/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { createRegion, checkMCCDetails, insertMCCDetails } from '../../store/modules/data/actions';
import { selectMccDetails, selectIsLoading } from '../../store/modules/data/selectors';
import { arrayOfRegions } from '../../constants/mostUsedArrays';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import './AddMccRep.scss';
import ConfirmModal from '../../components/ConfirmModal';

const AddMccRep = () => {
  // const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();
  // const mccDetails = useSelector((state) => selectMccDetails(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const regionName = localStorage.getItem('regionName');
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (regionName) {
      setValue('region', regionName !== 'HYDERABAD' ? regionName : 'HYDERABAD-1');
    }
  }, [setValue, regionName]);

  // useEffect(() => {
  //   if (mccDetails) {
  //     setShowConfirmModal(true);
  //   }
  // }, [mccDetails]);

  const onSubmit = ({
    pinCode,
    unitCode,
    region,
    type,
    unitName,
    mccCode,
    mccName,
    mccMobile,
    plateNo,
    plateName,
    state,
    district,
    mandal,
    panchayat,
    village,
  }) => {
    const formData = new FormData();
    formData.append('pinCode', pinCode);
    formData.append('unitCode', unitCode);
    formData.append('regionName', region ? region.toUpperCase() : regionName);
    formData.append('type', type);
    formData.append('unitName', unitName.toUpperCase());
    formData.append('mccCode', mccCode);
    formData.append('mccName', mccName.toUpperCase());
    formData.append('mccMobile', mccMobile);
    formData.append('plateCode', plateNo);
    formData.append('plateName', plateName.toUpperCase());
    formData.append('stateName', state.toUpperCase());
    formData.append('districtName', district.toUpperCase());
    formData.append('mendalName', mandal.toUpperCase());
    formData.append('panchayatName', panchayat.toUpperCase());
    formData.append('villageName', village.toUpperCase());

    dispatch(createRegion(formData));
  };

  const handleSelectFile = async () => {
    try {
      const { value: file } = await Swal.fire({
        icon: 'info',
        title: 'Upload an Excel File',
        input: 'file',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        toast: true,
        confirmButtonText: 'Upload',
        html: '<div><p>To download a sample Excel format, follow the link below.</p><a href="https://heritage-vet-plus-server-buckets.s3.ap-south-1.amazonaws.com/sample/mcc_hpc_format.csv">Download</a></div>',
        inputAttributes: {
          accept: '.xls, .xlsx, .csv',
          'aria-label': 'Upload your file',
        },
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose a file';
          }
        },
      });

      if (file) {
        const fileSize = file.size;
        const maxFileSize = 50 * 1024 * 1024; // 50MB limit
        if (fileSize > maxFileSize) {
          throw new Error(`File size exceeds ${maxFileSize / (1024 * 1024)}MB limit`);
        }
        if (!['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'].includes(file.type)) {
          throw new Error('Invalid file format');
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            let timerInterval;
            const fileSizeInMb = fileSize / (1024 * 1024);
            const uploadTimeInSeconds = Math.ceil(fileSizeInMb);
            Swal.fire({
              icon: 'success',
              title: 'Uploading your data!',
              html: `Uploading ${fileSizeInMb.toFixed(2)} MB file in <b>${uploadTimeInSeconds} seconds</b>.`,
              timer: uploadTimeInSeconds * 1000,
              toast: true,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
            const formData = new FormData();
            formData.append('file', file);
            dispatch(checkMCCDetails(formData));
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              toast: true,
              text: error.message,
            });
          } finally {
            Swal.getPopup().querySelector('input[type=file]').value = '';
          }
        };
        reader.readAsDataURL(file);
      } else {
        throw new Error('No file was uploaded.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        toast: true,
        text: error.message,
      });
    }
  };
  // const insertMCC = () => {
  //   dispatch(insertMCCDetails(mccDetails?.['Non-Eexisting Region Unit Mcc names']));
  // };

  // const toggleConfirmModal = () => {
  //   setShowConfirmModal(!showConfirmModal);
  // };

  return (
    <>
      <div className="add-mcc-rep">
        <div className="row between">
          <div className="col-md-auto">
            <div className="heading">
              <p className="heading__title">
                Add a New MCC/HPC Representative
              </p>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="upload">
              <button type="button" onClick={handleSelectFile} className="button primary">
                <FontAwesomeIcon
                  icon={faFileUpload}
                  className="button__icon"
                />
                File Upload
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="form__item">
                        <div className="row middle">
                          {/* <div className="col-12">
                            <span className="form__label">Sample Picture</span>
                          </div> */}
                          <div className="col-12">
                            <label htmlFor="picture">
                              <div className="picture">
                                <img src={logoImg} alt="sample" />
                                {/* {fileUrl && <img src={logoImg} alt="sample" />}
                                <div className="picture__placeholder">
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    className="picture__placeholder--icon"
                                  />
                                </div> */}
                              </div>
                              {/* <input
                                className="form__field"
                                type="file"
                                name="picture"
                                id="picture"
                                onChange={handleSelectFile}
                              />
                              {fileError && <div className="form__error">{fileError}</div>} */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="pinCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Pincode</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="pinCode"
                                name="pinCode"
                                placeholder="Pincode"
                                {...register('pinCode', {
                                  required: {
                                    value: true,
                                    message: 'Pincode is required',
                                  },
                                  minLength: {
                                    value: 6,
                                    message: 'Pincode must not be less than 6 digits',
                                  },
                                  maxLength: {
                                    value: 6,
                                    message: 'Pincode must not be greater than 6 digits',
                                  },
                                  pattern: {
                                    value: /\d/,
                                    message: 'Pincode must be digits',
                                  },
                                })}
                              />
                              {errors.pinCode && <div className="form__error">{errors.pinCode.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="unitCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC/HPC Code</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="unitCode"
                                name="unitCode"
                                placeholder="MCC/HPC Code"
                                {...register('unitCode', {
                                  required: {
                                    value: true,
                                    message: 'MCC/HPC Code is required',
                                  },
                                  minLength: {
                                    value: 4,
                                    message: 'MCC/HPC Code must not be less than 4 digits',
                                  },
                                  maxLength: {
                                    value: 4,
                                    message: 'MCC/HPC Code must not be greater than 4 digits',
                                  },
                                  pattern: {
                                    value: /\d/,
                                    message: 'MCC/HPC Code must be digits',
                                  },
                                })}
                              />
                              {errors.unitCode && <div className="form__error">{errors.unitCode.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="region">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Region</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="region"
                                name="region"
                                // disabled={regionName !== 'HYDERABAD'}
                                // defaultValue={regionName !== 'HYDERABAD'
                                //   ? regionName : 'HYDERABAD-1'}
                                {...register('region', {
                                  required: {
                                    value: true,
                                    message: 'Region name is required',
                                  },
                                })}
                              >
                                {arrayOfRegions?.map((item) => <option key={item} value={item} selected={regionName === item}>{item}</option>)}
                              </select>
                              {errors.region && <div className="form__error">{errors.region.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="type">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Type</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="type"
                                name="type"
                                {...register('type', {
                                  required: {
                                    value: true,
                                    message: 'Type is required',
                                  },
                                })}
                              >
                                <option value="" hidden>Select an option</option>
                                <option value="hpc">HPC</option>
                                <option value="mcc">MCC</option>
                              </select>
                              {errors.type && <div className="form__error">{errors.type.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="unitName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC/HPC Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="unitName"
                                name="unitName"
                                placeholder="MCC/HPC Name"
                                {...register('unitName', {
                                  required: {
                                    value: true,
                                    message: 'MCC/HPC Name is required',
                                  },
                                })}
                              />
                              {errors.unitName && <div className="form__error">{errors.unitName.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mccCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC/HPC Rep Code</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mccCode"
                                name="mccCode"
                                placeholder="MCC/HPC Rep Code"
                                {...register('mccCode', {
                                  required: {
                                    value: true,
                                    message: 'MCC/HPC Rep code is required',
                                  },
                                  minLength: {
                                    value: 10,
                                    message: 'MCC/HPC Rep code must not be less than 10 digits',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'MCC/HPC Rep code must not be greater than 10 digits',
                                  },
                                  pattern: {
                                    value: /\d/,
                                    message: 'MCC/HPC Rep code must be digits',
                                  },
                                })}
                              />
                              {errors.mccCode && <div className="form__error">{errors.mccCode.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mccName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC/HPC Rep Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mccName"
                                name="mccName"
                                placeholder="MCC/HPC Rep Name"
                                {...register('mccName', {
                                  required: {
                                    value: true,
                                    message: 'MCC/HPC Rep name is required',
                                  },
                                })}
                              />
                              {errors.mccName && <div className="form__error">{errors.mccName.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mccMobile">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC/HPC Mobile No</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mccMobile"
                                name="mccMobile"
                                placeholder="MCC/HPC Mobile No"
                                {...register('mccMobile', {
                                  required: {
                                    value: true,
                                    message: 'MCC/HPC Mobile No is required',
                                  },
                                  minLength: {
                                    value: 10,
                                    message: 'MCC/HPC Mobile No must not be less than 10 digits',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'MCC/HPC Mobile No must not be greater than 10 digits',
                                  },
                                  pattern: {
                                    value: /^[5-9][0-9]{9}$/,
                                    message: 'MCC/HPC Mobile No must be a valid mobile No',
                                  },
                                })}
                              />
                              {errors.mccMobile && <div className="form__error">{errors.mccMobile.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="plateNo">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Plant No</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="plateNo"
                                name="plateNo"
                                placeholder="Plant No"
                                {...register('plateNo', {
                                  required: {
                                    value: true,
                                    message: 'Plant No is required',
                                  },
                                  minLength: {
                                    value: 4,
                                    message: 'Plant No must not be less than 4 digits',
                                  },
                                  maxLength: {
                                    value: 4,
                                    message: 'Plant No must not be greater than 4 digits',
                                  },
                                  pattern: {
                                    value: /\d/,
                                    message: 'Plant No must be digits',
                                  },
                                })}
                              />
                              {errors.plateNo && <div className="form__error">{errors.plateNo.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="plateName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Plant Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="plateName"
                                name="plateName"
                                placeholder="Plant Name"
                                {...register('plateName', {
                                  required: {
                                    value: true,
                                    message: 'Plant name is required',
                                  },
                                })}
                              />
                              {errors.plateName && <div className="form__error">{errors.plateName.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="state">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">State</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="state"
                                name="state"
                                placeholder="State"
                                {...register('state', {
                                  required: {
                                    value: true,
                                    message: 'State is required',
                                  },
                                })}
                              />
                              {errors.state && <div className="form__error">{errors.state.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="district">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">District</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="district"
                                name="district"
                                placeholder="District"
                                {...register('district', {
                                  required: {
                                    value: true,
                                    message: 'District is required',
                                  },
                                })}
                              />
                              {errors.district && <div className="form__error">{errors.district.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mandal">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Mandal</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mandal"
                                name="mandal"
                                placeholder="Mandal"
                                {...register('mandal', {
                                  required: {
                                    value: true,
                                    message: 'Mandal is required',
                                  },
                                })}
                              />
                              {errors.mandal && <div className="form__error">{errors.mandal.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="panchayat">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Panchayat</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="panchayat"
                                name="panchayat"
                                placeholder="Panchayat"
                                {...register('panchayat', {
                                  required: {
                                    value: true,
                                    message: 'Panchayat is required',
                                  },
                                })}
                              />
                              {errors.panchayat && <div className="form__error">{errors.panchayat.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="village">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Village</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="village"
                                name="village"
                                placeholder="Village"
                                {...register('village', {
                                  required: {
                                    value: true,
                                    message: 'Village is required',
                                  },
                                })}
                              />
                              {errors.village && <div className="form__error">{errors.village.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="buttons">
                        <button type="button" className="button green" onClick={handleSubmit(onSubmit)}>Confirm And Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ConfirmModal
        show={showConfirmModal}
        toggle={toggleConfirmModal}
        onConfirm={insertMCC}
        title="Confirm"
        text={`${mccDetails?.['No Of Region Unit Mcc names Exist']} MCC details from the file already exist, insert ${mccDetails?.['No Of Region Unit Mcc names Not Exist']} new MCC details`}
      /> */}
      <LoadingModal show={isLoading} />
    </>
  );
};

export default AddMccRep;
