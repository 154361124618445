import React, { useState, useEffect } from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import PendingOrders from '../../pages/PendingOrders';
import PendingOrdersTable from '../../pages/PendingOrdersTable';
import PendingOrdersNewFlowViewById from '../../pages/PendingOrdersNewFlowViewById';
import ViewMccOrder from '../../pages/ViewMccOrder';
import AddOrderItems from '../../pages/AddOrderItems';
import CreateOrder from '../../pages/CreateOrder';
import ViewOrder from '../../pages/ViewOrder';
import Error404 from '../../pages/Error404';
import PlantInchargeApprovedRegistration from '../../pages/plantDashboard';
import PendingRegistrationApprovalForPlantIc from '../../pages/PendingRegistrationApprovalsForPlantIc';
import PendingHPCregistrationPlantsideViewById from '../../pages/PendingHPCregistrationPlantsideViewById';
import MccFarmerListViewPage from '../../pages/MccFarmerListViewPage';
import MccFarmerEditPage from '../../pages/MccFarmerListViewPage/MccFarmerEditPage';
import HnlIndenting from '../../pages/HnlIndenting';
import './PlantAdminDashboard.scss';
import UpdatePassword from '../../pages/UpdatePassword';

const MOBILE_SIZE = 960;

const DashboardLayout = () => {
  const { path } = useRouteMatch();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      setIsSidebarCollapsed(true);
    } else {
      setIsSidebarCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sidebarCollapsedClass = isSidebarCollapsed
    ? 'side-bar-collapsed'
    : '';

  return (
    <div className="layout">
      <div className="layout-wrapper">
        <Sidebar collapsed={isSidebarCollapsed} toggleCollapse={toggleSidebarCollapse} userRole="plant-admin" />
        <div className={`layout-content ${sidebarCollapsedClass}`}>
          <Header
            isSidebarCollapsed={isSidebarCollapsed}
            toggleSidebarCollapse={toggleSidebarCollapse}
          />
          <main className="main-content" role="main">
            <Switch>
              <Route
                exact
                path="/plant-admin"
                render={() => <Redirect from="/" to="plant-admin/pendingHpcRegistrations" />}
              />
              <Route exact path={`${path}/pendingHpcRegistrations`} component={PendingRegistrationApprovalForPlantIc} />
              <Route exact path={`${path}/pendingHpcRegistrations/:id`} component={PendingHPCregistrationPlantsideViewById} />
              <Route exact path={`${path}/MccFarmerListViewPage`} component={MccFarmerListViewPage} />
              <Route exact path={`${path}/MccFarmerEditPage/:id`} component={MccFarmerEditPage} />
              <Route exact path={`${path}/mcc-ordersNew`} component={PendingOrdersTable} />
              <Route exact path={`${path}/mcc-orders`} component={PendingOrders} />
              <Route exact path={`${path}/mcc-orders/:id`} component={ViewMccOrder} />
              <Route exact path={`${path}/mcc-ordersNew/:id`} component={PendingOrdersNewFlowViewById} />
              <Route exact path={`${path}/mcc-orders/:id/add`} component={AddOrderItems} />
              <Route exact path={`${path}/orders`} component={CreateOrder} />
              <Route exact path={`${path}/orders/:id`} component={ViewOrder} />
              <Route exact path={`${path}/updatePassword`} component={UpdatePassword} />
              <Route exact path={`${path}/hnl-indenting`} component={HnlIndenting} />
              <Route
                exact
                path={`${path}/users/dashboard`}
                component={PlantInchargeApprovedRegistration}
              />
              <Route component={Error404} />
            </Switch>
          </main>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardLayout;
