import {
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  ADD_START,
  ADD_SUCCESS,
  ADD_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
} from './types';

const initialState = {
  products: null,
  product: null,
  images: [],
  updated: null,
  isLoading: false,
  isUploading: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_SUCCESS:
      return {
        ...state,
        products: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        product: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPLOAD_IMAGE_START:
      return { ...state, errorMessage: null, isUploading: true };

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        images: payload || null,
        errorMessage: null,
        isUploading: false,
      };

    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isUploading: false,
      };

    case ADD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case ADD_SUCCESS:
      return {
        ...state,
        product: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case ADD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case DELETE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case DELETE_SUCCESS:
      return {
        ...state,
        product: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case DELETE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case UPDATE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case UPDATE_SUCCESS:
      return {
        ...state,
        updated: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    default:
      return state;
  }
};
