/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import Prescription from '../../components/Prescription';
import {
  getAppointment, rejectAppointment, finishAppointment, uploadPrescription, followUpAppointment,
} from '../../store/modules/appointment/actions';
import {
  selectAppointment,
  selectIsLoading,
  selectIsLoadingFinish,
  selectIsLoadingReject,
  selectIsLoadingPrescription,
  selectIsLoadingFollowUp,
  selectMessage,
} from '../../store/modules/appointment/selectors';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import downloadImg from '../../assets/images/download.png';

import './View.scss';
import config from '../../config/config';

const TextArea = ({
  id, name, placeholder, onChangeText,
}) => (
  <textarea
    className="form__field"
    type="text"
    defaultValue=""
    id={id}
    name={name}
    placeholder={placeholder}
    rows={3}
    onChange={onChangeText}
  />
);

const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Telugu', value: 'te' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Kannada', value: 'kn' },
];

const EVET = {
  en: [
    { name: 'Mastitis', link: `${config.evmUrl}/English/English-4 Mastitis.pdf` },
    { name: 'Teat Obstruction', link: `${config.evmUrl}/English/English-5 Teat Obstruction.pdf` },
    { name: 'Edema of Udders', link: `${config.evmUrl}/English/English-6 Udder edema.pdf` },
    { name: 'Retention of Placenta', link: `${config.evmUrl}/English/English-7 Rentention of placenta.pdf` },
    { name: 'Repeat Breeding', link: `${config.evmUrl}/English/English-8 Repeat breeding.pdf` },
    { name: 'Prolapse', link: `${config.evmUrl}/English/English-9 prolapse.pdf` },
    { name: 'FMD mouth lesions', link: `${config.evmUrl}/English/English-10 FMD.pdf` },
    { name: 'Fever', link: `${config.evmUrl}/English/English-12 Fever.pdf` },
    { name: 'Diarrhoea', link: `${config.evmUrl}/English/English-13 Diarrhoea.pdf` },
    { name: 'Bloat or indigestion', link: `${config.evmUrl}/English/English-14 indigetion.pdf` },
    { name: 'Ticks & ectoparasites', link: `${config.evmUrl}/English/English-16 Ectoparasites.pdf` },
    { name: 'Endoparasites', link: `${config.evmUrl}/English/English-15 Endoparasites.pdf` },
    { name: 'Udder Pox', link: `${config.evmUrl}/English/English-17 Udder pox.pdf` },
    { name: 'Wound', link: `${config.evmUrl}/English/English-18 wound.pdf` },
    { name: 'Post patrum complications', link: `${config.evmUrl}/English/English-19 Post patrum complications.pdf` },
    { name: 'Thelitis', link: `${config.evmUrl}/English/English-20 Thelitis.pdf` },
    { name: 'Pesticides Toxicity', link: `${config.evmUrl}/English/English-21 Pesticides Toxicity.pdf` },
    { name: 'Downer Cow', link: `${config.evmUrl}/English/English-22 Downer cow.pdf` },
    { name: 'Dermatitis', link: `${config.evmUrl}/English/English-23 Dermatitis.pdf` },
    { name: 'Hygroma', link: `${config.evmUrl}/English/English-24 Hygroma.pdf` },
    { name: 'Cough', link: `${config.evmUrl}/English/English-25 Cough.pdf` },
    { name: 'Blood in Milk', link: `${config.evmUrl}/English/English-26 Blood in milk.pdf` },
    { name: 'Anoestrus', link: `${config.evmUrl}/English/English-27 Anoestrus.pdf` },
    { name: 'Alergy', link: `${config.evmUrl}/English/English-28 Alergy.pdf` },
    { name: 'Lumpy Skin Disease', link: `${config.evmUrl}/English/English-29 Lumpy skin Disease.pdf` },
    { name: 'Other', link: null }],
  te: [
    { name: 'పొదుగు వాపు వ్యాధి', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 4.pdf` },
    { name: 'చన్నురంధ్రంలో అవరోధం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 5.pdf` },
    { name: 'పొదుగుకు నీరు దిగుట', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 6.pdf` },
    { name: 'మాయ పడకుండా ఉండుట.', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 7.pdf` },
    { name: 'తిరగ పొర్లుట', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 8.pdf` },
    { name: 'మెయ్య కు చికిత్స', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 9.pdf` },
    { name: 'గాలికుంటు వ్యాధి/కాలి పుండ్లకు చికిత్స', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 10.pdf` },
    { name: 'జ్వరము', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 12.pdf` },
    { name: 'పారుడు వ్యాధి', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 13.pdf` },
    { name: 'కడుపు ఉబ్బరం | అజీర్ణం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 14.pdf` },
    { name: 'జోరీగలు/ బాహ్య పరాన్నజీవులు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 16.pdf` },
    { name: 'అంతః పరాన్నజీవులు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 15.pdf` },
    { name: 'పొదుగు చర్మం పగలటం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 17.pdf` },
    { name: 'పుండు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 18.pdf` },
    { name: 'ఈనిన తరువాత సమస్యలు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 19.pdf` },
    { name: 'రొమ్ము వాపు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 20.pdf` },
    { name: 'పురుగుల విషతుల్యం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 21.pdf` },
    { name: 'సోలిపోవడం లేదా పైకి లేవ లేకపోవడం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 22.pdf` },
    { name: 'చర్మవాపు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 23.pdf` },
    { name: 'కీళ్ల వాపు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 24.pdf` },
    { name: 'దగ్గు', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 25.pdf` },
    { name: 'పాలలో రక్తం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 26.pdf` },
    { name: 'ఎదకు రాకపోవడం', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 27.pdf` },
    { name: 'గజ్జి', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 28.pdf` },
    { name: 'ముద్ద చర్మం వ్యాధి', link: `${config.evmUrl}/telugu/HERITAGE  FINAL Telugu 29.pdf` },

    { name: 'Other', link: null }],
  mr: [
    { name: 'स्तनदाह (सर्व प्रकारचा)', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 4.pdf` },
    { name: 'सडामध्ये अडथळा', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 5.pdf` },
    { name: 'कासेवर/ सडावर सूज येणे', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 6.pdf` },
    { name: 'झार/वार न पडणे', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 7.pdf` },
    { name: 'उलटणे/गाभण न राहणे', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 8.pdf` },
    { name: 'अंग/भांड बाहेर येणे', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 9.pdf` },
    { name: 'खरकुतः खुरामधील जखम', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 10.pdf` },
    { name: 'ताप', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 12.pdf` },
    { name: 'हगवण', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 13.pdf` },
    { name: 'पोट फुगी आणि अपचन', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 14.pdf` },
    { name: 'गोचीड आणि बाह्य परजीवी', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 16.pdf` },
    { name: 'अंतर्गत परोपजीवी जंतु', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 15.pdf` },
    { name: 'कासेचा गोवर', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 17.pdf` },
    { name: 'जखम', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 18.pdf` },
    { name: 'प्रसतीनंतरची गुंतागुंत', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 19.pdf` },
    { name: 'थेलाईटीस', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 20.pdf` },
    { name: 'किटकनाशक विषाक्तता', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 21.pdf` },
    { name: 'डाउनर गाय सिंड्रोम( खालची गाय)', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 22.pdf` },
    { name: 'त्वचा रोग', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 23.pdf` },
    { name: 'हायग्रोमा', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 24.pdf` },
    { name: 'खोकला', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 25.pdf` },
    { name: 'दुधात रक्त', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 26.pdf` },
    { name: 'एनोस्ट्रस', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 27.pdf` },
    { name: 'एलर्जी', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 28.pdf` },
    { name: 'ढेकुळ त्वचा रोग', link: `${config.evmUrl}/marathi/HERITAGE  FINAL Marathi 29.pdf` },
    { name: 'Other', link: null }],
  hi: [
    { name: 'थनैला', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 4.pdf` },
    { name: 'स्तनाग्र अवरुद्ध होने की स्थिति में', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 5.pdf` },
    { name: 'थन में शोफ (इडिमा)', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 6.pdf` },
    { name: 'जेर नहीं गिरना', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 7.pdf` },
    { name: 'बांझपन की समस्या', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 8.pdf` },
    { name: 'शरीर/अंग बाहर', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 9.pdf` },
    { name: 'खुरपका मुंहपका रोग में पैरों के घाव', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 10.pdf` },
    { name: 'बुखार', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 12.pdf` },
    { name: 'दस्त/अतिसार', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 13.pdf` },
    { name: 'आफरा एवं अपच', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 14.pdf` },
    { name: 'चिंचड़ी/बाह्य परजीवी', link: `${config.evmUrl}/Hindi/HERITAGE FINAL Hindi 16.pdf` },
    { name: 'अंतः परजीवी', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 15.pdf` },
    { name: 'थन चेचक', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 17.pdf` },
    { name: 'घाव', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 18.pdf` },
    { name: 'प्रसवोत्तर जटिलता', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 19.pdf` },
    { name: 'थेलाईटीस', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 20.pdf` },
    { name: 'किटकनाशक विषाक्तता', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 21.pdf` },
    { name: 'डाउनर गाय सिंड्रोम(निची गाय)', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 22.pdf` },
    { name: 'चमडे का प्रगह', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 23.pdf` },
    { name: 'हायग्रोमा', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 24.pdf` },
    { name: 'खाँसी', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 25.pdf` },
    { name: 'दुध में खुन', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 26.pdf` },
    { name: 'एनोस्ट्रस', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 27.pdf` },
    { name: 'एॅलर्जी', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 28.pdf` },
    { name: 'गाठदार त्वचा रोग व्हायरस', link: `${config.evmUrl}/Hindi/HERITAGE  FINAL Hindi 29.pdf` },
    { name: 'Other', link: null }],
  ta: [
    { name: 'மடிநோய் (அனைத்து வகை)', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 4.pdf` },
    { name: 'மடிக்காம்பு அடைப்பு', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 5.pdf` },
    { name: 'சினை மாட்டில் மடியில் நீர் கோர்த்தல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 6.pdf` },
    { name: 'நஞ்சுக்கொடிபோடாமல் இருத்தல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 7.pdf` },
    { name: 'பருவத்துக்குவந்தும்மாடுகள்சினைபிடிக்காமை', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 8.pdf` },
    { name: 'கர்ப்பப்பை வெளித் தள்ளுதல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 9.pdf` },
    { name: 'கோமாரி நோய் குளம்பு புண்/காயம்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 10.pdf` },
    { name: 'காய்ச்சல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 12.pdf` },
    { name: 'கழிச்சல் வயிற்றுப்போக்கு', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 13.pdf` },
    { name: 'வயிறுஉப்பிசம்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 14.pdf` },
    { name: 'உண்ணி/வெளிப்புற ஒட்டுண்ணி', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 16.pdf` },
    { name: 'அக ஒட்டுண்ணி', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 15.pdf` },
    { name: 'மாட்டு அம்மை', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 17.pdf` },
    { name: 'காயங்கள்/ புண்கள்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 18.pdf` },
    { name: 'பிரசவத்திற்கு பின் சிக்கல்கள்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 19.pdf` },
    { name: 'மடி காம்பு வீக்கம்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 20.pdf` },
    { name: 'பூச்சிக்கொல்லிகள் நச்சுத்தன்மை', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 21.pdf` },
    { name: 'ஈன்ற மாடுகள் எழ முடியாத நோய்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 22.pdf` },
    { name: 'தோல் நோய்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 23.pdf` },
    { name: 'மணிக்கட்டு ஹைக்ரோமா', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 24.pdf` },
    { name: 'இருமல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 25.pdf` },
    { name: 'பாலில் இரத்தம் கலந்து வருதல்', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 26.pdf` },
    { name: 'சினைப் பருவம் ஏற்படாத நிலை', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 27.pdf` },
    { name: 'அலர்ஜி', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 28.pdf` },
    { name: 'மாடுகளில் பெரியம்மை', link: `${config.evmUrl}/tamil/HERITAGE  FINAL TAMIL 29.pdf` },
    { name: 'Other', link: null },
  ],
  kn: [
    { name: 'ಕೆಚ್ಚಲುಬಾವು (ಎಲ್ಲಾ ವಿಧಗಳು/ ಅತಿ)', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 4.pdf` },
    { name: 'ಮೊಲೆತೊಟ್ಟು ರಂಧ್ರಗಳ ಅವರೋಧಗಳು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 5.pdf` },
    { name: 'ಕೆಚ್ಚಲು ಊತ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 6.pdf` },
    { name: 'ಸತ್ತೆ ಬೀಳದಿರುವುದು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 7.pdf` },
    { name: 'ಬಂಜೆತನ/ ಬೆದೆಗೆ ಬರದಿರುವುದು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 8.pdf` },
    { name: 'ಗರ್ಭಜಾರುವುದು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL KANADA 9.pdf` },
    { name: 'ಕಾಲು-ಬಾಯಿ ರೋಗ- ಕಾಲು ಗಾಯಗಳು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 10.pdf` },
    { name: 'ಜ್ವರ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 12.pdf` },
    { name: 'ಅತಿಸಾರ ಭೇದಿ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 13.pdf` },
    { name: 'ಹೊಟ್ಟೆ ಉಬ್ಬರ ಹಾಗೂ ಅಜೀರ್ಣ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 14.pdf` },
    { name: 'ಉಣ್ಣಿಗಳು/ ಬಾಹ್ಯ ಪರಾವಲಂಬಿ ಜೀವಿಗಳು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 16.pdf` },
    { name: 'ಒಳಪರಾವಲಂಬಿ ಜೀವಿ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 15.pdf` },
    { name: 'ಕೆಚ್ಚಲ ಗುಳ್ಳೆ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 17.pdf` },
    { name: 'ಗಾಯ ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 18.pdf` },
    { name: 'ಹೆರಿಗೆಯ ನಂತರದ ತೊಡಕು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 19.pdf` },
    { name: 'ಮೊಲೆ ಬಾವು ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 20.pdf` },
    { name: 'ಕೀಟನಾಶಕ ವಿಷತ್ವ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 21.pdf` },
    { name: 'ಡೌನರ ಹಸು / ಕೆಳಗೆ ಬಿದ್ದ ಹಸು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 22.pdf` },
    { name: 'ಚರ್ಮದ ಉರಿಯೂತ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 23.pdf` },
    { name: 'ಸಂಧಿ ಊತ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 24.pdf` },
    { name: 'ಕೆಮ್ಮು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 25.pdf` },
    { name: 'ಹಾಲಿನಲ್ಲಿ ರಕ್ತ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 26.pdf` },
    { name: 'ಬೆದೆಗೆ ಬಾರದಿರುವುದು', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 27.pdf` },
    { name: 'ಅಲರ್ಜಿ/ ಮೈನೂರತೆ ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 28.pdf` },
    { name: 'ಚರ್ಮಗಂಟು ರೋಗ', link: `${config.evmUrl}/kanada/HERITAGE  FINAL Kannada 29.pdf` },
    { name: 'Other', link: null },
  ],
};

const View = () => {
  const dispatch = useDispatch();
  const [evet, setEvet] = useState(EVET.en);
  const [values, setValues] = useState({
    currentDiagnosis: '',
    specialInstruction: '',
    historyAndFindings: '',
    treatment: '',
    evetName: '',
    evetLink: '',
    tabletName: '',
    frequency: '',
    duration: '',
    quantity: '',
  });
  const [errors, setErrors] = useState({
    currentDiagnosisError: null,
    specialInstructionError: null,
    historyAndFindingsError: null,
    treatmentError: null,
    evetError: null,
    tabletNameError: null,
    frequencyError: null,
    durationError: null,
    quantityError: null,
  });
  const [tablets, setTablets] = useState([]);
  const [showTabletModal, setShowTabletModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const data = useSelector((state) => selectAppointment(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingReject(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingFinish(state));
  const isLoadingPrescription = useSelector((state) => selectIsLoadingPrescription(state));
  const isLoadingFollowUp = useSelector((state) => selectIsLoadingFollowUp(state));
  const message = useSelector((state) => selectMessage(state));
  const userName = localStorage.getItem('userName');
  const specialization = localStorage.getItem('specialization');
  const regionName = localStorage.getItem('regionName');

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAppointment(id));
  }, []);

  // useEffect(() => {
  //   if (data && data.Medical) {
  //     setValues({
  //       currentDiagnosis: data.Medical.currentDiagnosis,
  //       specialInstruction: data.Medical.specialInstruction,
  //       historyAndFindings: data.Medical.historyFindings,
  //       treatment: data.Medical.treatment,
  //     });
  //   }
  // }, [data]);

  const toggleTabletModal = () => {
    setShowTabletModal(!showTabletModal);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setEvet(EVET[value]);
  };

  const handleEvetChange = (event) => {
    const { value } = event.target;
    setValues((prev) => ({
      ...prev,
      evetName: evet[value].name,
      evetLink: evet[value].link,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const {
      currentDiagnosis,
      specialInstruction,
      historyAndFindings,
      treatment,
      evetName,
      evetLink,
    } = values;

    // setTablets((prev) => ([...prev, {
    //   tabletName,
    //   frequency,
    //   duration,
    //   quantity,
    // }]));

    if (!currentDiagnosis) {
      setErrors({ currentDiagnosisError: 'Current Diagnosis is required' });
      return;
    }
    setErrors({ currentDiagnosisError: null });

    if (!specialInstruction) {
      setErrors({ specialInstructionError: 'Special Instruction is required' });
      return;
    }
    setErrors({ specialInstructionError: null });

    if (!historyAndFindings) {
      setErrors({ historyAndFindingsError: 'History and Findings is required' });
      return;
    }
    setErrors({ historyAndFindingsError: null });

    if (!treatment) {
      setErrors({ treatmentError: 'Treatment is required' });
      return;
    }
    setErrors({ treatmentError: null });

    if (!evetName && !evetLink) {
      setErrors({ evetError: 'Ethnoveterinary E-Booklet is required' });
      return;
    }
    setErrors({ evetError: null });

    const formData = new FormData();
    formData.append('currentDiagnosis', currentDiagnosis);
    formData.append('specialInstruction', specialInstruction);
    formData.append('historyFindings', historyAndFindings);
    formData.append('treatment', treatment);

    dispatch(uploadPrescription(id, formData));
  };

  const reject = () => {
    dispatch(rejectAppointment(id));
  };

  const finish = () => {
    dispatch(finishAppointment(id));
  };

  const followUp = () => {
    dispatch(followUpAppointment(id));
  };

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const getProps = async () => {
    await delay(1_000);
    return ({
      userName,
      specialization,
      regionName,
      farmerId: data.farmerId,
      cattleId: data.cattleId,
      prescriptionId: data.id,
      values,
      tablets,
    });
  };

  const generatePdf = async () => {
    setIsExporting(true);
    const props = await getProps();
    const doc = <Prescription data={props} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);

    const blob = await asPdf.toBlob();
    const newFile = new File([blob],
      'prescription.pdf',
      {
        type: 'application/pdf',
        lastModified: Date.now(),
      });
    // setFile(newFile);
    setIsExporting(false);
    // saveAs(blob, 'prescription.pdf');

    return newFile;
  };

  const handleSelectFile = (event) => {
    const { files } = event.target;
    setFile(files[0]);
  };

  const submitPrescription = async (event) => {
    event.preventDefault();
    // if (!file) {
    //   setFileError('Please, select a file');
    //   return;
    // }
    // setFileError(null);

    const {
      currentDiagnosis,
      specialInstruction,
      historyAndFindings,
      treatment,
      evetName,
      evetLink,
    } = values;

    if (!currentDiagnosis) {
      setErrors({ currentDiagnosisError: 'Current Diagnosis is required' });
      return;
    }
    setErrors({ currentDiagnosisError: null });

    if (!specialInstruction) {
      setErrors({ specialInstructionError: 'Special Instruction is required' });
      return;
    }
    setErrors({ specialInstructionError: null });

    if (!historyAndFindings) {
      setErrors({ historyAndFindingsError: 'History and Findings is required' });
      return;
    }
    setErrors({ historyAndFindingsError: null });

    if (!treatment) {
      setErrors({ treatmentError: 'Treatment is required' });
      return;
    }
    setErrors({ treatmentError: null });

    if (!evetName && !evetLink) {
      setErrors({ evetError: 'Ethnoveterinary E-Booklet is required' });
      return;
    }
    setErrors({ evetError: null });

    const document = await generatePdf();

    const formData = new FormData();
    formData.append('currentDiagnosis', currentDiagnosis);
    formData.append('specialInstruction', specialInstruction);
    formData.append('historyFindings', historyAndFindings);
    formData.append('treatment', treatment);
    formData.append('document', document);
    formData.append('ethnoveterinaryName', evetName);
    formData.append('ethnoveterinaryLink', evetLink);

    dispatch(uploadPrescription(id, formData));
  };

  const {
    currentDiagnosisError,
    specialInstructionError,
    historyAndFindingsError,
    treatmentError,
    evetError,
  } = errors;

  return (
    <>
      {!isLoading && data
        ? (
          <div className="view">
            <div className="row center">
              <div className="col-12 col-md-6">
                <div className="row center">
                  <div className="col-12">
                    <div className="status-card">
                      {`Current status : ${data.status === 'waiting' ? 'Waiting to be finished' : data.status}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="status-card">
                      <div className="left">
                        User ID :
                        {' '}
                        {data.Farmer ? data.Farmer.id : ''}
                      </div>
                      <div className="right">
                        Appointment ID :
                        {' '}
                        {data.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data.status === 'waiting' ? (
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="buttons mb-0">
                        <button type="button" className="button danger" onClick={reject}>Reject</button>
                        <button type="button" className="button primary" onClick={finish}>Finish</button>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form">
                        <div className="row center">
                          {/* <div className="col-6">
                            <div className="form__item">
                              <select
                                id="followUp"
                                name="followUp"
                                className="form__field"
                              >
                                <option value="1">1 day</option>
                                <option value="2">2 day</option>
                                <option value="3">3 day</option>
                              </select>
                              {currentDiagnosisError
                              && <div className="form__error">{currentDiagnosisError}</div>}
                            </div>
                          </div> */}
                          <div className="col-12">
                            <div className="form__item">
                              <button
                                type="button"
                                className="button success p-16 m-0"
                                onClick={followUp}
                              >
                                Follow-Up
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="row center">
              <div className="col-12 col-md-6 col-lg-5">
                <div className="status-card">
                  <div className="left">
                    User ID :
                    {' '}
                    {data.Farmer ? data.Farmer.id : ''}
                  </div>
                  <div className="right">
                    Appointment ID :
                    {' '}
                    {data.id}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item left">
                  <h4 className="title">User details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex-col sm-middle">
                        <div className="picture">
                          <img src={data.Farmer ? data.Farmer.profilePicture : ''} alt="User" />
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.farmerName : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Phone Number</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.phone.replace('+91', '') : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Gender</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.gender : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Age</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? moment(data.Farmer.age).format('DD/MM/YYYY') : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Pincode</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.pinCode : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Region Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.regionName : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Unit Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.unitName : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">MCC Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">{data.Farmer ? data.Farmer.mccName : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item right">
                  <h4 className="title">Cattle details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex-col sm-middle">
                        <div className="picture">
                          <img src={data.Cattle ? data.Cattle.profilePicture : ''} alt="Cattle" />
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Cattle</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">{data.Cattle ? data.Cattle.cattle : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">{data.Cattle ? data.Cattle.cattleName : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Age</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">{data.Cattle ? moment(data.Cattle.age).format('DD/MM/YYYY') : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Cattle Stage</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">{data.Cattle ? data.Cattle.category : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Breed</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">{data.Cattle ? data.Cattle.breed : ''}</div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Weight</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.weight : ''}
                          {' '}
                          kg
                        </div>
                      </div>
                    </div>
                    {data.Cattle && data.Cattle.Milking && data.Cattle.Milking.length
                      ? (
                        <>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Milk Production</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].milkProduction}
                                {' '}
                                L
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Fat In Milk</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].fatInMilk}
                                {' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">No. of month pregnant</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{data.Cattle.Milking[0].pregnantMonth}</div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Lactation No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{data.Cattle.Milking[0].LactationNumber}</div>
                            </div>
                          </div>
                        </>
                      )
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">Appointment details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Created Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.createdAt).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Telephonic Consultancy Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.appointmentDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Telephonic Consultancy Time</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {data.appointmentStartTime}
                            </div>
                          </div>
                        </div>
                      </div>
                      {data.status === 'finished' || data.status === 'rejected' ? (
                        <div className="col-12 col-md-6">
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Date' : 'Rejected Date'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {data.finishedDate && moment(data.finishedDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Time' : 'Rejected Time'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {data.finishedDate && moment(data.finishedDate).format('hh:mm a')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">Total Time Taken</div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {data.finishedDate && `${moment(data.finishedDate).diff(data.createdAt, 'days')} days`}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item left">
                  <h4 className="title">User Enquiry</h4>
                  <div className="content">
                    {data.description}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="row right">
                  <div className="col-12 col-md-6">
                    <div className="item p-left">
                      <h4 className="title">Picture 1</h4>
                      <div className="picture">
                        <img src={data.photos?.[0] ? data.photos[0] : null} alt="1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="item p-right">
                      <h4 className="title">Picture 2</h4>
                      <div className="picture">
                        <img src={data.photos?.[1] ? data.photos[1] : null} alt="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.status !== 'rejected' && (
            <div className="row">
              <div className="col-12">
                <div className="item">
                  <div className="content">
                    {data.status === 'waiting' && (
                    <div className="form">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">Current Diagnosis</span>
                              </div>
                              <div className="col-12">
                                <TextArea
                                  id="currentDiagnosis"
                                  name="currentDiagnosis"
                                  placeholder="Current Diagnosis"
                                  onChangeText={handleChange}
                                />
                                {currentDiagnosisError && <div className="form__error">{currentDiagnosisError}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">History and Findings</span>
                              </div>
                              <div className="col-12">
                                <TextArea
                                  id="historyAndFindings"
                                  name="historyAndFindings"
                                  placeholder="History and Findings"
                                  onChangeText={handleChange}
                                />
                                {historyAndFindingsError && <div className="form__error">{historyAndFindingsError}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">Special Instruction (Please mention follow up date)</span>
                              </div>
                              <div className="col-12">
                                <TextArea
                                  id="specialInstruction"
                                  name="specialInstruction"
                                  placeholder="Special Instruction"
                                  onChangeText={handleChange}
                                />
                                {specialInstructionError && <div className="form__error">{specialInstructionError}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">Treatment</span>
                              </div>
                              <div className="col-12">
                                <TextArea
                                  id="treatment"
                                  name="treatment"
                                  placeholder="Treatment"
                                  onChangeText={handleChange}
                                />
                                {treatmentError && <div className="form__error">{treatmentError}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">Choose Language</span>
                              </div>
                              <div className="col-12">
                                <select
                                  id="language"
                                  name="language"
                                  className="form__field"
                                  onChange={handleLanguageChange}
                                >
                                  {LANGUAGES.map((lang) => (
                                    <option key={lang.value} value={lang.value}>
                                      {lang.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <div className="row middle">
                              <div className="col-12">
                                <span className="form__label">Ethnoveterinary E-Booklet</span>
                              </div>
                              <div className="col-12">
                                <select
                                  id="evet"
                                  name="evet"
                                  className="form__field"
                                  onChange={handleEvetChange}
                                >
                                  {evet.map((item, index) => (
                                    <option key={evet.name} value={index} on>{item.name}</option>
                                  ))}
                                </select>
                                {evetError && (
                                <div className="form__error">
                                  {evetError}
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form__item">
                            <div className="row">
                              <div className="col-12 d-flex-col middle">
                                <button
                                  type="button"
                                  className="form__button"
                                  onClick={submitPrescription}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                    <div className="row">
                      <div className="col-12">
                        {message && (
                        <div className="message-card">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="message-card__icon"
                          />
                          <span className="message-card__text">
                            Prescription Successfully uploaded
                            {' '}
                            {'->'}
                            {' '}
                            Press on Finish or Reject Appointment Button
                          </span>
                        </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-12 col-md-6 d-flex-col center">
                        <div className="help-card mr-8">
                          <span>
                            Upload Confirmed Prescription to submit.
                          </span>
                        </div>
                        <div className="form">
                          <div className="form__item">
                            <label htmlFor="document">
                              <span className="form__label">
                                Upload File
                              </span>
                              <p className="form__help">
                                Upload prescription file using this form
                              </p>
                              <input
                                type="file"
                                name="document"
                                id="document"
                                onChange={handleSelectFile}
                              />
                              {fileError && <div className="form__error">{fileError}</div>}
                            </label>
                          </div>
                          <div className="form__item">
                            <button
                              type="button"
                              className="form__button"
                              onClick={submitPrescription}
                            >
                              Submit
                            </button>
                          </div>
                          {message && (
                          <div className="message-card">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="message-card__icon"
                            />
                            <span className="message-card__text">
                              Prescription Successfully uploaded
                              {' '}
                              {'->'}
                              {' '}
                              Press on Finish or Reject Appointment Button
                            </span>
                          </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex-col center">
                        <div className="help-card ml-8">
                          <span>
                            Download and check prescription.
                          </span>
                        </div>
                        <div className="downloadImg">
                          <img src={downloadImg} alt="download icon" />
                        </div>
                        <button type="button" className="export-button" onClick={generatePdf}>
                          {isExporting ? 'Exporting...' : 'Download'}
                        </button>
                      </div>
                    </div> */}
                    <div className="prescription">
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12 d-flex">
                            <div className="prescription__logo">
                              <img src={logoImg} alt="logo" />
                            </div>
                            <div className="prescription__address">
                              <div className="prescription__title">
                                Dr.
                                {' '}
                                {userName}
                              </div>
                              <div className="prescription__title">{specialization}</div>
                              <div className="prescription__title">{regionName}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-6">
                            <div className="prescription__title">
                              Name : Dr.
                              {' '}
                              {userName}
                            </div>
                          </div>
                          <div className="col-6 d-flex-col end">
                            <div className="prescription__title">{moment().format('DD/MM/YYYY')}</div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-6">
                            <div className="prescription__subtitle">
                              Patient ID :
                              {' '}
                              {data.Farmer ? data.Farmer.id : ''}
                            </div>
                            <div className="prescription__subtitle">
                              Cattle ID :
                              {' '}
                              {data.Cattle ? data.Cattle.id : ''}
                            </div>
                          </div>
                          <div className="col-6 d-flex-col end">
                            <div className="prescription__subtitle">
                              Prescription ID :
                              {' '}
                              {data.id}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div className="prescription__title">
                              Current diagnosis :
                              {' '}
                              {values.currentDiagnosis
                              || (data.Medical && data.Medical.currentDiagnosis)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div className="prescription__table-container">
                              <table className="prescription__table">
                                <thead>
                                  <tr>
                                    <th>History and Findings</th>
                                    <th>Treatment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(!!values.historyAndFindings && !!values.treatment)
                                  || data.Medical
                                    ? (
                                      <tr>
                                        <td>
                                          {values.historyAndFindings
                                           || data.Medical.historyFindings}
                                        </td>
                                        <td>{values.treatment || data.Medical.treatment}</td>
                                      </tr>
                                    )
                                    : <tr><td colSpan={2}>none</td></tr>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div>
                              <span className="prescription__title">
                                Special instruction :
                                {' '}
                              </span>
                              <span>
                                {values.specialInstruction
                              || (data.Medical && data.Medical.specialInstruction)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        )
        : null}
      <LoadingModal
        show={
          isLoading
          || isLoadingReject
          || isLoadingFinish
          || isLoadingPrescription
          || isExporting
          || isLoadingFollowUp
        }
      />
    </>
  );
};

export default View;
