const prefix = 'NEWUSER';

export const GET_ALL_PENDING_START = `${prefix}_GET_ALL_PENDING_START`;
export const GET_ALL_PENDING_SUCCESS = `${prefix}_GET_ALL_PENDING_SUCCESS`;
export const GET_ALL_PENDING_ERROR = `${prefix}_GET_ALL_PENDING_ERROR`;

export const GET_ALL_COMPLETED_START = `${prefix}_GET_ALL_COMPLETED_START`;
export const GET_ALL_COMPLETED_SUCCESS = `${prefix}_GET_ALL_COMPLETED_SUCCESS`;
export const GET_ALL_COMPLETED_ERROR = `${prefix}_GET_ALL_COMPLETED_ERROR`;

export const GET_SINGLE_USER_DETAILS_REP_START = `${prefix}_GET_SINGLE_USER_DETAILS_REP_START`;
export const GET_SINGLE_USER_DETAILS_REP_SUCCESS = `${prefix}_GET_SINGLE_USER_DETAILS_REP_SUCCESS`;
export const GET_SINGLE_USER_DETAILS_REP_ERROR = `${prefix}_GET_SINGLE_USER_DETAILS_REP_ERROR`;

export const GET_SINGLE_USER_DETAILS_FARMER_START = `${prefix}_GET_SINGLE_USER_DETAILS_FARMER_START`;
export const GET_SINGLE_USER_DETAILS_FARMER_SUCCESS = `${prefix}_GET_SINGLE_USER_DETAILS_FARMER_SUCCESS`;
export const GET_SINGLE_USER_DETAILS_FARMER_ERROR = `${prefix}_GET_SINGLE_USER_DETAILS_FARMER_ERROR`;

export const PUT_NEW_USER_START = `${prefix}_PUT_NEW_USER_START`;
export const PUT_NEW_USER_SUCCESS = `${prefix}_PUT_NEW_USER_SUCCESS`;
export const PUT_NEW_USER_ERROR = `${prefix}_PUT_NEW_USER_ERROR`;

export const GET_ALL_PENDING_PLANT_START = `${prefix}_GET_ALL_PENDING_PLANT_START`;
export const GET_ALL_PENDING_PLANT_SUCCESS = `${prefix}_GET_ALL_PENDING_PLANT_SUCCESS`;
export const GET_ALL_PENDING_PLANT_ERROR = `${prefix}_GET_ALL_PENDING_PLANT_ERROR`;

export const GET_ALL_COMPLETED_PLANT_START = `${prefix}_GET_ALL_COMPLETED_PLANT_START`;
export const GET_ALL_COMPLETED_PLANT_SUCCESS = `${prefix}_GET_ALL_COMPLETED_PLANT_SUCCESS`;
export const GET_ALL_COMPLETED_PLANT_ERROR = `${prefix}_GET_ALL_COMPLETED_PLANT_ERROR`;

export const PUT_NEW_USER_APPROVAL_START = `${prefix}_PUT_NEW_USER_APPROVAL_START`;
export const PUT_NEW_USER_APPROVAL_SUCCESS = `${prefix}_PUT_NEW_USER_APPROVAL_SUCCESS`;
export const PUT_NEW_USER_APPROVAL_ERROR = `${prefix}_PUT_NEW_USER_APPROVAL_ERROR`;

export const PUT_NEW_USER_REJECTION_START = `${prefix}_PUT_NEW_USER_REJECTION_START`;
export const PUT_NEW_USER_REJECTION_SUCCESS = `${prefix}_PUT_NEW_USER_REJECTION_SUCCESS`;
export const PUT_NEW_USER_REJECTION_ERROR = `${prefix}_PUT_NEW_USER_REJECTION_ERROR`;

export const PUT_NEW_USER_APPROVAL_PLANT_START = `${prefix}_PUT_NEW_USER_APPROVAL_PLANT_START`;
export const PUT_NEW_USER_APPROVAL_PLANT_SUCCESS = `${prefix}_PUT_NEW_USER_APPROVAL_PLANT_SUCCESS`;
export const PUT_NEW_USER_APPROVAL_PLANT_ERROR = `${prefix}_PUT_NEW_USER_APPROVAL_PLANT_ERROR`;

export const PUT_NEW_USER_REJECTION_PLANT_START = `${prefix}_PUT_NEW_USER_REJECTION_PLANT_START`;
export const PUT_NEW_USER_REJECTION_PLANT_SUCCESS = `${prefix}_PUT_NEW_USER_REJECTION_PLANT_SUCCESS`;
export const PUT_NEW_USER_REJECTION_PLANT_ERROR = `${prefix}_PUT_NEW_USER_REJECTION_PLANT_ERROR`;

export const PUT_NEW_USER_FARMERCODE_START = `${prefix}_PUT_NEW_USER_FARMERCODE_START`;
export const PUT_NEW_USER_FARMERCODE_SUCCESS = `${prefix}_PUT_NEW_USER_FARMERCODE_SUCCESS`;
export const PUT_NEW_USER_FARMERCODE_ERROR = `${prefix}_PUT_NEW_USER_FARMERCODE_ERROR`;

export const GET_ALL_PENDING_REGIONHEAD_START = `${prefix}_GET_ALL_PENDING_REGIONHEAD_START`;
export const GET_ALL_PENDING_REGIONHEAD_SUCCESS = `${prefix}_GET_ALL_PENDING_REGIONHEAD_SUCCESS`;
export const GET_ALL_PENDING_REGIONHEAD_ERROR = `${prefix}_GET_ALL_PENDING_REGIONHEAD_ERROR`;

export const GET_ALL_COMPLETED_REGIONHEAD_START = `${prefix}_GET_ALL_COMPLETED_REGIONHEAD_START`;
export const GET_ALL_COMPLETED_REGIONHEAD_SUCCESS = `${prefix}_GET_ALL_COMPLETED_REGIONHEAD_SUCCESS`;
export const GET_ALL_COMPLETED_REGIONHEAD_ERROR = `${prefix}_GET_ALL_COMPLETED_REGIONHEAD_ERROR`;

export const GET_ALL_HPC_CODES_START = `${prefix}_GET_ALL_HPC_CODES_START`;
export const GET_ALL_HPC_CODES_SUCCESS = `${prefix}_GET_ALL_HPC_CODES_SUCCESS`;
export const GET_ALL_HPC_CODES_ERROR = `${prefix}_GET_ALL_HPC_CODES_ERROR`;

export const EXPORT_EXCEL_PENDING_START = `${prefix}_EXPORT_EXCEL_PENDING_START`;
export const EXPORT_EXCEL_PENDING_SUCCESS = `${prefix}_EXPORT_EXCEL_PENDING_SUCCESS`;
export const EXPORT_EXCEL_PENDING_ERROR = `${prefix}_EXPORT_EXCEL_PENDING_ERROR`;

export const EXPORT_EXCEL_COMPLETED_START = `${prefix}_EXPORT_EXCEL_COMPLETED_START`;
export const EXPORT_EXCEL_COMPLETED_SUCCESS = `${prefix}_EXPORT_EXCEL_COMPLETED_SUCCESS`;
export const EXPORT_EXCEL_COMPLETED_ERROR = `${prefix}_EXPORT_EXCEL_COMPLETED_ERROR`;

export const GET_ALL_PLANT_CODE_START = `${prefix}_GET_ALL_PLANT_CODE_START`;
export const GET_ALL_PLANT_CODE_SUCCESS = `${prefix}_GET_ALL_PLANT_CODE_SUCCESS`;
export const GET_ALL_PLANT_CODE_ERROR = `${prefix}_GET_ALL_PLANT_CODE_ERROR`;

export const STORE_DIGITAL_SIGNATURE = `${prefix}_STORE_DIGITAL_SIGNATURE`;
export const CLEAR_DIGITAL_SIGNATURE = `${prefix}_CLEAR_DIGITAL_SIGNATURE`;

export const GET_DIGITAL_SIGNATURE_START = `${prefix}_GET_DIGITAL_SIGNATURE_START`;
export const GET_DIGITAL_SIGNATURE_SUCCESS = `${prefix}_GET_DIGITAL_SIGNATURE_SUCCESS`;
export const GET_DIGITAL_SIGNATURE_ERROR = `${prefix}_GET_DIGITAL_SIGNATURE_ERROR`;

export const STORE_DIGITAL_SIGNATURE_START = `${prefix}_STORE_DIGITAL_SIGNATURE_START`;
export const STORE_DIGITAL_SIGNATURE_SUCCESS = `${prefix}_STORE_DIGITAL_SIGNATURE_SUCCESS`;
export const STORE_DIGITAL_SIGNATURE_ERROR = `${prefix}_STORE_DIGITAL_SIGNATURE_ERROR`;

export const DELETE_DIGITAL_SIGNATURE_START = `${prefix}_DELETE_DIGITAL_SIGNATURE_START`;
export const DELETE_DIGITAL_SIGNATURE_SUCCESS = `${prefix}_DELETE_DIGITAL_SIGNATURE_SUCCESS`;
export const DELETE_DIGITAL_SIGNATURE_ERROR = `${prefix}_DELETE_DIGITAL_SIGNATURE_ERROR`;

export const STORE_USER_IP_ADDRESS_START = `${prefix}_STORE_USER_IP_ADDRESS_START`;
export const STORE_USER_IP_ADDRESS_SUCCESS = `${prefix}_STORE_USER_IP_ADDRESS_SUCCESS`;
export const STORE_USER_IP_ADDRESS_ERROR = `${prefix}_STORE_USER_IP_ADDRESS_ERROR`;

export const GET_COUNT_DIGITAL_REGISTRATION_START = `${prefix}_GET_COUNT_DIGITAL_REGISTRATION_START`;
export const GET_COUNT_DIGITAL_REGISTRATION_SUCCESS = `${prefix}_GET_COUNT_DIGITAL_REGISTRATION_SUCCESS`;
export const GET_COUNT_DIGITAL_REGISTRATION_ERROR = `${prefix}_GET_COUNT_DIGITAL_REGISTRATION_ERROR`;

export const BULK_UPLOAD_EAPI_START = `${prefix}_BULK_UPLOAD_EAPI_START`;
export const BULK_UPLOAD_EAPI_SUCCESS = `${prefix}_BULK_UPLOAD_EAPI_SUCCESS`;
export const BULK_UPLOAD_EAPI_ERROR = `${prefix}_BULK_UPLOAD_EAPI_ERROR`;

export const GET_ALL_MCC_FARMERS_START = `${prefix}_GET_ALL_MCC_FARMERS_START`;
export const GET_ALL_MCC_FARMERS_SUCCESS = `${prefix}_GET_ALL_MCC_FARMERS_SUCCESS`;
export const GET_ALL_MCC_FARMERS_ERROR = `${prefix}_GET_ALL_MCC_FARMERS_ERROR`;

export const GET_ALL_PENDING_MCC_FARMERS_START = `${prefix}_GET_ALL_PENDING_MCC_FARMERS_START`;
export const GET_ALL_PENDING_MCC_FARMERS_SUCCESS = `${prefix}_GET_ALL_PENDING_MCC_FARMERS_SUCCESS`;
export const GET_ALL_PENDING_MCC_FARMERS_ERROR = `${prefix}_GET_ALL_PENDING_MCC_FARMERS_ERROR`;

export const GET_ALL_COMPLETED_MCC_FARMERS_START = `${prefix}_GET_ALL_COMPLETED_MCC_FARMERS_START`;
export const GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS = `${prefix}_GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS`;
export const GET_ALL_COMPLETED_MCC_FARMERS_ERROR = `${prefix}_GET_ALL_COMPLETED_MCC_FARMERS_ERROR`;

export const GET_ALL_COUNT_MCC_FARMERS_START = `${prefix}_GET_ALL_COUNT_MCC_FARMERS_START`;
export const GET_ALL_COUNT_MCC_FARMERS_SUCCESS = `${prefix}_GET_ALL_COUNT_MCC_FARMERS_SUCCESS`;
export const GET_ALL_COUNT_MCC_FARMERS_ERROR = `${prefix}_GET_ALL_COUNT_MCC_FARMERS_ERROR`;

export const DOWNLOAD_PDF_START = `${prefix}_DOWNLOAD_PDF_START`;
export const DOWNLOAD_PDF_SUCCESS = `${prefix}_DOWNLOAD_PDF_SUCCESS`;
export const DOWNLOAD_PDF_ERROR = `${prefix}_DOWNLOAD_PDF_ERROR`;
