import { createSelector } from 'reselect';

export const selectRaw = (state) => state.notification;

export const selectNotification = createSelector(
  [selectRaw],
  (notification) => notification.notification,
);

export const selectNotifications = createSelector(
  [selectRaw],
  (notification) => notification.notifications,
);

export const selectMessage = createSelector(
  [selectRaw],
  (notification) => notification.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (notification) => notification.isLoading,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (notification) => notification.errorMessage,
);

const selectors = {
  selectRaw,
  selectNotification,
  selectNotifications,
  selectMessage,
  selectIsLoading,
  selectErrorMessage,
};

export default selectors;
