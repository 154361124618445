/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import {
  selectIsLoading,
  selectAutoPopulate,
  selectAllTicketDetails,
} from '../../../store/modules/tickets/selectors';
import {
  raiseTicket,
  autoPopulatedTicketDetails,
  storeAutoPopulatedData,
  clearTicketDetails,
} from '../../../store/modules/tickets/actions';
import AnimatedLoader from '../../../components/AnimatedLoader';
import {
  defaultStates,
  language,
  statesArray,
  ticketTypeArray,
} from './availabalearray';
import './RaiseTicket.scss';

const RaiseTicket = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoading(state));
  const autoPopulate = useSelector((state) => selectAutoPopulate(state));
  const ticketDetails = useSelector((state) => selectAllTicketDetails(state));
  const [selectedState, setSelectedState] = useState({
    state: null,
    district: null,
    village: null,
  });
  const handleOnAutoRecords = (autoPopulatedData) => {
    if (Array.isArray(autoPopulatedData?.data)) {
      const updatedState = {
        villages: autoPopulatedData?.data,
      };
      dispatch(storeAutoPopulatedData(updatedState));
    }
  };
  const handleOnSelected = (property, e) => {
    const updatedState = {
      ...selectedState,
      [property]: e?.target?.value,
    };
    setSelectedState(updatedState);
    const areAllRequiredFilled = Object?.values(updatedState)?.every(
      (value) => value !== null,
    );
    const fieldsToClear = ['state', 'district'];
    if (fieldsToClear?.includes(property)) {
      dispatch(clearTicketDetails());
    } else if (areAllRequiredFilled) {
      const filteredData = Object?.fromEntries(
        Object?.entries(updatedState)?.filter(([key, value]) => value !== null),
      );
      dispatch(autoPopulatedTicketDetails(filteredData, handleOnAutoRecords));
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const handleGoBack = () => {
    history.goBack();
    dispatch(clearTicketDetails());
  };
  const onSubmit = ({
    farmerName,
    phone,
    type,
    mccCode,
    mccName,
    plantCode,
    plantName,
    region,
    farmerLanguage,
    previouslyCalled,
    callDescription,
    state,
    district,
    village,
    agentName,
  }) => {
    const data = {
      farmerName,
      phone,
      type,
      mccCode,
      mccName,
      plantCode,
      plantName,
      region,
      farmerLanguage,
      previouslyCalled,
      callDescription,
      state,
      district,
      village,
      agentName,
    };
    Swal.fire({
      icon: 'info',
      text: 'Do you want to cross check all the fields?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#229945',
      cancelButtonColor: '#f26868',
      cancelButtonText: 'No',
    }).then((response) => {
      if (response.isDismissed) {
        const filteredData = Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value !== null),
        );
        dispatch(raiseTicket(filteredData, handleGoBack));
      }
    });
  };
  useEffect(() => {
    if (autoPopulate?.id && typeof autoPopulate === 'object' && autoPopulate !== null) {
      const keyMapping = {
        mccOrHpcCode: 'mccCode',
        mccOrHpcName: 'mccName',
      };
      const filteredData = {};
      Object?.keys(autoPopulate)?.forEach((key) => {
        if (autoPopulate[key] !== null) {
          const mappedKey = keyMapping[key] || key;
          filteredData[mappedKey] = autoPopulate[key];
        }
      });
      Object?.keys(filteredData)?.forEach((key) => {
        setValue(key, filteredData[key]);
      });
    }
  }, [autoPopulate, setValue]);
  return (
    <>
      <div className="ticket">
        <div className="row between">
          <div className="col-md-auto">
            <div className="heading">
              <p className="heading__title">
                Alrighty! Let's Get This Sorted 🛠️🎟️
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="pinCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Farmer Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="farmerName"
                                name="farmerName"
                                placeholder="Farmer Name"
                                {...register('farmerName', {
                                  required: {
                                    value: true,
                                    message: 'Name is required',
                                  },
                                })}
                              />
                              {errors.farmerName && (
                                <div className="form__error">
                                  {errors.farmerName.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="phone">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Mobile No</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="phone"
                                maxLength={10}
                                name="phone"
                                placeholder="Mobile No"
                                {...register('phone', {
                                  required: {
                                    value: true,
                                    message: 'Mobile No is required',
                                  },
                                  minLength: {
                                    value: 10,
                                    message:
                                      'Mobile No must not be less than 10 digits',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message:
                                      'Mobile No must not be greater than 10 digits',
                                  },
                                  pattern: {
                                    value: /^[5-9][0-9]{9}$/,
                                    message:
                                      'Mobile No must be a valid mobile No',
                                  },
                                })}
                              />
                              {errors.phone && (
                                <div className="form__error">
                                  {errors.phone.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="type">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Ticket Type</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="type"
                                name="type"
                                {...register('type', {
                                  required: {
                                    value: true,
                                    message: 'Type is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select an option
                                </option>
                                {ticketTypeArray?.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {errors.type && (
                                <div className="form__error">
                                  {errors.type.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="state">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">State Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="state"
                                onClick={(e) => handleOnSelected('state', e)}
                                name="state"
                                {...register('state', {
                                  required: {
                                    value: true,
                                    message: 'state is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select State
                                </option>
                                {statesArray?.map((item) => (
                                  <option key={item?.name} value={item?.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                              {errors.state && (
                                <div className="form__error">
                                  {errors.state.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="district">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">District Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="district"
                                onClick={(e) => handleOnSelected('district', e)}
                                name="district"
                                {...register('district', {
                                  required: {
                                    value: true,
                                    message: 'district is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select district
                                </option>
                                {selectedState?.state
                                  ? (statesArray
                                    ?.find(
                                      (state) => state?.name === selectedState?.state,
                                    )
                                    .districts?.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))) : (
                                      <option value="" disabled>
                                        🏘️ Explore districts by choosing a state! 🚀
                                      </option>
                                  )}
                              </select>
                              {errors?.district && (
                                <div className="form__error">
                                  {errors?.district?.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="village">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Village Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="village"
                                onClick={(e) => handleOnSelected('village', e)}
                                name="village"
                                {...register('village', {
                                  required: {
                                    value: true,
                                    message: 'Village Name is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select Village
                                </option>
                                {ticketDetails?.villages?.length > 0 ? (
                                  ticketDetails?.villages?.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    ✨ No villages here! Spice it up by choosing a state and district! 🌶️
                                  </option>
                                )}
                              </select>
                              {errors.village && (
                                <div className="form__error">
                                  {errors.village.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="region">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Region Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="region"
                                name="region"
                                placeholder="Region Name"
                                {...register('region', {
                                  required: {
                                    value: true,
                                    message: 'Region name is required',
                                  },
                                })}
                              />
                              {errors.region && (
                                <div className="form__error">
                                  {errors.region.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="plantCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Plant Code</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="plantCode"
                                maxLength={4}
                                name="plantCode"
                                placeholder="Plant Code"
                                {...register('plantCode', {
                                  required: {
                                    value: true,
                                    message: 'Plant code is required',
                                  },
                                  minLength: {
                                    value: 4,
                                    message:
                                      'Plant code must not be less than 4 digits',
                                  },
                                  maxLength: {
                                    value: 4,
                                    message:
                                      'Plant code must not be greater than 4 digits',
                                  },
                                  pattern: {
                                    value: /\d/,
                                    message: 'Plant code must be digits',
                                  },
                                })}
                              />
                              {errors.plantCode && (
                                <div className="form__error">
                                  {errors.plantCode.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="plateName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Plant Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="plantName"
                                name="plantName"
                                placeholder="Plant Name"
                                {...register('plantName', {
                                  required: {
                                    value: true,
                                    message: 'Plant name is required',
                                  },
                                })}
                              />
                              {errors.plantName && (
                                <div className="form__error">
                                  {errors.plantName.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mccCode">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC Code</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mccCode"
                                maxLength={4}
                                name="mccCode"
                                placeholder="MCC Code"
                                // {...register('mccCode', {
                                //   required: {
                                //     value: false,
                                //     message: 'MCC code is required',
                                //   },
                                //   minLength: {
                                //     value: 3,
                                //     message:
                                //       'MCC code must not be less than 3 digits',
                                //   },
                                //   maxLength: {
                                //     value: 4,
                                //     message:
                                //       'MCC code must not be greater than 4 digits',
                                //   },
                                //   pattern: {
                                //     value: /\d/,
                                //     message: 'MCC code must be digits',
                                //   },
                                // })}
                              />
                              {errors.mccCode && (
                                <div className="form__error">
                                  {errors.mccCode.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="mccName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">MCC Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="mccName"
                                name="mccName"
                                placeholder="MCC Name"
                                {...register('mccName', {
                                  required: {
                                    value: false,
                                    message: 'MCC name is required',
                                  },
                                })}
                              />
                              {errors.mccName && (
                                <div className="form__error">
                                  {errors.mccName.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="agentName">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">Agent Name</span>
                            </div>
                            <div className="col-12 col-md-6">
                              <input
                                className="form__field"
                                type="text"
                                id="agentName"
                                name="agentName"
                                placeholder="Agent Name"
                                {...register('agentName', {
                                  required: {
                                    value: true,
                                    message: 'Agent Name is required',
                                  },
                                })}
                              />
                              {errors.agentName && (
                                <div className="form__error">
                                  {errors.agentName.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="farmerLanguage">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">
                                Farmer Language
                              </span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="farmerLanguage"
                                name="farmerLanguage"
                                {...register('farmerLanguage', {
                                  required: {
                                    value: true,
                                    message: 'Language is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select a Language
                                </option>
                                {language?.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {errors.farmerLanguage && (
                                <div className="form__error">
                                  {errors.farmerLanguage.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="previouslyCalled">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">
                                Previously Called
                              </span>
                            </div>
                            <div className="col-12 col-md-6">
                              <select
                                className="form__field"
                                id="previouslyCalled"
                                name="previouslyCalled"
                                {...register('previouslyCalled', {
                                  required: {
                                    value: true,
                                    message:
                                      'Previously Called Status is required',
                                  },
                                })}
                              >
                                <option value="" hidden>
                                  Select Status
                                </option>
                                {defaultStates?.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {errors?.previouslyCalled && (
                                <div className="form__error">
                                  {errors?.previouslyCalled?.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form__item">
                        <label htmlFor="callDescription">
                          <div className="row middle">
                            <div className="col-12 col-md-6">
                              <span className="form__label">
                                Call Description
                              </span>
                            </div>
                            <div className="col-12 col-md-6">
                              <textarea
                                className="form__largeField"
                                type="text"
                                id="callDescription"
                                name="callDescription"
                                placeholder="call Description"
                                {...register('callDescription', {
                                  required: {
                                    value: true,
                                    message: 'Call Description is required',
                                  },
                                })}
                              />
                              {errors.callDescription && (
                                <div className="form__error">
                                  {errors.callDescription.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="buttons">
                        <button
                          type="button"
                          className="button radial"
                          onClick={handleSubmit(onSubmit)}
                        >
                          🚀 Launch the Ticket!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatedLoader show={isLoading} />
    </>
  );
};

export default RaiseTicket;
