/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import Lottie from 'lottie-react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import Swal from 'sweetalert2';
import { isNotNullUndefinedOrEmpty } from '../../components/Validators';
import { getAllProductsList, updatePlantSideHnlRequests } from '../../store/modules/product/actions';
import {
  selectIsLoading,
  selectProducts,
} from '../../store/modules/product/selectors';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import withScreenRefreshDetection from '../../utils/withScreenRefreshDetection';
import EmptyInventory from '../../assets/animations/EmptyInventory.json';
import './Hnlindenting.scss';

const Tickets = (props) => {
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const [query, setQuery] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [storePageSizeValue, setStorePageSizeValue] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [products, setProducts] = useState([]);
  const [productsController, setProductsController] = useState({
    thresholds: {},
    quantities: {},
  });
  const data = useSelector((state) => selectProducts(state));
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleCallBack = (response) => {
    if (response) {
      const values = {
        page: pageNum,
        limit: storePageSizeValue,
        plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
      };
      dispatch(getAllProductsList(values));
    }
  };
  const handleButtonClick = (material, thresholdValue, quantityValue) => {
    const values = {
      id: material?.id,
      threshold: thresholdValue,
      indentQuantity: quantityValue,
      plantIcStatus: 'approved',
    };
    const array = [
      Object.entries(values)
        .filter(([_, value]) => isNotNullUndefinedOrEmpty(value))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {}),
    ];
    Swal.fire({
      title: 'Confirm Approval?',
      text: `Are you sure you want to approve the ${material?.materialName ?? 'current item'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#229945',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updatePlantSideHnlRequests(array, handleCallBack));
      }
    });
  };
  const handleInputChange = (row, key, value) => {
    const updatedState = { ...productsController };
    updatedState[key][row.original.id] = value;

    setProductsController((prevState) => ({
      ...prevState,
      ...updatedState,
    }));

    // Assuming newData and setProducts are defined elsewhere
    const newData = { ...data };
    const updatedRow = { ...row.original, [key]: value };
    const updatedPaginate = newData?.paginate?.map((rowData) => (rowData.id === updatedRow.id ? updatedRow : rowData));
    newData.paginate = updatedPaginate;
    setProducts(newData);
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={(e) => {
              const { checked } = e.target;
              setSelectAll(checked);
              if (checked) {
                setSelectedRows(data.paginate.map((row) => row.id));
              } else {
                setSelectedRows([]);
              }
            }}
          />
        ),
        accessor: 'select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.original.id)}
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setSelectedRows([...selectedRows, row.original.id]);
              } else {
                setSelectedRows(
                  selectedRows.filter((id) => id !== row.original.id),
                );
              }
            }}
          />
        ),
      },
      {
        Header: 'INDENT ID',
        accessor: 'indentId',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PRODUCT NAME',
        accessor: 'materialName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PRODUCT CATEGORY',
        accessor: 'type',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PRODUCT SIZE',
        accessor: 'size',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'CURRENT INVENTORY',
        accessor: 'ohs',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'UNIT OF MEASUREMENT',
        accessor: 'uom',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'MATERIAL CODE',
        accessor: 'materialCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'BATCH NUMBER',
        accessor: 'batchNo',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PLANT CODE',
        accessor: 'plantCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PLANT NAME',
        accessor: 'plantName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      // {
      //   Header: 'INDENT THRESHOLD',
      //   accessor: 'threshold',
      //   Cell: ({ row }) => (
      //     <input
      //       type="number"
      //       disabled
      //       className="form__field"
      //       id="threshold"
      //       name="threshold"
      //       placeholder="THRESHOLD"
      //       value={productsController?.thresholds[row?.original?.id] ?? row?.original?.threshold}
      //       onChange={(e) => handleInputChange(row, 'thresholds', e.target.value)}
      //     />
      //   ),
      // },
      {
        Header: 'INDENT QUANTITY',
        accessor: 'indentQuantity',
        Cell: ({ row }) => (
          <input
            type="number"
            id="indentQuantity"
            name="indentQuantity"
            placeholder="QUANTITY"
            className="form__field"
            value={productsController?.quantities[row?.original?.id] ?? row?.original?.indentQuantity}
            onChange={(e) => handleInputChange(row, 'quantities', e?.target?.value)}
          />
        ),
      },
      {
        Header: 'ACTION TO BE TAKEN',
        Cell: ({ row }) => (
          <div className="buttons">
            <button
              type="button"
              className="mini-button radial"
              onClick={() => handleButtonClick(row?.original, productsController?.thresholds[row.original.id], productsController?.quantities[row?.original?.id])}
            >
              Approve
            </button>
          </div>
        ),
      },
    ],
    [selectAll, selectedRows, data, productsController],
  );
  const finishedColumns = useMemo(
    () => [{
      Header: 'INDENT ID',
      accessor: 'indentId',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PRODUCT NAME',
      accessor: 'materialName',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PRODUCT CATEGORY',
      accessor: 'type',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PRODUCT SIZE',
      accessor: 'size',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'CURRENT INVENTORY',
      accessor: 'ohs',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'UNIT OF MEASUREMENT',
      accessor: 'uom',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'CURRENT RAISED QUANTITY',
      accessor: 'currentQuantity',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'INDENT QUANTITY',
      accessor: 'indentQuantity',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'MATERIAL CODE',
      accessor: 'materialCode',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'BATCH NUMBER',
      accessor: 'batchNo',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PLANT CODE',
      accessor: 'plantCode',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PLANT NAME',
      accessor: 'plantName',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'REJECTED REASON',
      accessor: 'comment',
      Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
    },
    {
      Header: 'PlANT STATUS',
      accessor: 'plantIcStatus',
      Cell: ({ value }) => (value !== null ? (
        <p
          style={{
            color:
              value === 'pending'
                ? '#ffb100'
                : value === 'rejected'
                  ? 'red'
                  : 'green',
          }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    }, {
      Header: 'REGIONAL STATUS',
      accessor: 'rHStatus',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    },
    {
      Header: 'HNL STATUS',
      accessor: 'hnlStatus',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    }, {
      Header: 'OVERALL STATUS',
      accessor: 'status',
      Cell: ({ value }) => (value !== null ? (
        <p style={{
          color:
            value === 'pending'
              ? '#ffb100'
              : value === 'rejected'
                ? 'red'
                : 'green',
        }}
        >
          {value}
        </p>
      ) : <p>N/A</p>),
    }],
    [],
  );
  const isLoading = useSelector((state) => selectIsLoading(state));
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: tabIndex === 0 ? columns : finishedColumns,
    data:
      data && data?.paginate && data?.paginate?.length
        ? data?.paginate?.map((item) => ({
          ...item,
          phone: item?.phone?.replace('+91', ''),
        }))
        : [],
  });

  const nextPage = (page) => {
    setPageNum(page);
    const nextPageVal = {
      page,
      limit: storePageSizeValue,
      plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
    };
    dispatch(getAllProductsList(nextPageVal));
  };

  const previousPage = (page) => {
    setPageNum(page);
    const prevPageVal = {
      page,
      limit: storePageSizeValue,
      plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
    };
    dispatch(getAllProductsList(prevPageVal));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    const values = {
      prodName: query,
      page: pageNum,
      limit: 10,
      plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
    };
    dispatch(getAllProductsList(values));
  };

  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);
  const handleOnPageSizeSelected = (event) => {
    setStorePageSizeValue(event?.target?.value);
    const values = {
      page: pageNum,
      limit: `${event?.target?.value}`,
      plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
    };
    dispatch(getAllProductsList(values));
  };
  const handleOnBulkApproval = (event) => {
    if (event === 'submit') {
      const array = selectedRows.map((id) => {
        const thresholdValue = productsController?.thresholds[id];
        const quantityValue = productsController?.quantities[id];
        return thresholdValue !== undefined
          ? {
            id, threshold: thresholdValue, indentQuantity: quantityValue, plantIcStatus: 'approved',
          }
          : { id, plantIcStatus: 'approved' };
      });
      Swal.fire({
        title: 'Confirm Approval?',
        text: 'Are you sure you want to approve all the selected items?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updatePlantSideHnlRequests(array, handleCallBack));
        }
      });
    }
  };
  const arrayOfButtons = [
    {
      id: 0,
      name: 'Approve',
      className: 'button green',
      onClick: () => handleOnBulkApproval('submit'),
    },
  ];

  useEffect(() => {
    const values = {
      page: 1,
      limit: 10,
      plantIcStatus: tabIndex === 0 ? 'pending' : 'approved',
    };
    if (tabIndex === 1) {
      dispatch(getAllProductsList(values));
    } else {
      dispatch(getAllProductsList(values));
    }
  }, [dispatch, tabIndex]);

  return (
    <div className="hnl-indenting-dashboard">
      <Tabs
        defaultIndex={tabIndex}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="row between tab-list">
          <Tab className="col-6 tab">
            <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
              Ongoing Inventory
            </div>
          </Tab>
          <Tab className="col-6 tab">
            <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
              Finished Inventory
            </div>
          </Tab>
        </TabList>
        <TabPanel className="order-tab-panel">
          <div className="row end">
            {/* <div className="col-12 col-md-10">
              <Search
                value={query}
                label="Search By Product Name"
                onChangeText={handleChange}
                onSubmit={search}
              />
            </div> */}
            {selectedRows?.length > 0 ? (
              <div className="col-12 col-md-2">
                <div className="buttons">
                  {arrayOfButtons
                    ?.filter((item) => {
                      if (item?.name === 'Ticket' && role !== 'call_center') {
                        return false;
                      }
                      return true;
                    })
                    .map((item) => (
                      <button
                        key={item?.id}
                        type="button"
                        className={item?.className}
                        onClick={item?.onClick}
                      >
                        {item?.name}
                      </button>
                    ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="table-container">
            {data && data?.paginate?.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="table-footer__title">
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                              - data.Previous.limit
                              + 1
                            : 1}
                          {' '}
                          to
                          {' '}
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            : storePageSizeValue}
                          {' '}
                          {`of ${pageNum}`}
                          {' '}
                          {''}
                          <span className="form__label">Page Size : </span>
                          <select
                            onChange={handleOnPageSizeSelected}
                            className="dropdown-menu"
                          >
                            {pageSize?.map((item, index) => (
                              <option
                                key={index.toString()}
                                value={item}
                                className="dropdown-menu"
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(1)}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? previousPage(data.Previous.page) : {})}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {data && data.Next
                              ? data.Next.page - 1
                              : data && data.Previous
                                ? data.Previous.page + 1
                                : 1}
                            {/* /1 */}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup?.headers?.map((column, index) => (
                            <th
                              key={index.toString()}
                              {...column.getHeaderProps()}
                              style={{ fontSize: 12 }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{ fontSize: 13, fontWeight: 400 }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row middle h-200">
                <div className="d-flex-col center">
                  <Lottie animationData={EmptyInventory} loop={false} className="noUsersIcon" />
                  <p className="noRecordsFound">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel className="order-tab-panel">
          {/* <div className="row middle">
            <div className="col-12 col-md-10">
              <Search
                value={query}
                label="Search By Product Name"
                onChangeText={handleChange}
                onSubmit={search}
              />
            </div>
            {selectedRows?.length > 0 ? (
              <div className="col-12 col-md-2">
                <div className="buttons">
                  {arrayOfButtons
                    ?.filter((item) => {
                      if (item?.name === 'Ticket' && role !== 'call_center') {
                        return false;
                      }
                      return true;
                    })
                    .map((item) => (
                      <button
                        key={item?.id}
                        type="button"
                        className={item?.className}
                        onClick={item?.onClick}
                      >
                        <FontAwesomeIcon
                          icon={item?.icon}
                          className="button__icon"
                        />
                        {item?.name}
                      </button>
                    ))}
                </div>
              </div>
            ) : null}
          </div> */}
          <div className="table-container">
            {data && data?.paginate?.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="table-footer__title">
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                              - data.Previous.limit
                              + 1
                            : 1}
                          {' '}
                          to
                          {' '}
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            : storePageSizeValue}
                          {' '}
                          {`of ${pageNum}`}
                          {' '}
                          {''}
                          <span className="form__label">Page Size : </span>
                          <select
                            onChange={handleOnPageSizeSelected}
                            className="dropdown-menu"
                          >
                            {pageSize?.map((item, index) => (
                              <option
                                key={index.toString()}
                                value={item}
                                className="dropdown-menu"
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(1)}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? previousPage(data.Previous.page) : {})}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {data && data.Next
                              ? data.Next.page - 1
                              : data && data.Previous
                                ? data.Previous.page + 1
                                : 1}
                            {/* /1 */}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup?.headers?.map((column, index) => (
                            <th
                              key={index.toString()}
                              {...column.getHeaderProps()}
                              style={{ fontSize: 12 }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{ fontSize: 13, fontWeight: 400 }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row middle h-200">
                <div className="d-flex-col center">
                  <Lottie animationData={EmptyInventory} loop={false} className="noUsersIcon" />
                  <p className="noRecordsFound">No Records Found</p>
                </div>
              </div>
            )}
          </div>
        </TabPanel>
      </Tabs>
      <LoadingModal show={isLoading} />
    </div>
  );
};

export default withScreenRefreshDetection(Tickets);
