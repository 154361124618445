import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Player } from 'video-react';

import playImg from '../../assets/images/play.png';

import './ImageSliderModal.scss';

const ImageSliderModal = ({
  show, toggle, images,
}) => {
  const [index, setIndex] = useState(0);
  const showHideClassName = show ? 'image-slider-modal display-block' : 'image-slider-modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="product-image">
                    {images[index].split('.')[images[index].split('.').length - 1] === 'mp4'
                      ? (
                        <Player
                          playsInline
                          poster={playImg}
                          src={images[index]}
                          fluid={false}
                          height={400}
                          width="100%"
                        />
                      ) : (
                        <img src={images[index]} alt="product" />
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="form__item">
                        <button type="button" className="form__button" disabled={index === 0} onClick={() => setIndex(index - 1)}>Prev</button>
                      </div>
                    </div>
                    <div className="col-6 d-flex end">
                      <div className="form__item">
                        <button type="button" className="form__button" disabled={index === images.length - 1} onClick={() => setIndex(index + 1)}>Next</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImageSliderModal;
