/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCartPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getMccOrder, addMccOrderDetails } from '../../store/modules/order/actions';
import { getProducts } from '../../store/modules/product/actions';
import { selectOrder, selectIsLoading, selectedValues } from '../../store/modules/order/selectors';
import { selectProducts, selectIsLoading as selectIsLoadingProduct } from '../../store/modules/product/selectors';
import ProductModal from '../../components/ProductModal';
import LoadingModal from '../../components/LoadingModal';

import './AddOrderItems.scss';

const AddOrderItems = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { from } = location.state || { from: { pathname: `/plant-admin/mcc-ordersNew/${id}` } };
  const [selected, setSelected] = useState(null);
  const [cart, setCart] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectError, setSelectError] = useState(null);
  const storedValues = useSelector((state) => selectedValues(state));
  const columns = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'prodName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const order = useSelector((state) => selectOrder(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const products = useSelector((state) => selectProducts(state));
  const isLoadingProduct = useSelector((state) => selectIsLoadingProduct(state));
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm({ delivery: '1' });
  const watchDelivery = watch('delivery');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: products && products.paginate ? products.paginate : [],
  });

  useEffect(() => {
    dispatch(getMccOrder(id));
    dispatch(getProducts());
  }, []);

  const nextPage = (page) => {
    dispatch(getProducts(page));
  };

  const previousPage = (page) => {
    dispatch(getProducts(page));
  };

  const toggleProductModal = () => {
    setShowProductModal(!showProductModal);
  };

  const onSelect = (value) => {
    setSelected(value);
    const selectedItem = cart.find((item) => item.id === value.id);
    if (selectedItem) {
      setCart((prevState) => [
        ...prevState.filter((item) => item.id !== value.id),
      ]);
      return;
    }
    toggleProductModal();
  };

  const onCart = (value) => {
    setCart((prevState) => [...prevState, value]);
  };
  const placeOrder = ({ delivery }) => {
    // if (!cart.length || !delivery) {
    //   toast.error('Please, select a product to place an order');
    //   return;
    // }

    const total = cart.reduce(
      (acc, item) => acc + item.quantity * item.price, 0,
    ) + Number(order.total);
    const orderToPlace = {
      items: cart.map((item) => ({
        prodId: item.id,
        prodPrice: item.price,
        quantity: `${item.quantity}`,
        prodSize: item.size,
        palletType: item.palletType,
        subTotal: `${item.total}`,
        materialCode: item?.materialCode,
        recovery: true,
      })),
      // deliveryTimePeriod: `${delivery} days`,
      // deliveryDate: moment().add(watchDelivery, 'days'),
      unitCode: storedValues?.unitCode !== null && storedValues?.unitCode.trim(),
      unitName: storedValues?.unitName,
      farmerCode: storedValues?.unitCode.trim(),
      total: total.toFixed(2),
    };
    dispatch(addMccOrderDetails(id, orderToPlace, () => setCart([])));
    history.replace(from);
  };

  return (
    <>
      <div className="add-order-items">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Select Product and Quantity
              </p>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              <table {...getTableProps()} className="table table-bordered">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      <th>#</th>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                      <th>Size</th>
                      <th>Pallet Type</th>
                      <th>Quantity</th>
                      <th>Product Price/Unit</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        <td>
                          <div className="form__item p-0">
                            <div className="row middle">
                              <div className="col-12">
                                <input
                                  className="form__check"
                                  type="checkbox"
                                  id={row.original.id}
                                    // name="selected"
                                  checked={
                                      !!cart.find((el) => el.id === row.original.id)
                                    }
                                  onChange={() => onSelect(row.original)}
                                />
                                {errors.phone && <div className="form__error">{errors.quantity.message}</div>}
                              </div>
                            </div>
                          </div>
                        </td>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        <td>
                          <div className="form__item p-0 m-w-100px">
                            <label htmlFor="size">
                              <div className="row middle">
                                <div className="col-12">
                                  <select
                                    className="form__field"
                                    disabled
                                    readOnly
                                  >
                                    {cart.find((el) => el.id === row.original.id)
                                      ? (
                                        <option>
                                          {cart.find((el) => el.id === row.original.id).size}
                                        </option>
                                      )
                                      : row.original.Quantity.map((el) => (
                                        <option key={el.id}>{el.quantity}</option>))}
                                  </select>
                                </div>
                              </div>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="form__item p-0 m-w-100px">
                            <label htmlFor="size">
                              <div className="row middle">
                                <div className="col-12">
                                  <select
                                    className="form__field"
                                    disabled
                                    readOnly
                                  >
                                    {cart.find((el) => el.id === row.original.id)
                                      ? (
                                        <option>
                                          {cart.find(
                                            (el) => el.id === row.original.id,
                                          ).palletType}
                                        </option>
                                      )
                                      : row.original.Quantity[0].Pallet.map((el) => (
                                        <option>{el.palletType || '-'}</option>))}
                                  </select>
                                </div>
                              </div>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="form__item p-0 m-w-100px">
                            <label htmlFor="quantity">
                              <div className="row middle">
                                <div className="col-12">
                                  <input
                                    className="form__field"
                                    type="number"
                                    disabled
                                    readOnly
                                    value={cart.find((el) => el.id === row.original.id)
                                      ? cart.find((el) => el.id === row.original.id).quantity
                                      : 0}
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </td>
                        <td>
                          {cart.find((el) => el.id === row.original.id)
                            ? cart.find((el) => el.id === row.original.id).price
                            : row.original.Quantity[0].Pallet[0].price}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <div className="table-footer">
                <div className="row middle">
                  <div className="col-12">
                    <div className="table-footer__buttons">
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (products ? previousPage(products.Previous.page) : {})}
                        disabled={!products || !products.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className="table-footer__button__iclon"
                        />
                      </button>
                      <div className="table-footer__label">
                        {products && products.Next
                          ? products.Next.page - 1
                          : products && products.Previous
                            ? products.Previous.page + 1
                            : 1}
                        {/* /1 */}
                      </div>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (products ? nextPage(products.Next.page) : {})}
                        disabled={!products || !products.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span className="form__label">Order Details</span>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 col-md-2">
                        <span className="form__label">Total Price:</span>
                      </div>
                      <div className="col-12 col-md-4">
                        <span className="form__label">
                          {cart.length
                            ? cart.reduce((
                              acc, item,
                            ) => acc + item.quantity * item.price, 0).toFixed(2)
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="delivery">
                      <div className="row">
                        <div className="col-12 col-md-2">
                          <span className="form__label">Delivery By:</span>
                        </div>
                        <div className="col-12 col-md-4">
                          <select
                            className="form__field"
                            id="delivery"
                            name="delivery"
                            placeholder="Delivery By"
                            {...register('delivery', {
                              required: {
                                value: true,
                                message: 'Delivery By is required',
                              },
                            })}
                          >
                            <option value="1">1 Days</option>
                            <option value="3">3 Days</option>
                            <option value="5">5 Days</option>
                          </select>
                          {errors.delivery &&
                            <div className="form__error">{errors.delivery.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 col-md-2">
                        <span className="form__label">Delivery Date:</span>
                      </div>
                      <div className="col-12 col-md-4">
                        <span className="form__label">
                        {moment().add(watchDelivery || '7', 'days').format('DD/MM/YYYY')}</span>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" disabled={!order || !cart.length} onClick={handleSubmit(placeOrder)}>
                          <FontAwesomeIcon
                            icon={faCartPlus}
                            className="mr-16"
                          />
                          Add Items
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showProductModal && (
      <ProductModal
        show={showProductModal}
        toggle={toggleProductModal}
        onSubmit={onCart}
        product={selected}
      />
      )}
      <LoadingModal show={isLoading || isLoadingProduct} />
    </>
  );
};

export default AddOrderItems;
