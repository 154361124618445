import {
  GET_UNIT_NAME_START,
  GET_UNIT_NAME_SUCCESS,
  GET_UNIT_NAME_ERROR,
  GET_MCC_NAME_START,
  GET_MCC_NAME_SUCCESS,
  GET_MCC_NAME_ERROR,
  GET_UNIT_MCC_NAME_START,
  GET_UNIT_MCC_NAME_SUCCESS,
  GET_UNIT_MCC_NAME_ERROR,
  GET_MORE_UNIT_MCC_NAME_START,
  GET_MORE_UNIT_MCC_NAME_SUCCESS,
  GET_MORE_UNIT_MCC_NAME_ERROR,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_START,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
  GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS,
  GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR,
  CREATE_REGION_START,
  CREATE_REGION_SUCCESS,
  CREATE_REGION_ERROR,
  EXPORT_REGION_START,
  EXPORT_REGION_SUCCESS,
  EXPORT_REGION_ERROR,
  GET_YOUTUBE_VIDEOS_START,
  GET_YOUTUBE_VIDEOS_SUCCESS,
  GET_YOUTUBE_VIDEOS_ERROR,
  ADD_YOUTUBE_VIDEO_START,
  ADD_YOUTUBE_VIDEO_SUCCESS,
  ADD_YOUTUBE_VIDEO_ERROR,
  UPDATE_YOUTUBE_VIDEO_START,
  UPDATE_YOUTUBE_VIDEO_SUCCESS,
  UPDATE_YOUTUBE_VIDEO_ERROR,
  GET_HEADING_START,
  GET_HEADING_SUCCESS,
  GET_HEADING_ERROR,
  ADD_HEADING_START,
  ADD_HEADING_SUCCESS,
  ADD_HEADING_ERROR,
  UPDATE_HEADING_START,
  UPDATE_HEADING_SUCCESS,
  UPDATE_HEADING_ERROR,
  CHECK_MCC_DETAILS_START,
  CHECK_MCC_DETAILS_SUCCESS,
  CHECK_MCC_DETAILS_ERROR,
  INSERT_MCC_DETAILS_START,
  INSERT_MCC_DETAILS_SUCCESS,
  INSERT_MCC_DETAILS_ERROR,
} from './types';

const initialState = {
  unitNames: [],
  mccNames: [],
  unitMccNames: null,
  mccDetails: null,
  region: null,
  videos: [],
  video: null,
  headings: [],
  heading: null,
  message: null,
  isLoading: false,
  isLoadingUnitName: false,
  isLoadingMccName: false,
  isLoadingMoreUnitName: false,
  isLoadingMoreMccName: false,
  isLoadingExport: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_UNIT_NAME_START:
      return { ...state, errorMessage: null, isLoadingUnitName: true };

    case GET_UNIT_NAME_SUCCESS:
      return {
        ...state,
        unitNames: payload || [],
        errorMessage: null,
        isLoadingUnitName: false,
      };

    case GET_UNIT_NAME_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        unitNames: [],
        isLoadingUnitName: false,
      };

    case GET_MCC_NAME_START:
      return { ...state, errorMessage: null, isLoadingMccName: true };

    case GET_MCC_NAME_SUCCESS:
      return {
        ...state,
        mccNames: payload || [],
        errorMessage: null,
        isLoadingMccName: false,
      };

    case GET_MCC_NAME_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        mccNames: [],
        isLoadingMccName: false,
      };

    case GET_UNIT_MCC_NAME_START:
      return { ...state, errorMessage: null, isLoadingUnitName: true };

    case GET_UNIT_MCC_NAME_SUCCESS:
      return {
        ...state,
        unitMccNames: payload,
        errorMessage: null,
        isLoadingUnitName: false,
      };

    case GET_UNIT_MCC_NAME_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        unitMccNames: null,
        isLoadingUnitName: false,
      };

    case GET_MORE_UNIT_MCC_NAME_START:
      return { ...state, errorMessage: null, isLoadingMOreUnitName: true };

    case GET_MORE_UNIT_MCC_NAME_SUCCESS:
      return {
        ...state,
        unitMccNames: [...state.unitMccNames, ...payload.paginate],
        next: payload.Next,
        previous: payload.Previous,
        errorMessage: null,
        isLoadingMOreUnitName: false,
      };

    case GET_MORE_UNIT_MCC_NAME_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        mccNames: [],
        isLoadingMOreUnitName: false,
      };

    case GET_UNIT_MCC_NAME_BY_MCC_CODE_START:
      return { ...state, errorMessage: null, isLoadingMccName: true };

    case GET_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS:
      return {
        ...state,
        unitMccNames: payload,
        errorMessage: null,
        isLoadingMccName: false,
      };

    case GET_UNIT_MCC_NAME_BY_MCC_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        unitMccNames: null,
        isLoadingMccName: false,
      };

    case GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_START:
      return { ...state, errorMessage: null, isLoadingMoreMccName: true };

    case GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_SUCCESS:
      return {
        ...state,
        unitMccNames: [...state.unitMccNames, ...payload.paginate],
        next: payload.Next,
        previous: payload.Previous,
        errorMessage: null,
        isLoadingMoreMccName: false,
      };

    case GET_MORE_UNIT_MCC_NAME_BY_MCC_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        mccNames: [],
        isLoadingMoreMccName: false,
      };
    case CREATE_REGION_START:
      return { ...state, errorMessage: null, isLoading: true };

    case CREATE_REGION_SUCCESS:
      return {
        ...state,
        region: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case CREATE_REGION_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        region: null,
        isLoading: false,
      };

    case EXPORT_REGION_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_REGION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_REGION_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case GET_YOUTUBE_VIDEOS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_YOUTUBE_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: payload || [],
        errorMessage: null,
        isLoading: false,
      };

    case GET_YOUTUBE_VIDEOS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case ADD_YOUTUBE_VIDEO_START:
      return { ...state, errorMessage: null, isLoading: true };

    case ADD_YOUTUBE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: [...state.videos, payload],
        errorMessage: null,
        isLoading: false,
      };

    case ADD_YOUTUBE_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPDATE_YOUTUBE_VIDEO_START:
      return { ...state, errorMessage: null, isLoading: true };

    case UPDATE_YOUTUBE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: [
          ...state.videos.slice(0, state.videos.findIndex((obj) => obj.id === payload.id)),
          payload,
          ...state.videos.slice(state.videos.findIndex((obj) => obj.id === payload.id) + 1),
        ],
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_YOUTUBE_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_HEADING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_HEADING_SUCCESS:
      return {
        ...state,
        headings: payload || [],
        errorMessage: null,
        isLoading: false,
      };

    case GET_HEADING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case ADD_HEADING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case ADD_HEADING_SUCCESS:
      return {
        ...state,
        headings: [...state.headings, payload],
        errorMessage: null,
        isLoading: false,
      };

    case ADD_HEADING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPDATE_HEADING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case UPDATE_HEADING_SUCCESS:
      return {
        ...state,
        headings: [...state.headings.filter((h) => h.type !== payload.type), payload],
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_HEADING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case CHECK_MCC_DETAILS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case CHECK_MCC_DETAILS_SUCCESS:
      return {
        ...state,
        mccDetails: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case CHECK_MCC_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        mccDetails: null,
        isLoading: false,
      };

    case INSERT_MCC_DETAILS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case INSERT_MCC_DETAILS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
      };

    case INSERT_MCC_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    default:
      return state;
  }
};
