export default class StoreService {
  static setToken(token) {
    localStorage.setItem('heritage_dashboard_token', token);
  }

  static getToken() {
    return localStorage.getItem('heritage_dashboard_token');
  }

  static removeToken() {
    return localStorage.removeItem('heritage_dashboard_token');
  }

  static isLoggedIn() {
    return !!localStorage.getItem('heritage_dashboard_token');
  }
}
