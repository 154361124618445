/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';

import logoImg from '../../assets/images/logo.png';

import './CreativeModal.scss';

const CreativeModal = ({
  show, toggle, onSubmit, creative,
}) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const regionName = localStorage.getItem('regionName');
  const showHideClassName = show ? 'creative-modal display-block' : 'creative-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (creative) {
      setValue('text', creative.text);
      setFileUrl(creative.thumbnails || logoImg);
    }
    if (regionName) {
      setValue('regionName', regionName !== 'HYDERABAD' ? (creative?.regionName || regionName) : (creative?.regionName || 'HYDERABAD-1'));
    }
  }, [creative, regionName]);

  // useEffect(() => {
  //   if (regionName) {
  //     setValue('regionName', regionName !== 'HYDERABAD' ? regionName : 'HYDERABAD-1');
  //   }
  // }, [setValue, regionName]);

  const fileReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width < 1280 || img.height < 700) {
          setFileError('Thumbnail dimensions should be 1280 x 700');
        }
      };
      img.onerror = () => {
        setFileError('Invalid image content');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = (event) => {
    const { files } = event.target;
    if (!files.length) return;
    setFileError(null);
    const objectUrl = URL.createObjectURL(files[0]);
    setThumbnail(files[0]);
    setFileUrl(objectUrl);
    if (files[0].size > 1e6) {
      setFileError('Thumbnail should not be >1MB');
      return;
    }
    fileReader(files[0]);
  };

  const submit = ({
    text, regionName: regName,
  }) => {
    onSubmit({ text, thumbnail: thumbnail || fileUrl, regionName: regName || (regionName !== 'HYDERABAD' ? regionName : 'HYDERABAD-1') });
    toggle();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="text">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Text</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="text"
                            name="text"
                            placeholder="Please enter text"
                            {...register('text', {
                              required: {
                                value: true,
                                message: 'Text is required',
                              },
                            })}
                          />
                          {errors.url && <div className="form__error">{errors.url.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="regionName">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Region</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="regionName"
                            name="regionName"
                            disabled={regionName !== 'HYDERABAD'}
                            {...register('regionName', {
                              required: {
                                value: true,
                                message: 'Region name is required',
                              },
                            })}
                          >
                            <option value="HYDERABAD-1">HYDERABAD-1</option>
                            <option value="HYDERABAD-2">HYDERABAD-2</option>
                            <option value="KRISHNAGIRI">KRISHNAGIRI</option>
                            <option value="ONGOLE">ONGOLE</option>
                            <option value="RAI">RAI</option>
                            <option value="SANGVI">SANGVI</option>
                            <option value="TIRUPATI">TIRUPATI</option>
                            <option value="VIJAYAWADA">VIJAYAWADA</option>
                            <option value="VIZAG">VIZAG</option>
                          </select>
                          {errors.regionName && <div className="form__error">{errors.regionName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row center">
                      <div className="col-12 mb-4">
                        <span className="form__label">Thumbnail</span>
                      </div>
                      <div className="col-12">
                        <label htmlFor="thumbnail">
                          <div className="picture">
                            {fileUrl && (<img src={fileUrl} alt="product" />)}
                            <div style={{ background: fileUrl ? 'transparent' : '#55555520' }} className="picture__placeholder">
                              {!fileUrl && (
                              <FontAwesomeIcon
                                icon={faUpload}
                                className="picture__placeholder--icon"
                              />
                              )}
                            </div>
                          </div>
                          <input
                            className="form__field"
                            type="file"
                            name="thumbnail"
                            id="thumbnail"
                            onChange={handleSelectFile}
                          />
                        </label>
                        {errors.fileError && <div className="form__error">{errors.fileError}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-16">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreativeModal;
