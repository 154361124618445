/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import LoadingModal from '../../components/LoadingModal';
import {
  cattleAndMilkingReport,
  appointmentsReport,
  consultationReport,
  feedbackReport,
  usersReport,
  milkSlipsReport,
  feedReport,
  cattleMilkingReport,
  uninstalledReport,
  digitalRegistrationsReport,
  loanRequestReport,
  generateReportForMcc,
} from '../../store/modules/user/actions';
import {
  exportPlantOrders,
} from '../../store/modules/order/actions';
import {
  regionsReport,
} from '../../store/modules/data/actions';
import { selectIsLoadingExport } from '../../store/modules/user/selectors';
import { selectIsLoadingExport as selectIsLoadingExportOrders } from '../../store/modules/order/selectors';
import { selectIsLoadingExport as selectIsLoadingExportRegions } from '../../store/modules/data/selectors';

import './Reports.scss';

const Reports = () => {
  const dispatch = useDispatch();
  const isExporting = useSelector((state) => selectIsLoadingExport(state));
  const isExportingOrders = useSelector((state) => selectIsLoadingExportOrders(state));
  const isExportingRegions = useSelector((state) => selectIsLoadingExportRegions(state));
  const role = localStorage.getItem('role');
  const exportFarmers = () => {
    dispatch(usersReport());
  };

  const exportCattle = () => {
    dispatch(cattleAndMilkingReport());
  };

  const exportCattleMilking = () => {
    dispatch(cattleMilkingReport());
  };

  const exportMilkSlips = () => {
    dispatch(milkSlipsReport());
  };

  const exportFeedback = () => {
    dispatch(feedbackReport());
  };

  const exportAppointments = () => {
    dispatch(appointmentsReport());
  };

  const exportConsultations = () => {
    dispatch(consultationReport());
  };

  const exportOrders = () => {
    dispatch(exportPlantOrders());
  };

  const exportFeed = () => {
    dispatch(feedReport());
  };

  const exportRegions = () => {
    dispatch(regionsReport());
  };

  const exportUninstalled = () => {
    dispatch(uninstalledReport());
  };

  const exportDigitalRegistrationsReport = () => {
    dispatch(digitalRegistrationsReport());
  };

  const exportLoanRequestReport = () => {
    dispatch(loanRequestReport());
  };
  const exportNewMccFarmersReport = () => {
    dispatch(generateReportForMcc());
  };
  const arrayOfAdminReports = [
    {
      id: 0,
      name: 'Farmer Registration Report',
      action: exportFarmers,
    },
    {
      id: 1,
      name: 'Appointments Report',
      action: exportAppointments,
    },
    {
      id: 2,
      name: 'Doctor Prescription details Report',
      action: exportConsultations,
    },
    {
      id: 3,
      name: 'Cattle Report',
      action: exportCattle,
    },
    {
      id: 4,
      name: 'Milking Details Report',
      action: exportMilkSlips,
    },
    {
      id: 5,
      name: 'Feedback Report',
      action: exportFeedback,
    },
    {
      id: 6,
      name: 'Feed Report',
      action: exportFeed,
    },
    {
      id: 7,
      name: 'Orders Report',
      action: exportOrders,
    },
    {
      id: 8,
      name: 'MCC Region Report',
      action: exportRegions,
    },
    {
      id: 9,
      name: 'Uninstalled users',
      action: exportUninstalled,
    },
    {
      id: 10,
      name: 'Digital Registrations',
      action: exportDigitalRegistrationsReport,
    },
    {
      id: 11,
      name: 'Farmer Loan Reports',
      action: exportLoanRequestReport,
    },
    {
      id: 12,
      name: 'Mcc Farmers Report',
      action: exportNewMccFarmersReport,
    },
  ];
  const arrayOfHnlAdminReports = [
    {
      id: 0,
      name: 'Farmer Registration Report',
      action: exportFarmers,
    },
    {
      id: 1,
      name: 'Appointments Report',
      action: exportAppointments,
    },
    {
      id: 2,
      name: 'Doctor Prescription details Report',
      action: exportConsultations,
    },
    {
      id: 3,
      name: 'Cattle Report',
      action: exportCattle,
    },
    {
      id: 4,
      name: 'Feedback Report',
      action: exportFeedback,
    },
    {
      id: 5,
      name: 'Feed Report',
      action: exportFeed,
    },
    {
      id: 6,
      name: 'Uninstalled users',
      action: exportUninstalled,
    },
  ];
  return (
    <>
      <div className="reports">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                All Reports
              </p>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="row center">
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Report Name</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  { role === 'admin' || role === 'ea_pi' || role === 'regional_pi'
                    ? arrayOfAdminReports?.map((item, index) => (
                      <tr key={item?.id}>
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>
                          <button type="button" className="table__button green" onClick={item?.action}>
                            <FontAwesomeIcon icon={faFileExcel} className="table__button__icon" />
                            Export
                          </button>
                        </td>
                      </tr>
                    )) : arrayOfHnlAdminReports?.map((item, index) => (
                      <tr key={item?.id}>
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>
                          <button type="button" className="table__button green" onClick={item?.action}>
                            <FontAwesomeIcon icon={faFileExcel} className="table__button__icon" />
                            Export
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal show={isExporting || isExportingOrders || isExportingRegions} />
    </>
  );
};

export default Reports;
