import {
  GET_PENDING_START,
  GET_PENDING_SUCCESS,
  GET_PENDING_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_PENDING_START,
  SEARCH_PENDING_SUCCESS,
  SEARCH_PENDING_ERROR,
  SEARCH_APPROVED_START,
  SEARCH_APPROVED_SUCCESS,
  SEARCH_APPROVED_ERROR,
  APPROVE_START,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_LOCATION_START,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  UPDATE_CATTLE_START,
  UPDATE_CATTLE_SUCCESS,
  UPDATE_CATTLE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
  SEARCH_BY_PHONE_START,
  SEARCH_BY_PHONE_SUCCESS,
  SEARCH_BY_PHONE_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  GET_EMPLOYEES_START,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_SLIPS_STATS_START,
  GET_SLIPS_STATS_SUCCESS,
  GET_SLIPS_STATS_ERROR,
  GET_SLIPS_REPORT_START,
  GET_SLIPS_REPORT_SUCCESS,
  GET_SLIPS_REPORT_ERROR,
  FILTER_FIREBASE_START,
  FILTER_FIREBASE_SUCCESS,
  FILTER_FIREBASE_ERROR,
  SEARCH_MCC_REP_BY_MCC_CODE_START,
  SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
  SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
  SEARCH_PLANT_BY_PLANT_CODE_START,
  SEARCH_PLANT_BY_PLANT_CODE_SUCCESS,
  SEARCH_PLANT_BY_PLANT_CODE_ERROR,
  TRANSFER_MCC_REP_TO_PLANT_START,
  TRANSFER_MCC_REP_TO_PLANT_SUCCESS,
  TRANSFER_MCC_REP_TO_PLANT_ERROR,
  SEARCH_CONTINUED_BY_PHONE_START,
  SEARCH_CONTINUED_BY_PHONE_SUCCESS,
  SEARCH_CONTINUED_BY_PHONE_ERROR,
  SEARCH_DISCONTINUED_BY_PHONE_START,
  SEARCH_DISCONTINUED_BY_PHONE_SUCCESS,
  SEARCH_DISCONTINUED_BY_PHONE_ERROR,
  CONTINUE_DISCONTINUE_START,
  CONTINUE_DISCONTINUE_SUCCESS,
  CONTINUE_DISCONTINUE_ERROR,
  GET_DISCONTINUED_START,
  GET_DISCONTINUED_SUCCESS,
  GET_DISCONTINUED_ERROR,
} from './types';

const initialState = {
  pending: null,
  approved: null,
  user: null,
  count: null,
  employee: null,
  milkSlipsStats: [],
  milkSlipsReport: null,
  users: [],
  mccRep: null,
  plant: null,
  discontinued: null,
  message: null,
  isLoading: false,
  isLoadingApprove: false,
  isLoadingReject: false,
  isLoadingExport: false,
  isLoadingCount: false,
  isLoadingEmployees: false,
  isLoadingSlips: false,
  isLoadingUsers: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PENDING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PENDING_SUCCESS:
      return {
        ...state,
        pending: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PENDING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_APPROVED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_APPROVED_SUCCESS:
      return {
        ...state,
        approved: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_APPROVED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        user: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_PENDING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_PENDING_SUCCESS:
      return {
        ...state,
        pending: { paginate: [payload] } || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_PENDING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_APPROVED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_APPROVED_SUCCESS:
      return {
        ...state,
        approved: { paginate: [payload] } || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_APPROVED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case APPROVE_START:
      return { ...state, errorMessage: null, isLoadingApprove: true };

    case APPROVE_SUCCESS:
      return {
        ...state,
        user: payload || null,
        errorMessage: null,
        isLoadingApprove: false,
      };

    case APPROVE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingApprove: false,
      };

    case REJECT_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_SUCCESS:
      return {
        ...state,
        user: payload || null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };

    case UPDATE_USER_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: payload || null,
        message: 'User updated successfully',
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPDATE_LOCATION_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        user: payload || null,
        message: 'Location updated successfully',
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case UPDATE_CATTLE_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case UPDATE_CATTLE_SUCCESS:
      return {
        ...state,
        user: payload || null,
        message: 'Cattle updated successfully',
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_CATTLE_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case EXPORT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case COUNT_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case COUNT_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };

    case COUNT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };

    case SEARCH_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_BY_PHONE_SUCCESS:
      return {
        ...state,
        user: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_BY_PHONE_ERROR:
      return {
        ...state,
        user: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case DELETE_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        user: null,
        message: 'User deleted successfully',
        errorMessage: null,
        isLoading: false,
      };

    case DELETE_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_EMPLOYEES_START:
      return { ...state, errorMessage: null, isLoadingEmployees: true };

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: payload || null,
        errorMessage: null,
        isLoadingEmployees: false,
      };

    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingEmployees: false,
      };

    case GET_SLIPS_STATS_START:
      return { ...state, errorMessage: null, isLoadingSlips: true };

    case GET_SLIPS_STATS_SUCCESS:
      return {
        ...state,
        milkSlipsStats: payload || [],
        errorMessage: null,
        isLoadingSlips: false,
      };

    case GET_SLIPS_STATS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingSlips: false,
      };

    case GET_SLIPS_REPORT_START:
      return { ...state, errorMessage: null, isLoadingSlips: true };

    case GET_SLIPS_REPORT_SUCCESS:
      return {
        ...state,
        milkSlipsReport: payload || null,
        errorMessage: null,
        isLoadingSlips: false,
      };

    case GET_SLIPS_REPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingSlips: false,
      };

    case FILTER_FIREBASE_START:
      return { ...state, errorMessage: null, isLoadingUsers: true };

    case FILTER_FIREBASE_SUCCESS:
      return {
        ...state,
        users: payload?.paginate || [],
        errorMessage: null,
        isLoadingUsers: false,
      };

    case FILTER_FIREBASE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUsers: false,
      };

    case SEARCH_MCC_REP_BY_MCC_CODE_START:
      return { ...state, errorMessage: null, isLoadingUsers: true };

    case SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS:
      return {
        ...state,
        mccRep: payload || null,
        errorMessage: null,
        isLoadingUsers: false,
      };

    case SEARCH_MCC_REP_BY_MCC_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUsers: false,
      };

    case SEARCH_PLANT_BY_PLANT_CODE_START:
      return { ...state, errorMessage: null, isLoadingUsers: true };

    case SEARCH_PLANT_BY_PLANT_CODE_SUCCESS:
      return {
        ...state,
        plant: payload || null,
        errorMessage: null,
        isLoadingUsers: false,
      };

    case SEARCH_PLANT_BY_PLANT_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUsers: false,
      };

    case TRANSFER_MCC_REP_TO_PLANT_START:
      return { ...state, errorMessage: null, isLoadingUsers: true };

    case TRANSFER_MCC_REP_TO_PLANT_SUCCESS:
      return {
        ...state,
        mccRep: null,
        plant: null,
        errorMessage: null,
        isLoadingUsers: false,
      };

    case TRANSFER_MCC_REP_TO_PLANT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUsers: false,
      };

    case SEARCH_CONTINUED_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_CONTINUED_BY_PHONE_SUCCESS:
      return {
        ...state,
        user: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_CONTINUED_BY_PHONE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_DISCONTINUED_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_DISCONTINUED_BY_PHONE_SUCCESS:
      return {
        ...state,
        discontinued: payload ? { paginate: [payload] } : null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_DISCONTINUED_BY_PHONE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case CONTINUE_DISCONTINUE_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case CONTINUE_DISCONTINUE_SUCCESS:
      return {
        ...state,
        user: null,
        discontinued: state.discontinued
          ? {
            ...state.discontinued,
            paginate: state.discontinued.paginate.filter((dis) => dis.id !== payload.id),
          } : null,
        errorMessage: null,
        isLoading: false,
      };

    case CONTINUE_DISCONTINUE_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_DISCONTINUED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_DISCONTINUED_SUCCESS:
      return {
        ...state,
        discontinued: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_DISCONTINUED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    default:
      return state;
  }
};
