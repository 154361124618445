/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { loginAdmin } from '../../store/modules/auth/actions';
import {
  selectErrorMessage,
  selectIsLoadingAuth,
} from '../../store/modules/auth/selectors';
import Spinner from '../../components/Spinner';
import logo from '../../assets/images/logo.png';
import './AdminLogin.scss';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { from } = location.state || { from: { pathname: '/auth/admin' } };
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = ({ email, password }) => {
    const values = {
      email,
      password,
    };
    dispatch(
      loginAdmin(values, () => {
        history.replace(from);
      }),
    );
  };
  return (
    <div className="admin-main-container">
      <div className="login-form">
        <div className="logo">
          <img src={logo} className="logo__img" alt="logo" />
        </div>
        <div className="main">
          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="form__item">
              <label htmlFor="email">
                <input
                  className={`form__field ${errors?.email ? 'error' : ''}`}
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: '*Email is required',
                    },
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "*Email doesn't appear to be valid",
                    },
                  })}
                />
              </label>
              {errors?.email && (
                <div className="form__error">{errors?.email?.message}</div>
              )}
            </div>
            <div className="form__item">
              <label htmlFor="password">
                <input
                  className={`form__field ${errors?.password ? 'error' : ''}`}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: '*Password is required',
                    },
                  })}
                />
              </label>
              {errors?.password && (
                <div className="form__error">{errors?.password?.message}</div>
              )}
            </div>
            <div className="form__item">
              {errorMessage && (
                <div className="form__error">{`*${errorMessage}`}</div>
              )}
            </div>
            <div className="form__row">
              <input
                value={showPassword}
                type="checkbox"
                onChange={togglePasswordVisibility}
              />
              <span className="form__row">Show Password</span>
            </div>
            <div className="form__item">
              {isLoading ? (
                <Spinner />
              ) : (
                <input className="form__button" type="submit" value="Sign In" />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
