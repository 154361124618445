import { createSelector } from 'reselect';

export const selectRaw = (state) => state.order;

export const selectOrder = createSelector(
  [selectRaw],
  (order) => order.order,
);

export const selectUpcomingOrders = createSelector(
  [selectRaw],
  (order) => order.upcoming,
);

export const selectPastHeldOrders = createSelector(
  [selectRaw],
  (order) => order.pastHeld,
);

export const selectMccUpcomingOrders = createSelector(
  [selectRaw],
  (order) => order.upcomingMcc,
);

export const selectMccPastHeldOrders = createSelector(
  [selectRaw],
  (order) => order.pastHeldMcc,
);

export const selectPlantUpcomingOrders = createSelector(
  [selectRaw],
  (order) => order.upcomingPlant,
);

export const selectPlantPastHeldOrders = createSelector(
  [selectRaw],
  (order) => order.pastHeldPlant,
);

export const selectOrders = createSelector(
  [selectRaw],
  (order) => order.orders,
);

export const selectMessage = createSelector(
  [selectRaw],
  (order) => order.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (order) => order.isLoading,
);

export const selectIsLoadingFinish = createSelector(
  [selectRaw],
  (order) => order.isLoadingFinish,
);

export const selectIsLoadingReject = createSelector(
  [selectRaw],
  (order) => order.isLoadingReject,
);

export const selectIsLoadingExport = createSelector(
  [selectRaw],
  (order) => order.isLoadingExport,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (order) => order.errorMessage,
);

export const selectCount = createSelector(
  [selectRaw],
  (order) => order.count,
);
export const selectIsLoadingCount = createSelector(
  [selectRaw],
  (order) => order.isLoadingCount,
);
export const selectOHSValue = createSelector(
  [selectRaw],
  (order) => order?.getOHSValue,
);
export const selectFarmerCodeData = createSelector(
  [selectRaw],
  (order) => order?.farmerCodeData,
);
export const selectedValues = createSelector(
  [selectRaw],
  (order) => order?.storeValues,
);

export const selectedBatchNo = createSelector(
  [selectRaw],
  (order) => order?.batchNo,
);

export const selectedUpdatedValues = createSelector(
  [selectRaw],
  (order) => order?.updatedValues,
);
const selectors = {
  selectRaw,
  selectOrder,
  selectUpcomingOrders,
  selectPastHeldOrders,
  selectMccUpcomingOrders,
  selectMccPastHeldOrders,
  selectPlantUpcomingOrders,
  selectPlantPastHeldOrders,
  selectOrders,
  selectMessage,
  selectIsLoading,
  selectIsLoadingFinish,
  selectIsLoadingReject,
  selectIsLoadingExport,
  selectErrorMessage,
  selectCount,
  selectIsLoadingCount,
  selectFarmerCodeData,
  selectOHSValue,
  selectedValues,
  selectedBatchNo,
  selectedUpdatedValues,
};

export default selectors;
