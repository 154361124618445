const prefix = 'NOTIFICATION';

export const SEND_START = `${prefix}_SEND_START`;
export const SEND_SUCCESS = `${prefix}_SEND_SUCCESS`;
export const SEND_ERROR = `${prefix}_SEND_ERROR`;

export const GET_ALL_START = `${prefix}_GET_ALL_START`;
export const GET_ALL_SUCCESS = `${prefix}_GET_ALL_SUCCESS`;
export const GET_ALL_ERROR = `${prefix}_GET_ALL_ERROR`;

export const DELETE_START = `${prefix}_DELETE_START`;
export const DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;
export const DELETE_ERROR = `${prefix}_DELETE_ERROR`;
