/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { getUnitMccName, getUnitMccNameByMccCode } from '../../store/modules/data/actions';
import {
  selectIsLoadingUnitName,
  selectIsLoadingMccName,
  selectUnitMccNames,
} from '../../store/modules/data/selectors';

import Spinner from '../Spinner';
import './EditLocationModal.scss';

const EditLocationModal = ({
  show, toggle, onSubmit, values,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectError, setSelectError] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const dispatch = useDispatch();
  const isLoadingPinCode = useSelector((state) => selectIsLoadingUnitName(state));
  const isLoadingMccCode = useSelector((state) => selectIsLoadingMccName(state));
  const unitMccNames = useSelector((state) => selectUnitMccNames(state));
  const showHideClassName = show ? 'edit-location-modal display-block' : 'edit-location-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();
  const {
    register: registerMccCode,
    handleSubmit: handleSubmitMccCode,
    setValue: setValueMccCode,
    formState: { errors: errorsMccCode },
  } = useForm({
    defaultValues: {
      mccCode: '',
    },
  });

  useEffect(() => {
    if (values?.pinCode) {
      setValue('pinCode', values.pinCode);
    }
  }, [values?.pinCode]);

  useEffect(() => {
    if (values?.unitCode) {
      setValueMccCode('mccCode', values.unitCode);
    }
  }, [values?.unitCode]);

  const onSearch = ({ pinCode }) => {
    setSearchItem('pinCode');
    dispatch(getUnitMccName(pinCode));
  };

  const onSearchMccCode = ({ mccCode }) => {
    setSearchItem('mccCode');
    dispatch(getUnitMccNameByMccCode(mccCode));
  };

  const nextPage = ({ pinCode }) => {
    dispatch(getUnitMccName(pinCode, unitMccNames.Next.page));
  };

  const previousPage = ({ pinCode }) => {
    dispatch(getUnitMccName(pinCode, unitMccNames.Previous.page));
  };

  const nextPageMccCode = ({ mccCode }) => {
    dispatch(getUnitMccNameByMccCode(mccCode, unitMccNames.Next.page));
  };

  const previousPageMccCode = ({ mccCode }) => {
    dispatch(getUnitMccNameByMccCode(mccCode, unitMccNames.Previous.page));
  };

  const onSelect = (value) => {
    setSelected(value);
  };

  const submit = (evt) => {
    evt.preventDefault();
    if (!selected) {
      setSelectError('Please, select an item');
      return;
    }
    setSelectError(null);

    toggle();
    onSubmit(selected);
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="pinCode">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Pin code</span>
                        </div>
                        <div className="col-8 col-md-10">
                          <input
                            className="form__field"
                            type="text"
                            id="pinCode"
                            name="pinCode"
                            placeholder="Enter Pin code"
                            {...register('pinCode', {
                              required: {
                                value: true,
                                message: 'Pin code is required',
                              },
                              minLength: {
                                value: 6,
                                message: 'Pin code must not be less than 6 digits',
                              },
                              maxLength: {
                                value: 6,
                                message: 'Pin code must not be greater than 6 digits',
                              },
                            })}
                          />
                          {errors.pinCode && <div className="form__error">{errors.pinCode.message}</div>}
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="row">
                            <div className="col-12 d-flex-col middle">
                              {isLoadingPinCode
                                ? <Spinner />
                                : (
                                  <button type="button" className="form__button" onClick={handleSubmit(onSearch)}>
                                    <FontAwesomeIcon icon={faSearch} className="form__button__icon" />
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 d-flex middle">
                  <span className="form__label">Or</span>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="mccCode">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">MCC code</span>
                        </div>
                        <div className="col-8 col-md-10">
                          <input
                            className="form__field"
                            type="text"
                            id="mccCode"
                            name="mccCode"
                            placeholder="Enter MCC code"
                            {...registerMccCode('mccCode', {
                              required: {
                                value: true,
                                message: 'MCC code is required',
                              },
                              minLength: {
                                value: 3,
                                message: 'MCC code must not be less than 3 digits',
                              },
                              maxLength: {
                                value: 4,
                                message: 'MCC code must not be greater than 4 digits',
                              },
                            })}
                          />
                          {errorsMccCode.mccCode && <div className="form__error">{errorsMccCode.mccCode.message}</div>}
                        </div>
                        <div className="col-4 col-md-2">
                          <div className="row">
                            <div className="col-12 d-flex-col middle">
                              {isLoadingMccCode
                                ? <Spinner />
                                : (
                                  <button type="button" className="form__button" onClick={handleSubmitMccCode(onSearchMccCode)}>
                                    <FontAwesomeIcon icon={faSearch} className="form__button__icon" />
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {unitMccNames && unitMccNames.paginate.length
                    ? (
                      <div className="table">
                        <div className="row divider">
                          <div className="col-1" />
                          <div className="col-5 pr-8">
                            <span className="form__label">Unit Name</span>
                          </div>
                          <div className="col-5 pl-8">
                            <span className="form__label">MCC Name</span>
                          </div>
                        </div>
                        {unitMccNames.paginate.map((item) => (
                          <div className="form__item divider" key={item.id}>
                            <label htmlFor="unitMccName">
                              <div className="row">
                                <div className="col-1 d-flex center">
                                  <input
                                    className="form__check"
                                    type="radio"
                                    id="unitMccName"
                                    name="unitMccName"
                                    checked={selected && selected.id === item.id}
                                    onChange={() => onSelect(item)}
                                  />
                                </div>
                                <div className="col-10 col-md-5 d-flex-col pr-8 divider-right">
                                  <span className="label">{item.unitName}</span>
                                </div>
                                <div className="col-11 col-md-5 d-flex-col pl-8">
                                  <span className="label">{item.mccName}</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        ))}
                        <div className="form__item">
                          <div className="row">
                            <div className="col-6 d-flex-col middle">
                              <button
                                type="button"
                                className="load-more-button"
                                disabled={!unitMccNames.Previous}
                                onClick={searchItem === 'pinCode' ? handleSubmit(previousPage) : handleSubmitMccCode(previousPageMccCode)}
                              >
                                Prev
                              </button>
                            </div>
                            <div className="col-6 d-flex-col middle">
                              <button
                                type="button"
                                className="load-more-button"
                                disabled={!unitMccNames.Next}
                                onClick={searchItem === 'pinCode' ? handleSubmit(nextPage) : handleSubmitMccCode(nextPageMccCode)}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : null}
                </div>
                {unitMccNames ? (
                  <div className="col-12">
                    <div className="form__item">
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          {selectError && <div className="form__error">{selectError}</div>}
                          <button type="button" className="form__button" onClick={submit}>Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditLocationModal;
