/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import ConfirmModal from '../../components/ConfirmModal';
import { getAdminProducts, deleteProduct } from '../../store/modules/product/actions';
import { selectProducts, selectIsLoading } from '../../store/modules/product/selectors';
import LoadingModal from '../../components/LoadingModal';

import './Products.scss';

const Products = () => {
  const [showConfrimModal, setShowConfrimModal] = useState(false);
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();
  const products = useSelector((state) => selectProducts(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  useEffect(() => {
    dispatch(getAdminProducts());
  }, [dispatch]);

  const nextPage = (page) => {
    dispatch(getAdminProducts(page));
  };

  const previousPage = (page) => {
    dispatch(getAdminProducts(page));
  };

  const removeProduct = () => {
    dispatch(deleteProduct(item.id));
  };

  const toggleConfirmModal = (value) => {
    setItem(value || null);
    setShowConfrimModal(!showConfrimModal);
  };

  return (
    <>
      <div className="products">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="heading">
              <p className="heading__title">
                Products
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="buttons">
              <Link to="/admin/products/add" className="button primary">Add Product</Link>
            </div>
          </div>
        </div>
        <div className="row">
          {products && products.paginate.map((prod) => (
            <div className="col-12 col-md-4 col-lg-3">
              <div className="product-card">
                <div className="product-image">
                  <img src={prod.prodImage[0]} alt="product" />
                </div>
                <div className="row">
                  <div className="col-12">
                    <Link to={`/admin/products/${prod.id}`} className="product-name">
                      {prod.prodName}
                    </Link>
                  </div>
                  {/* <div className="col-12">
                    <div className="product-price">
                      <span>
                        Rs. 463
                      </span>
                    </div>
                  </div> */}
                </div>
                <div className="product-form">
                  <div className="row">
                    <div className="col-12">
                      <div className="product-form__item">
                        <div className="row middle">
                          <div className="col-12 mb-8">
                            <span className="product-form__label">Size</span>
                          </div>
                          <div className="col-12 d-flex center">
                            <div className="row center">
                              {prod.Quantity.map((q) => (
                                <div key={q.id} className="product-form__variant-button" role="button" tabIndex="0">
                                  {q.quantity}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="buttons mt-16">
                        <button type="button" className="button danger" onClick={() => toggleConfirmModal(prod)}>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {products
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!products.Previous}
                    onClick={() => previousPage(products.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {products.Next
                      ? products.Next.page - 1
                      : products.Previous
                        ? products.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!products.Next}
                    onClick={() => nextPage(products.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
      <ConfirmModal
        show={showConfrimModal}
        toggle={toggleConfirmModal}
        onConfirm={removeProduct}
        title="Confirm"
        text="Are you sure you want to delete this product?"
      />
      <LoadingModal show={isLoading} />
    </>
  );
};

export default Products;
