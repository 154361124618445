/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import {
  updateRaisedTicket,
  getRaisedTicketById,
} from '../../../store/modules/tickets/actions';
import {
  selectIsLoading,
  selectTicketByID,
} from '../../../store/modules/tickets/selectors';
import AnimatedLoader from '../../../components/AnimatedLoader';
import './TicketDetails.scss';

const TicketDetails = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoading(state));
  const data = useSelector((state) => selectTicketByID(state));
  const { id } = useParams();
  const handleOnItemClick = (status) => {
    const values = {
      id,
      status,
    };
    dispatch(updateRaisedTicket(values));
  };
  const arrayOfButtons = [
    {
      id: 0,
      name: 'Reject',
      className: 'button danger',
      onClick: () => handleOnItemClick('reject'),
    },
    {
      id: 1,
      name: 'On Hold',
      className: 'button warning',
      onClick: () => handleOnItemClick('hold'),
    },
    {
      id: 2,
      name: 'Approve',
      className: 'button green',
      onClick: () => handleOnItemClick('approve'),
    },
  ];
  const handleOnEditPressed = () => {
    (async () => {
      const { value: formValues } = await Swal.fire({
        title: 'Enter the following description',
        html: `
          <textarea id="followUpStatus" placeholder="Follow up status" class="swal2-input"></textarea>
          <textarea id="farmerFeedback" placeholder="Farmer Feedback" class="swal2-input"></textarea>
        `,
        focusConfirm: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        preConfirm: () => ({
          followUpStatus: document.getElementById('followUpStatus').value,
          farmerFeedback: document.getElementById('farmerFeedback').value,
        }),
      });
      if (formValues) {
        const valuesToPass = {
          id,
          ...formValues,
        };
        const filteredData = Object.fromEntries(
          Object.entries(valuesToPass).filter(([key, value]) => value !== null && value !== ''),
        );
        dispatch(updateRaisedTicket(filteredData));
      }
    })();
  };
  useEffect(() => {
    dispatch(getRaisedTicketById(id));
  }, [dispatch]);

  return (
    <div className="ticket-details">
      <div className="row center">
        <div className="col-12 col-md-6">
          <div className="row center">
            <div className="col-12">
              <div className="status-card">
                <div className="left">
                  {`Ticket Id: ${data?.ticketId ?? 'N/A'}`}
                </div>
                <div className="right">
                  {`Agent Name :${data?.representativeName ?? 'N/A'}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="buttons">
            {arrayOfButtons?.map((item) => (
              <button
                key={item?.id}
                type="button"
                className={item?.className}
                onClick={item?.onClick}
              >
                {item?.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex-col">
          <div className="item">
            <h4 className="title">Ticket details</h4>
            <div className="content">
              <div className="col-12 d-flex">
                <div className="row w-100 m-h-100">
                  <div className="col-12 col-md-6">
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Farmer Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.farmerName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Mobile Number</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.phone
                            ? data?.phone?.replace('+91', '')
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Ticket Type</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.type ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Ticket Status</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.status ? data?.status : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Previously Called</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.previouslyCalled ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Farmer Language</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.farmerLanguage ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">MCC/HPC Code</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.mccOrHpcCode ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">MCC/HPC Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.mccOrHpcName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Plant Code</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.plantCode ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Plant Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.plantName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Region Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.region ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">State Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.stateName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">District Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.districtName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Mandal Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.mandal ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Panchayat Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.panchayatName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">Village Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.villageName ?? 'N/A'}
                        </div>
                      </div>
                    </div>

                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          {data?.type === 'Health Related'
                            ? 'Doctor Name'
                            : 'Regional Incharge Name'}
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.type === 'Health Related'
                            ? data?.doctorName
                            : data?.regionalInchargeName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          {data?.type === 'Health Related'
                            ? 'Doctor Phone Number'
                            : 'Regional Incharge Phone'}
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.type === 'Health Related'
                            ? data?.doctorPhoneNumber
                            : data?.regionalInchargePhone ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Doctor Language Known
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.doctorLanguageKnown
                             ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Regional Incharge  Asst. Name
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.riAssistantName
                             ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Regional Incharge Asst. Phone Number
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          { data?.riAssistantNumber ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Ticket created On
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {moment(data?.createdAt).format('DD MMM, YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex-col">
          <div className="item">
            <h4 className="title">Brief details</h4>
            <div className="content">
              <div className="col-12 d-flex">
                <div className="row w-100 m-h-100">
                  <div className="col-12 d-flex end">
                    <FontAwesomeIcon
                      icon={faPen}
                      className="edit-icon"
                      onClick={handleOnEditPressed}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Ticket Id</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.ticketId ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Ticket Type</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.type ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Ticket Status</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.status ? data?.status : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Agent Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.representativeName ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 between w-100">
                      <div className="col-4">
                        <div className="content__title">Previously Called</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data?.previouslyCalled ?? 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Ticket created On
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {moment(data?.createdAt).format('DD MMM, YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="row between w-100">
                      <div className="col-4">
                        <div className="content__title">
                          Ticket updated On
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {moment(data?.updatedAt).format('DD MMM, YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <div className="col-4">
                        <div className="content__title">Call Description</div>
                      </div>
                      <div className="content__field">
                        {data?.callDescription ?? 'N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="col-4">
                        <div className="content__title">Follow up status</div>
                      </div>
                      <div className="content__field">
                        {data?.followUpStatus ?? 'N/A'}
                      </div>
                    </div>
                    <div>
                      <div className="col-4">
                        <div className="content__title">Farmer Feedback</div>
                      </div>
                      <div className="content__field">
                        {data?.farmerFeedback ?? 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <AnimatedLoader show={isLoading} />
    </div>
  );
};

export default TicketDetails;
