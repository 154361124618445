import { toast } from 'react-toastify';
import {
  RAISE_TICKET_START,
  RAISE_TICKET_SUCCESS,
  RAISE_TICKET_ERROR,
  GET_RAISE_TICKET_LIST_START,
  GET_RAISE_TICKET_LIST_SUCCESS,
  GET_RAISE_TICKET_LIST_ERROR,
  UPDATE_RAISE_TICKET_LIST_START,
  UPDATE_RAISE_TICKET_LIST_SUCCESS,
  UPDATE_RAISE_TICKET_LIST_ERROR,
  AUTO_POPULATE_TICKET_DETAILS_START,
  AUTO_POPULATE_TICKET_DETAILS_SUCCESS,
  AUTO_POPULATE_TICKET_DETAILS_ERROR,
  STORE_TICKET_DETAILS,
  CLEAR_TICKET_DETAILS,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';

export const getRaisedTicketList = (values, callBack) => async (dispatch) => {
  try {
    dispatch({ type: GET_RAISE_TICKET_LIST_START });
    const url = `/callCenter/get-all-tickets?page=${values?.page}&limit=${
      values?.limit
    }${values?.id ? `&id=${values.id}` : ''}${
      values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    dispatch({
      type: GET_RAISE_TICKET_LIST_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: GET_RAISE_TICKET_LIST_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const raiseTicket = (values, callBack) => async (dispatch) => {
  try {
    dispatch({ type: RAISE_TICKET_START });
    const response = await HttpService.post('/callCenter/raise-ticket', values);
    dispatch({
      type: RAISE_TICKET_SUCCESS,
      payload: response.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: RAISE_TICKET_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateRaisedTicket = (values, callBack) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_RAISE_TICKET_LIST_START });
    const url = '/callCenter/raise-ticket';
    const response = await HttpService.put(`${url}`, values);
    dispatch({
      type: UPDATE_RAISE_TICKET_LIST_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_RAISE_TICKET_LIST_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const getRaisedTicketById = (callBack) => async (dispatch) => {
  try {
    dispatch({ type: GET_RAISE_TICKET_LIST_START });
    const url = '/callCenter/raise-ticket';
    const response = await HttpService.get(`${url}`);
    dispatch({
      type: GET_RAISE_TICKET_LIST_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: GET_RAISE_TICKET_LIST_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const autoPopulatedTicketDetails = (values, callBack) => async (
  dispatch,
) => {
  try {
    dispatch({ type: AUTO_POPULATE_TICKET_DETAILS_START });
    const url = `/callCenter/get-villages${
      values?.state ? `?state=${values?.state}` : ''
    }${values?.district ? `&district=${values?.district}` : ''}${
      values?.village ? `&village=${values?.village}` : ''
    }${values?.region ? `&region=${values?.region}` : ''}${
      values?.plantCode ? `&plantCode=${values?.plantCode}` : ''
    }`;
    const response = await HttpService.get(`${url}`);
    dispatch({
      type: AUTO_POPULATE_TICKET_DETAILS_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: AUTO_POPULATE_TICKET_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const storeAutoPopulatedData = (ticket) => (dispatch) => {
  dispatch({
    type: STORE_TICKET_DETAILS,
    payload: ticket,
  });
};

export const clearTicketDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_TICKET_DETAILS,
  });
};
