/* eslint-disable max-len */
import { createSelector } from 'reselect';

export const selectRaw = (state) => state.appointment;

export const selectAppointment = createSelector(
  [selectRaw],
  (appointment) => appointment.appointment,
);

export const selectUpcomingAppointments = createSelector(
  [selectRaw],
  (appointment) => appointment.upcoming,
);

export const selectPastHeldAppointments = createSelector(
  [selectRaw],
  (appointment) => appointment.pastHeld,
);

export const selectAppointments = createSelector(
  [selectUpcomingAppointments, selectPastHeldAppointments],
  (upcoming, pastHeld) => [
    {
      title: 'Upcoming Appointments',
      data: upcoming || null,
    },
    {
      title: 'Past Held Appointments',
      data: pastHeld || null,
    },
  ],
);

export const selectPrescription = createSelector(
  [selectRaw],
  (appointment) => appointment.prescription,
);

export const selectFolloUpAppointments = createSelector(
  [selectRaw],
  (appointment) => appointment.followUp,
);

export const selectMessage = createSelector(
  [selectRaw],
  (appointment) => appointment.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (appointment) => appointment.isLoading,
);

export const selectIsLoadingFinish = createSelector(
  [selectRaw],
  (appointment) => appointment.isLoadingFinish,
);

export const selectIsLoadingReject = createSelector(
  [selectRaw],
  (appointment) => appointment.isLoadingReject,
);

export const selectIsLoadingPrescription = createSelector(
  [selectRaw],
  (appointment) => appointment.isLoadingPrescription,
);

export const selectIsLoadingFollowUp = createSelector(
  [selectRaw],
  (appointment) => appointment.isLoadingFollowUp,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (appointment) => appointment.errorMessage,
);

export const selectCount = createSelector([selectRaw], (appointment) => appointment.count);
export const selectIsLoadingCount = createSelector([selectRaw], (appointment) => appointment.isLoadingCount);
const selectors = {
  selectRaw,
  selectAppointment,
  selectUpcomingAppointments,
  selectPastHeldAppointments,
  selectAppointments,
  selectPrescription,
  selectFolloUpAppointments,
  selectMessage,
  selectIsLoading,
  selectIsLoadingFinish,
  selectIsLoadingReject,
  selectIsLoadingPrescription,
  selectIsLoadingFollowUp,
  selectErrorMessage,
  selectCount,
  selectIsLoadingCount,
};

export default selectors;
