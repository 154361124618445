/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import ImageSliderModal from '../../components/ImageSliderModal';
import LoadingModal from '../../components/LoadingModal';
import {
  getSale, updateSaleStatus, deleteSale,
} from '../../store/modules/market/actions';
import {
  selectSale,
  selectIsLoading,
  selectIsLoadingUpdate,
  selectIsLoadingDelete,
} from '../../store/modules/market/selectors';
import playImg from '../../assets/images/play.png';

import './ViewSale.scss';

const ViewSale = () => {
  const [showImageSliderModal, setShowImageSliderModal] = useState(false);
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const data = useSelector((state) => selectSale(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingUpdate = useSelector((state) => selectIsLoadingUpdate(state));
  const isLoadingDelete = useSelector((state) => selectIsLoadingDelete(state));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getSale(id));
  }, []);

  const remove = () => {
    dispatch(deleteSale(id));
  };

  const featured = () => {
    dispatch(updateSaleStatus(id, { attribute: 'featured', value: true }));
  };

  const approve = () => {
    dispatch(updateSaleStatus(id, { attribute: 'status', value: 'confirmed' }));
  };

  const sold = () => {
    dispatch(updateSaleStatus(id, { attribute: 'status', value: 'finished' }));
  };

  const toggleImageSliderModal = () => {
    setShowImageSliderModal(!showImageSliderModal);
  };

  return (
    <>
      {!isLoading && data
        ? (
          <div className="view-sale">
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  {data.status === 'waiting'
                  && <button type="button" className="button green" onClick={approve}>Approve</button>}
                  {(data.status === 'confirmed')
                  && (
                  <>
                    <button type="button" className="button primary" onClick={featured}>Featured</button>
                    <button type="button" className="button green" onClick={sold}>Sold</button>
                  </>
                  )}
                  <button type="button" className="button danger" onClick={remove}>Delete</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="status-card">
                  {`Current status : ${data.status === 'waiting' ? 'Waiting to be approved' : data.status === 'finished' ? 'sold' : data.status}`}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="status-card">
                  {data.featured ? 'Featured' : 'Not featured'}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">User details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="picture">
                              <img src={data.Farmer?.profilePicture} alt="User" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Name</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer?.farmerName}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Phone Number</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer?.phone ? data.Farmer?.phone.replace('+91', '') : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Gender</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer?.gender}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Age</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{moment(data.Farmer?.age).format('DD/MM/YYYY')}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Type of User</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer?.role}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Pin Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer?.pinCode}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Region Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer?.regionName}</div>
                              </div>
                            </div>
                            {role === 'admin' ? (
                              <>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">MCC Unit Code</div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">{data.Farmer?.unitCode}</div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">MCC Unit Name</div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">{data.Farmer?.unitName}</div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">MCC REP Code</div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">{data.Farmer?.mccCode}</div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">MCC REP Name</div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">{data.Farmer?.mccName}</div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">MCC REP Mobile No</div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">{data.Farmer?.mccMobile}</div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                          {role === 'admin' ? (
                            <div className="col-12 col-md-6">
                              <div className="row w-100 between w-100">
                                <div className="col-4">
                                  <div className="content__title">Plant No</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.plateCode}</div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">Plant Name</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.plateName}</div>
                                </div>
                              </div>
                              <div className="row w-100 between w-100">
                                <div className="col-4">
                                  <div className="content__title">State</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.stateName}</div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">District</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.districtName}</div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">Mandal</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.mendalName}</div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">Panchayat</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.panchayatName}</div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">Village</div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">{data.Farmer?.villageName}</div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Cattle details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex end">
                        <Link to={`/admin/sales/${id}/edit`}>
                          <FontAwesomeIcon
                            icon={faPen}
                            className="edit-icon"
                          />
                        </Link>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {data.pictures.map((img) => (
                            <div className="col-6 col-md-3">
                              <div
                                className="picture"
                                role="button"
                                tabIndex="0"
                                onClick={toggleImageSliderModal}
                              >
                                <img
                                  src={img.split('.')[img.split('.').length - 1] === 'mp4'
                                    ? playImg : img}
                                  alt="Cattle"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex-col">
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Cattle</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{data.cattle}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">category</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{data.category}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Breed</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{data.breed}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Ear tag</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{data.earTag}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Age</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{moment(data.age).format('DD/MM/YYYY')}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Weight</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {data.weight}
                              {' '}
                              kg
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Price</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              Rs.
                              {' '}
                              {data.price}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Negotiable</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">{data.negotiable ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex-col">
                        {data.category === 'milking' || data.category === 'dry'
                          ? (
                            <>
                              <div className="row between">
                                <div className="col-7">
                                  <div className="content__title">Current Milk (per day)</div>
                                </div>
                                <div className="col-4">
                                  <div className="content__text">
                                    {data.currentMilk}
                                    {' '}
                                    L
                                  </div>
                                </div>
                              </div>
                              <div className="row between">
                                <div className="col-7">
                                  <div className="content__title">Milk Capacity (per day)</div>
                                </div>
                                <div className="col-4">
                                  <div className="content__text">
                                    {data.milkCapacity}
                                    {' '}
                                    L
                                  </div>
                                </div>
                              </div>
                              <div className="row between">
                                <div className="col-7">
                                  <div className="content__title">Fat In Milk</div>
                                </div>
                                <div className="col-4">
                                  <div className="content__text">
                                    {data.fat}
                                    {' '}
                                    %
                                  </div>
                                </div>
                              </div>
                              <div className="row between">
                                <div className="col-7">
                                  <div className="content__title">Lactation No</div>
                                </div>
                                <div className="col-4">
                                  <div className="content__text">
                                    {data.LactationNumber}
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                          : null}
                        <div className="row between">
                          <div className="col-7">
                            <div className="content__title">Is it pregnant?</div>
                          </div>
                          <div className="col-4">
                            <div className="content__text">
                              {data.isPregnant ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-7">
                            <div className="content__title">No. of months pregnant</div>
                          </div>
                          <div className="col-4">
                            <div className="content__text">
                              {data.pregnantMonths}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-7">
                            <div className="content__title">Is there a calf?</div>
                          </div>
                          <div className="col-4">
                            <div className="content__text">
                              {data.isCalf ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Description</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data.description}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Address</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data?.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null}
      {showImageSliderModal && (
        <ImageSliderModal
          show={showImageSliderModal}
          toggle={toggleImageSliderModal}
          images={data.pictures}
        />
      )}
      <LoadingModal show={isLoading || isLoadingUpdate || isLoadingDelete} />
    </>
  );
};

export default ViewSale;
