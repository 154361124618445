/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditNewUserFarmerCodeModal.scss';

const EditNewUserFarmerCodeModal = ({
  show, toggle, onSubmit, values,
}) => {
  const showHideClassName = show
    ? 'edit-user-modal display-block'
    : 'edit-user-modal display-none';
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('farmerCode', values.farmerCode);
      setValue('vendorCode', values.vendorCode);
    }
  }, [setValue, values]);

  const submit = ({
    farmerCode,
    vendorCode,
  }) => {
    toggle();
    onSubmit({
      farmerCode,
      vendorCode,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button
              type="button"
              className="modal-main__button"
              onClick={toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Vendor Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="vendorCode"
                            maxLength={10}
                            name="vendorCode"
                            placeholder="Vendor Code"
                            {...register('vendorCode', {
                              required: {
                                value: true,
                                message: 'Vendor Code is required',
                              },
                              minLength: {
                                value: 9,
                                message: 'Vendor code must not be less than 9 digits',
                              },
                              maxLength: {
                                value: 10,
                                message: 'Vendor code must not be greater than 10 digits',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Vendor code must be digits',
                              },
                            })}
                          />
                          {errors.vendorCode && (
                            <div className="form__error">
                              {errors.vendorCode.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Farmer Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="farmerCode"
                            maxLength={4}
                            name="farmerCode"
                            placeholder="Farmer Code"
                            {...register('farmerCode', {
                              required: {
                                value: true,
                                message: 'Farmer Code is required',
                              },
                              minLength: {
                                value: 3,
                                message: 'Farmer code must not be less than 3 digits',
                              },
                              maxLength: {
                                value: 4,
                                message: 'Farmer code must not be greater than 4 digits',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Farmer code must be digits',
                              },
                            })}
                          />
                          {errors.farmerCode && (
                            <div className="form__error">
                              {errors.farmerCode.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button
                          type="button"
                          className="form__button"
                          onClick={handleSubmit(submit)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditNewUserFarmerCodeModal;
