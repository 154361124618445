/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import VideoModal from '../../components/VideoModal';
import CreativeModal from '../../components/CreativeModal';
import CreativeBannerModal from '../../components/CreativeBannerModal';
import CreativeHeadingModal from '../../components/CreativeHeadingModal';
import LoadingModal from '../../components/LoadingModal';
import {
  addHeading,
  addVideo, getHeadings, getVideos, updateHeading, updateVideo,
} from '../../store/modules/data/actions';
import {
  selectCreatives1, selectCreatives2, selectHeadings, selectIsLoading, selectVideos,
} from '../../store/modules/data/selectors';
import logoImg from '../../assets/images/logo.png';

import './VideoCreatives.scss';

const VideoCreatives = () => {
  const [item, setItem] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [heading, setHeading] = useState(null);
  const [headingType, setHeadingType] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showCreativeModal, setShowCreativeModal] = useState(false);
  const [showCreativeBannerModal, setShowCreativeBannerModal] = useState(false);
  const [showCreativeHeadingModal, setShowCreativeHeadingModal] = useState(false);

  const dispatch = useDispatch();
  const videos = useSelector((state) => selectVideos(state));
  const creatives1 = useSelector((state) => selectCreatives1(state));
  const creatives2 = useSelector((state) => selectCreatives2(state));
  const headings = useSelector((state) => selectHeadings(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  useEffect(() => {
    dispatch(getVideos());
    dispatch(getHeadings());
  }, []);

  const toggleVideoModal = (value, type) => {
    setItem(value);
    setItemType(type);
    setShowVideoModal(!showVideoModal);
  };

  const toggleCreativeModal = (value, type) => {
    setItem(value);
    setItemType(type);
    setShowCreativeModal(!showCreativeModal);
  };

  const toggleCreativeBannerModal = (value, type) => {
    setItem(value);
    setItemType(type);
    setShowCreativeBannerModal(!showCreativeBannerModal);
  };

  const toggleCreativeHeadingModal = (value, type) => {
    setHeading(value);
    setHeadingType(type);
    setShowCreativeHeadingModal(!showCreativeHeadingModal);
  };

  const onSubmit = ({ text, thumbnail, regionName }) => {
    const formData = new FormData();
    formData.append('regionName', regionName);
    formData.append('type', itemType);
    if (text) {
      formData.append('text', text);
    }
    if (thumbnail) {
      formData.append('image', thumbnail);
    }

    if (item) {
      if (item.regionName !== regionName) {
        dispatch(addVideo(formData));
        return;
      }
      formData.append('active', 'true');
      dispatch(updateVideo(item.id, formData));
    } else {
      dispatch(addVideo(formData));
    }
  };

  const onSubmitHeading = ({ title }) => {
    if (heading) {
      dispatch(updateHeading(heading.id, { title, type: headingType }));
    } else {
      dispatch(addHeading({ title, type: headingType }));
    }
  };

  return (
    <>
      <div className="video-creatives">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <div className="row center">
                <div className="col-8 col-md-auto">
                  <p className="heading__title">
                    {headings.find((h) => h.type === 'youtube')?.title ?? 'Please enter text'}
                  </p>
                </div>
                <div className="col-4 col-md-auto">
                  <button type="button" className="button primary" onClick={() => toggleCreativeHeadingModal(headings.find((h) => h.type === 'youtube') || null, 'youtube')}>Set</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="picture" tabIndex="-1" role="button" onClick={() => toggleVideoModal(videos[0] || null, 'youtube')}>
                      {videos[0]
                        ? <img src={videos[0].thumbnails} alt="Video Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="picture" tabIndex="-1" role="button" onClick={() => toggleVideoModal(videos[1] || null, 'youtube')}>
                      {videos[1]
                        ? <img src={videos[1].thumbnails} alt="Video Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="picture" tabIndex="-1" role="button" onClick={() => toggleVideoModal(videos[2] || null, 'youtube')}>
                      {videos[2]
                        ? <img src={videos[2].thumbnails} alt="Video Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="picture" tabIndex="-1" role="button" onClick={() => toggleVideoModal(videos[3] || null, 'youtube')}>
                      {videos[3]
                        ? <img src={videos[3].thumbnails} alt="Video Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="form__item">
                    <div className="picture" tabIndex="-1" role="button" onClick={() => toggleVideoModal(videos[4] || null, 'youtube')}>
                      {videos[4]
                        ? <img src={videos[4].thumbnails} alt="Video Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-16">
          <div className="col-12">
            <div className="heading">
              <div className="row center">
                <div className="col-8 col-md-auto">
                  <p className="heading__title">
                    {headings.find((h) => h.type === 'creative')?.title ?? 'Please enter text'}
                  </p>
                </div>
                <div className="col-4 col-md-auto">
                  <button type="button" className="button primary" onClick={() => toggleCreativeHeadingModal(headings.find((h) => h.type === 'creative') || null, 'creative')}>Set</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12">
                  <div className="heading">
                    <div className="row center">
                      <div className="col-8 col-md-auto">
                        <p className="heading__title">
                          {headings.find((h) => h.type === 'creative1')?.title ?? 'Please enter text'}
                        </p>
                      </div>
                      <div className="col-4 col-md-auto">
                        <button type="button" className="button primary" onClick={() => toggleCreativeHeadingModal(headings.find((h) => h.type === 'creative1') || null, 'creative1')}>Set</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-3">
                  <div className="form__item">
                    <div className="picture-creative" tabIndex="-1" role="button" onClick={() => toggleCreativeModal(creatives1[0] || null, 'creative1')}>
                      {creatives1[0]
                        ? <img src={creatives1[0].thumbnails} alt="Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture-creative__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <span className="form__label">{creatives1[0]?.text ?? 'Please enter text'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="form__item">
                    <div className="picture-creative" tabIndex="-1" role="button" onClick={() => toggleCreativeModal(creatives1[1] || null, 'creative1')}>
                      {creatives1[1]
                        ? <img src={creatives1[1].thumbnails} alt="Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture-creative__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <span className="form__label">{creatives1[1]?.text ?? 'Please enter text'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="form__item">
                    <div className="picture-creative" tabIndex="-1" role="button" onClick={() => toggleCreativeModal(creatives1[2] || null, 'creative1')}>
                      {creatives1[2]
                        ? <img src={creatives1[2].thumbnails} alt="Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture-creative__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <span className="form__label">{creatives1[2]?.text ?? 'Please enter text'}</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="form__item">
                    <div className="picture-creative" tabIndex="-1" role="button" onClick={() => toggleCreativeModal(creatives1[3] || null, 'creative1')}>
                      {creatives1[3]
                        ? <img src={creatives1[3].thumbnails} alt="Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture-creative__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                    <div className="mt-4">
                      <span className="form__label">{creatives1[3]?.text ?? 'Please enter text'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12">
                  <div className="heading">
                    <div className="row center">
                      <div className="col-8 col-md-auto">
                        <p className="heading__title">
                          {headings.find((h) => h.type === 'creative2')?.title ?? 'Please enter text'}
                        </p>
                      </div>
                      <div className="col-4 col-md-auto">
                        <button type="button" className="button primary" onClick={() => toggleCreativeHeadingModal(headings.find((h) => h.type === 'creative2') || null, 'creative2')}>Set</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form__item">
                    <div className="picture-creative" tabIndex="-1" role="button" onClick={() => toggleCreativeBannerModal(creatives2[0] || null, 'creative2')}>
                      {creatives2[0]
                        ? <img src={creatives2[0].thumbnails} alt="Banner Thumbnail" />
                        : (
                          <div style={{ background: '#55555520' }} className="picture__placeholder">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="picture-creative__placeholder--icon"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideoModal && (
      <VideoModal
        show={showVideoModal}
        toggle={() => setShowVideoModal(!showVideoModal)}
        onSubmit={onSubmit}
        video={item}
      />
      )}
      {showCreativeModal && (
      <CreativeModal
        show={showCreativeModal}
        toggle={() => setShowCreativeModal(!showCreativeModal)}
        onSubmit={onSubmit}
        creative={item}
      />
      )}
      {showCreativeBannerModal && (
      <CreativeBannerModal
        show={showCreativeBannerModal}
        toggle={() => setShowCreativeBannerModal(!showCreativeBannerModal)}
        onSubmit={onSubmit}
        creative={item}
      />
      )}
      {showCreativeHeadingModal && (
      <CreativeHeadingModal
        show={showCreativeHeadingModal}
        toggle={() => setShowCreativeHeadingModal(!showCreativeHeadingModal)}
        onSubmit={onSubmitHeading}
        creative={heading}
      />
      )}
      <LoadingModal show={isLoading} />
    </>
  );
};

export default VideoCreatives;
