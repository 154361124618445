/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditBankDetailsModal.scss';

const EditUserModal = ({
  show, toggle, onSubmit, values,
}) => {
  const showHideClassName = show ? 'edit-user-modal display-block' : 'edit-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('accountNumber', values?.accountNumber ?? values?.bankAccountNumber);
      setValue('bankName', values.bankName);
      setValue('branchName', values?.branchName);
      setValue('ifscCode', values?.ifscCode);
    }
  }, [setValue, values]);

  const submit = ({
    accountNumber,
    bankName,
    branchName,
    ifscCode,
  }) => {
    toggle();
    onSubmit({
      accountNumber,
      bankName,
      ifscCode,
      branchName,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Bank Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="bankName"
                            name="bankName"
                            placeholder="Bank Name"
                            {...register('bankName', { required: { value: false, message: 'Bank Name is required' } })}
                          />
                          {errors.bankName && <div className="form__error">{errors.bankName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Branch Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="branchName"
                            name="branchName"
                            placeholder="Branch Name"
                            {...register('branchName', { required: { value: false, message: 'Branch Name is required' } })}
                          />
                          {errors.branchName && <div className="form__error">{errors.branchName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">IFSC Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="ifscCode"
                            name="ifscCode"
                            placeholder="IFSC Code"
                            {...register('ifscCode', { required: { value: false, message: 'IFSC Code is required' } })}
                          />
                          {errors.ifscCode && <div className="form__error">{errors.ifscCode.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Account Number</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="accountNumber"
                            name="accountNumber"
                            placeholder="Account Number"
                            {...register('accountNumber', { required: { value: true, message: 'Account Number is required' } })}
                          />
                          {errors.accountNumber && <div className="form__error">{errors.accountNumber.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditUserModal;
