import React from 'react';

import Spinner from '../Spinner';
import './LoadingModal.scss';

function LoadingModal({ show }) {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <Spinner large />
      </section>
    </div>
  );
}

export default LoadingModal;
