import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  GET_MCC_UPCOMING_START,
  GET_MCC_UPCOMING_SUCCESS,
  GET_MCC_UPCOMING_ERROR,
  GET_MCC_PAST_HELD_START,
  GET_MCC_PAST_HELD_SUCCESS,
  GET_MCC_PAST_HELD_ERROR,
  GET_MCC_SINGLE_START,
  GET_MCC_SINGLE_SUCCESS,
  GET_MCC_SINGLE_ERROR,
  FINISH_MCC_START,
  FINISH_MCC_SUCCESS,
  FINISH_MCC_ERROR,
  REJECT_MCC_START,
  REJECT_MCC_SUCCESS,
  REJECT_MCC_ERROR,
  CREATE_START,
  CREATE_SUCCESS,
  CREATE_ERROR,
  GET_UPCOMING_START,
  GET_UPCOMING_SUCCESS,
  GET_UPCOMING_ERROR,
  GET_PAST_HELD_START,
  GET_PAST_HELD_SUCCESS,
  GET_PAST_HELD_ERROR,
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_PAST_HELD_START,
  SEARCH_PAST_HELD_SUCCESS,
  SEARCH_PAST_HELD_ERROR,
  GET_PLANT_UPCOMING_START,
  GET_PLANT_UPCOMING_SUCCESS,
  GET_PLANT_UPCOMING_ERROR,
  GET_PLANT_PAST_HELD_START,
  GET_PLANT_PAST_HELD_SUCCESS,
  GET_PLANT_PAST_HELD_ERROR,
  GET_PLANT_SINGLE_START,
  GET_PLANT_SINGLE_SUCCESS,
  GET_PLANT_SINGLE_ERROR,
  FINISH_PLANT_START,
  FINISH_PLANT_SUCCESS,
  FINISH_PLANT_ERROR,
  REJECT_PLANT_START,
  REJECT_PLANT_SUCCESS,
  REJECT_PLANT_ERROR,
  EXPORT_MCC_UPCOMING_START,
  EXPORT_MCC_UPCOMING_SUCCESS,
  EXPORT_MCC_UPCOMING_ERROR,
  EXPORT_MCC_PAST_HELD_START,
  EXPORT_MCC_PAST_HELD_SUCCESS,
  EXPORT_MCC_PAST_HELD_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  EXPORT_PLANT_START,
  EXPORT_PLANT_SUCCESS,
  EXPORT_PLANT_ERROR,
  EDIT_MCC_ORDER_DETAILS_START,
  EDIT_MCC_ORDER_DETAILS_SUCCESS,
  EDIT_MCC_ORDER_DETAILS_ERROR,
  REMOVE_MCC_ORDER_DETAILS_START,
  REMOVE_MCC_ORDER_DETAILS_SUCCESS,
  REMOVE_MCC_ORDER_DETAILS_ERROR,
  ADD_MCC_ORDER_DETAILS_START,
  ADD_MCC_ORDER_DETAILS_SUCCESS,
  ADD_MCC_ORDER_DETAILS_ERROR,
  SEARCH_UPCOMING_START,
  SEARCH_UPCOMING_SUCCESS,
  SEARCH_UPCOMING_ERROR,
  GET_COUNT_DETAILS_START,
  GET_COUNT_DETAILS_SUCCESS,
  GET_COUNT_DETAILS_ERROR,
  GET_OHS_VALUE_START,
  GET_OHS_VALUE_SUCCESS,
  GET_OHS_VALUE_ERROR,
  GET_FARMER_CODE_START,
  GET_FARMER_CODE_SUCCESS,
  GET_FARMER_CODE_ERROR,
  STORE_VALUES_START,
  GET_BATCHNO_START,
  GET_BATCHNO_SUCCESS,
  GET_BATCHNO_ERROR,
  UPDATE_UNITCODE_START,
  UPDATE_UNITCODE_SUCCESS,
  UPDATE_UNITCODE_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getMccUpcomingOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_MCC_UPCOMING_START });

    const res = await HttpService.post(`/plant/view-mcc-rep-orders-by-status?page=${page}&limit=${limit}`,
      { status: 'waiting', userType: 'MCC Representative' });

    dispatch({
      type: GET_MCC_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MCC_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMccPastHeldOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_MCC_PAST_HELD_START });

    const res = await HttpService.post(`/plant/view-mcc-rep-orders-by-status?page=${page}&limit=${limit}`,
      { status: 'finished', userType: 'MCC Representative' });

    dispatch({
      type: GET_MCC_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MCC_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMccOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_MCC_SINGLE_START });

    const res = await HttpService.post(`/plant/view-order-details/${id}`,
      { userType: 'MCC Representative' });

    dispatch({
      type: GET_MCC_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MCC_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const finishMccOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: FINISH_MCC_START });

    const res = await HttpService.patch(
      `/plant/update-mcc-rep-order-by-status/${orderId}`,
      { status: 'finished', userType: 'MCC Representative' },
    );

    dispatch({
      type: FINISH_MCC_SUCCESS,
      payload: res.data,
    });
    Swal.fire(
      'Success!',
      'You have succesfully approved the order',
      'success',
    );
  } catch (error) {
    dispatch({
      type: FINISH_MCC_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    Swal.fire(
      {
        title: 'Alert!',
        text: `${ApiErrorHandler.selectMessage(error)}`,
        icon: 'warning',
        toast: true,
      },
    );
  }
};

export const rejectMccOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_MCC_START });

    const res = await HttpService.patch(
      `/plant/update-mcc-rep-order-by-status/${orderId}`,
      { status: 'rejected', userType: 'MCC Representative' },
    );

    dispatch({
      type: REJECT_MCC_SUCCESS,
      payload: res.data,
    });
    toast.success('Order rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_MCC_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const createOrder = (order, cb) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_START });

    const res = await HttpService.post('/plant/create-order', order);

    dispatch({
      type: CREATE_SUCCESS,
      payload: res.data,
    });
    cb();
    toast.success('Order created successfully');
  } catch (error) {
    dispatch({
      type: CREATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getUpcomingOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_UPCOMING_START });

    const res = await HttpService.post(`/plant/view-mcc-rep-orders-by-status?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPastHeldOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PAST_HELD_START });

    const res = await HttpService.post(`/plant/view-mcc-rep-orders-by-status?page=${page}&limit=${limit}`, { status: 'finished' });

    dispatch({
      type: GET_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });

    const res = await HttpService.post(`/plant/view-orders-by-owner?page=${page}&limit=${limit}`,
      { status: 'waiting', userType: 'plant' });

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.post(`/plant/view-order-details/${id}`,
      { userType: 'plant' });

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchUpcomingOrders = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_UPCOMING_START });

    const res = await HttpService.post('/doctor/search-farmer-appointment', { phone, status: 'waiting' });

    dispatch({
      type: SEARCH_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchPastHeldOrders = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PAST_HELD_START });

    const res = await HttpService.post('/doctor/search-farmer-appointment', { phone, status: 'finished' });

    dispatch({
      type: SEARCH_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPlantUpcomingOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLANT_UPCOMING_START });

    const res = await HttpService.post(`/admin/view-plant-orders-by-status?page=${page}&limit=${limit}`,
      { status: 'waiting', userType: 'plant' });

    dispatch({
      type: GET_PLANT_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PLANT_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPlantPastHeldOrders = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLANT_PAST_HELD_START });

    const res = await HttpService.post(`/admin/view-plant-orders-by-status?page=${page}&limit=${limit}`,
      { status: 'finished', userType: 'plant' });

    dispatch({
      type: GET_PLANT_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PLANT_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPlantOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLANT_SINGLE_START });

    const res = await HttpService.post(`/admin/view-plant-order-details/${id}`,
      { userType: 'plant' });

    dispatch({
      type: GET_PLANT_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PLANT_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const finishPlantOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: FINISH_PLANT_START });

    const res = await HttpService.patch(
      `/admin/update-plant-order-by-status/${orderId}`,
      { status: 'finished', userType: 'plant' },
    );

    dispatch({
      type: FINISH_PLANT_SUCCESS,
      payload: res.data,
    });

    toast.success('Order finished successfully');
  } catch (error) {
    dispatch({
      type: FINISH_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const rejectPlantOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_PLANT_START });

    const res = await HttpService.patch(
      `/admin/update-plant-order-by-status/${orderId}`,
      { status: 'rejected', userType: 'plant' },
    );

    dispatch({
      type: REJECT_PLANT_SUCCESS,
      payload: res.data,
    });
    toast.success('Order rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportMccUpcomingOrders = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_MCC_UPCOMING_START });

    const res = await axios({
      url: `${config.apiUrl}/plant/export-mcc-orders`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
      data: { status: 'waiting', userType: 'MCC Representative' },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-MCC-Representative-orders-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_MCC_UPCOMING_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_MCC_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportMccPastHeldOrders = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_MCC_PAST_HELD_START });

    const res = await axios({
      url: `${config.apiUrl}/plant/export-mcc-orders`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
      data: { status: 'finished', userType: 'MCC Representative' },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-MCC-Representative-orders-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_MCC_PAST_HELD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_MCC_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportOrders = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/plant/export-plant-orders`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
      data: { status: null, userType: 'plant' },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Plant-Admin-orders-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportPlantOrders = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_PLANT_START });
    const res = await axios({
      url: `${config.apiUrl}/admin/export-orders`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
      data: { status: null, userType: 'plant' },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Orders-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_PLANT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const editMccOrderDetails = (orderId, orderDetailId, order) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_MCC_ORDER_DETAILS_START });

    const res = await HttpService.patch(`plant/update-mcc-rep-order/${orderId}/${orderDetailId}`, order);

    dispatch({
      type: EDIT_MCC_ORDER_DETAILS_SUCCESS,
      payload: res.data,
    });
    toast.success('Item updated successfully');
  } catch (error) {
    dispatch({
      type: EDIT_MCC_ORDER_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const removeMccOrderDetails = (orderId, orderDetailId, order) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_MCC_ORDER_DETAILS_START });

    const res = await HttpService.delete(`plant/delete-order-details/${orderId}/${orderDetailId}`, order);

    dispatch({
      type: REMOVE_MCC_ORDER_DETAILS_SUCCESS,
      payload: res.data,
    });
    window.location.reload();
    toast.success('Item removed successfully');
  } catch (error) {
    dispatch({
      type: REMOVE_MCC_ORDER_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const addMccOrderDetails = (orderId, order, cb) => async (dispatch) => {
  try {
    dispatch({ type: ADD_MCC_ORDER_DETAILS_START });

    const res = await HttpService.post(`plant/add-order-details/${orderId}`, order);

    dispatch({
      type: ADD_MCC_ORDER_DETAILS_SUCCESS,
      payload: res.data,
    });
    cb();
    toast.success('Item(s) added successfully');
  } catch (error) {
    dispatch({
      type: ADD_MCC_ORDER_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getCountofHnlOrders = (type) => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNT_DETAILS_START });
    const response = await HttpService.get(`admin/plant-order-summary?userType=${type}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_COUNT_DETAILS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COUNT_DETAILS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getOHSValueForOrders = (value) => async (dispatch) => {
  try {
    dispatch({ type: GET_OHS_VALUE_START });
    const response = await HttpService.get(`/plant/ohs?materialCode=${value?.materialCode}&batchNo=${value?.batchNo}`);
    dispatch({
      type: GET_OHS_VALUE_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_OHS_VALUE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getFarmerCodesValue = (value) => async (dispatch) => {
  try {
    dispatch({ type: GET_FARMER_CODE_START });
    const response = await HttpService.get(`/plant/farmer-codes?hpcCode=${value}`);
    dispatch({
      type: GET_FARMER_CODE_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FARMER_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getBatchNoForIndividualItems = (value) => async (dispatch) => {
  try {
    dispatch({ type: GET_BATCHNO_START });
    const response = await HttpService.get(`/plant/fetch-batches?materialCode=${value}`);
    dispatch({
      type: GET_BATCHNO_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BATCHNO_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateUnitCodeForNewOrders = (value) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_UNITCODE_START });
    const response = await HttpService.patch('/plant/update-unitcode', value);
    dispatch({
      type: UPDATE_UNITCODE_SUCCESS,
      payload: response?.data,
    });
    dispatch(getMccOrder(value.orderId));
  } catch (error) {
    dispatch({
      type: UPDATE_UNITCODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const storeValuesToRedux = (value) => async (dispatch) => {
  dispatch({
    type: STORE_VALUES_START,
    payload: value,
  });
};
