/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faChevronRight,
  faChevronLeft,
  faFileExcel,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  getSales, searchSales, deleteSale, exportSales,
} from '../../store/modules/market/actions';
import {
  selectSales, selectIsLoading, selectIsLoadingExport, selectIsLoadingDelete, selectMessage,
} from '../../store/modules/market/selectors';
import cow from '../../assets/images/cow.png';

import './PendingSales.scss';

const PendingSales = () => {
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectSales(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));
  const isLoadingDelete = useSelector((state) => selectIsLoadingDelete(state));
  const message = useSelector((state) => selectMessage(state));

  useEffect(() => {
    dispatch(getSales());
  }, [message]);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchSales(`+91${query}`));
  };

  const nextPage = (page) => {
    dispatch(getSales(page));
  };

  const previousPage = (page) => {
    dispatch(getSales(page));
  };

  const exportExcell = () => {
    dispatch(exportSales());
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSale(id));
        Swal.fire(
          {
            title: 'Deleted!',
            text: 'You have succesfully deleted!',
            icon: 'success',
            timer: 2000,
          },
        );
      }
    });
  };

  return (
    <>
      <div className="sales">
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {data && data.paginate && data.paginate.length
            ? data.paginate.map((sale) => (
              <div key={sale.id} className="col-12 col-lg-12">
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img
                        src={sale.pictures.map((pic) => (pic.split('.')[pic.split('.').length - 1] !== 'mp4'
                          ? pic
                          : 'https://res.cloudinary.com/your-highness/image/upload/v1641914654/tools/Play_Icon_dbsq64.jpg'))[0]}
                        alt="cattle"
                      />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          {sale.category}
                          {' '}
                          {sale.cattle}
                        </span>
                      </div>
                      <div>
                        <span className="card__title">
                          Price :
                          {' '}
                        </span>
                        {sale.price}
                      </div>
                      <div>
                        <span className="card__title">
                          Farmer Name :
                          {' '}
                        </span>
                        {sale.Farmer?.farmerName}
                      </div>
                      <div>
                        <span className="card__title">
                          Date :
                          {' '}
                        </span>
                        {moment(sale.createdAt).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <button type="button" className="card__button danger" onClick={() => deleteItem(sale.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <Link
                      to={`/admin/sales/${sale.id}`}
                      className="card__button"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            ))
            : (
              null
            )}
          {!isLoading && !data && (
          <div className="col-12 col-lg-12 d-flex-col middle">
            <div className="notFound">
              <FontAwesomeIcon icon={faUsers} className="notFound__icon" />
              <span className="notFound__text">No cattle for sale found</span>
            </div>
          </div>
          )}
        </div>
        {data
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Previous}
                    onClick={() => previousPage(data.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {data.Next
                      ? data.Next.page - 1
                      : data.Previous
                        ? data.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Next}
                    onClick={() => nextPage(data.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
      <LoadingModal show={isLoading || isLoadingDelete || isLoadingExport} />
    </>
  );
};

export default PendingSales;
