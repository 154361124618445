/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faFilter,
  faCheck,
  faClose,
  faUsersSlash,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  getAllNewUserCompletedPlantSideRequestDetailsByType,
  searchAllNewUserCompletedPlantSideRequestDetailsByType,
  filterPlantSideNewUsersCompletedrequests,
} from '../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectCompletedUserPlantSide,
} from '../../store/modules/newUser/selectors';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import ModalForFilter from '../../components/ModalForFilter';
import { isValidIndianPhoneNumber, isValidName } from '../../components/Validators';
import CountUp from '../../components/CountUp';
import './ApprovedRegistration.scss';

const ApprovedRegistration = () => {
  const isRefreshedRef = useRef(false);
  const [query, setQuery] = useState(null);
  const [showModalForFilter, setshowModalForFilter] = useState(false);
  const [pageNum, setpageNum] = useState(1);
  const [storePageSizeValue, setstorePageSizeValue] = useState(10);
  const columns = useMemo(
    () => [
      {
        Header: 'Registration ID',
        accessor: 'id',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'User Name',
        accessor: 'farmerName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'Role',
        accessor: 'mccType',
        Cell: ({ value }) => (value === 'hpc_farmer' ? <p>HPC Farmer</p> : <p> Not Available</p>),
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      // {
      //   Header: 'Age',
      //   accessor: 'age',
      //   Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      // },

      {
        Header: 'HPC Code',
        accessor: 'hpcCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      // {
      //   Header: 'Plant Code',
      //   accessor: 'plantCode',
      //   Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      // },
      {
        Header: 'E-KYC',
        accessor: 'isEqualGenerated',
        Cell: ({ value }) => (value === true ? (
          <FontAwesomeIcon icon={faCheck} color="green" />
        ) : (
          <FontAwesomeIcon icon={faClose} color="red" />
        )),
      },
      {
        Header: 'Plant IC Status',
        accessor: 'preparedByPlantIC',
        Cell: ({ value }) => (value !== null ? (
          <p
            style={{
              color:
                  value === 'pending'
                    ? '#ffb100'
                    : value === 'rejected'
                      ? 'red'
                      : 'green',
              textTransform: 'uppercase',
            }}
          >
            {value}
          </p>
        ) : (
          <p> Not Available</p>
        )),
      },
      // {
      //   Header: 'RIFA Status',
      //   accessor: 'verifiedByRIFandA',
      //   Cell: ({ value }) => (value !== null ? (
      //     <p
      //       style={{
      //         color:
      //             value === 'pending'
      //               ? '#ffb100'
      //               : value === 'rejected'
      //                 ? 'red'
      //                 : 'green',
      //         textTransform: 'uppercase',
      //       }}
      //     >
      //       {value}
      //     </p>
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      {
        Header: 'EAPI Status',
        accessor: 'createdByEAPandI',
        Cell: ({ value }) => (value !== null ? (
          <p
            style={{
              color:
                  value === 'pending'
                    ? '#ffb100'
                    : value === 'rejected'
                      ? 'red'
                      : 'green',
              textTransform: 'uppercase',
            }}
          >
            {value}
          </p>
        ) : (
          <p> Not Available</p>
        )),
      },
      {
        Header: 'RHPIC Status',
        accessor: 'approvedByRIPandI',
        Cell: ({ value }) => (value !== null ? (
          <p
            style={{
              color:
                  value === 'pending'
                    ? '#ffb100'
                    : value === 'rejected'
                      ? 'red'
                      : 'green',
              textTransform: 'uppercase',
            }}
          >
            {value}
          </p>
        ) : (
          <p> Not Available</p>
        )),
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({ value }) => (value !== null ? (
      //     <p
      //       style={{
      //         color:
      //             value === 'pending'
      //               ? '#ffb100'
      //               : value === 'rejected'
      //                 ? 'red'
      //                 : 'green',
      //         textTransform: 'uppercase',
      //       }}
      //     >
      //       {value}
      //     </p>
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      // {
      //   Header: 'Plant IC Sign',
      //   accessor: 'planticSign',
      //   Cell: ({ value }) => (value !== null ? (
      //     <img src={value} alt="sign" className="signatureImg" />
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      // {
      //   Header: 'RIFA Sign',
      //   accessor: 'rifaSign',
      //   Cell: ({ value }) => (value !== null ? (
      //     <img src={value} alt="sign" className="signatureImg" />
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      // {
      //   Header: 'EAPI Sign',
      //   accessor: 'eapiSign',
      //   Cell: ({ value }) => (value !== null ? (
      //     <img src={value} alt="sign" className="signatureImg" />
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      // {
      //   Header: 'RHPI Sign',
      //   accessor: 'rhpiSign',
      //   Cell: ({ value }) => (value !== null ? (
      //     <img src={value} alt="sign" className="signatureImg" />
      //   ) : (
      //     <p> Not Available</p>
      //   )),
      // },
      {
        Header: 'Reason For Rejection',
        accessor: 'comment',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>No Comments</p>),
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectCompletedUserPlantSide(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const role = localStorage.getItem('role');
  const filterApplied = localStorage.getItem('filterApplied');
  useEffect(() => {
    if (!filterApplied) {
      const values = {
        page: pageNum,
        limit: storePageSizeValue,
      };
      dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(values));
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Filter applied, do you want to continue anyway ?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((res) => {
        if (res.isDismissed) {
          localStorage.removeItem('filterApplied');
          const values = {
            page: pageNum,
            limit: storePageSizeValue,
          };
          dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(values));
        }
      });
    }
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:
      data && data?.paginate && data?.paginate?.length
        ? data?.paginate?.map((item) => ({
          ...item,
          phone: item?.phone?.replace('+91', ''),
          age: moment(item?.age).format('DD/MM/YYYY'),
          totalAnimals: item?.Cattle?.length,
        }))
        : [],
  });

  const nextPage = (page) => {
    setpageNum(page);
    const nextPageVal = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(nextPageVal));
  };

  const previousPage = (page) => {
    setpageNum(page);
    const prevPageVal = {
      page,
      limit: 10,
    };
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(prevPageVal));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    let values;
    Swal.fire({
      toast: true,
      icon: 'warning',
      title: 'Are you in search of a vendor code?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res?.isConfirmed) {
        values = {
          vendorCode: query,
          page: pageNum,
          limit: 10,
        };
      } else if (isValidIndianPhoneNumber(query)) {
        values = {
          phone: query,
          page: pageNum,
          limit: 10,
        };
      } else if (isValidName(query)) {
        values = {
          farmerName: query,
          page: pageNum,
          limit: 10,
        };
      }
      dispatch(searchAllNewUserCompletedPlantSideRequestDetailsByType(values));
    });
  };

  //   const exportExcell = () => {
  //     dispatch(exportApprovedUsers());
  //   };

  //   const showSwalAlert = () => {
  //     let html = '<div style="width: 100%;height: 250px;overflow-y: auto;text-align: center;padding: 20px;"><table style="border-collapse: collapse;width:100%"><thead><tr><th style="text-align: left;padding: 8px;">Id</th><th style="text-align: left;padding: 8px;">Region Name</th><th style="text-align: left;padding: 8px;">Pending</th><th style="text-align: left;padding: 8px;">Approved / Rejected</th></tr></thead><tbody>';

  //     count.countedDigitalRegistrations.regionData.forEach((item, index) => {
  //       const bgColor = index % 2 === 0 ? '' : '#D6EEEE'; // calculate background color
  //       const regionName = item?.regionName || ''; // null check unnecessary

  //       html += `<tr style="background-color: ${bgColor};"><td style="text-align: left;padding: 8px;">${
  //         index + 1
  //       }</td><td style="text-align: left;padding: 8px;">${regionName}</td><td style="text-align: center;padding: 8px;">${
  //         item?.pendingCount
  //       }</td><td style="text-align: center;padding: 8px;">${item?.completedRequestsCount}</td></tr>`;
  //     });

  //     html += '</tbody></table>';
  //     Swal.fire({
  //       title: 'Region-specific number of digital farmers enrolled',
  //       html,
  //     });
  //   };
  //   const showVplCountSwal = () => {
  //     let html = '<div style="width: 100%;height: 250px;overflow-y: auto;text-align: center;padding: 20px;"><table style="border-collapse: collapse;width:100%"><thead><tr><th style="text-align: left;padding: 8px;">Id</th><th style="text-align: left;padding: 8px;">Region Name</th><th style="text-align: left;padding: 8px;">Pending</th><th style="text-align: left;padding: 8px;">Approved / Rejected</th></tr></thead><tbody>';

  //     count.countedIndents.regionData.forEach((item, index) => {
  //       const bgColor = index % 2 === 0 ? '' : '#D6EEEE'; // calculate background color
  //       const regionName = item?.regionName || ''; // null check unnecessary

  //       html += `<tr style="background-color: ${bgColor};"><td style="text-align: left;padding: 8px;">${
  //         index + 1
  //       }</td><td style="text-align: left;padding: 8px;">${regionName}</td><td style="text-align: center;padding: 8px;">${
  //         item?.pendingCount
  //       }</td><td style="text-align: center;padding: 8px;">${item?.completedRequestsCount}</td></tr>`;
  //     });

  //     html += '</tbody></table>';
  //     Swal.fire({
  //       title: 'VPL Store Indents Region Wise',
  //       html,
  //     });
  //   };
  const handleOnFilter = () => {
    setshowModalForFilter(!showModalForFilter);
  };

  const submitFilter = (e) => {
    const filterValues = {
      hpcCode: e?.hpcCode,
      fromDate: moment(e?.fromDate).format('DD/MM/YYYY'),
      page: pageNum,
      limit: 10,
      toDate: moment(e?.toDate).format('DD/MM/YYYY'),
    };
    dispatch(filterPlantSideNewUsersCompletedrequests(filterValues));
    localStorage.setItem('filterApplied', true);
  };

  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);
  const handleOnPageSizeSelected = (event) => {
    setstorePageSizeValue(parseInt(event?.target?.value));
    const values = {
      page: pageNum,
      limit: `${event?.target?.value}`,
    };
    dispatch(getAllNewUserCompletedPlantSideRequestDetailsByType(values));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isRefreshedRef.current) {
        localStorage.removeItem('filterApplied');
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <div className="approved-registration">
        {
          filterApplied && (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon
              icon={faWarning}
              style={{ marginRight: 5 }}
            />
            In order to dynamically remove the filter that has been applied, please refresh the page.
          </div>
          )
        }

        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search
              value={query}
              label="Find Farmers By Name, Vendor Code, or Phone"
              onChangeText={handleChange}
              onSubmit={search}
            />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button
                type="button"
                className="button primary"
                onClick={handleOnFilter}
              >
                <FontAwesomeIcon icon={faFilter} className="button__icon" />
                Filter
              </button>
            </div>
          </div>
        </div>
        <div className="table-container">
          {
            data && data?.paginate?.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="table-footer">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="table-footer__title">
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                          - data.Previous.limit
                          + 1
                            : 1}
                          {' '}
                          to
                          {' '}
                          {data && data.Previous
                            ? (data.Previous.page + 1) * data.Previous.limit
                            : storePageSizeValue}
                          {' '}
                          of 1
                          {' '}
                          {''}
                          <span className="form__label">Page Size : </span>
                          <select onChange={handleOnPageSizeSelected}>
                            {pageSize?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </span>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="table-footer__buttons">
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => previousPage(1)}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleLeft}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? previousPage(data.Previous.page) : {})}
                            disabled={!data || !data.Previous}
                          >
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              className="table-footer__button__iclon"
                            />
                          </button>
                          <div className="table-footer__label">
                            {data && data.Next
                              ? data.Next.page - 1
                              : data && data.Previous
                                ? data.Previous.page + 1
                                : 1}
                            {/* /1 */}
                          </div>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                          <button
                            type="button"
                            className="table-footer__button"
                            onClick={() => (data ? nextPage(data.Next.page) : {})}
                            disabled={!data || !data.Next}
                          >
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              className="table-footer__button__icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table {...getTableProps()} className="table table-bordered">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup?.headers?.map((column) => (
                            <th {...column.getHeaderProps()} style={{ fontSize: 12 }}>
                              {column.render('Header')}
                            </th>
                          ))}
                          <th style={{ fontSize: 12 }}>All Details</th>
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell) => (
                              <td {...cell.getCellProps()} style={{ fontSize: 13, fontWeight: 400 }}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                            <td>
                              <Link
                                to={`/plant-admin/pendingHpcRegistrations/${row.original.id}`}
                                className="table__button"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row middle h-200">
                <div className="d-flex-col center">
                  <FontAwesomeIcon
                    icon={faUsersSlash}
                    color="#bebebe"
                    size="9x"
                    className="noUsersIcon"
                  />
                  <p className="form__label" style={{ margin: '20px', color: '#bebebe' }}>No Records Found</p>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <LoadingModal show={isLoading} />
      {showModalForFilter ? (
        <ModalForFilter
          show={showModalForFilter}
          toggle={handleOnFilter}
          onSubmit={submitFilter}
        />
      ) : null}
    </>
  );
};

export default ApprovedRegistration;
