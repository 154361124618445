/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import Each from '../Each';
import logoImg from '../../assets/images/logo.png';

import './VideoModal.scss';
import { regionsArray } from '../../pages/Tickets/RaiseTicket/availabalearray';
import { arrayOfRegions } from '../../constants/mostUsedArrays';

const VideoModal = ({
  show, toggle, onSubmit, video,
}) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const regionName = localStorage.getItem('regionName');
  const showHideClassName = show ? 'video-modal display-block' : 'video-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (video) {
      setValue('url', video.text);
      setFileUrl(video.thumbnails || logoImg);
    }
    if (regionName) {
      setValue('regionName', regionName !== 'HYDERABAD' ? (video?.regionName || regionName) : (video?.regionName || 'HYDERABAD-1'));
    }
  }, [video, regionName]);

  const role = localStorage.getItem('role');
  // useEffect(() => {
  //   if (regionName) {
  //     setValue('regionName', regionName !== 'HYDERABAD' ? regionName : 'HYDERABAD-1');
  //   }
  // }, [setValue, regionName]);

  const fileReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width < 1280 || img.height < 700) {
          setFileError('Video Thumbnail dimensions should be 1280 x 700');
        }
      };
      img.onerror = () => {
        setFileError('Invalid image content');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = (event) => {
    const { files } = event.target;
    if (!files.length) return;
    setFileError(null);
    const objectUrl = URL.createObjectURL(files[0]);
    setThumbnail(files[0]);
    setFileUrl(objectUrl);
    if (files[0].size > 1e6) {
      setFileError('Video Thumbnail should not be >1MB');
      return;
    }
    fileReader(files[0]);
  };

  const submit = ({
    url, regionName: regName,
  }) => {
    onSubmit({ text: url, thumbnail: thumbnail || fileUrl, regionName: regName || (regionName !== 'HYDERABAD' ? regionName : 'HYDERABAD-1') });
    toggle();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="url">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Video URL</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="url"
                            name="url"
                            placeholder="Video URL"
                            {...register('url', {
                              required: {
                                value: true,
                                message: 'Video URL is required',
                              },
                            })}
                          />
                          {errors.url && <div className="form__error">{errors.url.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="regionName">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Region</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="regionName"
                            name="regionName"
                            disabled={regionName !== 'HYDERABAD'}
                            {...register('regionName', {
                              required: {
                                value: true,
                                message: 'Region name is required',
                              },
                            })}
                          >
                            {
                              role === 'hnl-admin' ? (
                                <>
                                  <option value="HNL-EMPLOYEE">HNL-EMPLOYEE</option>
                                  <option value="HNL-DISTRIBUTOR">HNL-DISTRIBUTOR</option>
                                </>
                              ) : (
                                <>
                                  <Each
                                    of={arrayOfRegions}
                                    render={(item, index) => <option value={item} key={index}>{item}</option>}
                                  />

                                </>
                              )
                            }
                          </select>
                          {errors.regionName && <div className="form__error">{errors.regionName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row center">
                      <div className="col-12 mb-4">
                        <span className="form__label">Video Thumbnail</span>
                      </div>
                      <div className="col-12">
                        <label htmlFor="thumbnail">
                          <div className="picture">
                            {fileUrl && (<img src={fileUrl} alt="product" />)}
                            <div style={{ background: fileUrl ? 'transparent' : '#55555520' }} className="picture__placeholder">
                              {!fileUrl && (
                              <FontAwesomeIcon
                                icon={faUpload}
                                className="picture__placeholder--icon"
                              />
                              )}
                            </div>
                          </div>
                          <input
                            className="form__field"
                            type="file"
                            name="thumbnail"
                            id="thumbnail"
                            onChange={handleSelectFile}
                          />
                        </label>
                        {errors.fileError && <div className="form__error">{errors.fileError}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-16">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VideoModal;
