/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './ConfirmModal.scss';

const ConfirmModal = ({
  show, toggle, onConfirm, title, text,
}) => {
  const showHideClassName = show ? 'confirm-modal display-block' : 'confirm-modal display-none';

  const confirm = () => {
    toggle();
    onConfirm();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {/* <div className="d-flex end">
          <button type="button" className="modal-main__button" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div> */}
        <div className="p-16 d-flex-col">
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
        <div className="p-16 d-flex end">
          <button type="button" className="button mr-16" onClick={toggle}>No</button>
          <button type="button" className="button primary" onClick={confirm}>Yes</button>
        </div>
      </section>
    </div>
  );
};

export default ConfirmModal;
