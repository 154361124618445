/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPen } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {
  updateNewUsersRepDetailsByType,
  getNewUserDetailsRepById,
  handleNewUserRepApproval,
  handleNewUserRepRejection,
  updateNewUserFarmerCodeDetails,
} from '../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectSingleUserData,
  selectIsLoadingApproval,
  selectIsLoadingRejection,
} from '../../store/modules/newUser/selectors';
import EditNewUserModal from '../../components/EditNewUserModal';
import EditNewUserFarmerCodeModal from '../../components/EditNewUserFarmerCodeModal';
import EditBankDetailsModal from '../../components/EditBankDetailsModal';
import LoadingModal from '../../components/LoadingModal';
import EditGpaModal from '../../components/EditGpaModal';
import './RegionalHeadApprovedRequestsById.scss';
import ModalForImageZoom from '../../components/ModalForImageZoom';

const ViewRegistaration = () => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showBankDetailsModal, setshowBankDetailsModal] = useState(false);
  const [maskAadhar, setmaskAadhar] = useState(false);
  const [showModalForGPAUsers, setshowModalForGPAUsers] = useState(false);
  const [showImageModal, setshowImageModal] = useState(false);
  const [selectedImageValues, setselectedImageValues] = useState(null);
  const [catl, setCattle] = useState(null);
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectSingleUserData(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingApproval(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingRejection(state));

  const role = localStorage.getItem('role');
  const { id } = useParams();

  useEffect(() => {
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  }, [dispatch]);

  useEffect(() => {
    const ctx = chartRef.current;
    if (!ctx) {
      return;
    }
    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          '23 Jun',
          '24 Jun',
          '25 Jun',
          '26 Jun',
          '27 Jun',
          '28 Jun',
          '29 Jun',
        ],
        // labels: milkSlipsStats && milkSlipsStats.length
        //   ? milkSlipsStats.map((milkSlipStats) => moment(milkSlipStats.date).format('DD MMM'))
        //   : null,
        datasets: [
          {
            label: '',
            data: [12, 19, 3, 5, 2, 3, 5.2],
            // data: milkSlipsStats && milkSlipsStats.length
            //   ? milkSlipsStats.map((milkSlipStats) => Number(milkSlipStats.totalQuantity))
            //   : null,
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 2,
            tension: 0.2,
          },
        ],
      },
      plugins: [
        {
          id: 'canvas_background_color',
          beforeDraw: (chart) => {
            const canvas = chart.canvas.getContext('2d');
            canvas.save();
            canvas.globalCompositeOperation = 'destination-over';
            canvas.fillStyle = '#175fad';
            canvas.fillRect(0, 0, chart.width, chart.height);
            canvas.restore();
          },
        },
      ],
      options: {
        layout: {
          padding: 20,
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
          y: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      chartInstance.destroy();
    };
  });

  const downloadImage = (e) => {
    fetch(e.link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data?.farmerName} - ${e.type}.png`); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleOnImagepressed = (img) => {
    setshowImageModal(!showImageModal);
    setselectedImageValues(img);
  };
  const reject = () => {
    const rejectData = {};
    rejectData.id = id;
    rejectData.status = 'rejected';
    dispatch(handleNewUserRepRejection(rejectData));
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };

  const approve = () => {
    const approvedData = {};
    approvedData.id = id;
    approvedData.status = 'approved';

    dispatch(handleNewUserRepApproval(approvedData));
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };

  const toggleEditUserModal = () => {
    setShowEditUserModal(!showEditUserModal);
  };

  const toggleEditLocationModal = () => {
    setShowEditLocationModal(!showEditLocationModal);
  };

  const toggleBankDetailsModal = () => {
    setshowBankDetailsModal(!showBankDetailsModal);
  };

  const toggleGpaModal = () => {
    setshowModalForGPAUsers(!showModalForGPAUsers);
  };
  const submitUser = (values) => {
    const userDetailsThatNeedToBeUpdated = {
      farmerName: values?.farmerName,
      gender: values?.gender,
      dob: values?.dob,
      phone: values?.phone,
      hpcCode: values?.hpcCode,
      aadharNumber: values?.aadharNumber,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByType(userDetailsThatNeedToBeUpdated));
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };

  const submitLocation = (values) => {
    const repUpdateData = {
      farmerCode: values?.farmerCode ? values?.farmerCode : null,
      vendorCode: values?.vendorCode ? values?.vendorCode : null,
      id,
    };
    dispatch(updateNewUserFarmerCodeDetails(repUpdateData));
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const submitBankDetails = (values) => {
    const repUpdateData = {
      bankName: values?.bankName,
      branchName: values?.branchName,
      ifscCode: values?.ifscCode,
      accountNumber: values?.accountNumber,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByType(repUpdateData));
    const dataTobeSent = {
      id,
      type: 'admin',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const submitGpaDetails = (values) => {
    const repUpdateData = {
      nomineeIdentity: values?.nomineeIdentity,
      nomineeName: values?.nomineeName,
      enrolmentId: values?.enrolmentId,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByType(repUpdateData));
    const dataTobeSent = {
      id,
      type: 'plant',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const aadharNumberTobeShown = data?.aadharNumber?.replace(
    /\d(?=\d{4})/g,
    '*',
  );
  const toggleMaskingOfAadar = () => {
    setmaskAadhar(!maskAadhar);
  };
  return (
    <>
      {!isLoading && data ? (
        <div className="view-registration">
          {
          (role === 'ea_pi' && data.createdByEAPandI === 'pending') || (role === 'regional_fa' && data.verifiedByRIFandA === 'pending') || (role === 'regional_pi' && data.approvedByRIPandI === 'pending') ? (
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  <button
                    type="button"
                    className="button danger"
                    onClick={reject}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    onClick={approve}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          ) : null
}
          <div className="row center">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="status-card">
                <div className="left">
                  1.Prepared By Plant IC :
                  {' '}
                  {data.preparedByPlantIC}
                </div>
                <div className="right">
                  2.Status at EA P&I :
                  {''}
                  {data?.createdByEAPandI}
                </div>
                <div className="left">
                  3.Status at RI P & I :
                  {' '}
                  {data.approvedByRIPandI}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">User details</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {
                          (role === 'ea_pi' && data.createdByEAPandI === 'pending') || (role === 'regional_fa' && data.verifiedByRIFandA === 'pending') || (role === 'regional_pi' && data.approvedByRIPandI === 'pending') ? (
                            <div className="col-12 d-flex end">
                              <FontAwesomeIcon
                                icon={faPen}
                                className="edit-icon"
                                onClick={toggleEditUserModal}
                              />
                            </div>
                          ) : null
                        }
                        <div className="col-12 col-lg-6 d-flex-col">
                          <div className="row right">
                            <div className="col-12 col-md-6">
                              <div className="item p-left">
                                <h4 className="title">Aadhar Card Image</h4>
                                <div
                                  className="picture"
                                  onClick={() => handleOnImagepressed({
                                    type: 'Aadhar Card',
                                    link: data?.aadharCardUrl,
                                    farmerName: data?.farmerName,
                                  })}
                                >
                                  <img
                                    src={
                                      data.aadharCardUrl
                                        ? data.aadharCardUrl
                                        : null
                                    }
                                    alt="1"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="item p-right">
                                <h4 className="title">Bank passbook Image</h4>
                                <div
                                  className="picture"
                                  onClick={() => handleOnImagepressed({
                                    type: 'Bank Passbook',
                                    link: data?.bankPassbookUrl,
                                    farmerName: data?.farmerName,
                                  })}
                                >
                                  <img
                                    src={
                                      data.bankPassbookUrl
                                        ? data.bankPassbookUrl
                                        : null
                                    }
                                    alt="2"
                                  />
                                </div>
                              </div>
                            </div>
                            {data?.panCardUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Pan Card Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'Pan Card',
                                      link: data?.panCardUrl,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data.panCardUrl ? data.panCardUrl : null
                                      }
                                      alt="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex-col">
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Name</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data.farmerName}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Phone Number</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.phone
                                  ? data?.phone?.replace('+91', '')
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Gender</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.gender ? data?.gender : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Date of Birth
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {moment(data?.dob).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Aadhar Number
                              </div>
                            </div>
                            <div
                              className="col-8"
                              onClick={() => toggleMaskingOfAadar()}
                            >
                              <div className="content__text">
                                {maskAadhar
                                  ? data?.aadharNumber
                                  : aadharNumberTobeShown}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Plant Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">{data?.plantCode ? data?.plantCode : 'N/A'}</div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">HPC Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">{data?.hpcCode ? data?.hpcCode : 'N/A'}</div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Role</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.mccType
                                  ? data?.mccType === 'hpc_farmer'
                                    ? 'HPC Farmer'
                                    : 'HPC Representative'
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex">
                      <div className="row w-100 m-h-100">
                        {!data?.farmerCodeGeneration ? (
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div>
                        ) : null}

                        <div className="col-12 col-md-6">
                          <div>
                            <>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    No Of Cows
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data.noOfCows ? data?.noOfCows : 'N/A'}
                                  </div>
                                </div>
                              </div>
                              <div className="row w-100 between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    Cow Milk Quantity
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data?.cowMilkquantity
                                      ? data?.cowMilkquantity
                                      : 'N/A'}
                                  </div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    No of Buffalo
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data?.noOfBuffalo
                                      ? data?.noOfBuffalo
                                      : 'N/A'}
                                  </div>
                                </div>
                              </div>
                              <div className="row w-100 between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    Buffalo Milk Quantity
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data?.buffaloMilkQuantity
                                      ? data?.buffaloMilkQuantity
                                      : 'N/A'}
                                  </div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    Farmer Code
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data?.farmerCode
                                      ? data?.farmerCode
                                      : 'N/A'}
                                  </div>
                                </div>
                              </div>
                              <div className="row between w-100">
                                <div className="col-4">
                                  <div className="content__title">
                                    Vendor Code
                                  </div>
                                </div>
                                <div className="col-7">
                                  <div className="content__text">
                                    {data?.vendorCode
                                      ? data?.vendorCode
                                      : 'N/A'}
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">
                                House No/ Door No
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data?.hNoDno ? data?.hNoDno : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">District</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data?.district ? data?.district : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Mandal</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data?.mandal ? data?.mandal : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Village</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data?.village ? data?.village : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">Pin Code</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data?.pinCode ? data?.pinCode : 'N/A'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">Bank details</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Bank Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.bankName ? data?.bankName : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Branch Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.branchName ? data.branchName : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">IFSC Code</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.ifscCode ? data?.ifscCode : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Account Number</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.accountNumber ? data?.accountNumber : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                          (role === 'ea_pi' && data.createdByEAPandI === 'pending') || (role === 'regional_fa' && data.verifiedByRIFandA === 'pending') || (role === 'regional_pi' && data.approvedByRIPandI === 'pending') ? (
                            <div className="col-12 d-flex end">
                              <FontAwesomeIcon
                                icon={faPen}
                                className="edit-icon"
                                onClick={toggleBankDetailsModal}
                              />
                            </div>
                          ) : null
                        }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">GPA Nominee details</h4>
                <div className="content">
                  <div className="row">

                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Nominee Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">{data?.nomineeName ? data?.nomineeName : 'N/A'}</div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Nominee Relationship</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">{data?.nomineeIdentity ? data?.nomineeIdentity : 'N/A'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Enrollment Id</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.enrolmentId ? data?.enrolmentId : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                          (role === 'ea_pi' && data.createdByEAPandI === 'pending') || (role === 'regional_fa' && data.verifiedByRIFandA === 'pending') || (role === 'regional_pi' && data.approvedByRIPandI === 'pending') ? (
                            <div className="col-12 d-flex end">
                              <FontAwesomeIcon
                                icon={faPen}
                                className="edit-icon"
                                onClick={toggleGpaModal}
                              />
                            </div>
                          ) : null
                        }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showEditUserModal ? (
        <EditNewUserModal
          show={showEditUserModal}
          toggle={toggleEditUserModal}
          onSubmit={submitUser}
          values={data}
        />
      ) : null}
      {showEditLocationModal ? (
        <EditNewUserFarmerCodeModal
          show={showEditLocationModal}
          toggle={toggleEditLocationModal}
          onSubmit={submitLocation}
          values={data}
        />
      ) : null}
      {showBankDetailsModal ? (
        <EditBankDetailsModal
          show={showBankDetailsModal}
          toggle={toggleBankDetailsModal}
          onSubmit={submitBankDetails}
          values={data}
        />
      ) : null}
      {
        showModalForGPAUsers ? (
          <EditGpaModal
            show={showModalForGPAUsers}
            toggle={toggleGpaModal}
            onSubmit={submitGpaDetails}
            values={data}
          />
        ) : null
      }
      {
        showImageModal ? (
          <ModalForImageZoom
            show={showImageModal}
            toggle={handleOnImagepressed}
            imageUrl={selectedImageValues}
          />
        ) : null
      }
      <LoadingModal show={isLoading || isLoadingFinish || isLoadingReject} />
    </>
  );
};

export default ViewRegistaration;
