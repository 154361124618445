import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx';
import {
  GET_PENDING_START,
  GET_PENDING_SUCCESS,
  GET_PENDING_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_PENDING_START,
  SEARCH_PENDING_SUCCESS,
  SEARCH_PENDING_ERROR,
  SEARCH_APPROVED_START,
  SEARCH_APPROVED_SUCCESS,
  SEARCH_APPROVED_ERROR,
  APPROVE_START,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_LOCATION_START,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  UPDATE_CATTLE_START,
  UPDATE_CATTLE_SUCCESS,
  UPDATE_CATTLE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
  SEARCH_BY_PHONE_START,
  SEARCH_BY_PHONE_SUCCESS,
  SEARCH_BY_PHONE_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  GET_EMPLOYEES_START,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_SLIPS_STATS_START,
  GET_SLIPS_STATS_SUCCESS,
  GET_SLIPS_STATS_ERROR,
  GET_SLIPS_REPORT_START,
  GET_SLIPS_REPORT_SUCCESS,
  GET_SLIPS_REPORT_ERROR,
  FILTER_FIREBASE_START,
  FILTER_FIREBASE_SUCCESS,
  FILTER_FIREBASE_ERROR,
  SEARCH_MCC_REP_BY_MCC_CODE_START,
  SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
  SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
  SEARCH_PLANT_BY_PLANT_CODE_START,
  SEARCH_PLANT_BY_PLANT_CODE_SUCCESS,
  SEARCH_PLANT_BY_PLANT_CODE_ERROR,
  TRANSFER_MCC_REP_TO_PLANT_START,
  TRANSFER_MCC_REP_TO_PLANT_SUCCESS,
  TRANSFER_MCC_REP_TO_PLANT_ERROR,
  SEARCH_CONTINUED_BY_PHONE_START,
  SEARCH_CONTINUED_BY_PHONE_SUCCESS,
  SEARCH_CONTINUED_BY_PHONE_ERROR,
  SEARCH_DISCONTINUED_BY_PHONE_START,
  SEARCH_DISCONTINUED_BY_PHONE_SUCCESS,
  SEARCH_DISCONTINUED_BY_PHONE_ERROR,
  CONTINUE_DISCONTINUE_START,
  CONTINUE_DISCONTINUE_SUCCESS,
  CONTINUE_DISCONTINUE_ERROR,
  GET_DISCONTINUED_START,
  GET_DISCONTINUED_SUCCESS,
  GET_DISCONTINUED_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getPendingUsers = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_PENDING_START });

    const res = await HttpService.post(
      `/admin/view-farmers-by-status?page=${data?.page}&limit=${data?.limit}`,
      { status: data?.status, fromDate: data?.fromDate, toDate: data?.toDate },
    );

    dispatch({
      type: GET_PENDING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getApprovedUsers = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_APPROVED_START });

    const res = await HttpService.post(
      `/admin/view-farmers-by-status?page=${data?.page}&limit=${data?.limit}`,
      { status: data?.status, fromDate: data?.fromDate, toDate: data?.toDate },
    );

    dispatch({
      type: GET_APPROVED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_APPROVED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/admin/view-farmer-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchPendingUsers = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PENDING_START });

    const res = await HttpService.post('/admin/search-farmer-request', { phone, status: 'waiting' });

    dispatch({
      type: SEARCH_PENDING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchApprovedUsers = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_APPROVED_START });

    const res = await HttpService.post('/admin/search-farmer-request', { phone, status: 'finished' });

    dispatch({
      type: SEARCH_APPROVED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_APPROVED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const approveUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: APPROVE_START });

    const res = await HttpService.post(
      `/admin/update-farmer-status/${id}`,
      { status: 'finished' },
    );

    dispatch({
      type: APPROVE_SUCCESS,
      payload: res.data,
    });

    toast.success('User approved successfully');
  } catch (error) {
    dispatch({
      type: APPROVE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const rejectUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_START });

    const res = await HttpService.post(
      `/admin/update-farmer-status/${id}`,
      { status: 'rejected' },
    );

    dispatch({
      type: REJECT_SUCCESS,
      payload: res.data,
    });
    toast.success('User rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const editUser = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_START });

    const res = await HttpService.patch(`/admin/upate-farmer/${id}`, values);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data,
    });
    toast.success('User updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const editLocation = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LOCATION_START });

    const res = await HttpService.patch(`/admin/upate-farmer/${id}`, values);

    dispatch({
      type: UPDATE_LOCATION_SUCCESS,
      payload: res.data,
    });
    toast.success('User updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_LOCATION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const editCattle = (id, farmerId, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CATTLE_START });

    const res = await HttpService.patch(`/admin/upate-cattle/${farmerId}/${id}`, values);

    dispatch({
      type: UPDATE_CATTLE_SUCCESS,
      payload: res.data,
    });
    toast.success('Cattle updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_CATTLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportPendingUsers = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/export-farmers`,
      method: 'POST',
      data: { status: 'waiting' },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Pending-Farmers-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportApprovedUsers = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/export-farmers`,
      method: 'POST',
      data: { status: 'finished' },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Approved-Farmers-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const countUsers = (values) => async (dispatch) => {
  try {
    dispatch({ type: COUNT_START });
    let string = '/admin/count-data';
    if (values?.fromDate && values?.toDate) {
      string = `${string}?fromDate=${values.fromDate}&toDate=${values.toDate}`;
    } else if (values?.fromDate) {
      string = `${string}?fromDate=${values.fromDate}`;
    } else if (values?.toDate) {
      string = `${string}?toDate=${values.toDate}`;
    }
    const res = await HttpService.get(string);

    dispatch({
      type: COUNT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchUsersByPhone = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_BY_PHONE_START });

    const res = await HttpService.post('/admin/search-farmer-request', { phone });

    dispatch({
      type: SEARCH_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchDoctorsByPhone = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_BY_PHONE_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/search-doctor-by-phone`, { phone });

    dispatch({
      type: SEARCH_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteUser = (phone) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_START });

    const res = await HttpService.post('/admin/kill-farmer', { phone });

    dispatch({
      type: DELETE_SUCCESS,
      payload: res.data,
    });
    toast.success('User deleted successfully');
  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteDoctor = (phone) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/kill-doctor`, { phone });

    dispatch({
      type: DELETE_SUCCESS,
      payload: res.data,
    });
    toast.success('User deleted successfully');
  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const usersReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-farmers`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Farmers-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const cattleReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-cattles`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Catlle-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const feedbackReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-feedbacks`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Feedback-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const appointmentsReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-appointment`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Appointments-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const consultationReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-consultations-details`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Prescription-details-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getEmployees = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYEES_START });

    const res = await HttpService.get(`/admin/view-all-employees?page=${page}&limit=${limit}`);

    dispatch({
      type: GET_EMPLOYEES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEES_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMilkSlipsStats = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: GET_SLIPS_STATS_START });

    const res = await HttpService.post(`/admin/view-periodcally-farmer-slips/${id}`, data);

    dispatch({
      type: GET_SLIPS_STATS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SLIPS_STATS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMilkSlipsReport = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: GET_SLIPS_REPORT_START });

    const res = await HttpService.post(`/admin/filter-farmer-slips/${id}`, data);

    dispatch({
      type: GET_SLIPS_REPORT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SLIPS_REPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const milkSlipsReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-slip`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Slips-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const feedReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/export-feeds`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Feed-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const cattleMilkingReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-milking`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Slips-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const cattleAndMilkingReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-cattle-with-milking`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Cattle-with-milking-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const uninstalledReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-farmers-uninstalled-app`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Uninstalled-users-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterUsers = (values) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_FIREBASE_START });

    const res = await HttpService.post('/admin/filter-farmer-under-firebase-token', values);

    dispatch({
      type: FILTER_FIREBASE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_FIREBASE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchMccRepByMccCode = (mccCode) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_MCC_REP_BY_MCC_CODE_START });

    const res = await HttpService.post(`/admin/search-mcc-rep-by-mcc-Coce/${mccCode}`);

    dispatch({
      type: SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchPlantByPlantCode = (plantCode) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PLANT_BY_PLANT_CODE_START });

    const res = await HttpService.post(`/admin/search-plant-admin-by-plant-Coce/${plantCode}`);

    dispatch({
      type: SEARCH_PLANT_BY_PLANT_CODE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PLANT_BY_PLANT_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const transferMccRepToPlant = (values) => async (dispatch) => {
  try {
    dispatch({ type: TRANSFER_MCC_REP_TO_PLANT_START });

    const res = await HttpService.patch('/admin/transfer-mcc-rep-and-farmer', values);

    dispatch({
      type: TRANSFER_MCC_REP_TO_PLANT_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  } catch (error) {
    dispatch({
      type: TRANSFER_MCC_REP_TO_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchContinuedUsersByPhone = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_CONTINUED_BY_PHONE_START });

    const res = await HttpService.post(`/admin/search-continue-or-discontinue-user-by-phone/${phone}`, { discontinue: false });

    dispatch({
      type: SEARCH_CONTINUED_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_CONTINUED_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchDiscontinuedUsersByPhone = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_DISCONTINUED_BY_PHONE_START });

    const res = await HttpService.post(`/admin/search-continue-or-discontinue-user-by-phone/${phone}`, { discontinue: true });

    dispatch({
      type: SEARCH_DISCONTINUED_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_DISCONTINUED_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const discontinueUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CONTINUE_DISCONTINUE_START });

    const res = await HttpService.post(`/admin/continue-or-discontinue-user/${userId}`, { discontinue: true });

    dispatch({
      type: CONTINUE_DISCONTINUE_SUCCESS,
      payload: res.data,
    });
    toast.success('User discontinued successfully');
  } catch (error) {
    dispatch({
      type: CONTINUE_DISCONTINUE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const restoreUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CONTINUE_DISCONTINUE_START });

    const res = await HttpService.post(`/admin/continue-or-discontinue-user/${userId}`, { discontinue: false });

    dispatch({
      type: CONTINUE_DISCONTINUE_SUCCESS,
      payload: res.data,
    });
    toast.success('User restored successfully');
  } catch (error) {
    dispatch({
      type: CONTINUE_DISCONTINUE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const viewDiscontinuedUsers = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_DISCONTINUED_START });

    const res = await HttpService.post(
      `/admin/view-continue-or-discontinue-users?page=${page}&limit=${limit}`,
      { discontinue: true },
    );

    dispatch({
      type: GET_DISCONTINUED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_DISCONTINUED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const digitalRegistrationsReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-digitalRegistrations`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-DIGITAL-FARMER-REGISTRATIONS-${moment().format('YYYY-MM-DD')}.xlsx`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const loanRequestReport = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/loan-request-details`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const blob = new Blob([res.data]);
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Now you have the Excel data in the 'workbook' object
      // You can manipulate and convert it to text as needed

      // Example: Convert the first sheet to CSV text
      const csvText = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

      // Create a Blob with the CSV text
      const txtBlob = new Blob([csvText], { type: 'text/plain' });
      const txtUrl = window.URL.createObjectURL(txtBlob);

      const link = document.createElement('a');
      link.href = txtUrl;
      link.setAttribute('download', 'Enquiry.TXT');
      document.body.appendChild(link);
      link.click();

      dispatch({
        type: EXPORT_SUCCESS,
      });
    };

    reader.readAsArrayBuffer(blob);
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const generateReportForMcc = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });
    const baseUrl = `${config?.apiUrl}/mccFarmer/getAllMccFarmers?flag=export`;
    const response = await axios({
      url: `${baseUrl}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `MCC_FARMERS_REPORT-${moment().format('YYYY-MM-DD')}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();
    if (response?.status === 200) {
      dispatch({
        type: EXPORT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};
