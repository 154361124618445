/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';

import './VariantsModal.scss';

const VariantsModal = ({
  show, toggle, onSubmit, product,
}) => {
  const [pallets, setPallets] = useState({ pallet: null, mash: null });
  const showHideClassName = show ? 'variants-modal display-block' : 'variants-modal display-none';
  const {
    register, control, handleSubmit, watch, formState: { errors },
  } = useForm();
  const watchPallet = watch('palletType');

  const saveAttributes = ({
    palletType,
    palletSize,
    palletPacking,
    price,
    discount,
  }) => {
    setPallets({
      ...pallets,
      [palletType]: {
        palletType,
        palletSize,
        palletPacking,
        price,
        discount,
      },
    });
  };

  const submit = ({
    quantity,
    palletPacking,
    price,
    discount,
  }) => {
    if (product.prodCategory === 'feed') {
      onSubmit({
        quantity,
        pallets: Object.values(Object.keys(pallets)
          .filter((k) => pallets[k] != null)
          .reduce((a, k) => ({ ...a, [k]: pallets[k] }), {})),
      });
    } else {
      onSubmit({
        quantity,
        pallets: [{
          palletPacking,
          price,
          discount,
        }],
      });
    }

    toggle();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Quantity</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="quantity"
                            name="quantity"
                            placeholder="Quantity"
                            {...register('quantity', {
                              required: {
                                value: true,
                                message: 'Quantity is required',
                              },
                            })}
                          />
                          {errors.quantity && <div className="form__error">{errors.quantity.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {product && product.prodCategory === 'feed' ? (
                  <div className="col-12 col-md-6">
                    <div className="form__item">
                      <label htmlFor="palletType">
                        <div className="row middle">
                          <div className="col-12 mb-4">
                            <span className="form__label">Pallet</span>
                          </div>
                          <div className="col-12">
                            <select
                              className="form__field"
                              id="palletType"
                              name="palletType"
                              {...register('palletType', {
                                required: {
                                  value: true,
                                  message: 'Pallet is required',
                                },
                              })}
                            >
                              <option value={null} selected disabled>Pallet Type</option>
                              <option value="pallet">Pallet</option>
                              <option value="mash">Mash</option>
                            </select>
                            {errors.palletType && <div className="form__error">{errors.palletType.message}</div>}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="palletPacking">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Packing</span>
                        </div>
                        <div className="col-12">
                          <Controller
                            name="palletPacking"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'Packing is required',
                              },
                            }}
                            render={({ field }) => (
                              <input
                                className="form__field"
                                type="text"
                                id="palletPacking"
                                name="palletPacking"
                                placeholder="Packing"
                                {...field}
                                value={pallets[watchPallet] ? pallets[watchPallet].palletPacking : field.value}
                              />
                            )}
                          />
                          {errors.palletPacking && <div className="form__error">{errors.palletPacking.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {product && product.prodCategory === 'feed' ? (
                  <div className="col-12 col-md-6">
                    <div className="form__item">
                      <label htmlFor="palletSize">
                        <div className="row middle">
                          <div className="col-12 mb-4">
                            <span className="form__label">Pallet Size</span>
                          </div>
                          <div className="col-12">
                            <Controller
                              name="palletSize"
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Pallet Size is required',
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  className="form__field"
                                  type="text"
                                  id="palletSize"
                                  name="palletSize"
                                  placeholder="Pallet Size"
                                  {...field}
                                  value={pallets[watchPallet] ? pallets[watchPallet].palletSize : field.value}
                                />
                              )}
                            />
                            {errors.palletSize && <div className="form__error">{errors.palletSize.message}</div>}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="price">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Price</span>
                        </div>
                        <div className="col-12">
                          <Controller
                            name="price"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'Price is required',
                              },
                            }}
                            render={({ field }) => (
                              <input
                                className="form__field"
                                type="text"
                                id="price"
                                name="price"
                                placeholder="Price"
                                {...field}
                                value={pallets[watchPallet] ? pallets[watchPallet].price : field.value}
                              />
                            )}
                          />
                          {errors.price && <div className="form__error">{errors.price.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="palletPacking">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Discount %</span>
                        </div>
                        <div className="col-12">
                          <Controller
                            name="discount"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form__field"
                                type="text"
                                id="discount"
                                name="discount"
                                placeholder="Discount percent"
                                {...field}
                                value={pallets[watchPallet] ? pallets[watchPallet].discount : field.value}
                              />
                            )}
                          />
                          {errors.discount && <div className="form__error">{errors.discount.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {product && product.prodCategory === 'feed' ? (
                  <>
                    <div className="col-12 d-flex middle">
                      <div className="form__item">
                        <button type="button" className="form__variant-button" onClick={handleSubmit(saveAttributes)}>
                          Save Attributes
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="form__helper">
                        <strong>Note: </strong>
                        Add all required attributes by hitting
                        {' '}
                        <strong>Save Attributes</strong>
                        {' '}
                        and then add the variant
                      </p>
                    </div>
                  </>
                ) : null}
                <div className="col-12 mt-16">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" onClick={handleSubmit(submit)} disabled={product.prodCategory === 'feed' && !pallets.pallet && !pallets.mash}>Add Variant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VariantsModal;
