import {
  GET_UPCOMING_START,
  GET_UPCOMING_SUCCESS,
  GET_UPCOMING_ERROR,
  GET_PAST_HELD_START,
  GET_PAST_HELD_SUCCESS,
  GET_PAST_HELD_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_UPCOMING_START,
  SEARCH_UPCOMING_SUCCESS,
  SEARCH_UPCOMING_ERROR,
  SEARCH_PAST_HELD_START,
  SEARCH_PAST_HELD_SUCCESS,
  SEARCH_PAST_HELD_ERROR,
  FINISH_START,
  FINISH_SUCCESS,
  FINISH_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPLOAD_PRESCRIPTION_START,
  UPLOAD_PRESCRIPTION_SUCCESS,
  UPLOAD_PRESCRIPTION_ERROR,
  GET_FOLLOW_UP_START,
  GET_FOLLOW_UP_SUCCESS,
  GET_FOLLOW_UP_ERROR,
  SET_FOLLOW_UP_START,
  SET_FOLLOW_UP_SUCCESS,
  SET_FOLLOW_UP_ERROR,
  GET_ALL_COUNT_START,
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_ERROR,
  GET_ALL_COUNT_PHYSICAL_START,
  GET_ALL_COUNT_PHYSICAL_SUCCESS,
  GET_ALL_COUNT_PHYSICAL_ERROR,
} from './types';

const initialState = {
  upcoming: null,
  pastHeld: null,
  followUp: null,
  appointment: null,
  prescription: null,
  message: null,
  isLoading: false,
  isLoadingFinish: false,
  isLoadingReject: false,
  isLoadingFollowUp: false,
  errorMessage: null,
  count: null,
  isLoadingCount: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_UPCOMING_START:
      return {
        ...state,
        errorMessage: null,
        upcoming: null,
        isLoading: true,
      };

    case GET_UPCOMING_SUCCESS:
      return {
        ...state,
        upcoming: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        pastHeld: null,
        isLoading: false,
      };

    case GET_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeld: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        appointment: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_UPCOMING_SUCCESS:
      return {
        ...state,
        upcoming: { paginate: payload },
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeld: { paginate: payload },
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case FINISH_START:
      return { ...state, errorMessage: null, isLoadingFinish: true };

    case FINISH_SUCCESS:
      return {
        ...state,
        appointment: payload || null,
        errorMessage: null,
        isLoadingFinish: false,
      };

    case FINISH_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingFinish: false,
      };

    case REJECT_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_SUCCESS:
      return {
        ...state,
        appointment: payload || null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };

    case UPLOAD_PRESCRIPTION_START:
      return {
        ...state,
        message: null,
        errorMessage: null,
        isLoadingPrescription: true,
      };

    case UPLOAD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        message: payload.message,
        prescription: payload.data || null,
        errorMessage: null,
        isLoadingPrescription: false,
      };

    case UPLOAD_PRESCRIPTION_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingPrescription: false,
      };

    case GET_FOLLOW_UP_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUp: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_FOLLOW_UP_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SET_FOLLOW_UP_START:
      return { ...state, errorMessage: null, isLoadingFollowUp: true };

    case SET_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        appointment: payload || null,
        errorMessage: null,
        isLoadingFollowUp: false,
      };

    case SET_FOLLOW_UP_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingFollowUp: false,
      };
    case GET_ALL_COUNT_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };

    case GET_ALL_COUNT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };
    case GET_ALL_COUNT_PHYSICAL_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case GET_ALL_COUNT_PHYSICAL_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };

    case GET_ALL_COUNT_PHYSICAL_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };

    default:
      return state;
  }
};
