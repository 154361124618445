/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSignature,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { clearSignatureInLocal } from '../../store/modules/newUser/actions';
import './DigitalSignatureModal.scss';

const DigitalSignatureModal = ({ show, toggle, onSubmit }) => {
  const dispatch = useDispatch();
  const [showTypesDigitalSignature, setshowTypesDigitalSignature] = useState(
    false,
  );
  const [canvasColorSelector, setcanvasColorSelector] = useState('blue');
  const [fontStyle, setfontStyle] = useState("'Comic Sans MS', cursive");
  const [signature, setsignature] = useState(null);
  const sigCanvas = useRef({});
  const inputRef = useRef(null);
  const collorArray = [
    { id: 0, name: 'red', color: 'red' },
    { id: 1, name: 'blue', color: 'blue' },
    { id: 2, name: 'black', color: 'black' },
    { id: 3, name: 'green', color: 'green' },
    { id: 4, name: 'purple', color: 'purple' },
    { id: 5, name: 'yellow', color: 'yellow' },
    { id: 6, name: 'orange', color: 'orange' },
    { id: 7, name: 'indigo', color: 'indigo' },
  ];
  const fontsArray = [
    { id: 0, name: 'Cursive', fontFamily: "'Comic Sans MS', cursive" },
    {
      id: 1,
      name: 'Helvetica',
      fontFamily: "'Helvetica', sans-serif",
    },
    { id: 2, name: 'Courier', fontFamily: "'Courier New', monospace" },
    { id: 3, name: 'Times New Roman', fontFamily: "'Times New Roman', serif" },
    { id: 4, name: 'Calibri', fontFamily: "'Calibri', sans-serif" },
    { id: 5, name: 'Arial', fontFamily: "'Arial', sans-serif" },
    { id: 6, name: 'Impact', fontFamily: "'Impact', sans-serif" },
    { id: 7, name: 'Palatino', fontFamily: "'Palatino', serif" },
    { id: 8, name: 'Tahoma', fontFamily: "'Tahoma', sans-serif" },
    { id: 9, name: 'Georgia', fontFamily: "'Georgia', serif" },
  ];
  const modalStyles = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  const modalContentStyles = {
    position: 'relative',
    width: '90%',
    maxWidth: 500,
    maxHeight: '80vh',
    background: '#fff',
    borderRadius: 10,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#175fad',
    background: '#f1f6f8',
    border: 'none',
    borderBottomLeftRadius: '50%',
    padding: '0.5rem',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    outline: 'none',
    cursor: 'pointer',
    zIndex: 99999,
    transition: 'all 0.3s ease',
  };

  const sideBarStyles = { display: 'flex', flexDirection: 'column' };
  const controlsStyles = {
    backgroundColor: '#f1f6f8',
  };
  const buttonStyles = {
    background: '#f1f6f8',
    color: '#175fad',
    fontFamily: 'cursive',
    border: 'none',
    borderRadius: 30,
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    cursor: 'pointer',
  };
  const canvasStyles = { width: 500, height: 200 };
  const textStyles = {
    lineHeight: 1,
    fontSize: '14px',
    padding: '0.2rem 0.2rem',
    margin: '0.5rem',
    fontWeight: 600,
    fontStyle: 'cursive',
  };
  const textStylesWithUnderLine = {
    lineHeight: 1,
    fontSize: '14px',
    padding: '0.2rem 0.2rem',
    margin: '0.5rem',
    fontWeight: 600,
    fontStyle: 'cursive',
    textDecoration: 'underline',
  };
  const textFieldContainer = {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    height: 220,
    marginVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  };

  const signatureColorPicker = {
    border: 'none',
    borderRadius: 50,
    padding: '0.5rem 1rem',
    margin: '0.5rem',
    cursor: 'pointer',
  };
  const bottomButtons = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  };
  const inputStyle = {
    fontFamily: fontStyle,
    border: '0px',
    borderBottom: '1px solid',
    fontSize: '15px',
    marginBottom: '10px',
  };
  const handleOnCanvasColorSelector = (values) => {
    setcanvasColorSelector(values);
  };

  const saveCanvasImage = () => {
    // dispatch(storeSignatureInLocal(sigCanvas?.current?.toDataURL('image/png')));
    onSubmit(sigCanvas?.current?.toDataURL('image/png'));
    toggle();
  };
  const clearCanvasImage = () => {
    dispatch(clearSignatureInLocal());
    sigCanvas.current.clear();
  };
  const handleOnFontChange = (val) => {
    setfontStyle(val.target.value);
  };

  const convertToPNG = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const signatureFont = `20px ${fontStyle}`;
    const inputElement = inputRef.current;
    const inputWidth = inputElement.offsetWidth;
    const inputHeight = inputElement.offsetHeight;
    canvas.width = inputWidth;
    canvas.height = inputHeight;
    ctx.fillStyle = '#ffffff'; // Set background color, if needed
    ctx.fillRect(0, 0, inputWidth, inputHeight);
    ctx.font = signatureFont; // Set font style
    ctx.fillStyle = canvasColorSelector; // Set text color
    ctx.fillText(inputElement.value, 0, 16); // Render the input text
    const pngUrl = canvas.toDataURL('image/png');
    // dispatch(storeSignatureInLocal(pngUrl));
    onSubmit(pngUrl);
    // const downloadLink = document.createElement('a');
    // downloadLink.href = pngUrl;
    // downloadLink.download = 'signature.png';
    // downloadLink.click();
    toggle();
  };
  return (
    <div className="modal" style={modalStyles}>
      <div className="modal-content" style={modalContentStyles}>
        <div>
          <button
            type="button"
            className="close-btn"
            onClick={toggle}
            style={closeButtonStyles}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div style={sideBarStyles}>
          <div style={controlsStyles}>
            <button
              style={buttonStyles}
              onClick={() => setshowTypesDigitalSignature(false)}
              type="button"
            >
              <FontAwesomeIcon icon={faSignature} />
              <i style={textStyles}>Digital Canvas</i>
            </button>
            <button
              style={buttonStyles}
              onClick={() => setshowTypesDigitalSignature(true)}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
              <i style={textStyles}>Field input</i>
            </button>
          </div>
          <div>
            {showTypesDigitalSignature ? (
              <div style={textFieldContainer}>
                <div className="col-5 col-md-6">
                  <div>
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <div className="col-12">
                            <span className="form__label">Enter Signature</span>
                          </div>
                          <input
                            placeholder="Type here..."
                            value={signature}
                            ref={inputRef}
                            type="text"
                            className="form__field"
                            style={inputStyle}
                            onChange={(e) => setsignature(e?.target?.value)}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Select Font</span>
                        </div>
                        <div className="col-12">
                          <select
                            onChange={handleOnFontChange}
                            placeholder="Select Relationship"
                            style={inputStyle}
                          >
                            <option value="" hidden>
                              Choose Font
                            </option>
                            {fontsArray.map((item) => (
                              <option
                                key={item.id}
                                value={item.fontFamily}
                                style={{ fontFamily: item?.fontFamily }}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </label>
                    <div style={bottomButtons}>
                      {collorArray?.map((item) => (
                        <button
                          key={item?.id}
                          type="button"
                          onClick={() => handleOnCanvasColorSelector(item?.color)}
                          style={{
                            ...signatureColorPicker,
                            backgroundColor: item?.color,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <button
                  onClick={convertToPNG}
                  type="button"
                  style={buttonStyles}
                >
                  Save Signature
                </button>
              </div>
            ) : (
              <div>
                <i style={textStylesWithUnderLine}>
                  You can doodle your signature here:
                </i>
                <SignatureCanvas
                  penColor={canvasColorSelector}
                  ref={sigCanvas}
                  canvasProps={canvasStyles}
                />
                <div style={bottomButtons}>
                  {collorArray?.map((item) => (
                    <button
                      key={item?.id}
                      type="button"
                      onClick={() => handleOnCanvasColorSelector(item?.color)}
                      style={{
                        ...signatureColorPicker,
                        backgroundColor: item?.color,
                      }}
                    />
                  ))}
                </div>
                <div style={bottomButtons}>
                  <button
                    onClick={saveCanvasImage}
                    type="button"
                    style={buttonStyles}
                  >
                    Save
                  </button>
                  <button
                    onClick={clearCanvasImage}
                    type="button"
                    style={buttonStyles}
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalSignatureModal;
