/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faFileExcel,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import { getPastHeldAppointments, searchPastHeldAppointments } from '../../store/modules/appointment/actions';
import {
  selectPastHeldAppointments, selectIsLoading,
} from '../../store/modules/appointment/selectors';
import './PastHeld.scss';

const PastHeld = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [fromDate, setFromDate] = useState(new Date(1990, 0, 1));
  const [toDate, setToDate] = useState(new Date());
  const data = useSelector((state) => selectPastHeldAppointments(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const columns = useMemo(
    () => [
      {
        Header: 'Appointment ID',
        accessor: 'id',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'User Name',
        accessor: 'Farmer.farmerName',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Description by farmer',
        accessor: 'description',
      },
      {
        Header: 'Unit Code',
        accessor: 'Farmer.unitCode',
      },
      {
        Header: 'Treatment Provided',
        accessor: 'Medical.treatment',
      },
      {
        Header: 'Village Name',
        accessor: 'Farmer.villageName',
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(getPastHeldAppointments());
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:
    data && data.paginate && data.paginate.length
      ? data.paginate.map((item) => (
        {
          ...item,
          date: moment(item.appointmentDate).format('DD/MM/YYYY'),
          age: moment(item.Farmer?.age).format('DD/MM/YYYY'),
          phone: item.Farmer?.phone.replace('+91', ''),
        }
      ))
      : [],
  });

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchPastHeldAppointments(`+91${query}`));
  };

  const nextPage = (page) => {
    dispatch(getPastHeldAppointments(page));
  };

  const previousPage = (page) => {
    dispatch(getPastHeldAppointments(page));
  };

  return (
    <>
      <div className="past-held">
        {/* <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarAlt} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Upcoming
                </span>
                <span className="summary-card__value">
                  50
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card info mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarAlt} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Follow-Ups
                </span>
                <span className="summary-card__value">
                  30
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card success mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCheckCircle} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Finished
                </span>
                <span className="summary-card__value">
                  40
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card danger">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faTimesCircle} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Rejcted
                </span>
                <span className="summary-card__value">
                  40
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row middle">
          <div className="col-12 col-md-12">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          {/* <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button green">
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div> */}
        </div>
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              {/* <div className="table-header">
                <div className="filter-form">
                  <div className="row center">
                    <div className="col-12 col-md-2">
                      <div className="filter-form__item">
                        <div className="row center">
                          <div className="col-6">
                            <span className="filter-form__label">Filter By</span>
                          </div>
                          <div className="col-6">
                            <span className="filter-form__label">Date</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="filter-form__item">
                        <div className="row between">
                          <div className="col-12 col-md-2">
                            <span className="filter-form__label">From</span>
                          </div>
                          <div className="col-12 col-md-9">
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => setFromDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="filter-form__field"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="filter-form__item">
                        <div className="row middle">
                          <div className="col-12 col-md-2">
                            <span className="filter-form__label">To</span>
                          </div>
                          <div className="col-12 col-md-9">
                            <DatePicker
                              selected={toDate}
                              onChange={(date) => setToDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="filter-form__field"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          <button type="button" className="filter-form__button">Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="table-footer">
                <div className="row middle">
                  <div className="col-12 col-md-6">
                    <p className="table-footer__title">
                      {/* {data && data.Previous
                        ? ((data.Previous.page + 1) * data.Previous.limit - data.Previous.limit)
                             + 1
                        : 1}
                      {' '}
                      to
                      {' '}
                      {data && data.Previous
                        ? (data.Previous.page + 1) * data.Previous.limit
                        : 10}
                      {' '}
                      of 1 */}
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="table-footer__buttons">
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => previousPage(1)}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? previousPage(data.Previous.page) : {})}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          className="table-footer__button__iclon"
                        />
                      </button>
                      <div className="table-footer__label">
                        {data && data.Next
                          ? data.Next.page - 1
                          : data && data.Previous
                            ? data.Previous.page + 1
                            : 1}
                        {/* /1 */}
                      </div>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table {...getTableProps()} className="table table-bordered">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                      <th>View</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        <td>
                          <Link to={`/doctor/appointments/${row.original.id}`} className="table__button">View</Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="row">
          {data && data.paginate && data.paginate.length
            ? data.paginate.map((appointment) => (
              <div key={appointment.id} className="col-12 col-lg-12">
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img src={appointment.Farmer ?
                        appointment.Farmer.profilePicture : ''} alt="user" />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Farmer Name :
                          {' '}
                        </span>
                        {appointment.Farmer ? appointment.Farmer.farmerName : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Phone :
                          {' '}
                        </span>
                        {appointment.Farmer ? appointment.Farmer.phone.replace('+91', '') : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Date :
                          {' '}
                        </span>
                        {moment(appointment.appointmentDate).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Time :
                          {' '}
                        </span>
                        {appointment.appointmentStartTime}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <Link to={`/doctor/appointments/${appointment.id}`}
                    className="card__button">View</Link>
                    <div
                      className={`card__text--${appointment.status === 'finished'
                      ? 'primary' : appointment.status === 'rejected' ? 'danger' : ''}`}
                    >
                      <span
                        className="card__title"
                      >
                        Status :
                        {' '}
                      </span>
                      {appointment.status}
                    </div>
                  </div>
                </div>
              </div>
            ))
            : (
              null
            )}
          {!isLoading && !data && (
            <div className="col-12 col-lg-12 d-flex-col middle">
              <div className="notFound">
                <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                <span className="notFound__text">No past held appointments found</span>
              </div>
            </div>
          )}
        </div>
        {data
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Previous}
                    onClick={() => previousPage(data.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {data.Next
                      ? data.Next.page - 1
                      : data.Previous
                        ? data.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Next}
                    onClick={() => nextPage(data.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null} */}
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default PastHeld;
