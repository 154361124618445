import {
  StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  col6: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '50%',
    position: 'relative',
    width: '100%',
    maxWidth: '50%',
  },
  col12: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
  },
  end: {
    alignItems: 'flex-end',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: '#FFF',
  },
  prescription: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#222',
  },
  item: {
    padding: 8,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#222',
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    height: 80,
    width: 80,
  },
  logoImg: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    objectFit: 'cover',
    color: 'transparent',
    textIndent: 10000,
  },
  address: {
    paddingLeft: 8,
  },
  title: {
    fontSize: 16,
    color: '#222',
    fontFamily: 'Helvetica-Bold',
  },
  subtitle: {
    fontSize: 14,
    color: '#222',
    fontFamily: 'Helvetica-Bold',
  },
  text: {
    fontSize: 16,
    color: '#222',
    fontFamily: 'Helvetica',
  },
  tableContainer: {
    width: '100%',
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {

  },
  tr: {
    // borderBottom: "1 solid black",
    // borderRight: "1 solid black",
    // borderLeft: "1 solid black",
    // borderTop: "1 solid black",
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'stretch',
  },
  th: {
    flex: 1,
    justifyContent: 'stretch',
    textAlign: 'center',
    fontSize: 14,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#f1f6f8',
    verticalAlign: 'bottom',
    backgroundColor: '#175fad',
    color: '#FFF',
    fontFamily: 'Times-Bold',
  },
  td: {
    flex: 1,
    justifyContent: 'stretch',
    textAlign: 'left',
    fontSize: 12,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#f1f6f8',
    verticalAlign: 'center',
    backgroundColor: 'rgba(18 50 255 1)',
    color: '#000',
    fontFamily: 'Times-Roman',
  },
});

export default styles;
