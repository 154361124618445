const prefix = 'ORDER';

export const GET_UPCOMING_START = `${prefix}_GET_UPCOMING_START`;
export const GET_UPCOMING_SUCCESS = `${prefix}_GET_UPCOMING_SUCCESS`;
export const GET_UPCOMING_ERROR = `${prefix}_GET_UPCOMING_ERROR`;

export const GET_PAST_HELD_START = `${prefix}_GET_PAST_HELD_START`;
export const GET_PAST_HELD_SUCCESS = `${prefix}_GET_PAST_HELD_SUCCESS`;
export const GET_PAST_HELD_ERROR = `${prefix}_GET_PAST_HELD_ERROR`;

export const GET_ALL_START = `${prefix}_GET_ALL_START`;
export const GET_ALL_SUCCESS = `${prefix}_GET_ALL_SUCCESS`;
export const GET_ALL_ERROR = `${prefix}_GET_ALL_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const GET_MCC_UPCOMING_START = `${prefix}_GET_MCC_UPCOMING_START`;
export const GET_MCC_UPCOMING_SUCCESS = `${prefix}_GET_MCC_UPCOMING_SUCCESS`;
export const GET_MCC_UPCOMING_ERROR = `${prefix}_GET_MCC_UPCOMING_ERROR`;

export const GET_MCC_PAST_HELD_START = `${prefix}_GET_MCC_PAST_HELD_START`;
export const GET_MCC_PAST_HELD_SUCCESS = `${prefix}_GET_MCC_PAST_HELD_SUCCESS`;
export const GET_MCC_PAST_HELD_ERROR = `${prefix}_GET_MCC_PAST_HELD_ERROR`;

export const GET_MCC_SINGLE_START = `${prefix}_GET_MCC_SINGLE_START`;
export const GET_MCC_SINGLE_SUCCESS = `${prefix}_GET_MCC_SINGLE_SUCCESS`;
export const GET_MCC_SINGLE_ERROR = `${prefix}_GET_MCC_SINGLE_ERROR`;

export const GET_PLANT_UPCOMING_START = `${prefix}_GET_PLANT_UPCOMING_START`;
export const GET_PLANT_UPCOMING_SUCCESS = `${prefix}_GET_PLANT_UPCOMING_SUCCESS`;
export const GET_PLANT_UPCOMING_ERROR = `${prefix}_GET_PLANT_UPCOMING_ERROR`;

export const GET_PLANT_PAST_HELD_START = `${prefix}_GET_PLANT_PAST_HELD_START`;
export const GET_PLANT_PAST_HELD_SUCCESS = `${prefix}_GET_PLANT_PAST_HELD_SUCCESS`;
export const GET_PLANT_PAST_HELD_ERROR = `${prefix}_GET_PLANT_PAST_HELD_ERROR`;

export const GET_PLANT_SINGLE_START = `${prefix}_GET_PLANT_SINGLE_START`;
export const GET_PLANT_SINGLE_SUCCESS = `${prefix}_GET_PLANT_SINGLE_SUCCESS`;
export const GET_PLANT_SINGLE_ERROR = `${prefix}_GET_PLANT_SINGLE_ERROR`;

export const SEARCH_UPCOMING_START = `${prefix}_SEARCH_UPCOMING_START`;
export const SEARCH_UPCOMING_SUCCESS = `${prefix}_SEARCH_UPCOMING_SUCCESS`;
export const SEARCH_UPCOMING_ERROR = `${prefix}_SEARCH_UPCOMING_ERROR`;

export const SEARCH_PAST_HELD_START = `${prefix}_SEARCH_PAST_HELD_START`;
export const SEARCH_PAST_HELD_SUCCESS = `${prefix}_SEARCH_PAST_HELD_SUCCESS`;
export const SEARCH_PAST_HELD_ERROR = `${prefix}_SEARCH_PAST_HELD_ERROR`;

export const FINISH_MCC_START = `${prefix}_FINISH_MCC_START`;
export const FINISH_MCC_SUCCESS = `${prefix}_FINISH_MCC_SUCCESS`;
export const FINISH_MCC_ERROR = `${prefix}_FINISH_MCC_ERROR`;

export const REJECT_MCC_START = `${prefix}_REJECT_MCC_START`;
export const REJECT_MCC_SUCCESS = `${prefix}_REJECT_MCC_SUCCESS`;
export const REJECT_MCC_ERROR = `${prefix}_REJECT_MCC_ERROR`;

export const FINISH_PLANT_START = `${prefix}_FINISH_PLANT_START`;
export const FINISH_PLANT_SUCCESS = `${prefix}_FINISH_PLANT_SUCCESS`;
export const FINISH_PLANT_ERROR = `${prefix}_FINISH_PLANT_ERROR`;

export const REJECT_PLANT_START = `${prefix}_REJECT_PLANT_START`;
export const REJECT_PLANT_SUCCESS = `${prefix}_REJECT_PLANT_SUCCESS`;
export const REJECT_PLANT_ERROR = `${prefix}_REJECT_PLANT_ERROR`;

export const CREATE_START = `${prefix}_CREATE_START`;
export const CREATE_SUCCESS = `${prefix}_CREATE_SUCCESS`;
export const CREATE_ERROR = `${prefix}_CREATE_ERROR`;

export const EXPORT_MCC_UPCOMING_START = `${prefix}_EXPORT_MCC_UPCOMING_START`;
export const EXPORT_MCC_UPCOMING_SUCCESS = `${prefix}_EXPORT_MCC_UPCOMING_SUCCESS`;
export const EXPORT_MCC_UPCOMING_ERROR = `${prefix}_EXPORT_MCC_UPCOMING_ERROR`;

export const EXPORT_MCC_PAST_HELD_START = `${prefix}_EXPORT_MCC_PAST_HELD_START`;
export const EXPORT_MCC_PAST_HELD_SUCCESS = `${prefix}_EXPORT_MCC_PAST_HELD_SUCCESS`;
export const EXPORT_MCC_PAST_HELD_ERROR = `${prefix}_EXPORT_MCC_PAST_HELD_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;

export const EXPORT_PLANT_START = `${prefix}_EXPORT_PLANT_START`;
export const EXPORT_PLANT_SUCCESS = `${prefix}_EXPORT_PLANT_SUCCESS`;
export const EXPORT_PLANT_ERROR = `${prefix}_EXPORT_PLANT_ERROR`;

export const EDIT_MCC_ORDER_DETAILS_START = `${prefix}_EDIT_MCC_ORDER_DETAILS_START`;
export const EDIT_MCC_ORDER_DETAILS_SUCCESS = `${prefix}_EDIT_MCC_ORDER_DETAILS_SUCCESS`;
export const EDIT_MCC_ORDER_DETAILS_ERROR = `${prefix}_EDIT_MCC_ORDER_DETAILS_ERROR`;

export const REMOVE_MCC_ORDER_DETAILS_START = `${prefix}_REMOVE_MCC_ORDER_DETAILS_START`;
export const REMOVE_MCC_ORDER_DETAILS_SUCCESS = `${prefix}_REMOVE_MCC_ORDER_DETAILS_SUCCESS`;
export const REMOVE_MCC_ORDER_DETAILS_ERROR = `${prefix}_REMOVE_MCC_ORDER_DETAILS_ERROR`;

export const ADD_MCC_ORDER_DETAILS_START = `${prefix}_ADD_MCC_ORDER_DETAILS_START`;
export const ADD_MCC_ORDER_DETAILS_SUCCESS = `${prefix}_ADD_MCC_ORDER_DETAILS_SUCCESS`;
export const ADD_MCC_ORDER_DETAILS_ERROR = `${prefix}_ADD_MCC_ORDER_DETAILS_ERROR`;

export const GET_COUNT_DETAILS_START = `${prefix}_GET_COUNT_DETAILS_START`;
export const GET_COUNT_DETAILS_SUCCESS = `${prefix}_GET_COUNT_DETAILS_SUCCESS`;
export const GET_COUNT_DETAILS_ERROR = `${prefix}_GET_COUNT_DETAILS_ERROR`;

export const GET_OHS_VALUE_START = `${prefix}_GET_OHS_VALUE_START`;
export const GET_OHS_VALUE_SUCCESS = `${prefix}_GET_OHS_VALUE_SUCCESS`;
export const GET_OHS_VALUE_ERROR = `${prefix}_GET_OHS_VALUE_ERROR`;

export const GET_FARMER_CODE_START = `${prefix}_GET_FARMER_CODE_START`;
export const GET_FARMER_CODE_SUCCESS = `${prefix}_GET_FARMER_CODE_SUCCESS`;
export const GET_FARMER_CODE_ERROR = `${prefix}_GET_FARMER_CODE_ERROR`;

export const STORE_VALUES_START = `${prefix}_STORE_VALUES_START`;

export const GET_BATCHNO_START = `${prefix}_GET_BATCHNO_START`;
export const GET_BATCHNO_SUCCESS = `${prefix}_GET_BATCHNO_SUCCESS`;
export const GET_BATCHNO_ERROR = `${prefix}_GET_BATCHNO_ERROR`;

export const UPDATE_UNITCODE_START = `${prefix}_UPDATE_UNITCODE_START`;
export const UPDATE_UNITCODE_SUCCESS = `${prefix}_UPDATE_UNITCODE_SUCCESS`;
export const UPDATE_UNITCODE_ERROR = `${prefix}_UPDATE_UNITCODE_ERROR`;
