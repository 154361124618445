const prefix = 'FARMER';

export const GET_LOAN_UPCOMING_START = `${prefix}_GET_LOAN_UPCOMING_START`;
export const GET_LOAN_UPCOMING_SUCCESS = `${prefix}_GET_LOAN_UPCOMING_SUCCESS`;
export const GET_LOAN_UPCOMING_ERROR = `${prefix}_GET_LOAN_UPCOMING_ERROR`;

export const GET_LOAN_PAST_HELD_START = `${prefix}_GET_LOAN_PAST_HELD_START`;
export const GET_LOAN_PAST_HELD_SUCCESS = `${prefix}_GET_LOAN_PAST_HELD_SUCCESS`;
export const GET_LOAN_PAST_HELD_ERROR = `${prefix}_GET_LOAN_PAST_HELD_ERROR`;

export const GET_LOAN_SINGLE_START = `${prefix}_GET_LOAN_SINGLE_START`;
export const GET_LOAN_SINGLE_SUCCESS = `${prefix}_GET_LOAN_SINGLE_SUCCESS`;
export const GET_LOAN_SINGLE_ERROR = `${prefix}_GET_LOAN_SINGLE_ERROR`;

export const FINISH_LOAN_START = `${prefix}_FINISH_LOAN_START`;
export const FINISH_LOAN_SUCCESS = `${prefix}_FINISH_LOAN_SUCCESS`;
export const FINISH_LOAN_ERROR = `${prefix}_FINISH_LOAN_ERROR`;

export const REJECT_LOAN_START = `${prefix}_REJECT_LOAN_START`;
export const REJECT_LOAN_SUCCESS = `${prefix}_REJECT_LOAN_SUCCESS`;
export const REJECT_LOAN_ERROR = `${prefix}_REJECT_LOAN_ERROR`;

export const GET_ALL_COUNT_START = `${prefix}_GET_ALL_COUNT_START`;
export const GET_ALL_COUNT_SUCCESS = `${prefix}_GET_ALL_COUNT_SUCCESS`;
export const GET_ALL_COUNT_ERROR = `${prefix}_GET_ALL_COUNT_ERROR`;
