/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload, faBell, faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  filterUsers,
} from '../../store/modules/user/actions';
import {
  deleteNotification,
  getNotifications,
  sendNotification,
} from '../../store/modules/notification/actions';
import {
  selectAllUsers, selectIsLoadingUsers,
} from '../../store/modules/user/selectors';
import {
  selectIsLoading, selectNotifications,
} from '../../store/modules/notification/selectors';
import LoadingModal from '../../components/LoadingModal';
import './SendPushNotification.scss';
import NotificationModal from '../../components/NotificationModal';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';

const OPTIONS = [
  { key: 'role', label: 'User Type' },
  { key: 'gender', label: 'Gender' },
  { key: 'pinCode', label: 'Pin Code' },
  { key: 'unitCode', label: 'Unit Code' },
  { key: 'plateCode', label: 'Plant Code' },
  { key: 'stateName', label: 'State Name' },
  { key: 'districtName', label: 'District Name' },
  { key: 'mendalName', label: 'Mandal Name' },
  { key: 'panchayatName', label: 'Panchayat Name' },
  { key: 'villageName', label: 'Village Name' },
  { key: 'regionName', label: 'Region Name' },
];

const gender = [
  { key: 'Male', label: 'Male' },
  { key: 'Female', label: 'Female' },
];
const roles = [
  {
    key: 'trader',
    label: 'Trader',
  },
  {
    key: 'MCC Representative',
    label: 'MCC Representative',
  },
  {
    key: 'HPC Farmer',
    label: 'HPC Farmer',
  },
  {
    key: 'HP Representative',
    label: 'HP Representative',
  },
  {
    key: 'hnl-employee',
    label: 'HNL Employee',
  },
  {
    key: 'hnl-distributor',
    label: 'HNL Distributor',
  },
  {
    key: 'farmer',
    label: 'Farmer',
  },
  {
    key: 'employee',
    label: 'Employee',
  },
];
const SendPushNotification = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [time, setTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchKey, setSearchKey] = useState(null);
  // const [searchValue, setSearchValue] = useState('');
  const [storeValues, setstoreValues] = useState([]);
  const [textInputValues, settextInputValues] = useState({
    role: '',
    pinCode: '',
    gender: 'Male',
    unitCode: '',
    plateCode: '',
    stateName: '',
    mendalName: '',
    panchayatName: '',
    villageName: '',
    districtName: '',
    regionName: '',

  });
  const [searchKeyError, setSearchKeyError] = useState(null);
  const [searchValueError, setSearchValueError] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [item, setItem] = useState(null);

  const dispatch = useDispatch();
  const data = useSelector((state) => selectNotifications(state));
  const users = useSelector((state) => selectAllUsers(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingUsers = useSelector((state) => selectIsLoadingUsers(state));

  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm({
    defaultValues: {
      notificationTitle: '',
      notificationBody: '',
      notificationFrequency: 'Daily',
    },
  });

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const nextPage = (page) => {
    dispatch(getNotifications(page));
  };

  const previousPage = (page) => {
    dispatch(getNotifications(page));
  };

  const handleSelectFile = (event) => {
    const { files } = event.target;
    if (!files) return;
    setFileError(null);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (Math.floor(img.width / img.height) !== 2) {
          setFileError('Notification Image dimensions should be 2:1 ratio (e.g. 1000x500)');
        }
      };
      img.onerror = () => {
        setFileError('Invalid image content');
      };
      img.src = e.target.result;
      const objectUrl = URL.createObjectURL(files[0]);
      setFile(files[0]);
      setFileUrl(objectUrl);
    };
    reader.readAsDataURL(files[0]);
  };

  const onSearchKeyChange = (e) => {
    setSearchKeyError(null);
    // setSearchValue(null);
    if (Array.isArray(e) && e.length > 1) {
      const value = e.map((el) => el);
      setSearchKey(value);
      // setstoreValues((prevValues) => [
      //   ...prevValues,
      //   {
      //     attribute: value,
      //     value: '',
      //   },
      // ]);
    } else if (Array.isArray(e) && e.length === 1) {
      setSearchKey(e);
      // setstoreValues((prevValues) => [
      //   ...prevValues,
      //   {
      //     attribute: e,
      //     value: '',
      //   },
      // ]);
    } else {
      setSearchKey(null);
    }
  };

  const renderResultsFromDropdown = () => (
    <div className="flewWrappedTextInputs">
      {
     searchKey?.length > 0 && searchKey?.map((i) => (
       <div className="slide-in-animation">
         {
          i?.key === 'role' ? (
            <select
              className="form__field"
              value={textInputValues.role}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  role: e.target.value,
                }));
              }}
              onBlur={() => {
                setstoreValues((prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues?.role,
                  },
                ]);
              }}

            >
              <option value="" hidden>Choose Roles</option>
              {
            roles?.map((role) => (
              <option key={role?.key} value={role?.key}>{role?.label}</option>
            ))
            }
            </select>
          ) : i?.key === 'regionName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label.toUpperCase()}
              id="searchValue"
              name="searchValue"
              value={textInputValues.regionName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  regionName: e.target.value,
                }));
              }}
              onBlur={() => setstoreValues(
                (prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues?.regionName,
                  },
                ],
              )}
            />
          ) : i?.key === 'gender' ? (
            <select
              className="form__field"
              value={textInputValues.gender}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  gender: e.target.value,
                }));
              }}
              onBlur={() => {
                setstoreValues(
                  (prevValues) => [
                    ...prevValues,
                    {
                      attribute: i.key,
                      value: textInputValues?.gender,
                    },
                  ],
                );
              }}
            >
              <option value="" hidden>Choose Gender</option>
              {
              gender?.map((gen) => (
                <option key={gen?.key} value={gen?.label}>{gen?.label}</option>
              ))
              }
            </select>
          ) : i?.key === 'pinCode' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              maxLength={6}
              disabled={!searchKey}
              value={textInputValues.pinCode}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  pinCode: e.target.value,
                }));
              }}
              onBlur={() => {
                setstoreValues(
                  (prevValues) => [
                    ...prevValues,
                    {
                      attribute: i.key,
                      value: textInputValues?.pinCode,
                    },
                  ],
                );
              }}
            />
          ) : i?.key === 'unitCode' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.unitCode}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  unitCode: e.target.value,
                }));
              }}
              onBlur={
                () => setstoreValues(
                  (prevValues) => [
                    ...prevValues,
                    {
                      attribute: i.key,
                      value: textInputValues?.unitCode,
                    },
                  ],
                )
              }
            />
          ) : i?.key === 'plateCode' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.plateCode}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  plateCode: e.target.value,
                }));
              }}
              onBlur={() => setstoreValues(
                (prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues.plateCode,
                  },
                ],
              )}
            />
          ) : i.key === 'stateName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.stateName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  stateName: e.target.value,
                }));
              }}
              onBlur={() => setstoreValues(
                (prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues?.stateName,
                  },
                ],
              )}
            />
          ) : i.key === 'districtName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.districtName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  districtName: e.target.value,
                }));
              }}
              onBlur={() => setstoreValues(
                (prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues?.districtName,
                  },
                ],
              )}
            />
          ) : i.key === 'mendalName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.mendalName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  mendalName: e.target.value,
                }));
              }}
              onBlur={() => setstoreValues(
                (prevValues) => [
                  ...prevValues,
                  {
                    attribute: i.key,
                    value: textInputValues?.mendalName,
                  },
                ],
              )}
            />
          ) : i?.key === 'panchayatName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.panchayatName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  panchayatName: e.target.value,
                }));
              }}
              onBlur={() => {
                setstoreValues(
                  (prevValues) => [
                    ...prevValues,
                    {
                      attribute: i.key,
                      value: textInputValues?.panchayatName,
                    },
                  ],
                );
              }}
            />
          ) : i?.key === 'villageName' ? (
            <input
              className="form__field"
              type="text"
              placeholder={i.label}
              id="searchValue"
              name="searchValue"
              disabled={!searchKey}
              value={textInputValues.villageName}
              onChange={(e) => {
                settextInputValues((prevTextInputValues) => ({
                  ...prevTextInputValues,
                  villageName: e.target.value,
                }));
              }}
              onBlur={() => {
                setstoreValues(
                  (prevValues) => [
                    ...prevValues,
                    {
                      attribute: i.key,
                      value: textInputValues?.villageName,
                    },
                  ],
                );
              }}
            />
          ) : null
         }
       </div>
     ))
      }
      {
                      searchKey?.length > 0 && (
                      <div>
                        <button type="button" className="form__button" onClick={() => onSearch()}>Search</button>
                      </div>
                      )
                    }
    </div>
  );
  // const onSearchValueChange = (e) => {
  //   const { value } = e.target;
  //   setSearchValueError(null);
  //   setSearchValue(value);
  //   setFilter({ [searchKey]: value });
  // };

  const onSearch = () => {
    const isEmpty = storeValues.every((element) => element === undefined || element === null || element === '');
    if (!storeValues) {
      setSearchKeyError('Target audience is required');
      return;
    }
    if (!textInputValues) {
      setSearchValueError('Value is required');
      return;
    }
    setSearchKeyError(null);
    setSearchValueError(null);
    if (isEmpty) {
      Swal.fire({
        icon: 'error',
        title: 'Target audience is required',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
      });
    } else {
      dispatch(filterUsers({ filter: storeValues }));
    }
  };

  const send = ({ notificationTitle, notificationBody }) => {
    if (fileError) {
      return;
    }
    if (!users.length) {
      setSearchKeyError('Select Target audience');
      return;
    }
    setSearchKeyError(null);
    const formData = new FormData();
    formData.append('notificationTitle', notificationTitle);
    formData.append('notificationBody', notificationBody);
    // formData.append('notificationFrequency', 'Dialy');
    formData.append('time', moment(time).format('HH:mm'));
    formData.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    formData.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    formData.append('notificationURL', 'heritagevetplus://');
    // formData.append('receiversIds', 'Fix');
    users.forEach((user) => {
      formData.append('receiversIds', user.id);
    });
    if (file) {
      formData.append('file', file);
    }

    dispatch(sendNotification(formData));
  };

  const toggleNotificationModal = (value) => {
    setItem(value || null);
    setShowNotificationModal(!showNotificationModal);
  };

  const removeNotification = () => {
    dispatch(deleteNotification(item.id));
  };

  return (
    <>
      <div className="push-notifiaction">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Push notification category/segment wise
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="notificationTitle">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Notification Title</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="notificationTitle"
                            name="notificationTitle"
                            placeholder="Notification Title"
                            {...register('notificationTitle', {
                              required: {
                                value: true,
                                message: 'Notification Title is required',
                              },
                            })}
                          />
                          {errors.notificationTitle && <div className="form__error">{errors.notificationTitle.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="notificationBody">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Notification Text/Body</span>
                        </div>
                        <div className="col-12">
                          <textarea
                            className="form__field"
                            // rows="5"
                            type="text"
                            id="notificationBody"
                            name="notificationBody"
                            placeholder="Notification Text/Body"
                            {...register('notificationBody', {
                              required: {
                                value: true,
                                message: 'Notification Body is required',
                              },
                            })}
                          />
                          {errors.notificationBody && <div className="form__error">{errors.notificationBody.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="image">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Notification Image(Optional)</span>
                        </div>
                        <div className="col-12 d-flex">
                          <label htmlFor="image">
                            <div className="picture">
                              {fileUrl && <img src={fileUrl} alt="sample" />}
                              <div style={{ background: fileUrl ? 'transparent' : '#5555552' }} className="picture__placeholder">
                                {!fileUrl && (
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  className="picture__placeholder--icon"
                                />
                                )}
                              </div>
                            </div>
                            <input
                              className="form__field"
                              type="file"
                              name="image"
                              id="image"
                              onChange={handleSelectFile}
                            />
                            {fileError && <div className="form__error">{fileError}</div>}
                          </label>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-16 mb-8">
                  <span className="form__label">Schedule</span>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="notificationFrequency">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <span className="form__label">Send to eligible users</span>
                        </div>
                        <div className="col-12 col-md-4 mb-8">
                          <input
                            className="form__field"
                            type="text"
                            id="notificationFrequency"
                            name="notificationFrequency"
                            placeholder="Daily"
                            disabled
                            value="Daily"
                            {...register('notificationFrequency', {
                              required: {
                                value: true,
                                message: 'Notification Frequency is required',
                              },
                            })}
                          />
                          {errors.notificationFrequency && <div className="form__error">{errors.notificationFrequency.message}</div>}
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <span className="form__label ml-16 mr-16">at</span>
                          <DatePicker
                            selected={time}
                            minDate={new Date()}
                            onChange={(date) => setTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className="form__field"
                          />
                          {/* {tabletError && <div className="form__error">{tabletError}</div>} */}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Start</span>
                      </div>
                      <div className="col-12">
                        <DatePicker
                          selected={startDate}
                          minDate={new Date()}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className="form__field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12">
                        <span className="form__label mb-4">End</span>
                      </div>
                      <div className="col-12">
                        <DatePicker
                          selected={endDate}
                          minDate={new Date()}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className="form__field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-box">
          <div className="row">
            <div className="col-12">
              <div className="form__item">
                <label htmlFor="key">
                  <div className="row space-between">
                    <div className="col-12 mb-4">
                      <span className="form__label">Choose target audience</span>
                    </div>
                    <div className="col-12 d-flex-col col-md-5 mb-4">
                      <MultiSelectDropdown
                        options={OPTIONS}
                        onSelectedChange={(event) => onSearchKeyChange(event)}
                        label="Choose target audience / Search criteria"
                      />
                      {searchKeyError && (
                      <div className="form__error">
                        {searchKeyError}
                      </div>
                      )}
                    </div>
                    <div className="d-flex-col">
                      {renderResultsFromDropdown()}
                    </div>
                    {searchValueError && (
                      <div className="form__error">
                        {searchValueError}
                      </div>
                    )}
                  </div>
                </label>
              </div>
            </div>

            <div className="col-12">
              <div className="form__item">
                <span className="form__label">
                  Count:
                  {' '}
                  {users.length}
                </span>
              </div>
            </div>
            <div className="col-12 d-flex-col middle">
              <div className="buttons">
                <button type="button" className="button green" onClick={handleSubmit(send)}>Send</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="heading mt-16">
              <p className="heading__title">
                Current scheduled active notifications
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {data && data.paginate && data.paginate.length
            ? data.paginate.map((notif) => (
              <div key={notif.id} className="col-12 col-lg-6">
                <div className="card">
                  <div className="card__left">
                    {/* <div className="card__avatar">
                      <img src={notif.profilePicture} alt="notif" />
                    </div> */}
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Name :
                          {' '}
                        </span>
                        {notif.notificationTitle}
                      </div>
                      <div>
                        <span className="card__title">
                          Start Date :
                          {' '}
                        </span>
                        {moment(notif.startDate).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        <span className="card__title">
                          End Date :
                          {' '}
                        </span>
                        {moment(notif.endDate).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <button type="button" className="card__button" onClick={() => toggleNotificationModal(notif)}>View</button>
                  </div>
                </div>
              </div>
            ))
            : (
              null
            )}
          {!isLoading && !data && (
          <div className="col-12 col-lg-12 d-flex-col middle">
            <div className="notFound">
              <FontAwesomeIcon icon={faBell} className="notFound__icon" />
              <span className="notFound__text">No current scheduled active notifications found</span>
            </div>
          </div>
          )}
        </div>
        {data
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Previous}
                    onClick={() => previousPage(data.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {data.Next
                      ? data.Next.page - 1
                      : data.Previous
                        ? data.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Next}
                    onClick={() => nextPage(data.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
      <NotificationModal
        show={showNotificationModal}
        toggle={toggleNotificationModal}
        notification={item}
        action={removeNotification}
      />
      <LoadingModal show={isLoading || isLoadingUsers} />
    </>
  );
};

export default SendPushNotification;
