/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faChevronRight,
  faChevronLeft,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  getPendingDoctors, searchPendingDoctors, exportPendingDoctors, countDoctors,
} from '../../store/modules/doctor/actions';
import {
  selectPendingDoctors, selectCount, selectIsLoading, selectIsLoadingExport, selectIsLoadingCount,
} from '../../store/modules/doctor/selectors';
import veterinarian from '../../assets/images/veterinarian.png';
import technician from '../../assets/images/technician.png';
import pharmacy from '../../assets/images/pharmacy.png';

import './PendingDoctors.scss';

const PendingDoctors = () => {
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectPendingDoctors(state));
  const count = useSelector((state) => selectCount(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));
  const isLoadingCount = useSelector((state) => selectIsLoadingCount(state));

  useEffect(() => {
    dispatch(getPendingDoctors());
    dispatch(countDoctors());
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchPendingDoctors(`+91${query}`));
  };

  const nextPage = (page) => {
    dispatch(getPendingDoctors(page));
  };

  const previousPage = (page) => {
    dispatch(getPendingDoctors(page));
  };

  const exportExcell = () => {
    dispatch(exportPendingDoctors());
  };

  return (
    <>
      <div className="pending-doctors">
        <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <img alt="Farmer" src={veterinarian} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Veterinarian
                </span>
                <span className="summary-card__value">
                  {count ? count.veterinarian : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card info mr-8">
              <div className="summary-card__left">
                <img alt="Farmer" src={veterinarian} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Veterinary Assistant
                </span>
                <span className="summary-card__value">
                  {count ? count.veterinaryAssistant : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card success mr-8">
              <div className="summary-card__left">
                <img alt="Cow" src={technician} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  AI Technician
                </span>
                <span className="summary-card__value">
                  {count ? count.AITechnician : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card green">
              <div className="summary-card__left">
                <img alt="Cow" src={pharmacy} className="summary-card__img" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Pharmacy
                </span>
                <span className="summary-card__value">
                  {count ? count.pharmacy : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {data && data.paginate && data.paginate.length
            ? data.paginate.map((doctor) => (
              <div key={1} className="col-12 col-lg-12">
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img src={doctor?.profilePicture} alt="doctor" />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Name :
                          {' '}
                        </span>
                        {doctor?.doctorName}
                      </div>
                      <div>
                        <span className="card__title">
                          Phone Number :
                          {' '}
                        </span>
                        {doctor?.phone?.replace('+91', '')}
                      </div>
                      <div>
                        <span className="card__title">
                          Registration Date :
                          {' '}
                        </span>
                        {moment(doctor?.createdAt).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        <span className="card__title">
                          Registration Time :
                          {' '}
                        </span>
                        {moment(doctor?.createdAt).format('hh:mm a')}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <Link to={`/admin/doctors/${doctor?.id}`} className="card__button">View</Link>
                  </div>
                </div>
              </div>
            ))
            : (
              null
            )}
          {!isLoading && !data && (
          <div className="col-12 col-lg-12 d-flex-col middle">
            <div className="notFound">
              <FontAwesomeIcon icon={faUsers} className="notFound__icon" />
              <span className="notFound__text">No pending doctors found</span>
            </div>
          </div>
          )}
        </div>
        {data
          ? (
            <div className="row">
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="pagination">
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Previous}
                    onClick={() => previousPage(data.Previous.page)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                  </button>
                  <span className="pagination__page">
                    {data.Next
                      ? data.Next.page - 1
                      : data.Previous
                        ? data.Previous.page + 1
                        : 1}
                  </span>
                  <button
                    type="button"
                    className="pagination__btn"
                    disabled={!data.Next}
                    onClick={() => nextPage(data.Next.page)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
      </div>
      <LoadingModal show={isLoading || isLoadingExport || isLoadingCount} />
    </>
  );
};

export default PendingDoctors;
