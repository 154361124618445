/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import { getUpcomingAppointments, searchUpcomingAppointments } from '../../store/modules/appointment/actions';
import {
  selectUpcomingAppointments, selectIsLoading,
} from '../../store/modules/appointment/selectors';
import './CattleAppointments.scss';

const CattleAppointments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectUpcomingAppointments(state));
  const isLoading = useSelector((state) => selectIsLoading(state));

  // useEffect(() => {
  //   dispatch(getUpcomingAppointments());
  // }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchUpcomingAppointments(`+91${query}`));
  };

  const nextPage = (page) => {
    dispatch(getUpcomingAppointments(page));
  };

  const previousPage = (page) => {
    dispatch(getUpcomingAppointments(page));
  };

  return (
    <>
      <div className="cattle-appointments">
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Upcoming
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Past Held
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            {/* {data && data.paginate && data.paginate.length
            ? data.paginate.map((appointment) => ( */}
            <div className="card">
              <div className="card__left">
                <div className="card__avatar">
                  {/* <img src={appointment.Farmer
                        ? appointment.Farmer.profilePicture : ''} alt="user" /> */}
                </div>
                <div className="card__text">
                  <div>
                    <span className="card__title">
                      Farmer Name :
                      {' '}
                    </span>
                    {/* {appointment.Farmer ? appointment.Farmer.farmerName : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Phone :
                      {' '}
                    </span>
                    {/* {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Date :
                      {' '}
                    </span>
                    {/* {moment(appointment.appointmentDate).format('DD/MM/YYYY')} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Time :
                      {' '}
                    </span>
                    {/* {appointment.appointmentStartTime} */}
                  </div>
                </div>
              </div>
              <div className="card__right">
                <Link
                  to="/admin/appointments/1"
                  className="card__button"
                >
                  View
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card__left">
                <div className="card__avatar">
                  {/* <img src={appointment.Farmer
                        ? appointment.Farmer.profilePicture : ''} alt="user" /> */}
                </div>
                <div className="card__text">
                  <div>
                    <span className="card__title">
                      Farmer Name :
                      {' '}
                    </span>
                    {/* {appointment.Farmer ? appointment.Farmer.farmerName : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Phone :
                      {' '}
                    </span>
                    {/* {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Date :
                      {' '}
                    </span>
                    {/* {moment(appointment.appointmentDate).format('DD/MM/YYYY')} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Time :
                      {' '}
                    </span>
                    {/* {appointment.appointmentStartTime} */}
                  </div>
                </div>
              </div>
              <div className="card__right">
                <Link
                  to="/admin/appointments/1"
                  className="card__button"
                >
                  View
                </Link>
              </div>
            </div>
            {/* ))
            : (
              null
            )} */}
            {!isLoading && !data && (
            <div className="d-flex-col middle">
              <div className="notFound">
                <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                <span className="notFound__text">No upcoming appointments found</span>
              </div>
            </div>
            )}
            {data
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!data.Previous}
                        onClick={() => previousPage(data.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {data.Next
                          ? data.Next.page - 1
                          : data.Previous
                            ? data.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!data.Next}
                        onClick={() => nextPage(data.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            {/* {data && data.paginate && data.paginate.length
            ? data.paginate.map((appointment) => ( */}
            <div className="card">
              <div className="card__left">
                <div className="card__avatar">
                  {/* <img src={appointment.Farmer
                        ? appointment.Farmer.profilePicture : ''} alt="user" /> */}
                </div>
                <div className="card__text">
                  <div>
                    <span className="card__title">
                      Farmer Name :
                      {' '}
                    </span>
                    {/* {appointment.Farmer ? appointment.Farmer.farmerName : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Phone :
                      {' '}
                    </span>
                    {/* {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Date :
                      {' '}
                    </span>
                    {/* {moment(appointment.appointmentDate).format('DD/MM/YYYY')} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Time :
                      {' '}
                    </span>
                    {/* {appointment.appointmentStartTime} */}
                  </div>
                </div>
              </div>
              <div className="card__right">
                <div>
                  <span
                    className="card__title"
                  >
                    Status :
                    {' '}
                  </span>
                  {/* {appointment.status} */}
                </div>
                <Link
                  to="/admin/appointments/1"
                  className="card__button"
                >
                  View
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card__left">
                <div className="card__avatar">
                  {/* <img src={appointment.Farmer
                        ? appointment.Farmer.profilePicture : ''} alt="user" /> */}
                </div>
                <div className="card__text">
                  <div>
                    <span className="card__title">
                      Farmer Name :
                      {' '}
                    </span>
                    {/* {appointment.Farmer ? appointment.Farmer.farmerName : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Phone :
                      {' '}
                    </span>
                    {/* {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Date :
                      {' '}
                    </span>
                    {/* {moment(appointment.appointmentDate).format('DD/MM/YYYY')} */}
                  </div>
                  <div>
                    <span className="card__title">
                      Telephonic Consultancy Time :
                      {' '}
                    </span>
                    {/* {appointment.appointmentStartTime} */}
                  </div>
                </div>
              </div>
              <div className="card__right">
                <div>
                  <span
                    className="card__title"
                  >
                    Status :
                    {' '}
                  </span>
                  {/* {appointment.status} */}
                </div>
                <Link
                  to="/admin/appointments/1"
                  className="card__button"
                >
                  View
                </Link>
              </div>
            </div>
            {/* ))
            : (
              null
            )} */}
            {!isLoading && !data && (
            <div className="d-flex-col middle">
              <div className="notFound">
                <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                <span className="notFound__text">No upcoming appointments found</span>
              </div>
            </div>
            )}
            {data
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!data.Previous}
                        onClick={() => previousPage(data.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {data.Next
                          ? data.Next.page - 1
                          : data.Previous
                            ? data.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!data.Next}
                        onClick={() => nextPage(data.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default CattleAppointments;
