/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faChevronLeft, faCalendarAlt, faCalendarTimes, faCalendarCheck, faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import LoadingModal from '../../components/LoadingModal';
import { adminGetUpcomingPhysicalAppointments, adminGetPastHeldPhysicalAppointments, getAllCountDetailsForPhysicalAppointments } from '../../store/modules/appointment/actions';
import {
  selectUpcomingAppointments, selectPastHeldAppointments, selectIsLoading,
  selectCount, selectIsLoadingCount,
} from '../../store/modules/appointment/selectors';
import './PhysicalAppointments.scss';

const PhysicalAppointments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const upcoming = useSelector((state) => selectUpcomingAppointments(state));
  const pastHeld = useSelector((state) => selectPastHeldAppointments(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const count = useSelector((state) => selectCount(state));
  const isLoadingCount = useSelector((state) => selectIsLoadingCount(state));
  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(adminGetPastHeldPhysicalAppointments());
    } else {
      dispatch(adminGetUpcomingPhysicalAppointments());
    }
  }, [tabIndex]);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const upcomingNextPage = (page) => {
    dispatch(adminGetUpcomingPhysicalAppointments(page));
  };

  const upcomingPreviousPage = (page) => {
    dispatch(adminGetUpcomingPhysicalAppointments(page));
  };

  const pastHeldNextPage = (page) => {
    dispatch(adminGetPastHeldPhysicalAppointments(page));
  };

  const pastHeldPreviousPage = (page) => {
    dispatch(adminGetPastHeldPhysicalAppointments(page));
  };
  useEffect(() => {
    dispatch(getAllCountDetailsForPhysicalAppointments());
  }, [dispatch]);
  return (
    <>
      <div className="appointments">
        <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card warning mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarPlus} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Pending Appointments
                </span>

                <span className="summary-card__value">
                  {count ? count?.pendingVirtualAppointments : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card green mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarCheck} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Finished Appointments
                </span>

                <span className="summary-card__value">
                  {count ? count?.finsihedVirtualAppointments : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card danger mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarTimes} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Rejected Appointments
                </span>

                <span className="summary-card__value">
                  {count ? count?.rejectedVirtualAppointments : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faCalendarAlt} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Total Appointments
                </span>

                <span className="summary-card__value">
                  {count ? count?.totalVirtualAppointments : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Upcoming
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Past Held
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            {upcoming && upcoming.paginate && upcoming.paginate.length
              ? upcoming.paginate.map((appointment) => (
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img
                        src={appointment.Farmer
                          ? appointment.Farmer.profilePicture : ''}
                        alt="user"
                      />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Farmer Name :
                          {' '}
                        </span>
                        {appointment.Farmer ? appointment.Farmer.farmerName : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Phone :
                          {' '}
                        </span>
                        {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Date :
                          {' '}
                        </span>
                        {moment(appointment.appointmentDate).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Time :
                          {' '}
                        </span>
                        {appointment.appointmentStartTime}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <Link
                      to={`/admin/appointments/${appointment.id}`}
                      className="card__button"
                    >
                      View
                    </Link>
                  </div>
                </div>
              ))
              : (
                null
              )}
            {!isLoading && !upcoming && (
            <div className="d-flex-col middle">
              <div className="notFound">
                <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                <span className="notFound__text">No upcoming appointments found</span>
              </div>
            </div>
            )}
            {upcoming
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Previous}
                        onClick={() => upcomingPreviousPage(upcoming.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {upcoming.Next
                          ? upcoming.Next.page - 1
                          : upcoming.Previous
                            ? upcoming.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Next}
                        onClick={() => upcomingNextPage(upcoming.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            {pastHeld && pastHeld.paginate && pastHeld.paginate.length
              ? pastHeld.paginate.map((appointment) => (
                <div className="card">
                  <div className="card__left">
                    <div className="card__avatar">
                      <img
                        src={appointment.Farmer
                          ? appointment.Farmer.profilePicture : ''}
                        alt="user"
                      />
                    </div>
                    <div className="card__text">
                      <div>
                        <span className="card__title">
                          Farmer Name :
                          {' '}
                        </span>
                        {appointment.Farmer ? appointment.Farmer.farmerName : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Phone :
                          {' '}
                        </span>
                        {appointment.Farmer
                          ? appointment.Farmer.phone.replace('+91', '') : ''}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Date :
                          {' '}
                        </span>
                        {moment(appointment.appointmentDate).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        <span className="card__title">
                          Telephonic Consultancy Time :
                          {' '}
                        </span>
                        {appointment.appointmentStartTime}
                      </div>
                    </div>
                  </div>
                  <div className="card__right">
                    <div className={`card__text--${appointment.status === 'finished' ? 'primary' : appointment.status === 'rejected' ? 'danger' : ''}`}>
                      <span
                        className="card__title"
                      >
                        Status :
                        {' '}
                      </span>
                      {appointment.status}
                    </div>
                    <Link
                      to={`/admin/appointments/${appointment.id}`}
                      className="card__button"
                    >
                      View
                    </Link>
                  </div>
                </div>
              ))
              : (
                null
              )}
            {!isLoading && !pastHeld && (
            <div className="d-flex-col middle">
              <div className="notFound">
                <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                <span className="notFound__text">No upcoming appointments found</span>
              </div>
            </div>
            )}
            {pastHeld
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Previous}
                        onClick={() => pastHeldPreviousPage(pastHeld.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {pastHeld.Next
                          ? pastHeld.Next.page - 1
                          : pastHeld.Previous
                            ? pastHeld.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Next}
                        onClick={() => pastHeldNextPage(pastHeld.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading || isLoadingCount} />
    </>
  );
};

export default PhysicalAppointments;
