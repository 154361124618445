/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../store';
import AuthProvider from '../context/auth';
import Routes from '../Routes';
import './App.scss';
import hocNetworkDetector from '../utils/hocNetworkDetector';
import Alert from '../utils/toastr';
import SuccessAnimation from '../assets/animations/Wifi.json';
import ErrorAnimation from '../assets/animations/noInternet.json';
import MobileAnimation from '../assets/animations/mobile.json';

const App = (props) => {
  const triggerNetworkAlert = () => {
    const alertConfig = {
      type: props?.isOnline ? (props?.isMobileDevice ? 'warning' : 'success') : 'dark',
      showAnimation: props?.isOnline ? (props?.isMobileDevice ? MobileAnimation : SuccessAnimation) : ErrorAnimation,
      loop: props?.isOnline ? true : !!props?.isMobileDevice,
      message: props?.isOnline ? (props?.isMobileDevice ? 'Connected via Mobile Device' : 'Internet Connection is available') : 'No Internet Connection',
    };
    Alert(alertConfig);
  };

  useEffect(() => {
    triggerNetworkAlert();
  });

  return (
    <AuthProvider>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          // hideProgressBar
          newestOnTop
          // closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </Provider>
    </AuthProvider>
  );
};

export default hocNetworkDetector(App);
