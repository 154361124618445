/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { loginDoctor } from '../../store/modules/auth/actions';
import { selectErrorMessage, selectIsLoadingAuth } from '../../store/modules/auth/selectors';
import Spinner from '../../components/Spinner';
import logo from '../../assets/images/logo.png';
import './DoctorSignup.scss';

const Form1 = ({ onStepChange }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  // const onSubmit = handleSubmit((data) => {
  //   onStepChange(2);
  // });

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    onStepChange(2);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Personal Details
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>1</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="name">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Name</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Name is required',
                    },
                  })}
                />
                {errors.name && <div className="form__error">{errors.name.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12 mb-4">
              <span className="form__label">Gender</span>
            </div>
            <div className="col-12 col-lg-6">
              <label htmlFor="male">
                <input
                  className="form__radio"
                  type="radio"
                  id="male"
                  name="gender"
                  {...register('gender', {
                    required: {
                      value: true,
                      message: 'Gender is required',
                    },
                  })}
                />
                {' '}
                Male
              </label>
              <label htmlFor="female">
                <input
                  className="form__radio"
                  type="radio"
                  id="female"
                  name="gender"
                  {...register('gender', {
                    required: {
                      value: true,
                      message: 'Gender is required',
                    },
                  })}
                />
                {' '}
                Female
              </label>
              {errors.gender && <div className="form__error">{errors.gender.message}</div>}
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="phone">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Phone Number</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  {...register('phone', {
                    required: {
                      value: true,
                      message: 'Phone Number is required',
                    },
                  })}
                />
                <span className="form__note">Note: Pet calls will be redirected to this number. You can edit it later.</span>
                {errors.phone && <div className="form__error">{errors.phone.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="email">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Email Address</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Email Address is required',
                    },
                  })}
                />
                {errors.email && <div className="form__error">{errors.email.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="city">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">City</span>
              </div>
              <div className="col-12 col-lg-6">
                <select
                  className="form__field"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  {...register('city', {
                    required: {
                      value: true,
                      message: 'City is required',
                    },
                  })}
                >
                  <option value="">Select city</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Bengaluru">Bengaluru</option>
                  <option value="Other">Other</option>
                </select>
                {errors.city && <div className="form__error">{errors.city.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="address">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Enter Full Address With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Ex: 108 BABAR ROAD NEW DEHLI 110001 DEHLI"
                  {...register('address', {
                    required: {
                      value: true,
                      message: 'Full Address is required',
                    },
                  })}
                />
                {errors.address && <div className="form__error">{errors.address.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Education Qualification
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>2</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="degree">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Degree</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="degree"
                  name="degree"
                  placeholder="Degree"
                  {...register('degree', {
                    required: {
                      value: true,
                      message: 'Degree is required',
                    },
                  })}
                />
                {errors.degree && <div className="form__error">{errors.degree.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="college">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">College/instituter</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="college"
                  name="college"
                  placeholder="College/institute"
                  {...register('college', {
                    required: {
                      value: true,
                      message: 'College/institute is required',
                    },
                  })}
                />
                {errors.college && <div className="form__error">{errors.college.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="completionyear">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Year of completion</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="completionyear"
                  name="completionyear"
                  placeholder="Year of completion"
                  {...register('completionyear', {
                    required: {
                      value: true,
                      message: 'Year of completion is required',
                    },
                  })}
                />
                {errors.completionyear && <div className="form__error">{errors.completionyear.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="specialization">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Specialization</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="specialization"
                  name="specialization"
                  placeholder="Specialization"
                  {...register('specialization', {
                    required: {
                      value: true,
                      message: 'Specialization is required',
                    },
                  })}
                />
                {errors.specialization && <div className="form__error">{errors.specialization.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="treatment">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Treatment of animals</span>
              </div>
              <div className="col-12 col-lg-6">
                <select
                  className="form__field"
                  type="text"
                  id="treatment"
                  name="treatment"
                  placeholder="Treatment of animals"
                  {...register('treatment', {
                    required: {
                      value: true,
                      message: 'Treatment of animals is required',
                    },
                  })}
                >
                  <option value="">Select animals</option>
                  <option value="Dogs">Dogs</option>
                  <option value="Cats">Cats</option>
                  <option value="Birds">Birds</option>
                  <option value="Farm Animals">Farm Animals</option>
                  <option value="Wild Animals">Wild Animals</option>
                </select>
                {errors.treatment && <div className="form__error">{errors.treatment.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="experience">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Years of experience</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="experience"
                  name="experience"
                  placeholder="Years of experience"
                  {...register('experience', {
                    required: {
                      value: true,
                      message: 'Years of experience is required',
                    },
                  })}
                />
                {errors.experience && <div className="form__error">{errors.experience.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="buttons">
          <button
            className="button"
            type="button"
          >
            {'<< Back'}
          </button>
          <button
            className="button primary"
            type="submit"
          >
            {'Continue >>'}
          </button>
        </div>
      </form>
    </>
  );
};

const Form2 = ({ onStepChange }) => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  // const onSubmit = handleSubmit((data) => {
  //   onStepChange(3);
  // });

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    onStepChange(3);
  };

  const onBack = (data) => {
    window.scrollTo(0, 0);
    onStepChange(1);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Medical Registration
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>3</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="regnumber">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Registration number</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="regnumber"
                  name="regnumber"
                  placeholder="Registration number"
                  {...register('regnumber', {
                    required: {
                      value: true,
                      message: 'Registration number is required',
                    },
                  })}
                />
                {errors.regnumber && <div className="form__error">{errors.regnumber.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="regcouncil">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Registration council</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="regcouncil"
                  name="regcouncil"
                  placeholder="Registration council"
                  {...register('regcouncil', {
                    required: {
                      value: true,
                      message: 'Registration council is required',
                    },
                  })}
                />
                {errors.regcouncil && <div className="form__error">{errors.regcouncil.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="regyear">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Registration year</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="regyear"
                  name="regyear"
                  placeholder="Registration year"
                  {...register('regyear', {
                    required: {
                      value: true,
                      message: 'Registration year is required',
                    },
                  })}
                />
                {errors.regyear && <div className="form__error">{errors.regyear.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Connect a practice
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>4</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12 mb-4">
              <span className="form__label">Choose own a establishment / visit a establishment</span>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form__radio-field">
                <label htmlFor="own">
                  <input
                    className="form__radio"
                    type="radio"
                    id="own"
                    name="establishment"
                    {...register('establishment', {
                      required: {
                        value: true,
                        message: 'Establishment is required',
                      },
                    })}
                  />
                  {' '}
                  Own establishment
                </label>
                <div className="divider" />
                <label htmlFor="visited">
                  <input
                    className="form__radio"
                    type="radio"
                    id="visited"
                    name="establishment"
                    {...register('establishment', {
                      required: {
                        value: true,
                        message: 'Establishment is required',
                      },
                    })}
                  />
                  {' '}
                  Visited establishment
                </label>
              </div>
              {errors.establishment && <div className="form__error">{errors.establishment.message}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Establishment Basic Details
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>5</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentname">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Establishment name</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="establishmentname"
                  name="establishmentname"
                  placeholder="Establishment name"
                  {...register('establishmentname', {
                    required: {
                      value: true,
                      message: 'Establishment name is required',
                    },
                  })}
                />
                {errors.establishmentname && <div className="form__error">{errors.establishmentname.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentcity">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Establishment City</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="establishmentcity"
                  name="establishmentcity"
                  placeholder="Ex: 201 DEEPALI PITAM PURA DELHI 34 DELHI"
                  {...register('establishmentcity', {
                    required: {
                      value: true,
                      message: 'Establishment City is required',
                    },
                  })}
                />
                {errors.establishmentcity && <div className="form__error">{errors.establishmentcity.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="city">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Enter Establishment Locallity With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  {...register('city', {
                    required: {
                      value: true,
                      message: 'Establishment Locallity is required',
                    },
                  })}
                />
                {errors.city && <div className="form__error">{errors.city.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentlocality">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Enter Full Address With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="establishmentlocality"
                  name="establishmentlocality"
                  placeholder="Ex: Good-W-9/12, DLF Phase 3, Gurugram, Haryana 122002"
                  {...register('establishmentlocality', {
                    required: {
                      value: true,
                      message: 'Full Address is required',
                    },
                  })}
                />
                {errors.establishmentlocality && <div className="form__error">{errors.establishmentlocality.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Establishment Timings
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>6</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12">
              <span className="form__label">Week days</span>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form__weekday-field form__weekday-field--selected">
                <label htmlFor="monday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="monday"
                    name="monday"
                    value="monday"
                    {...register('monday')}
                  />
                  Mon
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="tuesday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="tuesday"
                    name="tuesday"
                    value="tuesday"
                    {...register('tuesday')}
                  />
                  Tue
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="wednesday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="wednesday"
                    name="wednesday"
                    value="wednesday"
                    {...register('wednesday')}
                  />
                  Wed
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="thursday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="thursday"
                    name="thursday"
                    value="thursday"
                    {...register('thursday')}
                  />
                  Thu
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="friday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="friday"
                    name="friday"
                    value="friday"
                    {...register('friday')}
                  />
                  Fri
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="saturday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="saturday"
                    name="saturday"
                    value="saturday"
                    {...register('tuesday')}
                  />
                  Sat
                </label>
              </div>
              <div className="form__weekday-field">
                <label htmlFor="sunday">
                  <input
                    className="form__field"
                    type="checkbox"
                    id="sunday"
                    name="sunday"
                    value="sunday"
                    {...register('sunday')}
                  />
                  Sun
                </label>
              </div>
              {/* {errors.degree && <div className="form__error">
                {errors.degree.message}</div>} */}
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="startTime">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Start Time</span>
              </div>
              <div className="col-12 col-lg-6">
                <DatePicker
                  selected={startTime}
                  minDate={new Date()}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form__field"
                />
                {/* {errors.degree && <div className="form__error">
                {errors.degree.message}</div>} */}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="college">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">End Time</span>
              </div>
              <div className="col-12 col-lg-6">
                <DatePicker
                  selected={endTime}
                  minDate={new Date()}
                  onChange={(date) => setEndTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form__field"
                />
                {/* {errors.college && <div className="form__error">
                {errors.college.message}</div>} */}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="treatment">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Client Interval Session</span>
              </div>
              <div className="col-12 col-lg-6">
                <select
                  className="form__field"
                  type="text"
                  id="treatment"
                  name="treatment"
                  placeholder="Treatment of animals"
                  {...register('treatment', {
                    required: {
                      value: true,
                      message: 'Treatment of animals is required',
                    },
                  })}
                >
                  <option value="">Session Time</option>
                  <option value="15 Mins">15 Mins</option>
                  <option value="20 Mins">20 Mins</option>
                  <option value="30 Mins">30 Mins</option>
                  <option value="40 Mins">40 Mins</option>
                  <option value="45 Mins">45 Mins</option>
                  <option value="1 Hour">1 Hour</option>
                </select>
                {errors.treatment && <div className="form__error">{errors.treatment.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Details consultant doctor
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>7</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="consultationfees">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Consultation fees</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="consultationfees"
                  name="consultationfees"
                  placeholder="Consultation fees"
                  {...register('consultationfees', {
                    required: {
                      value: true,
                      message: 'Consultation fees is required',
                    },
                  })}
                />
                {errors.consultationfees && <div className="form__error">{errors.consultationfees.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12 mb-4">
              <span className="form__label">Doctor&apos;s hours.</span>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form__radio-field">
                <label htmlFor="samehours">
                  <input
                    className="form__radio"
                    type="radio"
                    id="samehours"
                    name="establishmenthours"
                    value="same as establishment hours"
                    {...register('establishmenthours', {
                      required: {
                        value: true,
                        message: 'Doctor\'s hours is required',
                      },
                    })}
                  />
                  {' '}
                  Same as establishment hours
                </label>
                <div className="divider" />
                <label htmlFor="differenthours">
                  <input
                    className="form__radio"
                    type="radio"
                    id="differenthours"
                    name="establishmenthours"
                    value="different from establishment hours"
                    {...register('establishmenthours', {
                      required: {
                        value: true,
                        message: 'Doctor\'s hours is required',
                      },
                    })}
                  />
                  {' '}
                  Different from establishment hours
                </label>
              </div>
              <span className="form__note">Note: You can add more doctors later.</span>
              {errors.establishmenthours && <div className="form__error">{errors.establishmenthours.message}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Establishment Location
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>8</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentlocation">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Enter Establishment Address With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="address"
                  id="establishmentlocation"
                  name="establishmentlocation"
                  placeholder="Ex: LU 30 PITAM PURA DEHLI 110088 DEHLI"
                  {...register('establishmentlocation', {
                    required: {
                      value: true,
                      message: 'Establishment Address is required',
                    },
                  })}
                />
                {errors.establishmentlocation && <div className="form__error">{errors.establishmentlocation.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="buttons">
          <button
            className="button"
            type="button"
            onClick={onBack}
          >
            {'<< Back'}
          </button>
          <button
            className="button primary"
            type="submit"
          >
            {'Continue >>'}
          </button>
        </div>
      </form>
    </>
  );
};

const Form3 = ({ onStepChange, onDataSubmit }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));

  // const onSubmit = handleSubmit((data) => {
  //   onDataSubmit(data);
  // });

  const onSubmit = (data) => {
  //   onDataSubmit(data);
  };

  const onBack = (data) => {
    window.scrollTo(0, 0);
    onStepChange(2);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Medical Registration Proof
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>9</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12 col-lg-6">
              <label htmlFor="medicalregproofdocument">
                <div className="picture">
                  {/* {fileUrl && <img src={fileUrl} alt="Medical Registration Proof" />} */}
                  <div style={{ background: '#5555552' }} className="picture__placeholder">
                    <FontAwesomeIcon
                      icon={faUpload}
                      className="picture__placeholder--icon"
                    />
                  </div>
                </div>
                <input
                  className="form__field"
                  type="file"
                  name="medicalregproofdocument"
                  id="medicalregproofdocument"
                />
                {/* {fileError && <div className="form__error">{fileError}</div>} */}
              </label>
              <p className="form__note mt-16 mb-8">
                Please prepare and upload your medical registration proof document.
                Only licensed and genuine doctors are listed.
              </p>
              <p className="form__note mb-8">Acceptable document(jpg, png and pdf with a good name).</p>
              <p className="form__note">● Medical Registration Certification</p>
              <p className="form__note">● 987654567890 Delhi Medical Council</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Identity Proof
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>10</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <div className="row">
            <div className="col-12 col-lg-6">
              <label htmlFor="identityproofdocument">
                <div className="picture">
                  {/* {fileUrl && <img src={fileUrl} alt="Medical Registration Proof" />} */}
                  <div style={{ background: '#5555552' }} className="picture__placeholder">
                    <FontAwesomeIcon
                      icon={faUpload}
                      className="picture__placeholder--icon"
                    />
                  </div>
                </div>
                <input
                  className="form__field"
                  type="file"
                  name="identityproofdocument"
                  id="identityproofdocument"
                />
                {/* {fileError && <div className="form__error">{fileError}</div>} */}
              </label>
              <p className="form__note mt-16 mb-8">
                Please prepare and upload your identity proof to ensure
                that the ownership of your profile remains with only you.
              </p>
              <p className="form__note mb-8">Acceptable document(jpg, png and pdf with a good name).</p>
              <p className="form__note">● Voter Card</p>
              <p className="form__note">● Aadhar Card</p>
              <p className="form__note">● Driving License</p>
              <p className="form__note">● Any other Govt ID</p>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            className="button"
            type="button"
            onClick={onBack}
          >
            {'<< Back'}
          </button>
          <button
            className="button primary"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const DoctorSignup = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));

  const history = useHistory();
  const location = useLocation();

  const onChangeStep = (newStep) => {
    setStep(newStep);
  };

  const onSubmit = (data) => {
  };

  return (
    <div className="wrap">
      <div className="container">
        <div className="doctor-signup">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <p className="heading__title">
                  Pets world are looking for Doctors like you.
                </p>
              </div>
            </div>
          </div>
          <div className="main">
            {step === 1 && <Form1 onStepChange={onChangeStep} />}
            {step === 2 && <Form2 onStepChange={onChangeStep} />}
            {step === 3 && <Form3 onStepChange={onChangeStep} onDataSubmit={onSubmit} />}
            <div className="form__item">
              {errorMessage && <div className="form__error">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSignup;
