import {
  GET_MCC_UPCOMING_START,
  GET_MCC_UPCOMING_SUCCESS,
  GET_MCC_UPCOMING_ERROR,
  GET_MCC_PAST_HELD_START,
  GET_MCC_PAST_HELD_SUCCESS,
  GET_MCC_PAST_HELD_ERROR,
  GET_MCC_SINGLE_START,
  GET_MCC_SINGLE_SUCCESS,
  GET_MCC_SINGLE_ERROR,
  FINISH_MCC_START,
  FINISH_MCC_SUCCESS,
  FINISH_MCC_ERROR,
  REJECT_MCC_START,
  REJECT_MCC_SUCCESS,
  REJECT_MCC_ERROR,
  CREATE_START,
  CREATE_SUCCESS,
  CREATE_ERROR,
  GET_UPCOMING_START,
  GET_UPCOMING_SUCCESS,
  GET_UPCOMING_ERROR,
  GET_PAST_HELD_START,
  GET_PAST_HELD_SUCCESS,
  GET_PAST_HELD_ERROR,
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_UPCOMING_START,
  SEARCH_UPCOMING_SUCCESS,
  SEARCH_UPCOMING_ERROR,
  SEARCH_PAST_HELD_START,
  SEARCH_PAST_HELD_SUCCESS,
  SEARCH_PAST_HELD_ERROR,
  GET_PLANT_UPCOMING_START,
  GET_PLANT_UPCOMING_SUCCESS,
  GET_PLANT_UPCOMING_ERROR,
  GET_PLANT_PAST_HELD_START,
  GET_PLANT_PAST_HELD_SUCCESS,
  GET_PLANT_PAST_HELD_ERROR,
  GET_PLANT_SINGLE_START,
  GET_PLANT_SINGLE_SUCCESS,
  GET_PLANT_SINGLE_ERROR,
  FINISH_PLANT_START,
  FINISH_PLANT_SUCCESS,
  FINISH_PLANT_ERROR,
  REJECT_PLANT_START,
  REJECT_PLANT_SUCCESS,
  REJECT_PLANT_ERROR,
  EXPORT_MCC_UPCOMING_START,
  EXPORT_MCC_UPCOMING_SUCCESS,
  EXPORT_MCC_UPCOMING_ERROR,
  EXPORT_MCC_PAST_HELD_START,
  EXPORT_MCC_PAST_HELD_SUCCESS,
  EXPORT_MCC_PAST_HELD_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  EXPORT_PLANT_START,
  EXPORT_PLANT_SUCCESS,
  EXPORT_PLANT_ERROR,
  EDIT_MCC_ORDER_DETAILS_START,
  EDIT_MCC_ORDER_DETAILS_SUCCESS,
  EDIT_MCC_ORDER_DETAILS_ERROR,
  REMOVE_MCC_ORDER_DETAILS_START,
  REMOVE_MCC_ORDER_DETAILS_SUCCESS,
  REMOVE_MCC_ORDER_DETAILS_ERROR,
  ADD_MCC_ORDER_DETAILS_START,
  ADD_MCC_ORDER_DETAILS_SUCCESS,
  ADD_MCC_ORDER_DETAILS_ERROR,
  GET_COUNT_DETAILS_START,
  GET_COUNT_DETAILS_SUCCESS,
  GET_COUNT_DETAILS_ERROR,
  GET_OHS_VALUE_START,
  GET_OHS_VALUE_SUCCESS,
  GET_OHS_VALUE_ERROR,
  GET_FARMER_CODE_START,
  GET_FARMER_CODE_SUCCESS,
  GET_FARMER_CODE_ERROR,
  STORE_VALUES_START,
  GET_BATCHNO_START,
  GET_BATCHNO_SUCCESS,
  GET_BATCHNO_ERROR,
  UPDATE_UNITCODE_START,
  UPDATE_UNITCODE_SUCCESS,
  UPDATE_UNITCODE_ERROR,
} from './types';

const initialState = {
  upcoming: null,
  pastHeld: null,
  upcomingMcc: null,
  pastHeldMcc: null,
  upcomingPlant: null,
  pastHeldPlant: null,
  orders: null,
  order: null,
  message: null,
  batchNo: null,
  isLoading: false,
  isLoadingFinish: false,
  isLoadingReject: false,
  isLoadingExport: false,
  errorMessage: null,
  count: null,
  isLoadingCount: false,
  farmerCodeData: null,
  getOHSValue: null,
  storeValues: null,
  updatedValues: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MCC_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_MCC_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingMcc: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_MCC_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_MCC_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_MCC_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeldMcc: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_MCC_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_MCC_SINGLE_START:
      return {
        ...state, order: null, errorMessage: null, isLoading: true,
      };

    case GET_MCC_SINGLE_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_MCC_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case FINISH_MCC_START:
      return { ...state, errorMessage: null, isLoadingFinish: true };

    case FINISH_MCC_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingMcc: null,
        pastHeldMcc: null,
        errorMessage: null,
        isLoadingFinish: false,
      };

    case FINISH_MCC_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingFinish: false,
      };

    case REJECT_MCC_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_MCC_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingMcc: null,
        pastHeldMcc: null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_MCC_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };

    case CREATE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case CREATE_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case CREATE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_UPCOMING_SUCCESS:
      return {
        ...state,
        upcoming: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeld: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_ALL_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_SUCCESS:
      return {
        ...state,
        orders: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_UPCOMING_SUCCESS:
      return {
        ...state,
        upcoming: { paginate: payload },
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeld: { paginate: payload },
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_PLANT_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PLANT_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingPlant: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PLANT_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_PLANT_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PLANT_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeldPlant: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PLANT_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_PLANT_SINGLE_START:
      return {
        ...state, order: null, errorMessage: null, isLoading: true,
      };

    case GET_PLANT_SINGLE_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PLANT_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case FINISH_PLANT_START:
      return { ...state, errorMessage: null, isLoadingFinish: true };

    case FINISH_PLANT_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingPlant: null,
        pastHeldPlant: null,
        errorMessage: null,
        isLoadingFinish: false,
      };

    case FINISH_PLANT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingFinish: false,
      };

    case REJECT_PLANT_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_PLANT_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingPlant: null,
        pastHeldPlant: null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_PLANT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };

    case EXPORT_MCC_UPCOMING_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_MCC_UPCOMING_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_MCC_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case EXPORT_MCC_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_MCC_PAST_HELD_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_MCC_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case EXPORT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case EXPORT_PLANT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_PLANT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_PLANT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case EDIT_MCC_ORDER_DETAILS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case EDIT_MCC_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case EDIT_MCC_ORDER_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case REMOVE_MCC_ORDER_DETAILS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case REMOVE_MCC_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
      };

    case REMOVE_MCC_ORDER_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case ADD_MCC_ORDER_DETAILS_START:
      return { ...state, errorMessage: null, isLoading: true };

    case ADD_MCC_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case ADD_MCC_ORDER_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_COUNT_DETAILS_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case GET_COUNT_DETAILS_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };
    case GET_BATCHNO_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_BATCHNO_SUCCESS:
      return {
        ...state,
        batchNo: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_BATCHNO_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_COUNT_DETAILS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };

    case GET_OHS_VALUE_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_OHS_VALUE_SUCCESS:
      return {
        ...state,
        getOHSValue: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_OHS_VALUE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_FARMER_CODE_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
      };
    case GET_FARMER_CODE_SUCCESS:
      return {
        ...state,
        farmerCodeData: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_FARMER_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case UPDATE_UNITCODE_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case UPDATE_UNITCODE_SUCCESS:
      return {
        ...state,
        updatedValues: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case UPDATE_UNITCODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case STORE_VALUES_START:
      return {
        ...state,
        storeValues: payload || null,
      };
    default:
      return state;
  }
};
