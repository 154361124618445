/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './tooltip.css';

const Tooltip = (props) => (
  <div className="custom-tooltip">
    {props?.label}
    <span className="custom-tooltip-text">{props?.hoverText}</span>
  </div>
);

export default Tooltip;
