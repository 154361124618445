/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditCattleModal.scss';

const EditCattleModal = ({
  show, toggle, onSubmit, values,
}) => {
  const [age, setAge] = useState(values ? new Date(values.age) : new Date());
  const [tabletError, setTabletError] = useState(null);
  const [freqError, setFreqError] = useState(null);
  const [durationError, setDurationError] = useState(null);
  const [qtyError, setQtyError] = useState(null);
  const showHideClassName = show ? 'edit-cattle-modal display-block' : 'edit-cattle-modal display-none';
  const {
    register, handleSubmit, setValue, watch, formState: { errors },
  } = useForm();

  const watchCategory = watch('category');
  const watchCattle = watch('cattle');

  useEffect(() => {
    if (values) {
      setValue('cattle', values.cattle || 'cow');
      setValue('cattleName', values.cattleName);
      setValue('category', values.category || 'calf');
      setValue('breed', values.breed || 'hf - holstein friesian');
      setValue('weight', values.weight);
      setValue('milkProduction', values.Milking && values.Milking.length ? values.Milking[0].milkProduction : 0);
      setValue('fatInMilk', values.Milking && values.Milking.length ? values.Milking[0].fatInMilk : 0);
      setValue('pregnantMonth', values.Milking && values.Milking.length ? values.Milking[0].pregnantMonth : 0);
      setValue('lactationNo', values.Milking && values.Milking.length ? values.Milking[0].LactationNumber : 0);
      setAge(values ? new Date(values.age) : new Date());
    }
  }, [setValue, values]);

  const submit = (data) => {
    toggle();
    onSubmit({ ...data, age });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="tabletName">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Catlle</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="cattle"
                            name="cattle"
                            className="form__field"
                            {...register('cattle', { required: { value: true, message: 'Catlle is required' } })}
                          >
                            <option value="cow">Cow</option>
                            <option value="buffalo">Buffalo</option>
                          </select>
                          {tabletError && <div className="form__error">{tabletError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="cattleName"
                            name="cattleName"
                            placeholder="Name"
                            {...register('cattleName', { required: { value: true, message: 'Name is required' } })}
                          />
                          {freqError && <div className="form__error">{freqError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="durarion">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Age</span>
                        </div>
                        <div className="col-12">
                          <DatePicker
                            selected={age}
                            onChange={(date) => setAge(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form__field"
                          />
                          {durationError && <div className="form__error">{durationError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="category">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Cattle Stage</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="category"
                            name="category"
                            className="form__field"
                            {...register('category', { required: { value: true, message: 'Cattle Stage is required' } })}
                          >
                            <option value="calf">Calf</option>
                            <option value="heifer">Heifer</option>
                            <option value="milking">Milking</option>
                            <option value="dry">Dry</option>
                          </select>
                          {qtyError && <div className="form__error">{qtyError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="breed">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Breed</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="breed"
                            name="breed"
                            className="form__field"
                            {...register('breed', { required: { value: true, message: 'Breed Stage is required' } })}
                          >
                            {watchCattle === 'cow' ? (
                              <>
                                <option value="hf - holstein friesian">Hf - holstein friesian</option>
                                <option value="jersey">Jersey</option>
                                <option value="gir">Gir</option>
                                <option value="sahiwal">Sahiwal</option>
                                <option value="ongole">Ongole</option>
                                <option value="other">Other</option>
                              </>
                            )
                              : (
                                <>
                                  <option value="murrah">Murrah</option>
                                  <option value="jaffrabadi">Jaffrabadi</option>
                                  <option value="surti">Surti</option>
                                  <option value="bhadawari">Bhadawari</option>
                                  <option value="nilli ravi">Nilli ravi</option>
                                  <option value="other">Other</option>
                                </>
                              )}
                          </select>
                          {qtyError && <div className="form__error">{qtyError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Weight</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="number"
                            id="weight"
                            name="weight"
                            placeholder="Weight"
                            min={0}
                            {...register('weight', { required: { value: true, message: 'Weight Stage is required' } })}
                          />
                          {qtyError && <div className="form__error">{qtyError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  {watchCategory === 'milking' || watchCategory === 'dry' ? (
                    <>
                      <div className="form__item">
                        <label htmlFor="quantity">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Milk production</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="milkProduction"
                                name="milkProduction"
                                placeholder="Milk production"
                                min={0}
                                {...register('milkProduction')}
                              />
                              {qtyError && <div className="form__error">{qtyError}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="fatInMilk">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Fat in Milk</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="fatInMilk"
                                name="fatInMilk"
                                placeholder="Fat in Milk"
                                min={0}
                                {...register('fatInMilk')}
                              />
                              {qtyError && <div className="form__error">{qtyError}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="quantity">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">No. of month pregnant</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="pregnantMonth"
                                name="pregnantMonth"
                                placeholder="No. of month pregnant"
                                min={0}
                                {...register('pregnantMonth')}
                              />
                              {qtyError && <div className="form__error">{qtyError}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="quantity">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Lactation No</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="lactationNo"
                                name="lactationNo"
                                placeholder="Lactation No"
                                min={0}
                                {...register('lactationNo')}
                              />
                              {qtyError && <div className="form__error">{qtyError}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditCattleModal;
