const prefix = 'USER';

export const GET_PENDING_START = `${prefix}_GET_PENDING_START`;
export const GET_PENDING_SUCCESS = `${prefix}_GET_PENDING_SUCCESS`;
export const GET_PENDING_ERROR = `${prefix}_GET_PENDING_ERROR`;

export const GET_APPROVED_START = `${prefix}_GET_APPROVED_START`;
export const GET_APPROVED_SUCCESS = `${prefix}_GET_APPROVED_SUCCESS`;
export const GET_APPROVED_ERROR = `${prefix}_GET_APPROVED_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const SEARCH_PENDING_START = `${prefix}_SEARCH_PENDING_START`;
export const SEARCH_PENDING_SUCCESS = `${prefix}_SEARCH_PENDING_SUCCESS`;
export const SEARCH_PENDING_ERROR = `${prefix}_SEARCH_PENDING_ERROR`;

export const SEARCH_APPROVED_START = `${prefix}_SEARCH_APPROVED_START`;
export const SEARCH_APPROVED_SUCCESS = `${prefix}_SEARCH_APPROVED_SUCCESS`;
export const SEARCH_APPROVED_ERROR = `${prefix}_SEARCH_APPROVED_ERROR`;

export const APPROVE_START = `${prefix}_APPROVE_START`;
export const APPROVE_SUCCESS = `${prefix}_APPROVE_SUCCESS`;
export const APPROVE_ERROR = `${prefix}_APPROVE_ERROR`;

export const REJECT_START = `${prefix}_REJECT_START`;
export const REJECT_SUCCESS = `${prefix}_REJECT_SUCCESS`;
export const REJECT_ERROR = `${prefix}_REJECT_ERROR`;

export const UPDATE_USER_START = `${prefix}_UPDATE_USER_START`;
export const UPDATE_USER_SUCCESS = `${prefix}_UPDATE_USER_SUCCESS`;
export const UPDATE_USER_ERROR = `${prefix}_UPDATE_USER_ERROR`;

export const UPDATE_LOCATION_START = `${prefix}_UPDATE_LOCATION_START`;
export const UPDATE_LOCATION_SUCCESS = `${prefix}_UPDATE_LOCATION_SUCCESS`;
export const UPDATE_LOCATION_ERROR = `${prefix}_UPDATE_LOCATION_ERROR`;

export const UPDATE_CATTLE_START = `${prefix}_UPDATE_CATTLE_START`;
export const UPDATE_CATTLE_SUCCESS = `${prefix}_UPDATE_CATTLE_SUCCESS`;
export const UPDATE_CATTLE_ERROR = `${prefix}_UPDATE_CATTLE_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;

export const COUNT_START = `${prefix}_COUNT_START`;
export const COUNT_SUCCESS = `${prefix}_COUNT_SUCCESS`;
export const COUNT_ERROR = `${prefix}_COUNT_ERROR`;

export const SEARCH_BY_PHONE_START = `${prefix}_SEARCH_BY_PHONE_START`;
export const SEARCH_BY_PHONE_SUCCESS = `${prefix}_SEARCH_BY_PHONE_SUCCESS`;
export const SEARCH_BY_PHONE_ERROR = `${prefix}_SEARCH_BY_PHONE_ERROR`;

export const DELETE_START = `${prefix}_DELETE_START`;
export const DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;
export const DELETE_ERROR = `${prefix}_DELETE_ERROR`;

export const GET_EMPLOYEES_START = `${prefix}_GET_EMPLOYEES_START`;
export const GET_EMPLOYEES_SUCCESS = `${prefix}_GET_EMPLOYEES_SUCCESS`;
export const GET_EMPLOYEES_ERROR = `${prefix}_GET_EMPLOYEES_ERROR`;

export const GET_SLIPS_STATS_START = `${prefix}_GET_SLIPS_STATS_START`;
export const GET_SLIPS_STATS_SUCCESS = `${prefix}_GET_SLIPS_STATS_SUCCESS`;
export const GET_SLIPS_STATS_ERROR = `${prefix}_GET_SLIPS_STATS_ERROR`;

export const GET_SLIPS_REPORT_START = `${prefix}_GET_SLIPS_REPORT_START`;
export const GET_SLIPS_REPORT_SUCCESS = `${prefix}_GET_SLIPS_REPORT_SUCCESS`;
export const GET_SLIPS_REPORT_ERROR = `${prefix}_GET_SLIPS_REPORT_ERROR`;

export const FILTER_FIREBASE_START = `${prefix}_FILTER_FIREBASE_START`;
export const FILTER_FIREBASE_SUCCESS = `${prefix}_FILTER_FIREBASE_SUCCESS`;
export const FILTER_FIREBASE_ERROR = `${prefix}_FILTER_FIREBASE_ERROR`;

export const SEARCH_MCC_REP_BY_MCC_CODE_START = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_START`;
export const SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS`;
export const SEARCH_MCC_REP_BY_MCC_CODE_ERROR = `${prefix}_SEARCH_MCC_REP_BY_MCC_CODE_ERROR`;

export const SEARCH_PLANT_BY_PLANT_CODE_START = `${prefix}_SEARCH_PLANT_BY_PLANT_CODE_START`;
export const SEARCH_PLANT_BY_PLANT_CODE_SUCCESS = `${prefix}_SEARCH_PLANT_BY_PLANT_CODE_SUCCESS`;
export const SEARCH_PLANT_BY_PLANT_CODE_ERROR = `${prefix}_SEARCH_PLANT_BY_PLANT_CODE_ERROR`;

export const TRANSFER_MCC_REP_TO_PLANT_START = `${prefix}_TRANSFER_MCC_REP_TO_PLANT_START`;
export const TRANSFER_MCC_REP_TO_PLANT_SUCCESS = `${prefix}_TRANSFER_MCC_REP_TO_PLANT_SUCCESS`;
export const TRANSFER_MCC_REP_TO_PLANT_ERROR = `${prefix}_TRANSFER_MCC_REP_TO_PLANT_ERROR`;

export const SEARCH_CONTINUED_BY_PHONE_START = `${prefix}_SEARCH_CONTINUED_BY_PHONE_START`;
export const SEARCH_CONTINUED_BY_PHONE_SUCCESS = `${prefix}_SEARCH_CONTINUED_BY_PHONE_SUCCESS`;
export const SEARCH_CONTINUED_BY_PHONE_ERROR = `${prefix}_SEARCH_CONTINUED_BY_PHONE_ERROR`;

export const SEARCH_DISCONTINUED_BY_PHONE_START = `${prefix}_SEARCH_DISCONTINUED_BY_PHONE_START`;
export const SEARCH_DISCONTINUED_BY_PHONE_SUCCESS = `${prefix}_SEARCH_DISCONTINUED_BY_PHONE_SUCCESS`;
export const SEARCH_DISCONTINUED_BY_PHONE_ERROR = `${prefix}_SEARCH_DISCONTINUED_BY_PHONE_ERROR`;

export const CONTINUE_DISCONTINUE_START = `${prefix}_CONTINUE_DISCONTINUE_START`;
export const CONTINUE_DISCONTINUE_SUCCESS = `${prefix}_CONTINUE_DISCONTINUE_SUCCESS`;
export const CONTINUE_DISCONTINUE_ERROR = `${prefix}_CONTINUE_DISCONTINUE_ERROR`;

export const GET_DISCONTINUED_START = `${prefix}_GET_DISCONTINUED_START`;
export const GET_DISCONTINUED_SUCCESS = `${prefix}_GET_DISCONTINUED_SUCCESS`;
export const GET_DISCONTINUED_ERROR = `${prefix}_GET_DISCONTINUED_ERROR`;
