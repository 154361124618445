import {
  GET_PENDING_START,
  GET_PENDING_SUCCESS,
  GET_PENDING_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_PENDING_START,
  SEARCH_PENDING_SUCCESS,
  SEARCH_PENDING_ERROR,
  SEARCH_APPROVED_START,
  SEARCH_APPROVED_SUCCESS,
  SEARCH_APPROVED_ERROR,
  APPROVE_START,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  ADD_LOCATION_START,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  DELETE_LOCATION_START,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
  SEARCH_BY_PHONE_START,
  SEARCH_BY_PHONE_SUCCESS,
  SEARCH_BY_PHONE_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  SEARCH_MCC_REP_BY_MCC_CODE_START,
  SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
  SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
} from './types';

const initialState = {
  pending: null,
  approved: null,
  doctor: null,
  count: null,
  message: null,
  isLoading: false,
  isLoadingApprove: false,
  isLoadingReject: false,
  isLoadingExport: false,
  isLoadingCount: false,
  isLoadingDoctors: false,
  errorMessage: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PENDING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_PENDING_SUCCESS:
      return {
        ...state,
        pending: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_PENDING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_APPROVED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_APPROVED_SUCCESS:
      return {
        ...state,
        approved: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_APPROVED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_SINGLE_START:
      return {
        ...state, doctor: null, errorMessage: null, isLoading: true,
      };

    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        doctor: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_PENDING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_PENDING_SUCCESS:
      return {
        ...state,
        pending: { paginate: [payload] } || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_PENDING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_APPROVED_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_APPROVED_SUCCESS:
      return {
        ...state,
        approved: { paginate: [payload] } || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_APPROVED_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case APPROVE_START:
      return { ...state, errorMessage: null, isLoadingApprove: true };

    case APPROVE_SUCCESS:
      return {
        ...state,
        doctor: payload || null,
        errorMessage: null,
        isLoadingApprove: false,
      };

    case APPROVE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingApprove: false,
      };

    case REJECT_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_SUCCESS:
      return {
        ...state,
        doctor: payload || null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };

    case UPDATE_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        doctor: payload || null,
        message: 'Doctor updated successfully',
        errorMessage: null,
        isLoading: false,
      };

    case UPDATE_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case ADD_LOCATION_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        doctor: payload,
        message: 'Area of operation added successfully',
        errorMessage: null,
        isLoading: false,
      };

    case ADD_LOCATION_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case DELETE_LOCATION_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          DoctorRegion: state.doctor.DoctorRegion?.filter((region) => region.id !== payload),
        },
        message: 'Area of operation deleted successfully',
        errorMessage: null,
        isLoading: false,
      };

    case DELETE_LOCATION_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case EXPORT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };

    case COUNT_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case COUNT_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };

    case COUNT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };

    case SEARCH_BY_PHONE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_BY_PHONE_SUCCESS:
      return {
        ...state,
        doctor: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_BY_PHONE_ERROR:
      return {
        ...state,
        user: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case DELETE_START:
      return {
        ...state, message: null, errorMessage: null, isLoading: true,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        doctor: null,
        message: 'Doctor deleted successfully',
        errorMessage: null,
        isLoading: false,
      };

    case DELETE_ERROR:
      return {
        ...state,
        message: null,
        errorMessage: payload || null,
        isLoading: false,
      };

    case SEARCH_MCC_REP_BY_MCC_CODE_START:
      return { ...state, errorMessage: null, isLoadingUsers: true };

    case SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS:
      return {
        ...state,
        mccRep: payload || null,
        errorMessage: null,
        isLoadingUsers: false,
      };

    case SEARCH_MCC_REP_BY_MCC_CODE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingUsers: false,
      };

    default:
      return state;
  }
};
