import { createSelector } from 'reselect';

export const selectRaw = (state) => state?.newUser;

export const selectPendingUser = createSelector(
  [selectRaw],
  (state) => state?.dataFetchedFromApi,
);

export const selectCompletedUser = createSelector(
  [selectRaw],
  (state) => state?.dataFetchedWhenMarkedComplete,
);

export const selectPendingUserPlantSide = createSelector(
  [selectRaw],
  (state) => state?.dataFetchedFromApiPlantSide,
);

export const selectCompletedUserPlantSide = createSelector(
  [selectRaw],
  (state) => state?.dataFetchedFromApiPlantSideMarkedComplete,
);
export const selectIsLoading = createSelector(
  [selectRaw],
  (state) => state?.isLoading,
);

export const selectSingleUserData = createSelector(
  [selectRaw],
  (state) => state?.getSingleUserData,
);

export const selectIsLoadingApprovalPlant = createSelector(
  [selectRaw],
  (state) => state?.isLoadingFinish,
);

export const selectIsLoadingRejectionPlant = createSelector(
  [selectRaw],
  (state) => state?.isLoadingReject,
);
export const selectIsLoadingApproval = createSelector(
  [selectRaw],
  (state) => state?.isLoadingFinish,
);

export const selectIsLoadingRejection = createSelector(
  [selectRaw],
  (state) => state?.isLoadingReject,
);

export const selectSearchedData = createSelector(
  [selectRaw],
  (state) => state?.dataFetchedWhenSearched,
);

export const selectPlantCode = createSelector(
  [selectRaw],
  (state) => state?.plantCode,
);

export const selectSignature = createSelector(
  [selectRaw],
  (state) => state?.signatureData,
);
export const selectSignatureArray = createSelector(
  [selectRaw],
  (state) => state?.signatureArray,
);
export const selectIpAddress = createSelector(
  [selectRaw],
  (state) => state?.ipAddress,
);

export const selectMccData = createSelector(
  [selectRaw],
  (state) => state?.mccData,
);

export const selectDigitalRegistrationCount = createSelector(
  [selectRaw],
  (state) => state?.count,
);
export const selectPendingMccFarmersData = createSelector(
  [selectRaw],
  (state) => state?.pendingMccFarmersData,
);
export const selectCompletedMccFarmersData = createSelector(
  [selectRaw],
  (state) => state?.completedMccFarmersData,
);
export const selectMccFarmersCountData = createSelector(
  [selectRaw],
  (state) => state?.mccFarmersCount,
);
export const selectPdfDownloadUrl = createSelector(
  [selectRaw],
  (state) => state?.pdfData,
);
const selectors = {
  selectRaw,
  selectIsLoading,
  selectPendingUser,
  selectSingleUserData,
  selectCompletedUser,
  selectIsLoadingApproval,
  selectIsLoadingRejection,
  selectIsLoadingApprovalPlant,
  selectIsLoadingRejectionPlant,
  selectSearchedData,
  selectPlantCode,
  selectSignature,
  selectSignatureArray,
  selectIpAddress,
  selectMccData,
  selectPdfDownloadUrl,
  selectDigitalRegistrationCount,
  selectPendingMccFarmersData,
  selectCompletedMccFarmersData,
  selectMccFarmersCountData,
};

export default selectors;
