/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import PendingRegistration from '../../pages/PendingRegistration';
import ApprovedRegistration from '../../pages/ApprovedRegistration';
import ViewRegistaration from '../../pages/ViewRegistaration';
import DeleteUser from '../../pages/DeleteUser';
import Appointments from '../../pages/Appointments';
import CattleAppointments from '../../pages/CattleAppointments';
import Appointment from '../../pages/Appointment';
import AssignUser from '../../pages/AssignUser';
import AddMccRep from '../../pages/AddMccRep';
import Reports from '../../pages/Reports';
import MilkingReport from '../../pages/MilkingReport';
import AdminPendingOrders from '../../pages/AdminPendingOrders';
import AdminViewOrder from '../../pages/AdminViewOrder';
import SendPushNotification from '../../pages/SendPushNotification';
import TransferMccRep from '../../pages/TransferMccRep';
import Products from '../../pages/Products';
import AddProduct from '../../pages/AddProduct';
import EditProduct from '../../pages/EditProduct';
import DiscontinueUser from '../../pages/DiscontinueUser';
import VideoCreatives from '../../pages/VideoCreatives';
import PendingSales from '../../pages/PendingSales';
import ApprovedSales from '../../pages/ApprovedSales';
import ViewSale from '../../pages/ViewSale';
import EditSale from '../../pages/EditSale';
import PendingDoctors from '../../pages/PendingDoctors';
import RecordedDoctors from '../../pages/RecordedDoctors';
import ViewDoctor from '../../pages/ViewDoctor';
import PhysicalAppointments from '../../pages/PhysicalAppointments';
import Error404 from '../../pages/Error404';
import RegionHeadApprovedRequests from '../../pages/RegionalHeadApprovedRequests';
import RegionalHeadApprovedRequestsById from '../../pages/RegionalHeadApprovedRequestsById';
import './AdminDashboard.scss';
import FarmerLoan from '../../pages/FarmerLoan';
import FarmerLoanViewDetails from '../../pages/FarmerLoanViewDetails';
import UpdatePassword from '../../pages/UpdatePassword';
import PendingHpcRepRegistration from '../../pages/pendingHpcRepRegistration';
import ViewHpcRepdetailedPage from '../../pages/newHpcRepDetailedPage';
import TemporaryGPACertificates from '../../pages/TemporaryGPACertificates';
import PermanentGPACertificates from '../../pages/PermanentGPACertificates';
import DigitalEnrollmentDashboardAdminSide from '../../pages/DigitalEnrollmentDashboardAdminSide';
import MccFarmerDashboard from '../../pages/MccFarmerListViewPage/MccFarmerDashboard';
import Tickets from '../../pages/Tickets';
import RaiseTicket from '../../pages/Tickets/RaiseTicket';
import TicketDetailedPage from '../../pages/Tickets/getTicketById';
import RegionalHeadHnlIndentingApproval from '../../pages/HnlIndenting/regionalHeadHnlIndentingApproval';

const MOBILE_SIZE = 960;

const DashboardLayout = () => {
  const { path } = useRouteMatch();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const role = localStorage.getItem('role');
  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      setIsSidebarCollapsed(true);
    } else {
      setIsSidebarCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sidebarCollapsedClass = isSidebarCollapsed
    ? 'side-bar-collapsed'
    : '';

  return (
    <div className="layout">
      <div className="layout-wrapper">
        <Sidebar collapsed={isSidebarCollapsed} toggleCollapse={toggleSidebarCollapse} userRole={role === 'hnl-admin' ? 'hnl-admin' : role === 'ea_pi' ? 'ea_pi' : role === 'regional_fa' ? 'regional_fa' : role === 'regional_pi' ? 'regional_pi' : role === 'hfwa_user' ? 'hfwa_user' : role === 'call_center' ? 'call_center' : 'admin'} />
        <div className={`layout-content ${sidebarCollapsedClass}`}>
          <Header
            isSidebarCollapsed={isSidebarCollapsed}
            toggleSidebarCollapse={toggleSidebarCollapse}
          />
          <main className="main-content" role="main">
            <Switch>
              {
                role === 'admin' || role === 'regional_fa' || role === 'ea_pi' || role === 'regional_pi' || role === 'hnl-admin' ? (
                  <Route
                    exact
                    path="/admin"
                    render={() => <Redirect from="/" to="admin/users/pending" />}
                  />
                )
                  : role === 'call_center' ? (
                    <Route
                      exact
                      path="/admin"
                      render={() => <Redirect from="/" to="admin/Tickets" />}
                    />
                  )
                    : (
                      <Route
                        exact
                        path="/admin"
                        render={() => <Redirect from="/" to="admin/certificates/temporary" />}
                      />
                    )
              }

              <Route exact path={`${path}/users/dashboard`} component={DigitalEnrollmentDashboardAdminSide} />
              <Route exact path={`${path}/users/mccDashboard`} component={MccFarmerDashboard} />
              <Route exact path={`${path}/users/pending`} component={PendingRegistration} />
              <Route exact path={`${path}/users/recorded`} component={ApprovedRegistration} />
              <Route exact path={`${path}/users/discontinue`} component={DiscontinueUser} />
              <Route exact path={`${path}/users/delete`} component={DeleteUser} />
              <Route exact path={`${path}/users/:id`} component={ViewRegistaration} />
              <Route exact path={`${path}/users/:id/milking`} component={MilkingReport} />
              <Route exact path={`${path}/appointments`} component={Appointments} />
              <Route exact path={`${path}/appointments/cattle/:id`} component={CattleAppointments} />
              <Route exact path={`${path}/orders`} component={AdminPendingOrders} />
              <Route exact path={`${path}/farmerLoan`} component={FarmerLoan} />
              <Route exact path={`${path}/farmerLoan/:id`} component={FarmerLoanViewDetails} />
              <Route exact path={`${path}/updatePassword`} component={UpdatePassword} />
              <Route exact path={`${path}/newUser/pendingHpcRep`} component={PendingHpcRepRegistration} />
              <Route exact path={`${path}/newUser/:id`} component={ViewHpcRepdetailedPage} />
              <Route exact path={`${path}/orders/:id`} component={AdminViewOrder} />
              <Route exact path={`${path}/appointments/:id`} component={Appointment} />
              <Route exact path={`${path}/mcc-reps/assign`} component={AssignUser} />
              <Route exact path={`${path}/mcc-reps/add`} component={AddMccRep} />
              <Route exact path={`${path}/reports`} component={Reports} />
              <Route exact path={`${path}/notification`} component={SendPushNotification} />
              <Route exact path={`${path}/transfer-mcc`} component={TransferMccRep} />
              <Route exact path={`${path}/products`} component={Products} />
              <Route exact path={`${path}/products/add`} component={AddProduct} />
              <Route exact path={`${path}/products/:id`} component={EditProduct} />
              <Route exact path={`${path}/video-placement-creatives`} component={VideoCreatives} />
              <Route exact path={`${path}/sales/pending`} component={PendingSales} />
              <Route exact path={`${path}/sales/approved`} component={ApprovedSales} />
              <Route exact path={`${path}/sales/:id/edit`} component={EditSale} />
              <Route exact path={`${path}/sales/:id`} component={ViewSale} />
              <Route exact path={`${path}/doctors/pending`} component={PendingDoctors} />
              <Route exact path={`${path}/doctors/recorded`} component={RecordedDoctors} />
              <Route exact path={`${path}/doctors/:id`} component={ViewDoctor} />
              <Route exact path={`${path}/physical-appointments`} component={PhysicalAppointments} />
              <Route exact path={`${path}/regionalHead/pendingHPCApproval`} component={RegionHeadApprovedRequests} />
              <Route exact path={`${path}/regionHead/:id`} component={RegionalHeadApprovedRequestsById} />
              <Route exact path={`${path}/certificates/temporary`} component={TemporaryGPACertificates} />
              <Route exact path={`${path}/certificates/permanent`} component={PermanentGPACertificates} />
              <Route exact path={`${path}/Tickets`} component={Tickets} />
              <Route exact path={`${path}/Tickets/raiseTicket`} component={RaiseTicket} />
              <Route exact path={`${path}/Tickets/ticketId/:id`} component={TicketDetailedPage} />
              <Route exact path={`${path}/hnl-indenting-approval`} component={RegionalHeadHnlIndentingApproval} />

              <Route component={Error404} />
            </Switch>
          </main>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardLayout;
