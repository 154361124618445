import React, { createContext, useState } from 'react';

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export const authContext = createContext();

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);

  const value = {
    isAuthenticated,
    user,
    role,
    signin: (data, cb) => fakeAuth.signin(() => {
      setIsAuthenticated(true);
      setUser(data);
      setRole(data.role);
      cb();
    }),
    signout: (cb) => fakeAuth.signout(() => {
      setIsAuthenticated(false);
      setUser(null);
      setRole(null);
      cb();
    }),
  };

  return (
    <authContext.Provider value={value}>
      {children}
    </authContext.Provider>
  );
}
