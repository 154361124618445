import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Menu.scss';

const Menu = ({
  label, link, icon, button, onClick,
}) => (button ? (
  <li className="menuItem">
    <button type="button" className="menuItem__button" onClick={onClick}>
      <FontAwesomeIcon
        icon={icon}
        className="menuItem__icon"
      />
      <span className="menuItem__label">{label}</span>
    </button>
  </li>
) : (
  <li className="menuItem">
    <NavLink to={link} className="menuItem__link" activeClassName="active">
      <FontAwesomeIcon
        icon={icon}
        className="menuItem__icon"
      />
      <span className="menuItem__label">{label}</span>
    </NavLink>
  </li>
));

export default Menu;
