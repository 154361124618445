/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Lottie from 'lottie-react';
import Preloader from '../../assets/animations/Preloader.json';
import '../LoadingModal/LoadingModal.scss';

function LoadingModal(props) {
  const showHideClassName = props?.show ? 'modal display-block' : 'modal display-none';
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <Lottie
          animationData={props?.lottie || Preloader}
          loop={props?.loop || true}
          className="lottie"
        />
      </section>
    </div>
  );
}

export default LoadingModal;
