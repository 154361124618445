import { createSelector } from 'reselect';

export const selectRaw = (state) => state.product;

export const selectProduct = createSelector(
  [selectRaw],
  (product) => product.product,
);

export const selectProducts = createSelector(
  [selectRaw],
  (product) => product.products,
);

export const selectImages = createSelector(
  [selectRaw],
  (product) => product.images,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (product) => product.isLoading,
);

export const selectIsUploading = createSelector(
  [selectRaw],
  (product) => product.isUploading,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (product) => product.errorMessage,
);

const selectors = {
  selectRaw,
  selectProduct,
  selectProducts,
  selectImages,
  selectIsLoading,
  selectIsUploading,
  selectErrorMessage,
};

export default selectors;
