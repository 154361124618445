/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faFileExcel,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import Logo from '../../assets/images/logo.png';
import {
  getMccUpcomingOrders, getMccPastHeldOrders, exportMccUpcomingOrders, exportMccPastHeldOrders,
} from '../../store/modules/order/actions';
import {
  selectMccUpcomingOrders, selectMccPastHeldOrders, selectIsLoading, selectIsLoadingExport,
} from '../../store/modules/order/selectors';

import './PendingOrdersTable.scss';

const PendingOrders = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const upcoming = useSelector((state) => selectMccUpcomingOrders(state));
  const pastHeld = useSelector((state) => selectMccPastHeldOrders(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));

  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(getMccPastHeldOrders());
    } else {
      dispatch(getMccUpcomingOrders());
    }
  }, [tabIndex]);

  const nextUpcomingPage = (page) => {
    dispatch(getMccUpcomingOrders(page));
  };

  const previousUpcomingPage = (page) => {
    dispatch(getMccUpcomingOrders(page));
  };

  const nextPastHeldPage = (page) => {
    dispatch(getMccPastHeldOrders(page));
  };

  const previousPastHeldPage = (page) => {
    dispatch(getMccPastHeldOrders(page));
  };

  const exportUpcoming = () => {
    dispatch(exportMccUpcomingOrders());
  };

  const exportPastHeld = () => {
    dispatch(exportMccPastHeldOrders());
  };

  return (
    <>
      <div className="pending-orders">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Order Details
              </p>
            </div>
          </div>
        </div>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Upcoming
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Past Held
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            <div className="row end">
              {/* <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div> */}
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green" onClick={exportUpcoming}>
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {upcoming && upcoming.paginate && upcoming.paginate.length
                ? upcoming.paginate.map((order) => (
                  <div key={order.id} className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card__left">
                        <div className="card__avatar">
                          <img src={order.Farmer ? order.Farmer.profilePicture : Logo} alt="order" />
                        </div>
                        <div className="card__text">
                          <div>
                            <span className="card__title">
                              {
                                order?.Farmer?.role === 'HPC Farmer' ? 'HPC Farmer Name :' : order?.Farmer?.role === 'HP Representative' ? 'HP Representative Name:' : order?.Farmer?.role === 'employee' ? 'Heritage Employee Name :' : 'MCC Representative Name :'
                              }
                              {' '}

                            </span>
                            {order.Farmer ? order.Farmer.farmerName : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              MCC Representative Code :
                              {' '}
                            </span>
                            {order.Farmer ? order.Farmer.mccCode : ''}
                          </div>
                          <div>
                            <span className="card__title">
                              Order Date :
                              {' '}
                            </span>
                            {moment(order.createdAt).format('DD/MM/YYYY')}
                          </div>
                          <div>
                            <span className="card__title">
                              Order Time :
                              {' '}
                            </span>
                            {moment(order.createdAt).format('hh:mm a')}
                          </div>
                        </div>
                      </div>
                      <div className="card__right">
                        <Link to={`/plant-admin/mcc-ordersNew/${order.id}`} className="card__button">View</Link>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  null
                )}
              {!isLoading && !upcoming && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No upcoming orders found</span>
                </div>
              </div>
              )}
            </div>
            {upcoming
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Previous}
                        onClick={() => nextUpcomingPage(upcoming.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {upcoming.Next
                          ? upcoming.Next.page - 1
                          : upcoming.Previous
                            ? upcoming.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Next}
                        onClick={() => previousUpcomingPage(upcoming.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            <div className="row end">
              {/* <div className="col-12 col-md-12">
                <Search value={query} onChangeText={handleChange} onSubmit={search} />
              </div> */}
              <div className="col-12 col-md-2">
                <div className="buttons">
                  <button type="button" className="button green" onClick={exportPastHeld}>
                    <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {pastHeld && pastHeld.paginate && pastHeld.paginate.length
                ? pastHeld.paginate.map((order) => (
                  <div key={order.id} className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card__left">
                        <div className="card__avatar">
                          <img src={order.Farmer ? order.Farmer.profilePicture : Logo} alt="order" />
                        </div>
                        <div className="card__text">
                          <div className="row start">
                            <div className="col-12 col-lg-6">
                              <div>
                                <span className="card__title">
                                  {
                                order?.Farmer?.role === 'HPC Farmer' ? 'HPC Farmer Name :' : order?.Farmer?.role === 'HP Representative' ? 'HP Representative Name:' : order?.Farmer?.role === 'employee' ? 'Heritage Employee Name:' : 'MCC Representative Name :'
                              }
                                  {' '}
                                </span>
                                {order.Farmer ? order.Farmer.farmerName : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  MCC Representative Code :
                                  {' '}
                                </span>
                                {order.Farmer ? order.Farmer.mccCode : ''}
                              </div>
                              <div>
                                <span className="card__title">
                                  Order Date :
                                  {' '}
                                </span>
                                {moment(order.createdAt).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Order Time :
                                  {' '}
                                </span>
                                {moment(order.createdAt).format('hh:mm a')}
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div>
                                <span className="card__title">
                                  {order.status === 'finished' ? 'Finished Date' : 'Rejected Date'}
                                  {' '}
                                  :
                                  {' '}
                                </span>
                                {moment(order.finishedDate).format('DD/MM/YYYY')}
                              </div>
                              <div>
                                <span className="card__title">
                                  {order.status === 'finished' ? 'Finished Time' : 'Rejected Time'}
                                  {' '}
                                  :
                                  {' '}
                                </span>
                                {moment(order.finishedDate).format('hh:mm a')}
                              </div>
                              <div>
                                <span className="card__title">
                                  Total Time Taken :
                                  {' '}
                                </span>
                                {moment(order.finishedDate).diff(order.createdAt, 'days') > 0
                                  ? moment(order.finishedDate).diff(order.createdAt, 'days')
                                  : 0}
                                {' '}
                                days
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card__right">
                        <div
                          className={`card__text--${order.status === 'finished' ? 'primary'
                            : order.status === 'rejected' ? 'danger' : ''}`}
                        >
                          <span
                            className="card__title"
                          >
                            Status :
                            {' '}
                          </span>
                          {order.status === 'finished' ? 'approved' : order.status}
                        </div>
                        {
                        order?.sapStatus ? (
                          <div className="card__text--success">
                            <span
                              className="card__title"
                            >
                              Data pushed to SAP
                              {' '}
                            </span>
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : null
                       }
                        <Link
                          to={`/plant-admin/mcc-ordersNew/${order.id}`}
                          className="card__button"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  null
                )}
              {!isLoading && !pastHeld && (
                <div className="col-12 col-lg-12 d-flex-col middle">
                  <div className="notFound">
                    <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                    <span className="notFound__text">No past held orders found</span>
                  </div>
                </div>
              )}
            </div>
            {pastHeld
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Previous}
                        onClick={() => previousPastHeldPage(pastHeld.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {pastHeld.Next
                          ? pastHeld.Next.page - 1
                          : pastHeld.Previous
                            ? pastHeld.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Next}
                        onClick={() => nextPastHeldPage(pastHeld.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading || isLoadingExport} />
    </>
  );
};

export default PendingOrders;
