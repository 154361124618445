/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import {
  GET_ALL_TEMPORARY_START,
  GET_ALL_TEMPORARY_SUCCESS,
  GET_ALL_TEMPORARY_ERROR,
  GET_ALL_PERMANENT_START,
  GET_ALL_PERMANENT_SUCCESS,
  GET_ALL_PERMANENT_ERROR,
  SEARCH_TEMPORARY_START,
  SEARCH_TEMPORARY_SUCCESS,
  SEARCH_TEMPORARY_ERROR,
  SEARCH_PERMANET_START,
  SEARCH_PERMANET_SUCCESS,
  SEARCH_PERMANET_ERROR,
  FILTER_TEMPORARY_USING_DATE_START,
  FILTER_TEMPORARY_USING_DATE_SUCCESS,
  FILTER_TEMPORARY_USING_DATE_ERROR,
  FILTER_PERMANENT_USING_DATE_START,
  FILTER_PERMANENT_USING_DATE_SUCCESS,
  FILTER_PERMANENT_USING_DATE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  UPLOAD_START,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
} from './types';
import StoreService from '../../../services/Store';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getAllTempoaryGPACertificates = (value) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_TEMPORARY_START,
    });
    const response = await HttpService.get(`${config?.apiUrl}/admin/fetch-gpa?flag=temp&page=${value?.pageSize}&limit=${value?.limit}`);
    dispatch({
      type: GET_ALL_TEMPORARY_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_TEMPORARY_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllPermanentGPACertificates = (values) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PERMANENT_START,
    });
    const baseUrl = `${config?.apiUrl}/admin/fetch-gpa`;
    const params = new URLSearchParams();
    params?.append('flag', 'permanent');
    if (values) {
      Object.keys(values).forEach((key) => {
        const paramName = (key === 'pageSize') ? 'page' : key;
        params.append(paramName, values[key]);
      });
    }
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const response = await HttpService.get(`${string}`);
    dispatch({
      type: GET_ALL_PERMANENT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PERMANENT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchTemporaryGPACertificates = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_TEMPORARY_START,
    });
    const response = await HttpService.get(`${config?.apiUrl}/admin/fetch-gpa?flag=temp&phone=${value?.phone}&page=${value?.pageSize}&limit=${value?.limit}`);
    dispatch({
      type: SEARCH_TEMPORARY_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_TEMPORARY_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const searchPermanentGPACertificates = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PERMANET_START,
    });
    const response = await HttpService.get(`${config?.apiUrl}/admin/fetch-gpa?flag=permanent&phone=${value?.phone}&page=${value?.pageSize}&limit=${value?.limit}`);
    dispatch({
      type: SEARCH_PERMANET_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PERMANET_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterTemporaryGPACertificatesUsingDate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: FILTER_TEMPORARY_USING_DATE_START,
    });
    const response = await HttpService.get(`${config?.apiUrl}/admin/fetch-gpa?flag=temp&page=${value?.pageSize}&limit=${value?.limit}&fromDate=${value?.startDate}&toDate=${value?.endDate}`);
    dispatch({
      type: FILTER_TEMPORARY_USING_DATE_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_TEMPORARY_USING_DATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterPermanentGPACertificatesUsingDate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: FILTER_PERMANENT_USING_DATE_START,
    });
    const response = await HttpService.get(`${config?.apiUrl}/admin/fetch-gpa?flag=permanent&page=${value?.pageSize}&limit=${value?.limit}&fromDate=${value?.startDate}&toDate=${value?.endDate}`);
    dispatch({
      type: FILTER_PERMANENT_USING_DATE_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_PERMANENT_USING_DATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportGPACertificates = (values) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });
    let string = `${config?.apiUrl}/admin/export-gpa?flag=${values?.flag}`;
    if (values?.startDate !== null && values?.endDate !== null) {
      string = `${string}&fromDate=${values?.startDate}&toDate=${values?.endDate}`;
    }
    const response = await axios({
      url: string,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-${values?.flag === 'temp' ? 'TEMPORARY' : 'PERMANENT'}-GPA-DATA-${moment().format('DD/MM/YYYY')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const uploadFile = (value) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_START });
    const response = await HttpService.post(`${config.apiUrl}/admin/import-gpa`, value);
    dispatch({
      type: UPLOAD_SUCCESS,
      payload: response?.data,
    });
    toast.info(response?.message);
  } catch (error) {
    dispatch({
      type: UPLOAD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const countGpaCertificates = (values) => async (dispatch) => {
  try {
    dispatch({ type: COUNT_START });
    let string = '/admin/count-gpa-certificates';
    if (values?.fromDate && values?.toDate) {
      string = `${string}?fromDate=${values.fromDate}&toDate=${values.toDate}`;
    } else if (values?.fromDate) {
      string = `${string}?fromDate=${values.fromDate}`;
    } else if (values?.toDate) {
      string = `${string}?toDate=${values.toDate}`;
    } else if (values?.status) {
      string = `${string}?status=${values?.status}`;
    }
    const res = await HttpService.get(string);

    dispatch({
      type: COUNT_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
