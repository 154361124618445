import axios from 'axios';
import config from '../config/config';

export const instance = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
    Accept: 'application/json',
  },
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
instance.Cancel = axios.Cancel;

const onSuccess = (response) => response.data;

const onError = (error) => Promise.reject(error);

export const setHeaderToken = (userToken) => {
  if (userToken) {
    instance.defaults.headers.common.session = userToken;
  }
};

const request = async (options) => {
  try {
    const response = await instance(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// const request = (options) => instance(options)
//   .then(onSuccess)
//   .catch(onError);

export default request;
