import { createSelector } from 'reselect';

export const selectRaw = (state) => state?.tickets;

export const selectTicketList = createSelector(
  [selectRaw],
  (state) => state?.getTicketList,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (state) => state?.isLoading,
);

export const selectTicketByID = createSelector(
  [selectRaw],
  (state) => state?.getTicketByID,
);
export const selectErrorMessage = createSelector(
  [selectRaw],
  (state) => state?.errorMessage,
);

export const selectAutoPopulate = createSelector(
  [selectRaw],
  (state) => state?.autoPopulate,
);
export const selectAllTicketDetails = createSelector(
  [selectRaw],
  (state) => state?.storedTicketDetails,
);
const selectors = {
  selectRaw,
  selectIsLoading,
  selectTicketList,
  selectTicketByID,
  selectAutoPopulate,
  selectAllTicketDetails,
};

export default selectors;
