import { combineReducers } from 'redux';
import authReducer from './modules/auth/reducers';
import appointmentReducer from './modules/appointment/reducers';
import userReducer from './modules/user/reducers';
import orderReducer from './modules/order/reducers';
import productReducer from './modules/product/reducers';
import dataReducer from './modules/data/reducers';
import notificationReducer from './modules/notification/reducers';
import marketReducer from './modules/market/reducers';
import doctorReducer from './modules/doctor/reducers';
import farmerReducer from './modules/farmerLoan/reducers';
import newUserReducer from './modules/newUser/reducers';
import certificatesReducer from './modules/certificates/reducers';
import ticketsReducer from './modules/tickets/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  appointment: appointmentReducer,
  user: userReducer,
  order: orderReducer,
  product: productReducer,
  data: dataReducer,
  notification: notificationReducer,
  market: marketReducer,
  doctor: doctorReducer,
  farmer: farmerReducer,
  newUser: newUserReducer,
  certficates: certificatesReducer,
  tickets: ticketsReducer,
});

export default (state, action) => rootReducer(action.type === 'AUTH_LOGOUT_SUCCESS' ? undefined : state, action);
