import {
  GET_LOAN_UPCOMING_START,
  GET_LOAN_UPCOMING_SUCCESS,
  GET_LOAN_UPCOMING_ERROR,
  GET_LOAN_PAST_HELD_START,
  GET_LOAN_PAST_HELD_SUCCESS,
  GET_LOAN_PAST_HELD_ERROR,
  GET_LOAN_SINGLE_START,
  GET_LOAN_SINGLE_SUCCESS,
  GET_LOAN_SINGLE_ERROR,
  FINISH_LOAN_START,
  FINISH_LOAN_SUCCESS,
  FINISH_LOAN_ERROR,
  REJECT_LOAN_START,
  REJECT_LOAN_SUCCESS,
  REJECT_LOAN_ERROR,
  GET_ALL_COUNT_START,
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_ERROR,
} from './types';

const initialState = {
  upcoming: null,
  pastHeld: null,
  upcomingMcc: null,
  pastHeldMcc: null,
  upcomingPlant: null,
  pastHeldPlant: null,
  orders: null,
  order: null,
  message: null,
  isLoading: false,
  isLoadingFinish: false,
  isLoadingReject: false,
  isLoadingExport: false,
  errorMessage: null,
  count: null,
  isLoadingCount: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOAN_UPCOMING_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_LOAN_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingPlant: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_LOAN_UPCOMING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_LOAN_PAST_HELD_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_LOAN_PAST_HELD_SUCCESS:
      return {
        ...state,
        pastHeldPlant: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_LOAN_PAST_HELD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case GET_LOAN_SINGLE_START:
      return {
        ...state, order: null, errorMessage: null, isLoading: true,
      };

    case GET_LOAN_SINGLE_SUCCESS:
      return {
        ...state,
        order: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_LOAN_SINGLE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };

    case FINISH_LOAN_START:
      return { ...state, errorMessage: null, isLoadingFinish: true };

    case FINISH_LOAN_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingPlant: null,
        pastHeldPlant: null,
        errorMessage: null,
        isLoadingFinish: false,
      };

    case FINISH_LOAN_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingFinish: false,
      };

    case REJECT_LOAN_START:
      return { ...state, errorMessage: null, isLoadingReject: true };

    case REJECT_LOAN_SUCCESS:
      return {
        ...state,
        order: payload || null,
        upcomingPlant: null,
        pastHeldPlant: null,
        errorMessage: null,
        isLoadingReject: false,
      };

    case REJECT_LOAN_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingReject: false,
      };
    case GET_ALL_COUNT_START:
      return { ...state, errorMessage: null, isLoadingCount: true };

    case GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoadingCount: false,
      };

    case GET_ALL_COUNT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingCount: false,
      };

    default:
      return state;
  }
};
