import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'lottie-react';

const Alert = (props) => {
  const animationStyle = {
    width: '40px',
    height: '40px',
  };
  switch (props?.type) {
    case 'warning':
      return toast.warning(
        <div className="row between">
          {props?.showAnimation ? (
            <Lottie
              animationData={props?.showAnimation}
              loop={props?.loop}
              style={animationStyle}
            />
          ) : (
            <FontAwesomeIcon icon={props?.icon} style={{ marginRight: 10 }} />
          )}
          {props?.message}
        </div>,
      );
    case 'error':
      return toast.error(
        <div className="row between">
          {props?.showAnimation ? (
            <Lottie
              animationData={props?.showAnimation}
              loop={props?.loop}
              style={animationStyle}
            />
          ) : (
            <FontAwesomeIcon icon={props?.icon} style={{ marginRight: 10 }} />
          )}
          {props?.message}
        </div>,
      );
    case 'success':
      return toast.success(
        <div className="row between">
          {props?.showAnimation ? (
            <Lottie
              animationData={props?.showAnimation}
              loop={props?.loop}
              style={animationStyle}
            />
          ) : (
            <FontAwesomeIcon icon={props?.icon} style={{ marginRight: 10 }} />
          )}
          {props?.message}
        </div>,
      );
    case 'info':
      return toast.info(
        <div className="row between">
          {props?.showAnimation ? (
            <Lottie
              animationData={props?.showAnimation}
              loop={props?.loop}
              style={animationStyle}
            />
          ) : (
            <FontAwesomeIcon icon={props?.icon} style={{ marginRight: 10 }} />
          )}
          {props?.message}
        </div>,
      );
    case 'dark':
      return toast.dark(
        <div className="row between">
          {props?.showAnimation ? (
            <Lottie
              animationData={props?.showAnimation}
              loop={props?.loop}
              style={animationStyle}
            />
          ) : (
            <FontAwesomeIcon icon={props?.icon} style={{ marginRight: 10 }} />
          )}
          {props?.message}
        </div>,
      );
    default:
      return toast(props?.message);
  }
};
export default Alert;
