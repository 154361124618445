/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { adminGetAppointment } from '../../store/modules/appointment/actions';
import {
  selectAppointment,
  selectIsLoading,
  selectMessage,
} from '../../store/modules/appointment/selectors';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';

import './Appointment.scss';

const Appointment = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAppointment(state));
  const role = localStorage.getItem('role');
  const isLoading = useSelector((state) => selectIsLoading(state));
  const message = useSelector((state) => selectMessage(state));
  const userName = localStorage.getItem('userName');
  const specialization = localStorage.getItem('specialization');
  const regionName = localStorage.getItem('regionName');

  const { id } = useParams();

  useEffect(() => {
    dispatch(adminGetAppointment(id));
  }, []);

  return (
    <>
      {!isLoading && data
        ? (
          <div className="appointment">
            <div className="row center">
              <div className="col-12 col-md-6">
                <div className="row center">
                  <div className="col-12">
                    <div className="status-card">
                      {`Current status : ${data.status === 'waiting' ? 'Waiting to be finished' : data.status}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="status-card">
                      <div className="left">
                        User ID :
                        {' '}
                        {data.Farmer ? data.Farmer.id : ''}
                      </div>
                      <div className="right">
                        Appointment ID :
                        {' '}
                        {data.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item left">
                  <h4 className="title">User details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex-col sm-middle">
                        <div className="picture">
                          <img src={data.Farmer ? data.Farmer.profilePicture : ''} alt="User" />
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">
                          {data.Farmer ? data.Farmer.farmerName : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Phone Number</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">
                          {data.Farmer ? data.Farmer.phone.replace('+91', '') : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Gender</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">
                          {data.Farmer ? data.Farmer.gender : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Age</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">
                          {data.Farmer ? moment(data.Farmer.age).format('DD/MM/YYYY') : ''}
                        </div>
                      </div>
                    </div>
                    {
                      data?.Farmer?.pinCode !== null ? (
                        <div className="row between">
                          <div className="col-3">
                            <div className="content__title">Pincode</div>
                          </div>
                          <div className="col-8">
                            <div className="content__text">
                              {data?.Farmer ? data?.Farmer?.pinCode : ''}
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    <div className="row between">
                      <div className="col-3">
                        <div className="content__title">Region Name</div>
                      </div>
                      <div className="col-8">
                        <div className="content__text">
                          {data?.Farmer ? data?.Farmer?.regionName : ''}
                        </div>
                      </div>
                    </div>
                    {role === 'admin' ? (
                      <div className="row between">
                        <div className="col-3">
                          <div className="content__title">Unit Name</div>
                        </div>
                        <div className="col-8">
                          <div className="content__text">
                            {data.Farmer ? data.Farmer.unitName : ''}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {
                      role === 'admin' ? (
                        <div className="row between">
                          <div className="col-3">
                            <div className="content__title">MCC Name</div>
                          </div>
                          <div className="col-8">
                            <div className="content__text">
                              {data.Farmer ? data.Farmer.mccName : ''}
                            </div>
                          </div>
                        </div>
                      ) : null
                    }

                  </div>
                </div>
              </div>
              {data.Cattle && (
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item right">
                  <h4 className="title">Cattle details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex-col sm-middle">
                        <div className="picture">
                          <img
                            src={data.Cattle
                              ? data.Cattle.profilePicture : ''}
                            alt="Cattle"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Cattle</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.cattle : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Name</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.cattleName : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Age</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? moment(data.Cattle.age).format('DD/MM/YYYY') : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Cattle Stage</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.category : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Breed</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.breed : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-4">
                        <div className="content__title">Weight</div>
                      </div>
                      <div className="col-7">
                        <div className="content__text">
                          {data.Cattle ? data.Cattle.weight : ''}
                          {' '}
                          kg
                        </div>
                      </div>
                    </div>
                    {data.Cattle && data.Cattle.Milking && data.Cattle.Milking.length
                      ? (
                        <>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Milk Production</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].milkProduction}
                                {' '}
                                L
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Fat In Milk</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].fatInMilk}
                                {' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">No. of month pregnant</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].pregnantMonth}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Lactation No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {data.Cattle.Milking[0].LactationNumber}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                      : null}
                  </div>
                </div>
              </div>
              )}
            </div>
            {data.description && data.photos?.length && (
            <div className="row">
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item left">
                  <h4 className="title">User Enquiry</h4>
                  <div className="content">
                    {data.description}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="row right">
                  <div className="col-12 col-md-6">
                    <div className="item p-left">
                      <h4 className="title">Picture 1</h4>
                      <div className="picture">
                        <img src={data.photos[0] ? data.photos[0] : null} alt="1" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="item p-right">
                      <h4 className="title">Picture 2</h4>
                      <div className="picture">
                        <img src={data.photos[1] ? data.photos[1] : null} alt="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
            {data.status === 'finished' && (
            <div className="row">
              <div className="col-12">
                <div className="item">
                  <h4 className="title">Prescription details</h4>
                  <div className="content">
                    <div className="prescription">
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12 d-flex">
                            <div className="prescription__logo">
                              <img src={logoImg} alt="logo" />
                            </div>
                            <div className="prescription__address">
                              <div className="prescription__title">
                                Dr.
                                {' '}
                                {data.Doctor ? data.Doctor.doctorName : ''}
                              </div>
                              <div className="prescription__title">{data.Doctor ? data.Doctor.specialization : ''}</div>
                              <div className="prescription__title">{data.Doctor ? data.Doctor.regionName : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-6">
                            <div className="prescription__title">
                              Name : Dr.
                              {' '}
                              {data.Doctor ? data.Doctor.doctorName : ''}
                            </div>
                          </div>
                          <div className="col-6 d-flex-col end">
                            <div className="prescription__title">{data.Medical ? moment(data.Medical.createdAt).format('DD/MM/YYYY') : ''}</div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-6">
                            <div className="prescription__subtitle">
                              Patient ID :
                              {' '}
                              {data.Farmer ? data.Farmer.id : ''}
                            </div>
                            <div className="prescription__subtitle">
                              Cattle ID :
                              {' '}
                              {data.Cattle ? data.Cattle.id : ''}
                            </div>
                          </div>
                          <div className="col-6 d-flex-col end">
                            <div className="prescription__subtitle">
                              Prescription ID :
                              {' '}
                              {data.id}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div className="prescription__title">
                              Current diagnosis :
                              {' '}
                              {data.Medical ? data.Medical.currentDiagnosis : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div className="prescription__table-container">
                              <table className="prescription__table">
                                <thead>
                                  <tr>
                                    <th>History and Findings</th>
                                    <th>Treatment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.Medical
                                    ? (
                                      <tr>
                                        <td>{data.Medical.historyFindings}</td>
                                        <td>{data.Medical.treatment}</td>
                                      </tr>
                                    )
                                    : <tr><td colSpan={2}>none</td></tr>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription__item">
                        <div className="row">
                          <div className="col-12">
                            <div>
                              <span className="prescription__title">
                                Special instruction :
                                {' '}
                              </span>
                              <span>{data.Medical ? data.Medical.specialInstruction : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
            {data.status === 'finished' && !data.isVirtual && (
            <div className="row">
              <div className="col-12 col-lg-6 d-flex-col">
                <div className="item left">
                  <h4 className="title">Bill details</h4>
                  <div className="content">
                    <div className="row between">
                      <div className="col-8">
                        <div className="content__title">Consultation Charges</div>
                      </div>
                      <div className="col-3">
                        <div className="content__text">
                          {data.Bill?.consultationCharges || ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-8">
                        <div className="content__title">Medicine Charges</div>
                      </div>
                      <div className="col-3">
                        <div className="content__text">
                          {data.Bill?.medecineCharges || ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-8">
                        <div className="content__title">Taxes</div>
                      </div>
                      <div className="col-3">
                        <div className="content__text">
                          {data.Bill?.taxes || ''}
                        </div>
                      </div>
                    </div>
                    <div className="row between">
                      <div className="col-8">
                        <div className="content__title">Total</div>
                      </div>
                      <div className="col-3">
                        <div className="content__text">
                          {data.Bill?.total || ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        )
        : null}
      <LoadingModal
        show={
          isLoading
        }
      />
    </>
  );
};

export default Appointment;
