/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { loginDoctor } from '../../store/modules/auth/actions';
import { selectErrorMessage, selectIsLoadingAuth } from '../../store/modules/auth/selectors';
import Spinner from '../../components/Spinner';
import logo from '../../assets/images/logo.png';
import './ServiceSignup.scss';

const Form1 = ({ onStepChange }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  // const onSubmit = handleSubmit((data) => {
  //   onStepChange(2);
  // });

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    onStepChange(2);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Owner Details
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>1</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="name">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Name</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Name is required',
                    },
                  })}
                />
                {errors.name && <div className="form__error">{errors.name.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="email">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Email Address</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Email Address is required',
                    },
                  })}
                />
                {errors.email && <div className="form__error">{errors.email.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="phone">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Phone Number</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  {...register('phone', {
                    required: {
                      value: true,
                      message: 'Phone Number is required',
                    },
                  })}
                />
                <span className="form__note">Note: Pet calls will be redirected to this number. You can edit it later.</span>
                {errors.phone && <div className="form__error">{errors.phone.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="address">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Address With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Ex: 108 BABAR ROAD NEW DEHLI 110001 DEHLI"
                  {...register('address', {
                    required: {
                      value: true,
                      message: 'Address is required',
                    },
                  })}
                />
                {errors.address && <div className="form__error">{errors.address.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="buttons">
          <button
            className="button"
            type="button"
          >
            {'<< Back'}
          </button>
          <button
            className="button primary"
            type="submit"
          >
            {'Continue >>'}
          </button>
        </div>
      </form>
    </>
  );
};

const Form2 = ({ onStepChange, onDataSubmit }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  // const onSubmit = handleSubmit((data) => {
  //   onDataSubmit(data);
  // });

  const onSubmit = (data) => {
  //   onDataSubmit(data);
  };

  const onBack = (data) => {
    window.scrollTo(0, 0);
    onStepChange(1);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="title">
              <p className="title__title">
                Establishment Basic Details
              </p>
              <hr className="title__hr" />
              <p className="title__subtitle">
                Step
                {' '}
                <span>2</span>
              </p>
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentname">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Establishment name</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="establishmentname"
                  name="establishmentname"
                  placeholder="Establishment name"
                  {...register('establishmentname', {
                    required: {
                      value: true,
                      message: 'Establishment name is required',
                    },
                  })}
                />
                {errors.establishmentname && <div className="form__error">{errors.establishmentname.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="establishmentaddress">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Establishment Address With Pin Code</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="establishmentaddress"
                  name="establishmentaddress"
                  placeholder="Ex: Good-W-9/12, DLF Phase 3, Gurugram, Haryana 122002"
                  {...register('establishmentaddress', {
                    required: {
                      value: true,
                      message: 'Establishment Address is required',
                    },
                  })}
                />
                {errors.establishmentaddress && <div className="form__error">{errors.establishmentaddress.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="form__item">
          <label htmlFor="regnumber">
            <div className="row">
              <div className="col-12 mb-4">
                <span className="form__label">Registration/License number</span>
              </div>
              <div className="col-12 col-lg-6">
                <input
                  className="form__field"
                  type="text"
                  id="regnumber"
                  name="regnumber"
                  placeholder="Registration/License number"
                  {...register('regnumber', {
                    required: {
                      value: true,
                      message: 'Registration/License number is required',
                    },
                  })}
                />
                {errors.regnumber && <div className="form__error">{errors.regnumber.message}</div>}
              </div>
            </div>
          </label>
        </div>
        <div className="buttons">
          <button
            className="button"
            type="button"
            onClick={onBack}
          >
            {'<< Back'}
          </button>
          <button
            className="button primary"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const ServiceSignup = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => selectIsLoadingAuth(state));
  const errorMessage = useSelector((state) => selectErrorMessage(state));

  const history = useHistory();
  const location = useLocation();

  const onChangeStep = (newStep) => {
    setStep(newStep);
  };

  const onSubmit = (data) => {
  };

  return (
    <div className="wrap">
      <div className="container">
        <div className="service-signup">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <p className="heading__title">
                  Pets world are looking for Groomers like you.
                </p>
              </div>
            </div>
          </div>
          <div className="main">
            {step === 1 && <Form1 onStepChange={onChangeStep} />}
            {step === 2 && <Form2 onStepChange={onChangeStep} onDataSubmit={onSubmit} />}
            <div className="form__item">
              {errorMessage && <div className="form__error">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSignup;
