const prefix = 'GPA_CERTIFICATES';

export const GET_ALL_TEMPORARY_START = `${prefix}_GET_ALL_TEMPORARY_START`;
export const GET_ALL_TEMPORARY_SUCCESS = `${prefix}_GET_ALL_TEMPORARY_SUCCESS`;
export const GET_ALL_TEMPORARY_ERROR = `${prefix}_GET_ALL_TEMPORARY_ERROR`;

export const GET_ALL_PERMANENT_START = `${prefix}_GET_ALL_PERMANENT_START`;
export const GET_ALL_PERMANENT_SUCCESS = `${prefix}_GET_ALL_PERMANENT_SUCCESS`;
export const GET_ALL_PERMANENT_ERROR = `${prefix}_GET_ALL_PERMANENT_ERROR`;

export const SEARCH_TEMPORARY_START = `${prefix}_SEARCH_TEMPORARY_START`;
export const SEARCH_TEMPORARY_SUCCESS = `${prefix}_SEARCH_TEMPORARY_SUCCESS`;
export const SEARCH_TEMPORARY_ERROR = `${prefix}_SEARCH_TEMPORARY_ERROR`;

export const SEARCH_PERMANET_START = `${prefix}_SEARCH_PERMANET_START`;
export const SEARCH_PERMANET_SUCCESS = `${prefix}_SEARCH_PERMANET_SUCCESS`;
export const SEARCH_PERMANET_ERROR = `${prefix}_SEARCH_PERMANET_ERROR`;

export const FILTER_TEMPORARY_USING_DATE_START = `${prefix}_FILTER_TEMPORARY_USING_DATE_START`;
export const FILTER_TEMPORARY_USING_DATE_SUCCESS = `${prefix}_FILTER_TEMPORARY_USING_DATE_SUCCESS`;
export const FILTER_TEMPORARY_USING_DATE_ERROR = `${prefix}_FILTER_TEMPORARY_USING_DATE_ERROR`;

export const FILTER_PERMANENT_USING_DATE_START = `${prefix}_FILTER_PERMANENT_USING_DATE_START`;
export const FILTER_PERMANENT_USING_DATE_SUCCESS = `${prefix}_FILTER_PERMANENT_USING_DATE_SUCCESS`;
export const FILTER_PERMANENT_USING_DATE_ERROR = `${prefix}_FILTER_PERMANENT_USING_DATE_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;

export const UPLOAD_START = `${prefix}_UPLOAD_START`;
export const UPLOAD_SUCCESS = `${prefix}_UPLOAD_SUCCESS`;
export const UPLOAD_ERROR = `${prefix}_UPLOAD_ERROR`;

export const COUNT_START = `${prefix}_COUNT_START`;
export const COUNT_SUCCESS = `${prefix}_COUNT_SUCCESS`;
export const COUNT_ERROR = `${prefix}_COUNT_ERROR`;
