/* eslint-disable max-len */
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import {
  GET_ALL_PENDING_START,
  GET_ALL_PENDING_SUCCESS,
  GET_ALL_PENDING_ERROR,
  GET_ALL_COMPLETED_START,
  GET_ALL_COMPLETED_SUCCESS,
  GET_ALL_COMPLETED_ERROR,
  PUT_NEW_USER_START,
  PUT_NEW_USER_SUCCESS,
  PUT_NEW_USER_ERROR,
  GET_SINGLE_USER_DETAILS_REP_START,
  GET_SINGLE_USER_DETAILS_REP_SUCCESS,
  GET_SINGLE_USER_DETAILS_REP_ERROR,
  GET_SINGLE_USER_DETAILS_FARMER_START,
  GET_SINGLE_USER_DETAILS_FARMER_SUCCESS,
  GET_SINGLE_USER_DETAILS_FARMER_ERROR,
  GET_ALL_PENDING_PLANT_START,
  GET_ALL_PENDING_PLANT_SUCCESS,
  GET_ALL_PENDING_PLANT_ERROR,
  GET_ALL_COMPLETED_PLANT_START,
  GET_ALL_COMPLETED_PLANT_SUCCESS,
  GET_ALL_COMPLETED_PLANT_ERROR,
  PUT_NEW_USER_APPROVAL_PLANT_START,
  PUT_NEW_USER_APPROVAL_PLANT_SUCCESS,
  PUT_NEW_USER_APPROVAL_PLANT_ERROR,
  PUT_NEW_USER_REJECTION_PLANT_START,
  PUT_NEW_USER_REJECTION_PLANT_SUCCESS,
  PUT_NEW_USER_REJECTION_PLANT_ERROR,
  PUT_NEW_USER_APPROVAL_START,
  PUT_NEW_USER_APPROVAL_SUCCESS,
  PUT_NEW_USER_APPROVAL_ERROR,
  PUT_NEW_USER_REJECTION_START,
  PUT_NEW_USER_REJECTION_SUCCESS,
  PUT_NEW_USER_REJECTION_ERROR,
  PUT_NEW_USER_FARMERCODE_START,
  PUT_NEW_USER_FARMERCODE_SUCCESS,
  PUT_NEW_USER_FARMERCODE_ERROR,
  GET_ALL_PENDING_REGIONHEAD_START,
  GET_ALL_PENDING_REGIONHEAD_SUCCESS,
  GET_ALL_PENDING_REGIONHEAD_ERROR,
  GET_ALL_COMPLETED_REGIONHEAD_START,
  GET_ALL_COMPLETED_REGIONHEAD_SUCCESS,
  GET_ALL_COMPLETED_REGIONHEAD_ERROR,
  GET_ALL_HPC_CODES_START,
  GET_ALL_HPC_CODES_SUCCESS,
  GET_ALL_HPC_CODES_ERROR,
  EXPORT_EXCEL_PENDING_START,
  EXPORT_EXCEL_PENDING_SUCCESS,
  EXPORT_EXCEL_PENDING_ERROR,
  EXPORT_EXCEL_COMPLETED_START,
  EXPORT_EXCEL_COMPLETED_SUCCESS,
  EXPORT_EXCEL_COMPLETED_ERROR,
  GET_ALL_PLANT_CODE_START,
  GET_ALL_PLANT_CODE_ERROR,
  GET_ALL_PLANT_CODE_SUCCESS,
  STORE_DIGITAL_SIGNATURE,
  CLEAR_DIGITAL_SIGNATURE,
  STORE_DIGITAL_SIGNATURE_ERROR,
  STORE_DIGITAL_SIGNATURE_START,
  STORE_DIGITAL_SIGNATURE_SUCCESS,
  DELETE_DIGITAL_SIGNATURE_START,
  DELETE_DIGITAL_SIGNATURE_SUCCESS,
  GET_DIGITAL_SIGNATURE_START,
  GET_DIGITAL_SIGNATURE_SUCCESS,
  DELETE_DIGITAL_SIGNATURE_ERROR,
  GET_DIGITAL_SIGNATURE_ERROR,
  STORE_USER_IP_ADDRESS_START,
  STORE_USER_IP_ADDRESS_SUCCESS,
  STORE_USER_IP_ADDRESS_ERROR,
  GET_COUNT_DIGITAL_REGISTRATION_START,
  GET_COUNT_DIGITAL_REGISTRATION_SUCCESS,
  GET_COUNT_DIGITAL_REGISTRATION_ERROR,
  BULK_UPLOAD_EAPI_START,
  BULK_UPLOAD_EAPI_SUCCESS,
  BULK_UPLOAD_EAPI_ERROR,
  GET_ALL_MCC_FARMERS_START,
  GET_ALL_MCC_FARMERS_SUCCESS,
  GET_ALL_MCC_FARMERS_ERROR,
  GET_ALL_PENDING_MCC_FARMERS_START,
  GET_ALL_PENDING_MCC_FARMERS_SUCCESS,
  GET_ALL_PENDING_MCC_FARMERS_ERROR,
  GET_ALL_COMPLETED_MCC_FARMERS_START,
  GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS,
  GET_ALL_COMPLETED_MCC_FARMERS_ERROR,
  GET_ALL_COUNT_MCC_FARMERS_START,
  GET_ALL_COUNT_MCC_FARMERS_SUCCESS,
  GET_ALL_COUNT_MCC_FARMERS_ERROR,
  DOWNLOAD_PDF_START,
  DOWNLOAD_PDF_SUCCESS,
  DOWNLOAD_PDF_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';
import StoreService from '../../../services/Store';

export const handleRefreshPage = () => {
  window.location.reload();
};

export const storeUserIpAddress = () => async (dispatch) => {
  try {
    dispatch({
      type: STORE_USER_IP_ADDRESS_START,
    });

    const response = await fetch(config?.ipV4Url || config?.ip64Url);
    dispatch({
      type: STORE_USER_IP_ADDRESS_SUCCESS,
      payload: response.json(),
    });
  } catch (error) {
    dispatch({
      type: STORE_USER_IP_ADDRESS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};
export const getAllNewUserPendingRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_START });
    const response = await HttpService.get(`/admin/enrollement-list?type=pending&page=${values?.page}&limit=${values?.limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const searchByPhonePendingNewUsers = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_START });
    const response = await HttpService.get(`/admin/enrollement-list?type=pending&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchByPhoneCompletedNewUsers = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_START });
    const response = await HttpService.get(`/admin/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const getAllNewUserCompletedRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_START });
    const response = await HttpService.get(`/admin/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllNewUserPendingPlantSideRequestDetailsByType = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_PLANT_START });
    const response = await HttpService.get(`/plant/enrollement-list?type=pending&page=${page}&limit=${limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchNewUserPendingPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_PLANT_START });
    const response = await HttpService.get(`/plant/enrollement-list?type=pending&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchAllNewUserCompletedPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_PLANT_START });
    const response = await HttpService.get(`/plant/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllNewUserCompletedPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_PLANT_START });
    const response = await HttpService.get(`/plant/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateNewUsersFarmerDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user/farmer', values);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateNewUsersRepDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', values);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
      toast.success('User updated succesfully');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateNewUsersRepDetailsByTypeForPlant = (values) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/plant/enrollment-user', values);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
      toast.success('User has been updated');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepRejection = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_REJECTION_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, status: data?.status, comment: data?.comment });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_REJECTION_SUCCESS,
        payload: response.data,
      });
      toast.error('User has been rejected');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_REJECTION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepRejectionForEAandPandI = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, creatrdByEAPandI: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepRejectionpreparedByPlantIC = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_REJECTION_PLANT_START });
    const response = await HttpService.put('/plant/enrollment-user', { id: data?.id, status: data?.status, comment: data?.comment });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_REJECTION_PLANT_SUCCESS,
        payload: response.data,
      });
      toast.error('User has been rejected');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_REJECTION_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepRejectionVerifiedByRIFandA = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, verifiedByRIFandA: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepRejectionapprovedByRIPandI = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, approvedByRIPandI: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const handleNewUserRepApproval = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_APPROVAL_START });
    const response = await HttpService.put('/admin/enrollment-user', data);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_APPROVAL_SUCCESS,
        payload: response.data,
      });
      toast.success('User has been approved');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_APPROVAL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepApprovalForEAandPandI = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, creatrdByEAPandI: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepApprovalpreparedByPlantIC = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_APPROVAL_PLANT_START });
    const response = await HttpService.put('/plant/enrollment-user', data);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_APPROVAL_PLANT_SUCCESS,
        payload: response.data,
      });
      toast.success('User has been approved');
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_APPROVAL_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepApprovalVerifiedByRIFandA = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, verifiedByRIFandA: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserRepApprovalapprovedByRIPandI = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, approvedByRIPandI: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const handleNewUserFarmerRejection = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, status: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const handleNewUserFarmerApproval = (data) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/admin/enrollment-user', { id: data?.id, status: data?.status });
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getNewUserDetailsFarmerById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_USER_DETAILS_FARMER_START });
    const response = await HttpService.get(`admin/enrollment-by-user?id=${id}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_SINGLE_USER_DETAILS_FARMER_SUCCESS,
        payload: response?.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SINGLE_USER_DETAILS_FARMER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getNewUserDetailsRepById = (dataTobeReceived) => async (dispatch) => {
  dispatch(storeUserIpAddress());
  try {
    dispatch({ type: GET_SINGLE_USER_DETAILS_REP_START });
    const response = await HttpService.get(`${dataTobeReceived?.type}/enrollment-by-user?id=${dataTobeReceived?.id}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_SINGLE_USER_DETAILS_REP_SUCCESS,
        payload: response?.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SINGLE_USER_DETAILS_REP_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateNewUserFarmerCodeDetails = (dataTobeReceived) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_FARMERCODE_START });
    const response = await HttpService.put('admin/update-farmer-code', dataTobeReceived);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_FARMERCODE_SUCCESS,
        payload: response?.data,
      });
      handleRefreshPage();
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_FARMERCODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const getRHPIApprovedResultsForPending = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_REGIONHEAD_START });
    const url = `admin/region-pi-approved-requests?status=pending&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_REGIONHEAD_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_REGIONHEAD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const getRHPIApprovedResultsForCompleted = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_REGIONHEAD_START });
    const url = `admin/region-pi-approved-requests?status=completed&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_REGIONHEAD_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_REGIONHEAD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const getAllHpcCodesBySearch = (query) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_HPC_CODES_START });
    if (query?.type === 'plant') {
      const response = await HttpService.get(`${query.type}/hpc-hpr`);
      if (response?.status === 200) {
        dispatch({
          type: GET_ALL_HPC_CODES_SUCCESS,
          payload: response?.data,
        });
      }
    }
    if (query?.type === 'admin' && query?.plantCode !== undefined) {
      const response = await HttpService.get(`${query.type}/hpc-hpr?plantCode=${query?.plantCode}`);
      if (response?.status === 200) {
        dispatch({
          type: GET_ALL_HPC_CODES_SUCCESS,
          payload: response?.data,
        });
      }
    }
    if (query?.type === 'plant' && query?.hpcCode !== undefined) {
      const response = await HttpService.get(`${query.type}/hpc-hpr?hpcCode=${query?.hpcCode}`);
      if (response?.status === 200) {
        dispatch({
          type: GET_ALL_HPC_CODES_SUCCESS,
          payload: response?.data,
        });
      }
    } if (query?.type !== undefined && query?.hpcCode !== undefined && query?.plantCode !== undefined) {
      const response = await HttpService.get(`${query.type}/hpc-hpr?hpcCode=${query?.hpcCode}&plantCode=${query?.plantCode}`);
      if (response?.status === 200) {
        dispatch({
          type: GET_ALL_HPC_CODES_SUCCESS,
          payload: response?.data,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_HPC_CODES_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const getAllPlantCodes = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PLANT_CODE_START });
    const response = await HttpService.get('admin/fetch-plants');
    dispatch({
      type: GET_ALL_PLANT_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PLANT_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};
export const exportExcelForPending = (status) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_EXCEL_PENDING_START });
    const response = await axios({
      url: `${config?.apiUrl}/admin/export-ripi-approved-hpc-users?status=${status}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-RIPI-PENDING-HPC-USERS-${moment().format('YYYY-MM-DD')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    if (response?.status === 200) {
      dispatch({
        type: EXPORT_EXCEL_PENDING_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_EXCEL_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const exportExcelForCompleted = (status) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_EXCEL_COMPLETED_START });
    const response = await axios({
      url: `${config?.apiUrl}/admin/export-ripi-approved-hpc-users?status=${status}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-RIPI-PENDING-HPC-USERS-${moment().format('YYYY-MM-DD')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    if (response?.status === 200) {
      dispatch({
        type: EXPORT_EXCEL_COMPLETED_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_EXCEL_COMPLETED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const filterAllNewUserCompletedRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_START });
    const url = `/admin/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}${
      values?.plantCode ? `&plantCode=${values.plantCode}` : ''
    }${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterAllNewUserPendingRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_START });
    const url = `/admin/enrollement-list?type=pending&page=${values?.page}&limit=${values?.limit}${
      values?.plantCode ? `&plantCode=${values.plantCode}` : ''
    }${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

// plant side
export const filterPlantSideNewUsersPendingrequests = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_PLANT_START });
    const url = `/plant/enrollement-list?type=pending&page=${values?.page}&limit=${values?.limit}${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterPlantSideNewUsersCompletedrequests = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_PLANT_START });
    const url = `/plant/enrollement-list?type=completed&page=${values?.page}&limit=${values?.limit}${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_PLANT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const storeSignatureInLocal = (values) => async (dispatch) => {
  if (values) {
    dispatch({
      type: STORE_DIGITAL_SIGNATURE,
      payload: values,
    });
  }
};

export const getSignatureData = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_DIGITAL_SIGNATURE_START });
    const response = await HttpService.get(`/${values?.type}/get-signatures`);
    if (response?.status === 200) {
      dispatch({
        type: GET_DIGITAL_SIGNATURE_SUCCESS,
        payload: response?.data,
      });
      return;
    }
  } catch (error) {
    dispatch({
      type: GET_DIGITAL_SIGNATURE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};
export const postSignatureDataInPlantSide = (values) => async (dispatch) => {
  try {
    dispatch({ type: STORE_DIGITAL_SIGNATURE_START });

    const response = await HttpService.post('/plant/create-signature', values);
    dispatch({
      type: STORE_DIGITAL_SIGNATURE_SUCCESS,
      payload: response,
    });
    const dataTobepassed = {
      type: 'plant',
    };
    dispatch(getSignatureData(dataTobepassed));
  } catch (error) {
    dispatch({
      type: STORE_DIGITAL_SIGNATURE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const postSignatureDataInAdminSide = (values) => async (dispatch) => {
  try {
    dispatch({ type: STORE_DIGITAL_SIGNATURE_START });

    const response = await HttpService.post('/admin/create-signature', values);
    dispatch({
      type: STORE_DIGITAL_SIGNATURE_SUCCESS,
      payload: response,
    });
    const dataTobepassed = {
      type: 'admin',
    };
    dispatch(getSignatureData(dataTobepassed));
  } catch (error) {
    dispatch({
      type: STORE_DIGITAL_SIGNATURE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const deleteSignatureById = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DIGITAL_SIGNATURE_START });
    const response = await HttpService.delete(`/${values?.type}/delete-signature?signatureId=${values?.id}`);
    dispatch({
      type: DELETE_DIGITAL_SIGNATURE_SUCCESS,
      payload: response?.data,
    });
    dispatch(getSignatureData(values));
  } catch (error) {
    dispatch({
      type: DELETE_DIGITAL_SIGNATURE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const getcountDigitalRegistraion = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNT_DIGITAL_REGISTRATION_START });
    const response = await HttpService.get(`${values}/count-digitalEnrollment`);
    dispatch({
      type: GET_COUNT_DIGITAL_REGISTRATION_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNT_DIGITAL_REGISTRATION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};
export const bulkUploadEapi = (value, callBack) => async (dispatch) => {
  try {
    dispatch({ type: BULK_UPLOAD_EAPI_START });
    const response = await HttpService.post(`${config.apiUrl}/admin/upload-vendorCode`, value);
    dispatch({
      type: BULK_UPLOAD_EAPI_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
    toast.info(response?.message);
  } catch (error) {
    dispatch({
      type: BULK_UPLOAD_EAPI_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const clearSignatureInLocal = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DIGITAL_SIGNATURE,
    payload: null,
  });
};

export const getAllMccFarmersPendingPlantSideRequestDetailsByType = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_MCC_FARMERS_START });
    const response = await HttpService.get(`/mccFarmer/mccFarmers-dashboard?status=pending&page=${page}&limit=${limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_MCC_FARMERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const searchMccFarmersPendingPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_MCC_FARMERS_START });
    const response = await HttpService.get(`/mccFarmer/mccFarmers-dashboard?status=pending&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_MCC_FARMERS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMccFarmersCompletedPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_MCC_FARMERS_START });
    const response = await HttpService.get(`/mccFarmer/mccFarmers-dashboard?status=approved&status=rejected&page=${values?.page}&limit=${values?.limit}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchMccFarmersCompletedPlantSideRequestDetailsByType = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_MCC_FARMERS_START });
    const response = await HttpService.get(`/mccFarmer/mccFarmers-dashboard?status=completed&page=${values?.page}&limit=${values?.limit}${
      values?.phone ? `&phone=${values.phone}` : ''
    }${values?.farmerName ? `&farmerName=${values.farmerName}` : ''
    }${values?.vendorCode ? `&vendorCode=${values.vendorCode}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterMccFarmersPlantSidePendingRequests = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PENDING_MCC_FARMERS_START });
    const url = `/mccFarmer/mccFarmers-dashboard?status=pending&page=${values?.page}&limit=${values?.limit}${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_PENDING_MCC_FARMERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_PENDING_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const filterMccFarmersPlantSideCompletedRequests = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPLETED_MCC_FARMERS_START });
    const url = `/mccFarmer/mccFarmers-dashboard?status=completed&page=${values?.page}&limit=${values?.limit}${values?.hpcCode ? `&hpcCode=${values.hpcCode}` : ''
    }${values?.fromDate ? `&fromDate=${values.fromDate}` : ''
    }${values?.toDate ? `&toDate=${values.toDate}` : ''}`;
    const response = await HttpService.get(`${url}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLETED_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getMccFarmersDetailsById = (value) => async (dispatch) => {
  dispatch(storeUserIpAddress());
  try {
    dispatch({ type: GET_SINGLE_USER_DETAILS_REP_START });
    const response = await HttpService.get(`mccFarmer/mccFarmers-dashboard?id=${value?.id}`);
    if (response?.status === 200) {
      dispatch({
        type: GET_SINGLE_USER_DETAILS_REP_SUCCESS,
        payload: response?.data?.paginate[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SINGLE_USER_DETAILS_REP_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getCountMccFarmersDigitalRegistraion = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COUNT_MCC_FARMERS_START });
    const response = await HttpService.get(`${values}/mccFarmer-count-digital-registraion`);
    dispatch({
      type: GET_ALL_COUNT_MCC_FARMERS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNT_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const handleMccFarmerApprovalByPlantIC = (data, callBack) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_APPROVAL_PLANT_START });
    const response = await HttpService.put('/mccFarmer/update-mccFarmer', data);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_APPROVAL_PLANT_SUCCESS,
        payload: response.data,
      });
      toast.success('User has been Activated successfully');
    }
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_APPROVAL_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const handleMccFarmerRejectionByPlantIC = (data, callBack) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_REJECTION_PLANT_START });
    const response = await HttpService.put('/mccFarmer/update-mccFarmer', data);
    if (response?.status === 200) {
      dispatch({
        type: PUT_NEW_USER_REJECTION_PLANT_SUCCESS,
        payload: response.data,
      });
      toast.error('User has been Inactivated');
    }
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_REJECTION_PLANT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const updateMccFarmersDetailsByTypeForPlant = (values, callBack) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_USER_START });
    const response = await HttpService.put('/mccFarmer/update-mccFarmer', values);
    dispatch({
      type: PUT_NEW_USER_SUCCESS,
      payload: response.data,
    });
    toast.success('User has been updated');
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: PUT_NEW_USER_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    // toast.error(ApiErrorHandler.selectMessage(error));
  }
};
export const getAllMccFarmersData = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_MCC_FARMERS_START });
    const response = await HttpService.get(`/mccFarmer/getAllMccFarmers?page=${
      values?.page
    }&limit=${values?.limit}${
      values?.mccCode ? `&mccCode=${values?.mccCode}` : ''
    }${values?.plantCode ? `&plantCode=${values?.plantCode}` : ''}${
      values?.regionName ? `&regionName=${values?.regionName}` : ''
    }`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_MCC_FARMERS_SUCCESS,
        payload: response?.data,
      });
      toast.success('MCC Farmers Data Fetched Successfully');
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_MCC_FARMERS_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllMccFarmersCount = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNT_DIGITAL_REGISTRATION_START });
    const response = await HttpService.get('/mccFarmer/get-mccCount');
    dispatch({
      type: GET_COUNT_DIGITAL_REGISTRATION_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNT_DIGITAL_REGISTRATION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const exportExcelForMcc = (values) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_EXCEL_PENDING_START });
    const baseUrl = `${config?.apiUrl}/mccFarmer/getAllMccFarmers`;
    const params = new URLSearchParams();
    if (values?.page) params.append('page', values?.page);
    if (values?.limit) params.append('limit', values?.limit);
    if (values?.mccCode) params.append('mccCode', values?.mccCode);
    if (values?.plantCode) params.append('plantCode', values?.plantCode);
    if (values?.regionName) params.append('regionName', values?.regionName);
    if (values?.flag) params.append('flag', values?.flag);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const response = await axios({
      url: `${string}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `MCC_FARMERS_REPORT-${moment().format('YYYY-MM-DD')}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();
    if (response?.status === 200) {
      dispatch({
        type: EXPORT_EXCEL_PENDING_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_EXCEL_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const downloadDigitalPdf = (values, callBack) => async (dispatch) => {
  try {
    dispatch({ type: DOWNLOAD_PDF_START });
    const response = await HttpService.put('/admin/farmer-pdf-download ', values);
    dispatch({
      type: DOWNLOAD_PDF_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response?.data);
    }
  } catch (error) {
    dispatch({
      type: DOWNLOAD_PDF_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
