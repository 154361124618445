const selectErrorCode = (error) => {
  if (error && error.response) {
    return Number(error.response.status);
  }

  if (error && error.request) {
    return 500;
  }

  return 500; // Default to 500 if no response or request object
};

const selectErrorMessage = (error) => {
  if (error && error.response && error.response.status) {
    const { status } = error.response;
    switch (status) {
      case 400:
        return 'The server could not understand the request due to invalid syntax.';
      case 401:
        return 'You are not authorized to access this resource.';
      case 403:
        return 'Access to the requested resource is forbidden.';
      case 404:
        return 'The requested resource could not be found.';
      case 405:
        return 'The method specified in the request is not allowed for the resource.';
      case 406:
        return 'The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.';
      case 408:
        return 'The server did not receive a complete request message within the time that it was prepared to wait.';
      case 409:
        return 'The request could not be completed due to a conflict with the current state of the target resource.';
      case 410:
        return 'The requested resource is no longer available at the server and no forwarding address is known.';
      case 412:
        return 'One or more conditions given in the request header fields evaluated to false when tested on the server.';
      case 413:
        return 'The server is refusing to process a request because the request payload is larger than the server is willing or able to process.';
      case 414:
        return 'The server is refusing to service the request because the request-target is longer than the server is willing to interpret.';
      case 415:
        return 'The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method.';
      case 422:
        return 'The server understands the content type of the request entity, and the syntax of the request entity is correct, but it was unable to process the contained instructions.';
      case 429:
        return 'The user has sent too many requests in a given amount of time.';
      case 500:
        return 'The server encountered an unexpected condition that prevented it from fulfilling the request.';
      case 501:
        return 'The server does not support the functionality required to fulfill the request.';
      case 502:
        return 'The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed.';
      case 503:
        return error.response.message || 'The server is currently unavailable (overloaded or down). Please try again later.';
      case 504:
        return 'The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server specified by the URI or some other auxiliary server it needed to access in order to complete the request.';
      case 505:
        return 'The server does not support, or refuses to support, the major version of HTTP that was used in the request message.';
      // Add more cases as needed for other status codes
      default:
        return `An error occurred (${status}).`;
    }
  }

  if (error && error.request) {
    return 'There was an error with the request. Please try again later.';
  }

  return 'An unknown error occurred. Please try again later.';
};

export default class ApiErrorHandler {
  static errorCode(error) {
    return selectErrorCode(error);
  }

  static selectMessage(error) {
    return selectErrorMessage(error);
  }
}
