/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditDoctorModal.scss';

const EditDoctorModal = ({
  show, toggle, onSubmit, values,
}) => {
  const showHideClassName = show ? 'edit-user-modal display-block' : 'edit-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('doctorName', values.doctorName);
      setValue('phone', values.phone ? values.phone.replace('+91', '') : '');
      setValue('email', values.email);
      setValue('gender', values.gender);
      setValue('role', values.role);
      setValue('steps', values.steps);
    }
  }, [setValue, values]);

  const submit = ({
    doctorName,
    gender,
    role,
    steps,
  }) => {
    toggle();
    onSubmit({
      doctorName,
      gender,
      role,
      steps,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="doctorName"
                            name="doctorName"
                            placeholder="Name"
                            {...register('doctorName', { required: { value: true, message: 'Name is required' } })}
                          />
                          {errors.doctorName && <div className="form__error">{errors.doctorName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Phone number</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone number"
                            disabled
                            {...register('phone', { required: { value: true, message: 'Phone number is required' } })}
                            // value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Gender</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="gender"
                            name="gender"
                            className="form__field"
                            {...register('gender', { required: { value: true, message: 'Gender is required' } })}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {errors.gender && <div className="form__error">{errors.gender.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Email</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email"
                            disabled
                            {...register('email', { required: { value: true, message: 'Email is required' } })}
                          />
                          {errors.phone && <div className="form__error">{errors.phone.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Type of user</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="role"
                            name="role"
                            {...register('role', { required: { value: true, message: 'Type of user is required' } })}
                          >
                            <option value="veterinarian">Veterinarian</option>
                            <option value="veterinary assistant">Veterinary Assistant</option>
                            <option value="AI technician">AI Technician</option>
                            <option value="pharmacy">Pharmacy</option>
                          </select>
                          {errors.role && <div className="form__error">{errors.role.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Registration step</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="steps"
                            name="steps"
                            {...register('steps')}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="complete">complete</option>
                          </select>
                          {errors.steps && <div className="form__error">{errors.steps.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditDoctorModal;
