import React from 'react';
import {
  Link,
} from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import './Landing.scss';

const Landing = () => (
  <div className="landing">
    <div className="wrapper">
      <header className="header">
        <nav className="navbar">
          {/* <ul className="navbar__nav">
            <li className="navbar__item">
              <Link to="/" className="navbar__brand">
                <img src={logo} alt="logo" />
              </Link>
            </li>
          </ul> */}
          <ul className="navbar__nav">
            <li className="navbar__item">
              <Link to="/auth/doctor" className="navbar__link">
                For Doctors
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/auth/plant-admin" className="navbar__link">
                For Chilling Center
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/auth/admin" className="navbar__link">
                For Admin
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <main className="main-content" role="main">
        <div className="row d-flex w-100">
          <div className="col-12 d-flex-col center">
            <div className="brand">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </main>
    </div>
    {/* <Footer /> */}
  </div>
);

export default Landing;
