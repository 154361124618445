import { createSelector } from 'reselect';

export const selectRaw = (state) => state.data;

export const selectUnitNames = createSelector(
  [selectRaw],
  (data) => (data.unitNames && data.unitNames.length
    ? data.unitNames.map((item) => ({
      key: item.unitName,
      label: item.unitName,
      mccName: item.mccName,
    }))
    : []),
);

export const selectMccNames = createSelector(
  [selectRaw],
  (data) => (data.mccNames && data.mccNames.length
    ? data.mccNames.map((item) => ({
      key: item.mccName,
      label: item.mccName,
    }))
    : []),
);

export const selectUnitMccNames = createSelector(
  [selectRaw],
  (data) => data.unitMccNames,
);

export const selectUnitMccNamesNext = createSelector(
  [selectRaw],
  (data) => data.next || null,
);

export const selectMccDetails = createSelector(
  [selectRaw],
  (data) => data.mccDetails,
);

export const selectVideos = createSelector(
  [selectRaw],
  (data) => data.videos.filter((video) => video.type === 'youtube'),
);

export const selectCreatives1 = createSelector([selectRaw], (data) => data.videos.filter((video) => video.type === 'creative1'));

export const selectCreatives2 = createSelector([selectRaw], (data) => data.videos.filter((video) => video.type === 'creative2'));

export const selectVideo = createSelector(
  [selectRaw],
  (data) => data.video,
);

export const selectHeadings = createSelector(
  [selectRaw],
  (data) => data.headings,
);

export const selectHeading = createSelector(
  [selectRaw],
  (data) => data.heading,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (data) => data.isLoading,
);

export const selectIsLoadingUnitName = createSelector(
  [selectRaw],
  (data) => data.isLoadingUnitName,
);

export const selectIsLoadingMccName = createSelector(
  [selectRaw],
  (data) => data.isLoadingMccName,
);

export const selectIsLoadingMoreUnitName = createSelector(
  [selectRaw],
  (data) => data.isLoadingUnitName,
);

export const selectIsLoadingMoreMccName = createSelector(
  [selectRaw],
  (data) => data.isLoadingMccName,
);

export const selectIsLoadingExport = createSelector(
  [selectRaw],
  (user) => user.isLoadingExport,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (data) => data.errorMessage,
);

const selectors = {
  selectRaw,
  selectUnitNames,
  selectMccNames,
  selectUnitMccNames,
  selectUnitMccNamesNext,
  selectMccDetails,
  selectVideos,
  selectCreatives1,
  selectCreatives2,
  selectVideo,
  selectHeadings,
  selectHeading,
  selectIsLoading,
  selectIsLoadingUnitName,
  selectIsLoadingMccName,
  selectIsLoadingMoreUnitName,
  selectIsLoadingMoreMccName,
  selectIsLoadingExport,
  selectErrorMessage,
};

export default selectors;
