/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import EditVariantsModal from '../../components/EditVariantsModal';
import ImageSliderModal from '../../components/ImageSliderModal';
import LoadingModal from '../../components/LoadingModal';
import { getAdminProduct, upadateProduct, uploadImages } from '../../store/modules/product/actions';
import {
  selectIsLoading,
  selectIsUploading,
  selectImages,
  selectProduct,
} from '../../store/modules/product/selectors';

import './EditProduct.scss';

const EditProduct = () => {
  const [imagesFile, setImagesFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [showVariantsModal, setShowVariantsModal] = useState(false);
  const [showImageSliderModal, setShowImageSliderModal] = useState(false);
  const [product, setProduct] = useState(null);
  const [quantities, setQuantities] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [pallet, setPallet] = useState(null);
  const [variantsError, setVariantsError] = useState(null);

  const dispatch = useDispatch();
  const data = useSelector((state) => selectProduct(state));
  const uploadedImages = useSelector((state) => selectImages(state));
  const [images, setImages] = useState(uploadedImages);
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isUploading = useSelector((state) => selectIsUploading(state));
  const { id } = useParams();
  const regionName = localStorage.getItem('regionName');

  const {
    register, control, handleSubmit, setValue, watch, formState: { errors },
  } = useForm();
  const watchCategory = watch('prodCategory');

  useEffect(() => {
    dispatch(getAdminProduct(id));
  }, []);

  useEffect(() => {
    if (data) {
      setValue('prodName', data.prodName);
      setValue('prodDesc', data.prodDesc);
      setValue('prodCategory', data.prodCategory);
      setValue('prodRegionName', data.prodRegionName);
      setImages(data.prodImage);
      setQuantities(data.Quantity.map((q) => ({
        ...q,
        Pallet: q.Pallet?.map((p) => ({
          ...p,
          discount: p.discount && p.discount * 100,
        })),
      })));
    }
  }, [setValue, data]);

  useEffect(() => {
    setImages(uploadedImages);
  }, [uploadedImages]);

  useEffect(() => {
    if (quantities.length) {
      setQuantity(quantities[0]);
    }
  }, [quantities]);

  useEffect(() => {
    if (quantity) {
      setPallet(quantity.Pallet[0]);
    }
  }, [quantity]);

  useEffect(() => {
    if (imagesFile && imagesFile.length && !fileError) {
      const formData = new FormData();
      for (let i = 0; i < imagesFile.length; i++) {
        formData.append('images', imagesFile[i]);
      }
      dispatch(uploadImages(formData));
    }
  }, [imagesFile, fileError]);

  const fileReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 700 || img.height > 600) {
          setFileError('Product Image dimensions should not exceed 700 x 600');
        }
      };
      img.onerror = () => {
        setFileError('Invalid image content');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = (event) => {
    setImagesFile(null);
    const { files } = event.target;
    if (!files.length) return;
    setFileError(null);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1e6) {
        setFileError('Product Image size should not be >1MB');
        return;
      }
      fileReader(files[i]);
    }

    setImagesFile(files);
  };

  const toggleVariantsModal = () => {
    setShowVariantsModal(!showVariantsModal);
  };

  const toggleImageSliderModal = () => {
    setShowImageSliderModal(!showImageSliderModal);
  };

  const onShowVariantsModal = ({
    prodName, prodDesc, prodCategory, prodRegionName,
  }) => {
    setProduct({
      prodName, prodDesc, prodCategory, prodRegionName,
    });
    toggleVariantsModal();
  };

  const onVariantSelected = (values) => {
    setQuantities([...quantities.filter((q) => q.id !== values.id), values]);
    setValue('quantity', quantities[0]);
    setVariantsError(null);
  };

  const onQuantityChange = (e) => {
    setQuantity(quantities[e.target.value]);
  };

  const onPalletChange = (e) => {
    setPallet(quantity.Pallet[e.target.value]);
  };

  const update = ({
    prodName, prodDesc, prodCategory, prodRegionName,
  }) => {
    if (!images.length) {
      setFileError('Product Image is required');
      return;
    }
    if (!quantities.length) {
      setVariantsError('Add Product Variant');
      return;
    }

    dispatch(upadateProduct(id, {
      prodName,
      prodDesc,
      prodCategory,
      prodRegionName,
      prodImage: images,
      prodRating: 5,
      quantities: quantities.map(({ Pallet, ...rest }) => ({
        ...rest,
        pallets: Pallet.map((p) => ({
          ...p,
          discount: p.discount ? p.discount / 100 : null,
        })),
      })),
    }));
  };

  return (
    <>
      <div className="edit-product">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Edit Product
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="prodName">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Product Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="prodName"
                            name="prodName"
                            placeholder="Product Name"
                            {...register('prodName', {
                              required: {
                                value: true,
                                message: 'Product Name is required',
                              },
                              maxLength: {
                                value: 64,
                                message: 'Product Name should not exceed 64 character length',
                              },
                            })}
                          />
                          {errors.prodName && <div className="form__error">{errors.prodName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="prodDesc">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Product Description</span>
                        </div>
                        <div className="col-12">
                          <textarea
                            className="form__field"
                            rows="5"
                            type="text"
                            id="prodDesc"
                            name="prodDesc"
                            placeholder="Product Description"
                            {...register('prodDesc', {
                              required: {
                                value: true,
                                message: 'Product Description is required',
                              },
                              maxLength: {
                                value: 900,
                                message: 'Product Description should not exceed 900 character length',
                              },
                            })}
                          />
                          {errors.prodDesc && <div className="form__error">{errors.prodDesc.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="prodCategory">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Product Category</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="prodCategory"
                            name="prodCategory"
                            {...register('prodCategory', {
                              required: {
                                value: true,
                                message: 'Product Category is required',
                              },
                            })}
                          >
                            <option value="feed">Feed</option>
                            <option value="supplement">Supplement</option>
                            <option value="pharmacy">Pharmacy</option>
                          </select>
                          {errors.prodCategory && <div className="form__error">{errors.prodCategory.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12 mb-4">
                        <span className="form__label">Product Images</span>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {images.length ? images.slice(0, 2).map((img) => (
                            <div className="col">
                              <div className="picture">
                                <img key={img} src={img} alt="product" />
                              </div>
                            </div>
                          ))
                            : null}
                          {images.length > 2
                            ? (
                              <div className="col">
                                <div className="picture">
                                  <img src={images[2]} alt="product" />
                                  <div
                                    style={{ background: '#00000060' }}
                                    className="picture__placeholder"
                                    role="button"
                                    tabIndex="0"
                                    onClick={toggleImageSliderModal}
                                  >
                                    <span style={{ color: '#FFF', fontWeight: '600', fontSize: 16 }}>
                                      +
                                      {images.length - 2}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          <div className="col">
                            <label htmlFor="image">
                              <div className="picture">
                                <div style={{ background: fileUrl ? 'transparent' : '#55555520' }} className="picture__placeholder">
                                  {!fileUrl && (
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      className="picture__placeholder--icon"
                                    />
                                  )}
                                </div>
                              </div>
                              <input
                                className="form__field"
                                type="file"
                                name="files[]"
                                id="image"
                                multiple
                                onChange={handleSelectFile}
                              />
                            </label>
                          </div>
                        </div>
                        {fileError && <div className="form__error">{fileError}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <label htmlFor="prodRegionName">
                      <div className="row middle">
                        <div className="col-12 mb-4">
                          <span className="form__label">Region</span>
                        </div>
                        <div className="col-12">
                          <select
                            className="form__field"
                            id="prodRegionName"
                            name="prodRegionName"
                            disabled={regionName !== 'HYDERABAD'}
                            {...register('prodRegionName', {
                              required: {
                                value: true,
                                message: 'Region name is required',
                              },
                            })}
                          >
                            <option value="HYDERABAD-1" selected={regionName === 'HYDERABAD-1'}>HYDERABAD-1</option>
                            <option value="HYDERABAD-2" selected={regionName === 'HYDERABAD-2'}>HYDERABAD-2</option>
                            <option value="KRISHNAGIRI" selected={regionName === 'KRISHNAGIRI'}>KRISHNAGIRI</option>
                            <option value="ONGOLE" selected={regionName === 'ONGOLE'}>ONGOLE</option>
                            <option value="RAI" selected={regionName === 'RAI'}>RAI</option>
                            <option value="SANGVI" selected={regionName === 'SANGVI'}>SANGVI</option>
                            <option value="TIRUPATI" selected={regionName === 'TIRUPATI'}>TIRUPATI</option>
                            <option value="VIJAYAWADA" selected={regionName === 'VIJAYAWADA'}>VIJAYAWADA</option>
                            <option value="VIZAG" selected={regionName === 'VIZAG'}>VIZAG</option>
                          </select>
                          {errors.prodRegionName && <div className="form__error">{errors.prodRegionName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-8 mb-16">
                  <div className="d-flex">
                    {quantities.length ? (
                      <div className="form__item">
                        <button type="button" className="button primary" onClick={handleSubmit(onShowVariantsModal)}>Edit Variant</button>
                      </div>
                    ) : null}
                  </div>
                  {variantsError && <div className="form__error start">{variantsError}</div>}
                </div>
                {quantities.length
                  ? (
                    <>
                      <div className="col-12 col-md-6">
                        <div className="form__item">
                          <label htmlFor="quantity">
                            <div className="row center">
                              <div className="col-12 mb-4">
                                <span className="form__label">Quantity</span>
                              </div>
                              <div className="col-12">
                                <select
                                  className="form__field"
                                  type="text"
                                  id="quantity"
                                  name="quantity"
                                  placeholder="Quantity"
                                  onChange={onQuantityChange}
                                >
                                  {quantities.map((q, i) => (
                                    <option value={i}>{q.quantity}</option>
                                  ))}
                                </select>
                                {errors.quantity
                                  && <div className="form__error">{errors.quantity.message}</div>}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      {watchCategory === 'feed' ? (
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <label htmlFor="palletType">
                              <div className="row middle">
                                <div className="col-12 mb-4">
                                  <span className="form__label">Pallet</span>
                                </div>
                                <div className="col-12">
                                  <select
                                    className="form__field"
                                    id="palletType"
                                    name="palletType"
                                    onChange={onPalletChange}
                                  >
                                    {quantity
                                    && quantity.Pallet.map((p, i) => (
                                      <option value={i}>{p.palletType}</option>
                                    ))}
                                  </select>
                                  {errors.palletType
                                    && (
                                    <div className="form__error">
                                      {errors.palletType.message}
                                    </div>
                                    )}
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 col-md-6">
                        <div className="form__item">
                          <label htmlFor="palletPacking">
                            <div className="row middle">
                              <div className="col-12 mb-4">
                                <span className="form__label">Packing</span>
                              </div>
                              <div className="col-12">
                                <input
                                  className="form__field"
                                  type="text"
                                  id="palletPacking"
                                  name="palletPacking"
                                  placeholder="Packing"
                                  readOnly
                                  disabled
                                  defaultValue=""
                                  value={pallet ? pallet.palletPacking : ''}
                                />
                                {errors.palletPacking
                                  && (
                                  <div className="form__error">
                                    {errors.palletPacking.message}
                                  </div>
                                  )}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      {watchCategory === 'feed' ? (
                        <div className="col-12 col-md-6">
                          <div className="form__item">
                            <label htmlFor="palletSize">
                              <div className="row middle">
                                <div className="col-12 mb-4">
                                  <span className="form__label">Pallet Size</span>
                                </div>
                                <div className="col-12">
                                  <input
                                    className="form__field"
                                    type="text"
                                    id="palletSize"
                                    name="palletSize"
                                    placeholder="Pallet Size"
                                    readOnly
                                    disabled
                                    defaultValue=""
                                    value={pallet ? pallet.palletSize : ''}
                                  />
                                  {errors.palletSize
                                    && (
                                    <div className="form__error">
                                      {errors.palletSize.message}
                                    </div>
                                    )}
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 col-md-6">
                        <div className="form__item">
                          <label htmlFor="price">
                            <div className="row middle">
                              <div className="col-12 mb-4">
                                <span className="form__label">Price</span>
                              </div>
                              <div className="col-12">
                                <input
                                  className="form__field"
                                  type="text"
                                  id="price"
                                  name="price"
                                  placeholder="Price"
                                  readOnly
                                  disabled
                                  defaultValue=""
                                  value={pallet ? pallet.price : ''}
                                />
                                {errors.price
                                  && <div className="form__error">{errors.price.message}</div>}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form__item">
                          <label htmlFor="discount">
                            <div className="row middle">
                              <div className="col-12 mb-4">
                                <span className="form__label">Discount %</span>
                              </div>
                              <div className="col-12">
                                <input
                                  className="form__field"
                                  type="text"
                                  id="discount"
                                  name="discount"
                                  placeholder="Discount percent"
                                  readOnly
                                  disabled
                                  defaultValue=""
                                  value={pallet?.discount ?? ''}
                                />
                                {errors.discount
                                  && <div className="form__error">{errors.discount.message}</div>}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : null}
                <div className="col-12 d-flex-col middle">
                  <div className="buttons">
                    <button type="button" className="button green" onClick={handleSubmit(update)}>Update Product</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVariantsModal && (
      <EditVariantsModal
        show={showVariantsModal}
        toggle={toggleVariantsModal}
        onSubmit={onVariantSelected}
        product={product}
        variant={quantity}
      />
      )}
      {showImageSliderModal && (
      <ImageSliderModal
        show={showImageSliderModal}
        toggle={toggleImageSliderModal}
        images={images}
      />
      )}
      <LoadingModal show={isLoading || isUploading} />
    </>
  );
};

export default EditProduct;
