import { createSelector } from 'reselect';

export const selectRaw = (state) => state.doctor;

export const selectDoctor = createSelector(
  [selectRaw],
  (doctor) => doctor.doctor,
);

export const selectPendingDoctors = createSelector(
  [selectRaw],
  (doctor) => doctor.pending,
);

export const selectApprovedDoctors = createSelector(
  [selectRaw],
  (doctor) => doctor.approved,
);

export const selectDoctors = createSelector(
  [selectPendingDoctors, selectApprovedDoctors],
  (pending, approved) => ({
    pending: pending || null,
    approved: approved || null,
  }),
);

export const selectCount = createSelector(
  [selectRaw],
  (doctor) => doctor.count,
);

export const selectAllDoctors = createSelector(
  [selectRaw],
  (doctor) => doctor.doctors,
);

export const selectMccRep = createSelector(
  [selectRaw],
  (doctor) => doctor.mccRep,
);

export const selectMessage = createSelector(
  [selectRaw],
  (doctor) => doctor.message,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoading,
);

export const selectIsLoadingApprove = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoadingApprove,
);

export const selectIsLoadingReject = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoadingReject,
);

export const selectIsLoadingExport = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoadingExport,
);

export const selectIsLoadingCount = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoadingCount,
);

export const selectIsLoadingDoctors = createSelector(
  [selectRaw],
  (doctor) => doctor.isLoadingDoctors,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (doctor) => doctor.errorMessage,
);

const selectors = {
  selectRaw,
  selectDoctor,
  selectPendingDoctors,
  selectApprovedDoctors,
  selectDoctors,
  selectCount,
  selectAllDoctors,
  selectMccRep,
  selectMessage,
  selectIsLoading,
  selectIsLoadingApprove,
  selectIsLoadingReject,
  selectIsLoadingExport,
  selectIsLoadingCount,
  selectIsLoadingDoctors,
  selectErrorMessage,
};

export default selectors;
