import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import logoImg from '../../assets/images/logo.png';
import styles from './styles';

const Prescription = ({
  data: {
    userName,
    specialization,
    regionName,
    farmerId,
    cattleId,
    prescriptionId,
    values,
  },
}) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.prescription}>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.logo}>
              <Image source={logoImg} alt="logo" style={styles.logoImg} />
            </View>
            <View style={styles.address}>
              <Text style={styles.title}>
                Dr.
                {' '}
                {userName}
              </Text>
              <Text style={styles.title}>{specialization}</Text>
              <Text style={styles.title}>{regionName}</Text>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.col6}>
              <Text style={styles.title}>
                Name : Dr.
                {' '}
                {userName}
              </Text>
            </View>
            <View style={[styles.col6, styles.end]}>
              <Text style={styles.title}>{moment().format('DD/MM/YYYY')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.col6}>
              <Text style={styles.subtitle}>
                Patient ID :
                {' '}
                {farmerId}
              </Text>
              <Text style={styles.subtitle}>
                Cattle ID :
                {' '}
                {cattleId}
              </Text>
            </View>
            <View style={[styles.col6, styles.end]}>
              <Text style={styles.subtitle}>
                Prescription ID :
                {' '}
                {prescriptionId}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.col12}>
              <Text style={styles.title}>
                Current diagnosis :
                {' '}
                {values.currentDiagnosis}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.col12}>
              <View style={styles.tableContainer}>
                <View style={styles.table}>
                  <View style={styles.tableHeader}>
                    <View style={styles.tr}>
                      <View style={styles.th}>
                        <Text>History and Findings</Text>
                      </View>
                      <View style={styles.th}>
                        <Text>Treatment</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tableBody}>
                    {!!values.historyAndFindings && !!values.treatment
                      ? (
                        <View style={styles.tr}>
                          <View style={styles.td}><Text>{values.historyAndFindings}</Text></View>
                          <View style={styles.td}><Text>{values.treatment}</Text></View>
                        </View>
                      )
                      : (
                        <View style={styles.tr}>
                          <View style={styles.td}><Text>none</Text></View>
                        </View>
                      )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={styles.row}>
            <View style={styles.col12}>
              <View>
                <Text style={styles.title}>
                  Special instruction :
                  {' '}
                </Text>
                <Text style={styles.text}>{values.specialInstruction}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default Prescription;
