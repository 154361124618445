const prefix = 'AUTH';

export const AUTH_INIT_SUCCESS = `${prefix}_INIT_SUCCESS`;
export const AUTH_INIT_ERROR = `${prefix}_INIT_ERROR`;

export const AUTH_START = `${prefix}_START`;
export const AUTH_SUCCESS = `${prefix}_SUCCESS`;
export const AUTH_ERROR = `${prefix}_ERROR`;

export const PASSWORD_RESET_START = `${prefix}_PASSWORD_RESET_START`;
export const PASSWORD_RESET_SUCCESS = `${prefix}_PASSWORD_RESET_SUCCESS`;
export const PASSWORD_RESET_ERROR = `${prefix}_PASSWORD_RESET_ERROR`;

export const UPDATE_PROFILE_START = `${prefix}_UPDATE_PROFILE_START`;
export const UPDATE_PROFILE_SUCCESS = `${prefix}_UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_ERROR = `${prefix}_UPDATE_PROFILE_ERROR`;

export const UPDATE_STATUS_START = `${prefix}_UPDATE_STATUS_START`;
export const UPDATE_STATUS_SUCCESS = `${prefix}_UPDATE_STATUS_SUCCESS`;
export const UPDATE_STATUS_ERROR = `${prefix}_UPDATE_STATUS_ERROR`;

export const UPDATE_PASSWORD_START = `${prefix}_UPDATE_PASSWORD_START`;
export const UPDATE_PASSWORD_SUCCESS = `${prefix}_UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_ERROR = `${prefix}_UPDATE_PASSWORD_ERROR`;

export const LOGOUT_START = `${prefix}_LOGOUT_START`;
export const LOGOUT_SUCCESS = `${prefix}_LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `${prefix}_LOGOUT_ERROR`;
