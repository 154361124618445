/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import {
  SEND_START,
  SEND_SUCCESS,
  SEND_ERROR,
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';

export const sendNotification = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_START });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post('/notification/schedule-push-notification', data, config);

    dispatch({
      type: SEND_SUCCESS,
      payload: res.message,
    });
    toast.success(res.message);
  } catch (error) {
    dispatch({
      type: SEND_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getNotifications = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });

    const res = await HttpService.get(`/notification/view-scheduled-push-notifications?page=${page}&limit=${limit}`);

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_START });

    const res = await HttpService.delete(`/notification/delete-scheduled-push-notification/${id}`);

    dispatch({
      type: DELETE_SUCCESS,
      payload: res.message,
    });
    toast.success('Notification deleted successfully');
  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
