import {
  GET_ALL_PENDING_START,
  GET_ALL_PENDING_SUCCESS,
  GET_ALL_PENDING_ERROR,
  GET_ALL_COMPLETED_START,
  GET_ALL_COMPLETED_SUCCESS,
  GET_ALL_COMPLETED_ERROR,
  PUT_NEW_USER_START,
  PUT_NEW_USER_SUCCESS,
  PUT_NEW_USER_ERROR,
  GET_SINGLE_USER_DETAILS_REP_START,
  GET_SINGLE_USER_DETAILS_REP_SUCCESS,
  GET_SINGLE_USER_DETAILS_REP_ERROR,
  GET_SINGLE_USER_DETAILS_FARMER_START,
  GET_SINGLE_USER_DETAILS_FARMER_SUCCESS,
  GET_SINGLE_USER_DETAILS_FARMER_ERROR,
  GET_ALL_PENDING_PLANT_START,
  GET_ALL_PENDING_PLANT_SUCCESS,
  GET_ALL_PENDING_PLANT_ERROR,
  GET_ALL_COMPLETED_PLANT_START,
  GET_ALL_COMPLETED_PLANT_SUCCESS,
  GET_ALL_COMPLETED_PLANT_ERROR,
  PUT_NEW_USER_APPROVAL_PLANT_START,
  PUT_NEW_USER_APPROVAL_PLANT_SUCCESS,
  PUT_NEW_USER_APPROVAL_PLANT_ERROR,
  PUT_NEW_USER_REJECTION_PLANT_START,
  PUT_NEW_USER_REJECTION_PLANT_SUCCESS,
  PUT_NEW_USER_REJECTION_PLANT_ERROR,
  PUT_NEW_USER_APPROVAL_START,
  PUT_NEW_USER_APPROVAL_SUCCESS,
  PUT_NEW_USER_APPROVAL_ERROR,
  PUT_NEW_USER_REJECTION_START,
  PUT_NEW_USER_REJECTION_SUCCESS,
  PUT_NEW_USER_REJECTION_ERROR,
  PUT_NEW_USER_FARMERCODE_START,
  PUT_NEW_USER_FARMERCODE_SUCCESS,
  PUT_NEW_USER_FARMERCODE_ERROR,
  GET_ALL_PENDING_REGIONHEAD_START,
  GET_ALL_PENDING_REGIONHEAD_SUCCESS,
  GET_ALL_PENDING_REGIONHEAD_ERROR,
  GET_ALL_COMPLETED_REGIONHEAD_START,
  GET_ALL_COMPLETED_REGIONHEAD_SUCCESS,
  GET_ALL_COMPLETED_REGIONHEAD_ERROR,
  GET_ALL_HPC_CODES_START,
  GET_ALL_HPC_CODES_SUCCESS,
  GET_ALL_HPC_CODES_ERROR,
  GET_ALL_PLANT_CODE_START,
  GET_ALL_PLANT_CODE_SUCCESS,
  GET_ALL_PLANT_CODE_ERROR,
  STORE_DIGITAL_SIGNATURE,
  CLEAR_DIGITAL_SIGNATURE,
  STORE_DIGITAL_SIGNATURE_START,
  STORE_DIGITAL_SIGNATURE_SUCCESS,
  STORE_DIGITAL_SIGNATURE_ERROR,
  DELETE_DIGITAL_SIGNATURE_START,
  DELETE_DIGITAL_SIGNATURE_SUCCESS,
  DELETE_DIGITAL_SIGNATURE_ERROR,
  GET_DIGITAL_SIGNATURE_START,
  GET_DIGITAL_SIGNATURE_SUCCESS,
  GET_DIGITAL_SIGNATURE_ERROR,
  STORE_USER_IP_ADDRESS_START,
  STORE_USER_IP_ADDRESS_SUCCESS,
  STORE_USER_IP_ADDRESS_ERROR,
  GET_COUNT_DIGITAL_REGISTRATION_START,
  GET_COUNT_DIGITAL_REGISTRATION_SUCCESS,
  GET_COUNT_DIGITAL_REGISTRATION_ERROR,
  BULK_UPLOAD_EAPI_START,
  BULK_UPLOAD_EAPI_SUCCESS,
  BULK_UPLOAD_EAPI_ERROR,
  GET_ALL_MCC_FARMERS_START,
  GET_ALL_MCC_FARMERS_SUCCESS,
  GET_ALL_MCC_FARMERS_ERROR,
  GET_ALL_PENDING_MCC_FARMERS_START,
  GET_ALL_PENDING_MCC_FARMERS_SUCCESS,
  GET_ALL_PENDING_MCC_FARMERS_ERROR,
  GET_ALL_COMPLETED_MCC_FARMERS_START,
  GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS,
  GET_ALL_COMPLETED_MCC_FARMERS_ERROR,
  GET_ALL_COUNT_MCC_FARMERS_START,
  GET_ALL_COUNT_MCC_FARMERS_SUCCESS,
  GET_ALL_COUNT_MCC_FARMERS_ERROR,
  DOWNLOAD_PDF_START,
  DOWNLOAD_PDF_SUCCESS,
  DOWNLOAD_PDF_ERROR,
} from './types';

const initialState = {
  dataFetchedFromApi: null,
  dataPostedToApi: null,
  count: null,
  dataFetchedFromApiPlantSide: null,
  dataFetchedWhenMarkedComplete: null,
  dataFetchedFromApiPlantSideMarkedComplete: null,
  dataFetchedWhenSearched: null,
  getSingleUserData: null,
  plantCode: null,
  isLoading: false,
  isLoadingFinish: false,
  isLoadingReject: false,
  errorMessage: null,
  signatureData: null,
  signatureArray: null,
  ipAddress: null,
  mccData: null,
  pdfData: null,
  pendingMccFarmersData: null,
  completedMccFarmersData: null,
  mccFarmersCount: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PENDING_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_PENDING_SUCCESS:
      return {
        ...state,
        dataFetchedFromApi: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_ALL_PENDING_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_COMPLETED_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_COMPLETED_SUCCESS:
      return {
        ...state,
        dataFetchedWhenMarkedComplete: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_COMPLETED_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case PUT_NEW_USER_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        dataFetchedFromApi: null,
        isLoading: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case GET_SINGLE_USER_DETAILS_REP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_SINGLE_USER_DETAILS_REP_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_SINGLE_USER_DETAILS_REP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case GET_SINGLE_USER_DETAILS_FARMER_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_SINGLE_USER_DETAILS_FARMER_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_SINGLE_USER_DETAILS_FARMER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_PENDING_PLANT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_ALL_PENDING_PLANT_SUCCESS:
      return {
        ...state,
        dataFetchedFromApiPlantSide: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_PENDING_PLANT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };

    case GET_ALL_COMPLETED_PLANT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_ALL_COMPLETED_PLANT_SUCCESS:
      return {
        ...state,
        dataFetchedFromApiPlantSideMarkedComplete: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_COMPLETED_PLANT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_APPROVAL_PLANT_START:
      return {
        ...state,
        isLoadingFinish: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_APPROVAL_PLANT_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoadingFinish: false,
        errorMessage: null,
        dataFetchedFromApiPlantSide: null,
        dataFetchedFromApiPlantSideMarkedComplete: null,
      };
    case PUT_NEW_USER_APPROVAL_PLANT_ERROR:
      return {
        ...state,
        isLoadingFinish: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_REJECTION_PLANT_START:
      return {
        ...state,
        isLoadingReject: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_REJECTION_PLANT_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoadingReject: false,
        errorMessage: null,
        dataFetchedFromApiPlantSide: null,
        dataFetchedFromApiPlantSideMarkedComplete: null,
      };
    case PUT_NEW_USER_REJECTION_PLANT_ERROR:
      return {
        ...state,
        isLoadingReject: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_APPROVAL_START:
      return {
        ...state,
        isLoadingFinish: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoadingFinish: false,
        errorMessage: null,
        dataFetchedFromApi: null,
        dataFetchedWhenMarkedComplete: null,
      };
    case PUT_NEW_USER_APPROVAL_ERROR:
      return {
        ...state,
        isLoadingFinish: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_REJECTION_START:
      return {
        ...state,
        isLoadingReject: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_REJECTION_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        isLoadingReject: false,
        errorMessage: null,
        dataFetchedFromApi: null,
        dataFetchedWhenMarkedComplete: null,
      };
    case PUT_NEW_USER_REJECTION_ERROR:
      return {
        ...state,
        isLoadingReject: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_FARMERCODE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case PUT_NEW_USER_FARMERCODE_SUCCESS:
      return {
        ...state,
        getSingleUserData: payload || null,
        dataFetchedFromApi: null,
        isLoading: false,
        errorMessage: null,
      };
    case PUT_NEW_USER_FARMERCODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case GET_ALL_PENDING_REGIONHEAD_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_PENDING_REGIONHEAD_SUCCESS:
      return {
        ...state,
        dataFetchedFromApi: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_ALL_PENDING_REGIONHEAD_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_COMPLETED_REGIONHEAD_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_COMPLETED_REGIONHEAD_SUCCESS:
      return {
        ...state,
        dataFetchedWhenMarkedComplete: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_COMPLETED_REGIONHEAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case GET_ALL_HPC_CODES_START:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: true,
      };
    case GET_ALL_HPC_CODES_SUCCESS:
      return {
        ...state,
        dataFetchedWhenSearched: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_HPC_CODES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case GET_ALL_PLANT_CODE_START:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: true,
      };
    case GET_ALL_PLANT_CODE_SUCCESS:
      return {
        ...state,
        plantCode: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_PLANT_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case STORE_DIGITAL_SIGNATURE:
      return {
        ...state,
        signatureData: payload || null,
      };
    case CLEAR_DIGITAL_SIGNATURE:
      return {
        ...state,
        signatureData: null,
      };
    case STORE_DIGITAL_SIGNATURE_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case STORE_DIGITAL_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureData: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case STORE_DIGITAL_SIGNATURE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_DIGITAL_SIGNATURE_START:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: true,
      };
    case GET_DIGITAL_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureArray: payload || null,
        isLoading: false,
      };
    case GET_DIGITAL_SIGNATURE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case DELETE_DIGITAL_SIGNATURE_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case DELETE_DIGITAL_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureArray: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case DELETE_DIGITAL_SIGNATURE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case STORE_USER_IP_ADDRESS_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case STORE_USER_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        ipAddress: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case STORE_USER_IP_ADDRESS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_COUNT_DIGITAL_REGISTRATION_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_COUNT_DIGITAL_REGISTRATION_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_COUNT_DIGITAL_REGISTRATION_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case BULK_UPLOAD_EAPI_START:
      return { ...state, errorMessage: null, isLoading: true };

    case BULK_UPLOAD_EAPI_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
      };

    case BULK_UPLOAD_EAPI_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_MCC_FARMERS_START:
      return {
        ...state, errorMessage: null, isLoading: true,
      };
    case GET_ALL_MCC_FARMERS_SUCCESS:
      return {
        ...state,
        mccData: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_ALL_MCC_FARMERS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_PENDING_MCC_FARMERS_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_PENDING_MCC_FARMERS_SUCCESS:
      return {
        ...state,
        pendingMccFarmersData: payload || null,
        errorMessage: null,
        isLoading: false,
      };
    case GET_ALL_PENDING_MCC_FARMERS_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_COMPLETED_MCC_FARMERS_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_COMPLETED_MCC_FARMERS_SUCCESS:
      return {
        ...state,
        completedMccFarmersData: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_COMPLETED_MCC_FARMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case GET_ALL_COUNT_MCC_FARMERS_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case GET_ALL_COUNT_MCC_FARMERS_SUCCESS:
      return {
        ...state,
        mccFarmersCount: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case GET_ALL_COUNT_MCC_FARMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    case DOWNLOAD_PDF_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    case DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        pdfData: payload || null,
        isLoading: false,
        errorMessage: null,
      };
    case DOWNLOAD_PDF_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload || null,
      };
    default:
      return state;
  }
};
