import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  GET_PENDING_START,
  GET_PENDING_SUCCESS,
  GET_PENDING_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_PENDING_START,
  SEARCH_PENDING_SUCCESS,
  SEARCH_PENDING_ERROR,
  SEARCH_APPROVED_START,
  SEARCH_APPROVED_SUCCESS,
  SEARCH_APPROVED_ERROR,
  APPROVE_START,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  ADD_LOCATION_START,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  DELETE_LOCATION_START,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
  SEARCH_MCC_REP_BY_MCC_CODE_START,
  SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
  SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getPendingDoctors = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PENDING_START });

    const res = await HttpService.post(
      `${config.doctorApiUrl}/admin/view-doctors-by-status?page=${page}&limit=${limit}`,
      { status: 'waiting' },
    );

    dispatch({
      type: GET_PENDING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getApprovedDoctors = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_APPROVED_START });

    const res = await HttpService.post(
      `${config.doctorApiUrl}/admin/view-doctors-by-status?page=${page}&limit=${limit}`,
      { status: 'confirmed' },
    );

    dispatch({
      type: GET_APPROVED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_APPROVED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getDoctor = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`${config.doctorApiUrl}/admin/view-doctor-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchPendingDoctors = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PENDING_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/search-doctor-by-status-and-phone/${phone}`, { status: 'waiting' });

    dispatch({
      type: SEARCH_PENDING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PENDING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchApprovedDoctors = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_APPROVED_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/search-doctor-by-status-and-phone/${phone}`, { status: 'confirmed' });

    dispatch({
      type: SEARCH_APPROVED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_APPROVED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const approveDoctor = (id) => async (dispatch) => {
  try {
    dispatch({ type: APPROVE_START });

    const res = await HttpService.patch(
      `${config.doctorApiUrl}/admin/update-doctor-status/${id}`,
      { status: 'confirmed' },
    );

    dispatch({
      type: APPROVE_SUCCESS,
      payload: res.data,
    });

    toast.success('Doctor approved successfully');
  } catch (error) {
    dispatch({
      type: APPROVE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const rejectDoctor = (id) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_START });

    const res = await HttpService.patch(
      `${config.doctorApiUrl}/admin/update-doctor-status/${id}`,
      { status: 'rejected' },
    );

    dispatch({
      type: REJECT_SUCCESS,
      payload: res.data,
    });
    toast.success('Doctor rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const editDoctor = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });

    const res = await HttpService.patch(`${config.doctorApiUrl}/admin/update-doctor-details/${id}`, values);

    dispatch({
      type: UPDATE_SUCCESS,
      payload: res.data,
    });
    toast.success('Doctor updated successfully');
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const addLocation = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LOCATION_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/create-doctor-region/${id}`, values);

    dispatch({
      type: ADD_LOCATION_SUCCESS,
      payload: res.data,
    });
    toast.success('Area of operation added successfully');
  } catch (error) {
    dispatch({
      type: ADD_LOCATION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteLocation = (id, doctorId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_LOCATION_START });

    await HttpService.delete(`${config.doctorApiUrl}/admin/delete-doctor-region/${id}/${doctorId}`);

    dispatch({
      type: DELETE_LOCATION_SUCCESS,
      payload: id,
    });
    toast.success('Area of operation deleted successfully');
  } catch (error) {
    dispatch({
      type: DELETE_LOCATION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportPendingDoctors = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.doctorApiUrl}/admin/report-doctors-by-status`,
      method: 'POST',
      data: { status: 'waiting' },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Pending-Doctors-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportApprovedDoctors = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.doctorApiUrl}/admin/report-doctors-by-status`,
      method: 'POST',
      data: { status: 'confirmed' },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Approved-Doctors-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const countDoctors = () => async (dispatch) => {
  try {
    dispatch({ type: COUNT_START });

    const res = await HttpService.get(`${config.doctorApiUrl}/admin/count-doctors-by-role`);

    dispatch({
      type: COUNT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchMccRepByMccCode = (mccCode) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_MCC_REP_BY_MCC_CODE_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/admin/search-mcc-rep-by-mcc-Coce/${mccCode}`);

    dispatch({
      type: SEARCH_MCC_REP_BY_MCC_CODE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_MCC_REP_BY_MCC_CODE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
