/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faFileAlt,
  faTrash,
  faCaretDown,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  getDoctor, rejectDoctor, approveDoctor, editDoctor, addLocation, deleteLocation,
} from '../../store/modules/doctor/actions';
import {
  selectDoctor,
  selectIsLoading,
  selectIsLoadingApprove,
  selectIsLoadingReject,
} from '../../store/modules/doctor/selectors';
import EditDoctorModal from '../../components/EditDoctorModal';
import EditLocationModal from '../../components/EditLocationModal';
import ConfirmModal from '../../components/ConfirmModal';
import LoadingModal from '../../components/LoadingModal';

import './ViewDoctor.scss';

const ViewDoctor = () => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [showAreaDetails, setShowAreaDetails] = useState();
  const dispatch = useDispatch();
  const data = useSelector((state) => selectDoctor(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingReject(state));
  const isLoadingApprove = useSelector((state) => selectIsLoadingApprove(state));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getDoctor(id));
  }, []);

  const reject = () => {
    dispatch(rejectDoctor(id));
  };

  const approve = () => {
    dispatch(approveDoctor(id));
  };

  const toggleEditUserModal = () => {
    setShowEditUserModal(!showEditUserModal);
  };

  const toggleAddLocationModal = () => {
    setShowAddLocationModal(!showAddLocationModal);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const submitUser = (values) => {
    dispatch(editDoctor(id, values));
  };

  const submitLocation = (values) => {
    dispatch(addLocation(id, { regionIds: [values.id] }));
  };

  const removeLocation = (locationId) => {
    dispatch(deleteLocation(locationId, id));
  };

  return (
    <>
      {!isLoading && data
        ? (
          <div className="view-doctor">
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  <button type="button" className="button danger" onClick={reject}>Reject</button>
                  <button type="button" className="button primary" onClick={approve}>Approve</button>
                </div>
              </div>
            </div>
            <div className="row center">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="status-card">
                  <div className="left">
                    Doctor ID :
                    {' '}
                    {data.id}
                  </div>
                  <div className="right">
                    Registration Step :
                    {' '}
                    {data.steps}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Doctor details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditUserModal}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="picture">
                              <img src={data.profilePicture} alt="User" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Name</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.doctorName}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Phone Number</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.phone ? data.phone.replace('+91', '') : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Email</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.email}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Gender</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.gender}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Role</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.role}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="row w-100">
                          <div className="col-12 col-md-6">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Address With Pincode</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.address}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.district}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">City</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.city}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Consultation Charges</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.consultationCharges}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Certificate</div>
                              </div>
                              <div className="col-8">
                                <a className="content__text" href={data.certificateDocument} target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon
                                    icon={faFileAlt}
                                  />
                                  {' '}
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Identity</div>
                              </div>
                              <div className="col-8">
                                <a className="content__text" href={data.identityDocument} target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon
                                    icon={faFileAlt}
                                  />
                                  {' '}
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <div className="d-flex center between">
                    <h4 className="title">Areas of operation</h4>
                    <button
                      type="button"
                      className="button primary"
                      onClick={toggleAddLocationModal}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="add-icon mr-16"
                      />
                      Add
                    </button>
                  </div>
                  {data.DoctorRegion?.map((region) => (
                    <div key={region.id} className="content">
                      <div className="row center">
                        <div className="col-12 col-md-10">
                          <div className="row">
                            <div className="col">
                              <div className="d-flex">
                                <div className="content__title mb-0">
                                  Plant Code:
                                  {' '}
                                </div>
                                <div className="content__text mb-0">{region.RegionUnitMccname?.plateCode}</div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="d-flex">
                                <div className="content__title mb-0">
                                  MCC Code:
                                  {' '}
                                </div>
                                <div className="content__text mb-0">{region.RegionUnitMccname?.unitCode}</div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="d-flex">
                                <div className="content__title mb-0">
                                  MCC REP Name:
                                  {' '}
                                </div>
                                <div className="content__text mb-0">{region.RegionUnitMccname?.mccName}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 d-flex center justify-end">
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="dropdown-icon mr-16"
                            onClick={() => setShowAreaDetails(
                              showAreaDetails !== region.id ? region.id : undefined,
                            )}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon ml-16"
                            onClick={() => {
                              setIdToDelete(region.id);
                              toggleConfirmModal();
                            }}
                          />
                        </div>
                      </div>
                      {showAreaDetails === region.id && (
                      <div className="row mt-16">
                        <div className="col-12 col-md-6">
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Pin Code</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.pinCode}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Region Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.regionName}</div>
                            </div>
                          </div>
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">MCC Unit Code</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.unitCode}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">MCC Unit Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.unitName}</div>
                            </div>
                          </div>
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">MCC REP Code</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.mccCode}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">MCC REP Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.mccName}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">MCC REP Mobile No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.mccMobile}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">Plant No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.plateCode}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Plant Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.plateName}</div>
                            </div>
                          </div>
                          <div className="row w-100 between w-100">
                            <div className="col-4">
                              <div className="content__title">State</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.stateName}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">District</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.districtName}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Mandal</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.mendalName}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Panchayat</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.panchayatName}</div>
                            </div>
                          </div>
                          <div className="row between w-100">
                            <div className="col-4">
                              <div className="content__title">Village</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">{region.RegionUnitMccname?.villageName}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      ) }
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Education Qualification</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          {/* <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div> */}
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Degree</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.degree}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">College/Institute</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.college}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Year of Completion</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.completionYear}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Animals</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.specialization}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Years of Experience</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.experienceYear}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Medical Registration</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          {/* <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div> */}
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Registartion Number</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.registrationNumber}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Registration Council</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.registrationCouncil}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Registration Year</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.registrationYear}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <h4 className="title">Working Timings</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          {/* <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div> */}
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Days</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.workingDays?.join(', ')}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Client Interval Session</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.sessionTime}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Start Time</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.startTime}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">End Time</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.endTime}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null}
      <EditDoctorModal
        show={showEditUserModal}
        toggle={toggleEditUserModal}
        onSubmit={submitUser}
        values={data}
      />
      <EditLocationModal
        show={showAddLocationModal}
        toggle={toggleAddLocationModal}
        onSubmit={submitLocation}
      />
      <ConfirmModal
        title="Confirm"
        text="Are you sure you want to delete this area of opearation?"
        show={showConfirmModal}
        toggle={toggleConfirmModal}
        onConfirm={() => removeLocation(idToDelete)}
      />
      <LoadingModal show={isLoading || isLoadingReject || isLoadingApprove} />
    </>
  );
};

export default ViewDoctor;
