const prefix = 'PRODUCT';

export const GET_ALL_START = `${prefix}_GET_ALL_START`;
export const GET_ALL_SUCCESS = `${prefix}_GET_ALL_SUCCESS`;
export const GET_ALL_ERROR = `${prefix}_GET_ALL_ERROR`;

export const GET_SINGLE_START = `${prefix}_GET_SINGLE_START`;
export const GET_SINGLE_SUCCESS = `${prefix}_GET_SINGLE_SUCCESS`;
export const GET_SINGLE_ERROR = `${prefix}_GET_SINGLE_ERROR`;

export const UPLOAD_IMAGE_START = `${prefix}_UPLOAD_IMAGE_START`;
export const UPLOAD_IMAGE_SUCCESS = `${prefix}_UPLOAD_IMAGE_SUCCESS`;
export const UPLOAD_IMAGE_ERROR = `${prefix}_UPLOAD_IMAGE_ERROR`;

export const ADD_START = `${prefix}_ADD_START`;
export const ADD_SUCCESS = `${prefix}_ADD_SUCCESS`;
export const ADD_ERROR = `${prefix}_ADD_ERROR`;

export const UPDATE_START = `${prefix}_UPDATE_START`;
export const UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;
export const UPDATE_ERROR = `${prefix}_UPDATE_ERROR`;

export const DELETE_START = `${prefix}_DELETE_START`;
export const DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;
export const DELETE_ERROR = `${prefix}_DELETE_ERROR`;

export const EXPORT_START = `${prefix}_EXPORT_START`;
export const EXPORT_SUCCESS = `${prefix}_EXPORT_SUCCESS`;
export const EXPORT_ERROR = `${prefix}_EXPORT_ERROR`;
