// eslint-disable-next-line import/prefer-default-export
export const language = [
  'Telugu',
  'English',
  'Hindi',
  'Tamil',
  'Odia',
  'Marathi',
  'Bengali',
  'Kannada',
  'Malayalam',
];

export const ticketTypeArray = ['Health Related', 'Non Health Related'];

export const statesArray = [
  {
    name: 'ANDHRA PRADESH',
    districts: [
      'CHITTOOR',
      'ANANTAPUR',
      'GUNTUR',
      'PRAKASAM',
      'WEST GODAVARI',
      'KRISHNA',
      'EAST GODAVARI',
      'VIZIANAGARAM',
      'VISAKHAPATNAM',
      'SRIKAKULAM',
      'NELLORE',
      'KURNOOL',
      'CUDDAPAH',
    ],
  },
  {
    name: 'TELANGANA',
    districts: [
      'KHAMMAM',
      'NALGONDA',
      'NIZAMABAD',
      'MEDAK',
      'RANGAREDDY',
      'WARANGAL',
      'MAHABUBNAGAR',
    ],
  },
  {
    name: 'MAHARASTHRA',
    districts: [
      'SOLAPUR',
      'MAHARASTHRA',
      'SATARA',
      'PUNE',
      'SANGLI',
      'AMHADNAGAR',
    ],
  },
  {
    name: 'KARNATAKA',
    districts: [
      'CHIKBALLAPUR',
      'KOLAR',
      'KOPPAL',
      'TUMKUR',
      'BANGALORE',
      'BANGALORE RURAL',
      'RAICHUR',
    ],
  },
  {
    name: 'HARYANA',
    districts: [
      'ALWAR',
      'KARNAL',
      'SIWANI',
      'REWARI',
      'KURRESHTRA',
      'AMBALA',
      'KAITHAL',
    ],
  },
  {
    name: 'ODISHA',
    districts: ['PURI', 'GANJAM', 'JAGATSINGHPUR', 'CUTTACK'],
  },
  {
    name: 'PUNJAB',
    districts: [
      'LUDHIANA',
      'PATIALA',
      'SANGRUR',
      'KHATHAL',
      'FATEHGARH',
      'SAHIB',
      'BARNALA',
    ],
  },
  {
    name: 'TAMIL NADU',
    districts: [
      'VELLORE',
      'TIRUVALLUR',
      'TIRUVANNAMALAI',
      'VILLUPURAM',
      'CHENNAI',
      'NAMAKKAL',
      'KRISHNAGIRI',
      'DHARMAPURI',
      'SALEM',
      'DINDIGUL',
      'KARUR',
      'TIRUCHIRAPPALLI',
      'TRICHY',
      'UDUMALPET',
      'TIRUPPUR',
      'TIRUPUR',
      'COIMBATORE',
      'ERODE',
    ],
  },
  {
    name: 'RAJASTHAN',
    districts: [
      'ALWAR',
      'JAIPUR',
      'AJMER',
      'SIKHAR',
      'NAGAUR',
      'JHUNJHUNU',
      'CHURU',
      'BIKANER',
      'TONK',
      'BHILWARA',
      'MANSA',
      'FATEHGARH',
      ' SAHIB',
    ],
  },
  {
    name: 'UTTAR PRADESH',
    districts: [
      'AGRA',
      'JEWAR',
      'KARNAL',
      'BHIWANI',
      'SIWANI',
      'MAHNDERGARH',
      'JHUNJHUNU',
      'AMBALA',
      'JIND',
      'ALWAR',
      'FATHEHABAD',
    ],
  },
];

export const regionsArray = [
  'HYDERABAD-1',
  'HYDERABAD-2',
  'KRISHNAGIRI',
  'NANDED',
  'NASHIK',
  'ODISHA',
  'ONGOLE',
  'RAI',
  'SANGVI',
  'TIRUPATI',
  'VIJAYAWADA',
  'VIZAG',
];
export const defaultStates = ['YES', 'NO'];
