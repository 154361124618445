import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_APPROVED_START,
  GET_APPROVED_SUCCESS,
  GET_APPROVED_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_BY_PHONE_START,
  SEARCH_BY_PHONE_SUCCESS,
  SEARCH_BY_PHONE_ERROR,
  SEARCH_APPROVED_BY_PHONE_START,
  SEARCH_APPROVED_BY_PHONE_SUCCESS,
  SEARCH_APPROVED_BY_PHONE_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import StoreService from '../../../services/Store';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getSales = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });

    const res = await HttpService.post(
      `/admin/view-farmers-items?page=${page}&limit=${limit}`,
      { status: 'waiting' },
    );

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getApprovedSales = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_APPROVED_START });

    const res = await HttpService.post(
      `/admin/view-farmers-items?page=${page}&limit=${limit}`,
      { status: 'confirmed' },
    );

    dispatch({
      type: GET_APPROVED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_APPROVED_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getSale = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/admin/view-farmers-single-item/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchSales = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_BY_PHONE_START });

    const res = await HttpService.post('/admin/search-items-by-phone', { phone });

    dispatch({
      type: SEARCH_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchApprovedSales = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_APPROVED_BY_PHONE_START });

    const res = await HttpService.post('/admin/search-items-by-phone', { phone });

    dispatch({
      type: SEARCH_APPROVED_BY_PHONE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_APPROVED_BY_PHONE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateSale = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });

    const res = await HttpService.patch(`/admin/update-farmer-item/${id}`, data);

    dispatch({
      type: UPDATE_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const updateSaleStatus = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });

    const res = await HttpService.patch(`/admin/update-farmers-item-situation/${id}`, data);

    dispatch({
      type: UPDATE_SUCCESS,
      payload: res.data,
    });
    toast.success(res.message);
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteSale = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_START });

    const res = await HttpService.delete(`/admin/delete-farmer-single-item/${id}`);

    dispatch({
      type: DELETE_SUCCESS,
      payload: res.message,
    });
    dispatch(getSales());
    toast.success(res.message);
  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const exportSales = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });

    const res = await axios({
      url: `${config.apiUrl}/admin/report-items`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-Cattle-for-Sale-${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
