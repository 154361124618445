/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getUserDetailsById, rejectLoanRequest, ApproveLoanRequest } from '../../store/modules/farmerLoan/actions';
import {
  selectOrder, selectIsLoading, selectIsLoadingReject, selectIsLoadingFinish,
} from '../../store/modules/farmerLoan/selectors';
import LoadingModal from '../../components/LoadingModal';

import './FarmerLoanViewDetails.scss';

const FarmerLoanViewDetails = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectOrder(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingReject(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingFinish(state));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserDetailsById(id));
  }, []);

  const reject = () => {
    dispatch(rejectLoanRequest(id));
  };

  const approve = () => {
    dispatch(ApproveLoanRequest(id));
  };

  return (
    <>
      {!isLoading && data
        ? (
          <div className="admin-view-order">
            <div className="row center">
              <div className="col-12 col-md-6">
                <div className="row center">
                  <div className="col-12">
                    <div className="status-card">
                      {`Current status : ${data?.requestStatus === 'Pending' ? 'Waiting to be finished' : data?.requestStatus}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="status-card">
                      <div className="left">
                        User ID :
                        {' '}
                        {data?.farmerId}
                      </div>
                      <div className="right">
                        Loan ID :
                        {' '}
                        {data?.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data?.requestStatus === 'Pending' ? (
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="buttons mb-0">
                        <button type="button" className="button danger" onClick={reject}>Reject</button>
                        <button type="button" className="button primary" onClick={approve}>Approve</button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">Loan details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Loan Id</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data?.id ? data?.id : ''}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Loan Request Date</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">{moment(data?.createdAt).format('DD/MM/YYYY')}</div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Loan Request Time</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">{moment(data?.createdAt).format('hh:mm A')}</div>
                          </div>
                        </div>
                      </div>
                      {data?.requestStatus === 'Approved' || data?.requestStatus === 'Rejected' ? (
                        <div className="col-12 col-md-6">
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data?.requestStatus === 'Approved' ? 'Approved Date' : 'Rejected Date'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data?.updatedAt).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data?.requestStatus === 'Approved' ? 'Approved Time' : 'Rejected Time'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data?.updatedAt).format('hh:mm A')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">Total Time Taken</div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data?.updatedAt).diff(data?.createdAt, 'days')}
                                {' '}
                                days
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">User details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          {/* <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditUserModal}
                            />
                          </div> */}
                          <div className="col-12 col-md-6">
                            <div className="picture">
                              <img src={data?.Farmer?.profilePicture} alt="User" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Name</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data?.Farmer?.farmerName}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Phone Number</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? data?.Farmer?.phone.replace('+91', '') : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Gender</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data?.Farmer?.gender}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Age</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{moment().diff(data?.Farmer?.age, 'years') }</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Type of User</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data?.Farmer?.role}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          {/* <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faPen}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div> */}
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Pin Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.pinCode}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Region Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.regionName}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC/HP Unit Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.unitCode}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC/HP Unit Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.unitName}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC/HP REP Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.mccCode}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC/HP REP Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.mccName}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC/HP REP Mobile No</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.mccMobile}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant No</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.plateCode}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.plateName}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">State</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.stateName}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.districtName}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Mandal</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.mendalName}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Panchayat</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.panchayatName}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Village</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data?.Farmer?.villageName}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null}
      <LoadingModal
        show={
          isLoading
          || isLoadingReject
          || isLoadingFinish
        }
      />
    </>
  );
};

export default FarmerLoanViewDetails;
