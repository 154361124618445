import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './MoreMenuData.scss';

const index = (props) => {
  const [showMoreData, setshowMoreData] = useState(false);
  const handleOnDropDownSelected = () => {
    setshowMoreData(!showMoreData);
  };
  return (
    <li className="menuItem">
      <button
        onClick={handleOnDropDownSelected}
        type="button"
        className="menuItem__button"
      >
        <FontAwesomeIcon icon={props?.icon} className="menuItem__icon" />
        <span className="menuItem__label">{props?.label}</span>
        <FontAwesomeIcon
          icon={showMoreData ? faCaretUp : faCaretDown}
          className="menuItem__icon"
        />
      </button>
      {showMoreData ? (
        <li className="menuItem">
          {props?.data?.map((item) => (
            <NavLink
              to={item?.link}
              key={item?.id}
              style={{ backgroundColor: item?.color }}
              className="menuItem__link"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={item?.icon} className="menuItem__icon" />
              <span className="menuItem__label">{item?.label}</span>
            </NavLink>
          ))}
        </li>
      ) : null}
    </li>
  );
};

export default index;
