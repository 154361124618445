/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faMoneyBill,
  faFileExcel,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import logoImg from '../../assets/images/logo.png';
import {
  exportExcelForCompleted, exportExcelForPending,
  getRHPIApprovedResultsForPending,
  getRHPIApprovedResultsForCompleted,
  searchByPhoneCompletedNewUsers,
  searchByPhonePendingNewUsers,
} from '../../store/modules/newUser/actions';
import {
  selectCompletedUser, selectIsLoading, selectPendingUser,
} from '../../store/modules/newUser/selectors';

import './RegionalHeadApprovedRequests.scss';

const FarmerLoan = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState(null);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const dispatch = useDispatch();
  const upcoming = useSelector((state) => selectPendingUser(state));
  const pastHeld = useSelector((state) => selectCompletedUser(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  useEffect(() => {
    const values = {
      page: 1,
      limit: 10,
    };
    if (tabIndex === 1) {
      dispatch(getRHPIApprovedResultsForCompleted(values));
    } else {
      dispatch(getRHPIApprovedResultsForPending(values));
    }
  }, [tabIndex]);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };
  const handleOnSearch = async () => {
    const values = {
      phone: query,
      page: 1,
      limit: 10,
    };
    if (tabIndex === 0) {
      setTabIndex(0);
      dispatch(getRHPIApprovedResultsForPending(values));
    } else {
      setTabIndex(1);
      dispatch(getRHPIApprovedResultsForCompleted(values));
    }
  };
  const exportExcelForPendingRecords = () => {
    dispatch(exportExcelForPending('pending'));
  };
  const exportExcelForCompletedRecords = () => {
    dispatch(exportExcelForCompleted('completed'));
  };
  const nextUpcomingPage = (page) => {
    const values = {
      page,
      limit: 10,
    };
    dispatch(getRHPIApprovedResultsForPending(values));
  };

  const previousUpcomingPage = (page) => {
    const values = {
      page,
      limit: 10,
    };
    dispatch(getRHPIApprovedResultsForPending(values));
  };

  const nextPastHeldPage = (page) => {
    const values = {
      page,
      limit: 10,
    };
    dispatch(getRHPIApprovedResultsForCompleted(values));
  };

  const previousPastHeldPage = (page) => {
    const values = {
      page,
      limit: 10,
    };
    dispatch(getRHPIApprovedResultsForCompleted(values));
  };
  const handleOnFilter = () => {
    setshowFilterModal(!showFilterModal);
  };
  return (
    <>
      <div className="admin-orders">
        {/* <div className="row middle">
          <div className="col-12 col-md-3">
            <div className="summary-card warning mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Pending Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.pendingLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card green mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Finished Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.finsihedLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card danger mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Rejected Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.rejectedLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="summary-card primary mr-8">
              <div className="summary-card__left">
                <FontAwesomeIcon icon={faMoneyBill} className="summary-card__icon" />
              </div>
              <div className="summary-card__right">
                <span className="summary-card__label">
                  Total Loan Request
                </span>

                <span className="summary-card__value">
                  {count ? count?.totalLoanRequests : 0}
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Pending Farmer Code Registrations
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search
              value={query}
              onChangeText={handleChange}
              onSubmit={handleOnSearch}
            />
          </div>
        </div>
        <Tabs
          defaultIndex={0}
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList className="row between tab-list">
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 0 ? 'selected' : ''}`}>
                Waiting for Approval
              </div>
            </Tab>
            <Tab className="col-6 tab">
              <div className={`type-card ${tabIndex === 1 ? 'selected' : ''}`}>
                Completed
              </div>
            </Tab>
          </TabList>
          <TabPanel className="appointments-tab-panel">
            {
              upcoming?.paginate?.length >= 0 ? (
                <div>
                  <div className="justify-content-end">
                    <div className="buttons">
                      <button type="button" className="button green" onClick={exportExcelForPendingRecords}>
                        <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            }
            <div className="row">
              {upcoming && upcoming.paginate && upcoming.paginate.length
                ? upcoming.paginate.map((item) => (
                  <>
                    {
                    item?.equalId === null && item?.isEqualGenerated === false ? (
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card__left">
                            <div className="card__avatar">
                              {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                            </div>
                            <div className="row between col-9">
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    Farmer Name :
                                    {' '}
                                  </span>
                                  {item ? item?.farmerName : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Registration ID :
                                    {' '}
                                  </span>
                                  {item ? item?.id : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Phone:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.phone : ''}

                                </div>
                                <div>
                                  <span className="card__title">
                                    Role:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                                </div>
                              </div>
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    User Created At :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Plant Code :
                                    {' '}
                                  </span>
                                  {item?.plantCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    HPC Code :
                                    {' '}
                                  </span>
                                  {item?.hpcCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    User Created Time :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('hh:mm a')}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="card__right">
                            {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCertificate} />

                            </div>
                            )
                          }
                            <Link to={`/admin/regionHead/${item?.id}`} className="card__button">View</Link>
                          </div>
                        </div>
                      </div>
                    ) : item?.equalId && item?.isEqualGenerated ? (
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card__left">
                            <div className="card__avatar">
                              {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                            </div>
                            <div className="row between col-9">
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    Farmer Name :
                                    {' '}
                                  </span>
                                  {item ? item?.farmerName : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Registration ID :
                                    {' '}
                                  </span>
                                  {item ? item?.id : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Phone:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.phone : ''}

                                </div>
                                <div>
                                  <span className="card__title">
                                    Role:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                                </div>
                              </div>
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    User Created At :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Plant Code :
                                    {' '}
                                  </span>
                                  {item?.plantCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    HPC Code :
                                    {' '}
                                  </span>
                                  {item?.hpcCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    User Created Time :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('hh:mm a')}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="card__right">
                            {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCertificate} />

                            </div>
                            )
                          }
                            <Link to={`/admin/regionHead/${item?.id}`} className="card__button">View</Link>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  </>

                ))
                : (
                  null
                )}
              {!isLoading && !upcoming && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No upcoming Loan Request found</span>
                </div>
              </div>
              )}
            </div>
            {upcoming
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Previous}
                        onClick={() => previousUpcomingPage(upcoming.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {upcoming.Next
                          ? upcoming.Next.page - 1
                          : upcoming.Previous
                            ? upcoming.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!upcoming.Next}
                        onClick={() => nextUpcomingPage(upcoming.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
          <TabPanel className="appointments-tab-panel">
            {pastHeld?.paginate?.length >= 0 ? (
              <div>
                <div className="justify-content-end">
                  <div className="buttons">
                    <button type="button" className="button green" onClick={exportExcelForCompletedRecords}>
                      <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                      Export
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row">
              {pastHeld && pastHeld.paginate && pastHeld.paginate.length
                ? pastHeld.paginate.map((item) => (
                  <>
                    {
                    item?.equalId === null && item?.isEqualGenerated === false ? (
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card__left">
                            <div className="card__avatar">
                              {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                            </div>
                            <div className="row between col-9">
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    Farmer Name :
                                    {' '}
                                  </span>
                                  {item ? item?.farmerName : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Registration ID :
                                    {' '}
                                  </span>
                                  {item ? item?.id : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Phone:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.phone : ''}

                                </div>
                                <div>
                                  <span className="card__title">
                                    Role:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                                </div>
                                {
                              item?.status === 'rejected' && item?.comment && (
                                <div>
                                  <span className="card__title">
                                    Reason:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  <span className="card__text--danger">
                                    {item ? item?.comment : ''}
                                  </span>

                                </div>
                              )
                            }

                              </div>
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    User Created At :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Plant Code :
                                    {' '}
                                  </span>
                                  {item?.plantCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    HPC Code :
                                    {' '}
                                  </span>
                                  {item?.hpcCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    User Created Time :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('hh:mm a')}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="card__right">
                            <div
                              className={`card__text--${item?.status === 'approved' ? 'success'
                                : item?.status === 'pending' ? 'warning' : item?.status === 'rejected' ? 'danger' : ''}`}
                            >
                              <span
                                className="card__title"
                              >
                                Status :
                                {' '}
                              </span>
                              {item?.status?.toUpperCase()}
                            </div>
                            {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCertificate} />

                            </div>
                            )
                          }
                            <Link
                              to={`/admin/regionHead/${item.id}`}
                              className="card__button"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : item?.equalId && item?.isEqualGenerated ? (
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card__left">
                            <div className="card__avatar">
                              {
                            item?.profilePicture ? (<img src={item?.profilePicture} alt="order" />
                            ) : <img src={logoImg} alt="user" />

                          }
                            </div>
                            <div className="row between col-9">
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    Farmer Name :
                                    {' '}
                                  </span>
                                  {item ? item?.farmerName : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Registration ID :
                                    {' '}
                                  </span>
                                  {item ? item?.id : ''}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Phone:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.phone : ''}

                                </div>
                                <div>
                                  <span className="card__title">
                                    Role:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  {item ? item?.mccType === 'hpc_farmer' ? 'HPC Farmer' : 'HPC Representative' : ''}

                                </div>
                                {
                              item?.status === 'rejected' && item?.comment && (
                                <div>
                                  <span className="card__title">
                                    Reason:
                                    {' '}
                                  </span>
                                  {/* {item?.id ? item?.id : ''} */}
                                  <span className="card__text--danger">
                                    {item ? item?.comment : ''}
                                  </span>

                                </div>
                              )
                            }

                              </div>
                              <div className="card__text">
                                <div>
                                  <span className="card__title">
                                    User Created At :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </div>
                                <div>
                                  <span className="card__title">
                                    Plant Code :
                                    {' '}
                                  </span>
                                  {item?.plantCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    HPC Code :
                                    {' '}
                                  </span>
                                  {item?.hpcCode ?? 'N/A'}
                                </div>
                                <div>
                                  <span className="card__title">
                                    User Created Time :
                                    {' '}
                                  </span>
                                  {moment(item?.createdAt).format('hh:mm a')}
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="card__right">
                            <div
                              className={`card__text--${item?.status === 'approved' ? 'success'
                                : item?.status === 'pending' ? 'warning' : item?.status === 'rejected' ? 'danger' : ''}`}
                            >
                              <span
                                className="card__title"
                              >
                                Status :
                                {' '}
                              </span>
                              {item?.status?.toUpperCase()}
                            </div>
                            {
                            item?.isEqualGenerated === true && (
                            <div
                              className="card__text--success"
                            >
                              <span className="card__title">E-KYC DONE </span>
                              <FontAwesomeIcon icon={faCertificate} />

                            </div>
                            )
                          }
                            <Link
                              to={`/admin/regionHead/${item.id}`}
                              className="card__button"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  </>

                ))
                : (
                  null
                )}
              {!isLoading && !pastHeld && (
              <div className="col-12 col-lg-12 d-flex-col middle">
                <div className="notFound">
                  <FontAwesomeIcon icon={faCalendarAlt} className="notFound__icon" />
                  <span className="notFound__text">No past held users found</span>
                </div>
              </div>
              )}
            </div>
            {pastHeld
              ? (
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex-col middle">
                    <div className="pagination">
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Previous}
                        onClick={() => previousPastHeldPage(pastHeld.Previous.page)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="pagination__btn__icon" />
                      </button>
                      <span className="pagination__page">
                        {pastHeld.Next
                          ? pastHeld.Next.page - 1
                          : pastHeld.Previous
                            ? pastHeld.Previous.page + 1
                            : 1}
                      </span>
                      <button
                        type="button"
                        className="pagination__btn"
                        disabled={!pastHeld.Next}
                        onClick={() => nextPastHeldPage(pastHeld.Next.page)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="pagination__btn__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
          </TabPanel>
        </Tabs>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default FarmerLoan;
