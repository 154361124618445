/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartPlus,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import EditItemModal from '../../components/EditItemModal';
import LoadingModal from '../../components/LoadingModal';
import ConfirmModal from '../../components/ConfirmModal';
import {
  getMccOrder, rejectMccOrder, finishMccOrder, editMccOrderDetails, removeMccOrderDetails,
} from '../../store/modules/order/actions';
import {
  selectOrder,
  selectIsLoading,
  selectIsLoadingFinish,
  selectIsLoadingReject,
  selectMessage,
} from '../../store/modules/order/selectors';

import './ViewMccOrder.scss';

const ViewMccOrder = () => {
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showConfrimModal, setShowConfrimModal] = useState(false);
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectOrder(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingReject(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingFinish(state));
  const message = useSelector((state) => selectMessage(state));

  const { id } = useParams();

  useEffect(() => {
    dispatch(getMccOrder(id));
  }, []);

  const reject = () => {
    dispatch(rejectMccOrder(id));
  };

  const finish = () => {
    dispatch(finishMccOrder(id));
  };

  const toggleEditItemModal = (value) => {
    setItem(value || null);
    setShowEditItemModal(!showEditItemModal);
  };

  const toggleConfirmModal = (value) => {
    setItem(value || null);
    setShowConfrimModal(!showConfrimModal);
  };

  const removeItem = () => {
    const total = Number(data.total) - Number(item.subTotal);

    dispatch(removeMccOrderDetails(data.id, item.id, { total: total.toFixed(2) }));
  };

  const onEditItem = (orderDetail) => {
    const total = Number(data.total) - Number(orderDetail.subTotal) + Number(orderDetail.total);
    const order = {
      items: [{
        prodPrice: orderDetail.price,
        quantity: `${orderDetail.quantity}`,
        prodSize: orderDetail.size,
        palletType: orderDetail.palletType,
        subTotal: `${orderDetail.total}`,
      }],
      total: total.toFixed(2),
    };

    dispatch(editMccOrderDetails(data.id, orderDetail.id, order));
  };

  return (
    <>
      {!isLoading && data
        ? (
          <div className="view-mcc-order">
            <div className="row center">
              <div className="col-12 col-md-6">
                <div className="row center">
                  <div className="col-12">
                    <div className="status-card">
                      {`Current status : ${data.status === 'waiting' ? 'Waiting to be finished' : data.status}`}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="status-card">
                      <div className="left">
                        User ID :
                        {' '}
                        {data.ownerId}
                      </div>
                      <div className="right">
                        Order ID :
                        {' '}
                        {data.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data.status === 'waiting' ? (
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="buttons mb-0">
                        <button type="button" className="button danger" onClick={reject}>Reject</button>
                        <button type="button" className="button primary" onClick={finish}>Finish</button>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form">
                        {/* <div className="row center"> */}
                        {/* <div className="col-6">
                            <div className="form__item">
                              <select
                                id="followUp"
                                name="followUp"
                                className="form__field"
                              >
                                <option value="1">1 day</option>
                                <option value="2">2 day</option>
                                <option value="3">3 day</option>
                              </select>
                              {currentDiagnosisError
                              && <div className="form__error">{currentDiagnosisError}</div>}
                            </div>
                          </div> */}
                        {/* <div className="col-12">
                      <div className="form__item">
                        <button
                          type="button"
                          className="button success p-16 m-0"
                          onClick={followUp}
                        >
                          Delivery Follow-Up
                        </button>
                      </div>
                    </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="row center">
              <div className="col-12 col-md-6 col-lg-5">
                <div className="status-card">
                  <div className="left">
                    User ID :
                    {' '}
                    {data.Farmer ? data.Farmer.id : ''}
                  </div>
                  <div className="right">
                    Appointment ID :
                    {' '}
                    {data.id}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 d-flex-col">
                <div className="item left">
                  <h4 className="title">User details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 mb-16">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="picture">
                              <img src={data.Farmer ? data.Farmer.profilePicture : ''} alt="User" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex-col">
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Name</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? data.Farmer.farmerName : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Phone Number</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? data.Farmer.phone.replace('+91', '') : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Gender</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? data.Farmer.gender : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Age</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? moment(data.Farmer.age).format('DD/MM/YYYY') : ''}</div>
                              </div>
                            </div>
                            <div className="row between">
                              <div className="col-3">
                                <div className="content__title">Type of User</div>
                              </div>
                              <div className="col-8">
                                <div className="content__text">{data.Farmer ? data.Farmer.role : ''}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="row w-100 m-h-100">
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Pin Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.pinCode : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Region Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.regionName : ''}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC Unit Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.unitCode : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC Unit Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.unitName : ''}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC REP Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.mccCode : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC REP Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.mccName : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">MCC REP Mobile No</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.mccMobile : ''}</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant No</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.plateCode : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Plant Name</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.plateName : ''}</div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">State</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.stateName : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.districtName : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Mandal</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.mendalName : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Panchayat</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.panchayatName : ''}</div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Village</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">{data.Farmer ? data.Farmer.villageName : ''}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item right">
                  <h4 className="title">Delivery details</h4>
                  <div className="content">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Created Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.createdAt).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Delivery Time Period</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {data.deliveryTimePeriod}
                            </div>
                          </div>
                        </div>
                        <div className="row between">
                          <div className="col-5">
                            <div className="content__title">Delivery Date</div>
                          </div>
                          <div className="col-6">
                            <div className="content__text">
                              {moment(data.deliveryDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                      {data.status === 'finished' || data.status === 'rejected' ? (
                        <div className="col-12 col-md-6">
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Date' : 'Rejected Date'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">
                                {data.status === 'finished' ? 'Finished Time' : 'Rejected Time'}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).format('hh:mm a')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-5">
                              <div className="content__title">Total Time Taken</div>
                            </div>
                            <div className="col-6">
                              <div className="content__text">
                                {moment(data.finishedDate).diff(data.createdAt, 'days')}
                                {' '}
                                days
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex-col">
                <div className="item">
                  <div className="row center">
                    <div className="col-12 col-md-10">
                      <h4 className="title">Order details</h4>
                    </div>
                    <div className="col-12 col-md-2 d-flex end">
                      <Link to={`/plant-admin/mcc-orders/${data.id}/add`} className="button primary">
                        <FontAwesomeIcon
                          icon={faCartPlus}
                          className="mr-16"
                        />
                        Add Items
                      </Link>
                    </div>
                  </div>
                  <div className="content">
                    <div className="row">
                      {data.OrderDetail ? data.OrderDetail.map((orderDetail, index) => (
                        <div key={orderDetail.id} className="col-12 col-md-6 d-flex-col mb-16">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="prod-picture">
                                <img src={orderDetail.Product ? orderDetail.Product.prodImage[0] : ''} alt="Product" />
                              </div>
                            </div>
                            {data.status === 'waiting' ? (
                              <div className="col-12 col-md-6 d-flex start mb-16">
                                <button type="button" className="button primary" onClick={() => toggleEditItemModal(orderDetail)}>
                                  <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button type="button" className="button danger" onClick={() => toggleConfirmModal(orderDetail)}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            )
                              : null}
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Item No</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {index + 1}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product ID</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodId}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product Name</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.Product ? orderDetail.Product.prodName : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Product Price</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodPrice}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Size</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.prodSize}
                              </div>
                            </div>
                          </div>
                          {orderDetail.palletType
                          && (
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Pallet Type</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.palletType}
                              </div>
                            </div>
                          </div>
                          )}
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Quantity</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.quantity}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-4">
                              <div className="content__title">Sub Total</div>
                            </div>
                            <div className="col-7">
                              <div className="content__text">
                                {orderDetail.subTotal}
                              </div>
                            </div>
                          </div>
                        </div>
                      )) : null}
                      <div className="col-12 d-flex-col">
                        <div className="row between">
                          <div className="col-4">
                            <div className="content__title">Total</div>
                          </div>
                          <div className="col-7">
                            <div className="content__text">
                              {data.total}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null}
      <LoadingModal
        show={
          isLoading
          || isLoadingReject
          || isLoadingFinish
        }
      />
      {showEditItemModal && (
      <EditItemModal
        show={showEditItemModal}
        toggle={toggleEditItemModal}
        onSubmit={onEditItem}
        item={item}
      />
      )}
      <ConfirmModal
        show={showConfrimModal}
        toggle={toggleConfirmModal}
        onConfirm={removeItem}
        title="Confirm"
        text="Are you sure you want to remove this item?"
      />
    </>
  );
};

export default ViewMccOrder;
