/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/modules/auth/selectors';
import StoreService from '../services/Store';

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector((state) => selectIsAuthenticated(state));
  const token = StoreService.getToken();

  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated || token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
