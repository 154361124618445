import { createSelector } from 'reselect';

export const selectRaw = (state) => state.farmer;

export const selectOrder = createSelector(
  [selectRaw],
  (state) => state.order,
);

export const selectPlantUpcomingOrders = createSelector(
  [selectRaw],
  (state) => state.upcomingPlant,
);

export const selectPlantPastHeldOrders = createSelector(
  [selectRaw],
  (state) => state.pastHeldPlant,
);

export const selectIsLoading = createSelector(
  [selectRaw],
  (state) => state.isLoading,
);

export const selectIsLoadingFinish = createSelector(
  [selectRaw],
  (state) => state.isLoadingFinish,
);

export const selectIsLoadingReject = createSelector(
  [selectRaw],
  (state) => state.isLoadingReject,
);

export const selectCount = createSelector(
  [selectRaw],
  (state) => state.count,
);
export const selectIsLoadingCount = createSelector(
  [selectRaw],
  (state) => state.isLoadingCount,
);

const selectors = {
  selectRaw,
  selectOrder,
  selectPlantUpcomingOrders,
  selectPlantPastHeldOrders,
  selectIsLoading,
  selectIsLoadingFinish,
  selectIsLoadingReject,
  selectCount,
};

export default selectors;
