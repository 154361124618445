const prefix = 'CALL_CENTER_';

export const RAISE_TICKET_START = `${prefix}RAISE_TICKET_START`;
export const RAISE_TICKET_SUCCESS = `${prefix}RAISE_TICKET_SUCCESS`;
export const RAISE_TICKET_ERROR = `${prefix}RAISE_TICKET_ERROR`;

export const GET_RAISE_TICKET_LIST_START = `${prefix}GET_RAISE_TICKET_LIST_START`;
export const GET_RAISE_TICKET_LIST_SUCCESS = `${prefix}GET_RAISE_TICKET_LIST_SUCCESS`;
export const GET_RAISE_TICKET_LIST_ERROR = `${prefix}GET_RAISE_TICKET_LIST_ERROR`;

export const UPDATE_RAISE_TICKET_LIST_START = `${prefix}_UPDATE_RAISE_TICKET_LIST_START`;
export const UPDATE_RAISE_TICKET_LIST_SUCCESS = `${prefix}_UPDATE_RAISE_TICKET_LIST_SUCCESS`;
export const UPDATE_RAISE_TICKET_LIST_ERROR = `${prefix}_UPDATE_RAISE_TICKET_LIST_ERROR`;

export const AUTO_POPULATE_TICKET_DETAILS_START = `${prefix}_AUTO_POPULATE_TICKET_DETAILS_START`;
export const AUTO_POPULATE_TICKET_DETAILS_SUCCESS = `${prefix}_AUTO_POPULATE_TICKET_DETAILS_SUCCESS`;
export const AUTO_POPULATE_TICKET_DETAILS_ERROR = `${prefix}_AUTO_POPULATE_TICKET_DETAILS_ERROR`;

export const STORE_TICKET_DETAILS = `${prefix}_STORE_TICKET_DETAILS`;

export const CLEAR_TICKET_DETAILS = `${prefix}_CLEAR_TICKET_DETAILS`;
