/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import '../UserModalUpdate.scss';

const UserGpaUpdate = ({
  show, toggle, onSubmit, values,
}) => {
  const userRole = localStorage?.getItem('role');
  const showHideClassName = show ? 'edit-user-modal display-block' : 'edit-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('nomineeIdentity', values.nomineeRelationship);
      setValue('nomineeName', values.nomineeName);
    }
  }, [setValue, values]);

  const submit = ({
    nomineeIdentity,
    nomineeName,
  }) => {
    toggle();
    onSubmit({
      nomineeIdentity,
      nomineeName,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Nominee Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="nomineeName"
                            name="nomineeName"
                            placeholder="Nominee Name"
                            {...register('nomineeName', { required: { value: true, message: 'Nominee Name is required' } })}
                          />
                          {errors.nomineeName && <div className="form__error">{errors.nomineeName.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Nominee Relationship</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="nomineeIdentity"
                            name="nomineeIdentity"
                            placeholder="Select Relationship"
                            className="form__field"
                            {...register('nomineeIdentity', { required: { value: true, message: 'Nominee Relationship is required' } })}
                          >
                            <option value="wife">Wife</option>
                            <option value="husband">Husband</option>
                            <option value="mother">Mother</option>
                            <option value="father">Father</option>
                            <option value="son">Son</option>
                            <option value="daughter">Daughter</option>
                            <option value="brother">Brother</option>
                            <option value="sister">Sister</option>
                          </select>
                          {errors.nomineeIdentity && <div className="form__error">{errors.nomineeIdentity.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserGpaUpdate;
