/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import './EditNewUserLocationModal.scss';

const EditNewUserLocationModal = ({
  show, toggle, onSubmit, values,
}) => {
  const showHideClassName = show
    ? 'edit-user-modal display-block'
    : 'edit-user-modal display-none';
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (values) {
      setValue('village', values.village);
      setValue('hNoDno', values.hNoDno);
      setValue('mandal', values.mandal);
      setValue('district', values.district);
      setValue('pinCode', values.pinCode);
      setValue('cowMilkquantity', values.cowMilkquantity);
      setValue('buffaloMilkQuantity', values.buffaloMilkQuantity);
      setValue('noOfBuffalo', values.noOfBuffalo);
      setValue('noOfCows', values.noOfCows);
      // setValue('farmerCode', values.farmerCode);
      // setValue('vendorCode', values.vendorCode);
    }
  }, [setValue, values]);

  const submit = ({
    village,
    hNoDno,
    mandal,
    district,
    pinCode,
    cowMilkquantity,
    buffaloMilkQuantity,
    noOfBuffalo,
    noOfCows,
    // farmerCode,
    // vendorCode,
  }) => {
    toggle();
    onSubmit({
      village,
      hNoDno,
      mandal,
      district,
      pinCode,
      cowMilkquantity,
      buffaloMilkQuantity,
      noOfBuffalo,
      noOfCows,
      // farmerCode,
      // vendorCode,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button
              type="button"
              className="modal-main__button"
              onClick={toggle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Village Name</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="village"
                            name="village"
                            placeholder="Village Name"
                            {...register('village', {
                              required: {
                                value: true,
                                message: 'Village Name is required',
                              },
                            })}
                          />
                          {errors.village && (
                            <div className="form__error">
                              {errors.village.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">House No./D.no</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="hNoDno"
                            name="hNoDno"
                            placeholder="House number"
                            {...register('hNoDno', {
                              required: {
                                value: true,
                                message: 'House number is required',
                              },
                            })}
                          />
                          {errors.hNoDno && (
                            <div className="form__error">
                              {errors.hNoDno.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">No of Cows</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="noOfCows"
                            name="noOfCows"
                            placeholder="No of Cows"
                            {...register('noOfCows', {
                              required: {
                                value: false,
                                message: 'Number of Cows is required',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'No of cows should be in number',
                              },
                            })}
                          />
                          {errors.noOfCows && (
                            <div className="form__error">
                              {errors.noOfCows.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Cow Milk Quantity</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="cowMilkquantity"
                            name="cowMilkquantity"
                            placeholder="Cow Milk Quantity"
                            {...register('cowMilkquantity', {
                              required: {
                                value: false,
                                message: 'Cow Milk Quantity is required',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Cow milk quantity should be in number',
                              },
                            })}
                          />
                          {errors.cowMilkquantity && (
                            <div className="form__error">
                              {errors.cowMilkquantity.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Vendor Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="vendorCode"
                            name="vendorCode"
                            placeholder="Vendor Code"
                            {...register('vendorCode', {
                              required: {
                                value: false,
                                message: 'vendorCode name is required',
                              },
                            })}
                          />
                          {errors.vendorCode && (
                            <div className="form__error">
                              {errors.vendorCode.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div> */}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Mandal</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="mandal"
                            name="mandal"
                            placeholder="Mandal"
                            {...register('mandal', {
                              required: {
                                value: true,
                                message: 'Mandal name is required',
                              },
                            })}
                          />
                          {errors.mandal && (
                            <div className="form__error">
                              {errors.mandal.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">District</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="district"
                            name="district"
                            placeholder="District"
                            {...register('district', {
                              required: {
                                value: true,
                                message: 'District name is required',
                              },
                            })}
                          />
                          {errors.district && (
                            <div className="form__error">
                              {errors.district.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Pin Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="pinCode"
                            name="pinCode"
                            maxLength={6}
                            placeholder="Pin Code"
                            {...register('pinCode', {
                              required: {
                                value: true,
                                message: 'Pin Code is required',
                              },
                              minLength: {
                                value: 6,
                                message:
                                  'Pin Code must not be less than 6 Digits',
                              },
                              maxLength: {
                                value: 6,
                                message:
                                  'Pin Code must not be less than 6 digits',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Pincode must be a number',
                              },
                            })}
                          />
                          {errors.pinCode && (
                            <div className="form__error">
                              {errors.pinCode.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">No of Buffalo</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="noOfBuffalo"
                            name="noOfBuffalo"
                            placeholder="No of Buffalo"
                            {...register('noOfBuffalo', {
                              required: {
                                value: false,
                                message: 'No. of Buffalo is required',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'No of buffallo should be a number',
                              },
                            })}
                          />
                          {errors.noOfBuffalo && (
                            <div className="form__error">
                              {errors.noOfBuffalo.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">
                            Buffalo Milk Quantity
                          </span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="buffaloMilkQuantity"
                            name="buffaloMilkQuantity"
                            placeholder="Buffalo Milk Quantity"
                            {...register('buffaloMilkQuantity', {
                              required: {
                                value: false,
                                message: 'Buffalo Milk Quantity is required',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Buffallo Milk quantity should be a number',
                              },
                            })}
                          />
                          {errors.buffaloMilkQuantty && (
                            <div className="form__error">
                              {errors.buffaloMilkQuantty.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* <div className="form__item">
                    <label htmlFor="frequency">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Farmer Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="farmerCode"
                            name="farmerCode"
                            placeholder="Farmer Code"
                            {...register('farmerCode', {
                              required: {
                                value: false,
                                message: 'farmerCode name is required',
                              },
                            })}
                          />
                          {errors.farmerCode && (
                            <div className="form__error">
                              {errors.farmerCode.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div> */}
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button
                          type="button"
                          className="form__button"
                          onClick={handleSubmit(submit)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditNewUserLocationModal;
