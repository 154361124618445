/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faSignature, faTimes, faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import NotFound from '../../assets/animations/notfound.json';
import './SignaturesModal.scss';

const SignaturesModal = ({
  show, toggle, onSubmit, showSignatureCanvas,
  isLoading, signatures,
  onDelete,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectError, setSelectError] = useState(null);

  const showHideClassName = show ? 'employee-modal display-block' : 'employee-modal display-none';
  const onSelect = (value) => {
    setSelected(value);
  };

  const submit = (evt) => {
    evt.preventDefault();
    if (!selected) {
      setSelectError('Please, select an signature');
      toast.info('Please, select an signature');

      return;
    }
    setSelectError(null);

    toggle();
    onSubmit(selected);
  };
  const handleSignatureCanvas = () => {
    if (signatures?.length === 5) {
      setSelectError('You cannot add more than 5 Signatures');
      toast.error('You cannot add more than 5 Signatures');
    } else {
      setSelectError(null);
      toggle();
      showSignatureCanvas();
    }
  };
  const onDeleteButtonPressed = (evt) => {
    evt.preventDefault();
    if (!selected) {
      setSelectError('Please, select an signature you want to delete');
      toast.error('Please, select an signature you want to delete');
      return;
    }
    setSelectError(null);
    onDelete(selected);
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 d-flex middle">
                  <span className="form__label">
                    Choose a signature that you have added
                    {' '}
                    <Tooltip label={<FontAwesomeIcon icon={faInfoCircle} color="#bebebe" />} hoverText="Note: Button disabled at maximum of 5 saved signatures. You can either handwrite a new signature or type it in for approval." />
                  </span>
                  {signatures?.length < 5 ? (
                    <Tooltip
                      label={(
                        <button onClick={handleSignatureCanvas} className="form__button" type="button">
                          <FontAwesomeIcon icon={faSignature} />
                          Create New
                        </button>
                   )}
                      hoverText="Create new Signature"
                    />
                  ) : null}
                </div>
                {isLoading ? <Spinner />
                  : signatures && signatures?.length
                    ? (
                      <div className="col-12">
                        <div className="table">
                          <div className="row pt-8 pb-8 pl-8 pr-8 divider">
                            <div className="col-1 d-flex center" />
                            <div className="col-11">
                              <span className="form__label">Signature</span>
                            </div>
                          </div>
                          {
                            signatures?.slice(0, 5).map((item) => (
                              <div className="form__item divider" key={item?.id}>
                                <label htmlFor="signatureUrl">
                                  <div className="row">
                                    <div className="col-1 d-flex center">
                                      <input
                                        className="form__check"
                                        type="radio"
                                        id="signatureUrl"
                                        name="signatureUrl"
                                        checked={selected && selected.id === item.id}
                                        onChange={() => onSelect(item)}
                                      />
                                    </div>
                                    <div className="col-11 row between d-flex">
                                      <img src={item?.signatureUrl} alt="signature" className="signatureImage" />
                                      <Tooltip label={<FontAwesomeIcon icon={faTrashCan} onClick={onDeleteButtonPressed} type="button" color="red" />} hoverText="Select signature before you delete" />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))
                          }
                          {/* <div className="form__item">
                            <div className="row">
                              <div className="col-6 d-flex-col middle">
                                <button
                                  type="button"
                                  className="load-more-button"
                                  disabled={!signatures.Previous}
                                  onClick={previousPage}
                                >
                                  Prev
                                </button>
                              </div>
                              <div className="col-6 d-flex-col middle">
                                <button
                                  type="button"
                                  className="load-more-button"
                                  disabled={!signatures.Next}
                                  onClick={nextPage}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>

                      </div>
                    ) : (
                      <div className="noSignaturesFound">
                        <Lottie animationData={NotFound} loop={false} className="noSignaturesFound__icon" />
                        <span className="noSignaturesFound__label">No Signatures Found</span>
                      </div>
                    )}
                {signatures ? (
                  <div className="col-12">
                    <div className="form__item">
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          {selectError && <div className="form__error">{selectError}</div>}
                          <Tooltip label={<button type="button" className="form__button" onClick={submit}>Approve</button>} hoverText="Select a digital signature from the list or you can create a new signature" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignaturesModal;
