/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { searchMccRepByMccCode, searchPlantByPlantCode, transferMccRepToPlant } from '../../store/modules/user/actions';
import {
  selectMccRep, selectPlant, selectIsLoadingUsers, selectIsLoading,
} from '../../store/modules/user/selectors';
import ConfirmModal from '../../components/ConfirmModal';
import LoadingModal from '../../components/LoadingModal';

import './TransferMccRep.scss';

const TransferMccRep = () => {
  const [showConfrimModal, setShowConfrimModal] = useState(false);
  const dispatch = useDispatch();
  const mccRep = useSelector((state) => selectMccRep(state));
  const plant = useSelector((state) => selectPlant(state));
  const isLoadingUsers = useSelector((state) => selectIsLoadingUsers(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ defaultValues: { mccCode: '', plateCode: '' } });

  const search = ({ mccCode, plateCode }) => {
    dispatch(searchMccRepByMccCode(mccCode));
    dispatch(searchPlantByPlantCode(plateCode));
  };

  const transfer = ({ mccCode, plateCode }) => {
    if (mccRep && plant) {
      dispatch(transferMccRepToPlant({ mccCode, plateCode, plateName: plant.plantName }));
    }
  };

  const toggleConfirmModal = () => {
    setShowConfrimModal(!showConfrimModal);
  };

  const killUser = () => {
    // dispatch(deleteUser(user.phone));
  };

  return (
    <>
      <div className="transfer-mcc-rep">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Transfer MCC Representative to a new Plant
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="mccCode">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">MCC Rep Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="mccCode"
                            name="mccCode"
                            placeholder="Enter MCC Rep Code"
                            {...register('mccCode', {
                              required: {
                                value: true,
                                message: 'MCC Rep Code is required',
                              },
                              pattern: {
                                value: /^\d*$/,
                                message: 'MCC Rep Code must be valid digits',
                              },
                              minLength: {
                                value: 8,
                                message: 'MCC Rep Code must not be less than 8 digits',
                              },
                              maxLength: {
                                value: 10,
                                message: 'MCC Rep Code must not be greater than 10 digits',
                              },
                            })}
                          />
                          {errors.mccCode && <div className="form__error">{errors.mccCode.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="plateCode">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Plant Code</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="plateCode"
                            name="plateCode"
                            placeholder="Enter Plant Code"
                            {...register('plateCode', {
                              required: {
                                value: true,
                                message: 'Plant Code is required',
                              },
                              pattern: {
                                value: /^\d*$/,
                                message: 'Plant Code must be valid digits',
                              },
                              minLength: {
                                value: 4,
                                message: 'Plant Code must not be less than 4 digits',
                              },
                              maxLength: {
                                value: 4,
                                message: 'Plant Code must not be greater than 4 digits',
                              },
                            })}
                          />
                          {errors.plateCode && <div className="form__error">{errors.plateCode.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  {mccRep ? (
                    <div className="form__item">
                      <div>
                        <span>
                          MCC Rep Name :
                          {' '}
                          {mccRep.farmerName}
                        </span>
                      </div>
                      <div>
                        <span>
                          MCC Phone Number :
                          {' '}
                          {mccRep.phone}
                        </span>
                      </div>
                      <div>
                        <span>
                          Current Plant Code :
                          {' '}
                          {mccRep.plateCode}
                        </span>
                      </div>
                      <div>
                        <span>
                          District :
                          {' '}
                          {mccRep.districtName}
                        </span>
                      </div>
                      <div>
                        <span>
                          Village :
                          {' '}
                          {mccRep.villageName}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  {plant ? (
                    <div className="form__item">
                      <div>
                        <span>
                          Plant Name :
                          {' '}
                          {plant.plantName}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" onClick={handleSubmit(search)}>Search</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button green" disabled={!mccRep || !plant} onClick={handleSubmit(transfer)}>Transfer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={showConfrimModal}
        toggle={toggleConfirmModal}
        onConfirm={killUser}
        title="Confirm"
        text="Are you sure you want to delete this user?"
      />
      <LoadingModal show={isLoadingUsers || isLoading} />
    </>
  );
};

export default TransferMccRep;
