import { toast } from 'react-toastify';
import {
  GET_UPCOMING_START,
  GET_UPCOMING_SUCCESS,
  GET_UPCOMING_ERROR,
  GET_PAST_HELD_START,
  GET_PAST_HELD_SUCCESS,
  GET_PAST_HELD_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  SEARCH_UPCOMING_START,
  SEARCH_UPCOMING_SUCCESS,
  SEARCH_UPCOMING_ERROR,
  SEARCH_PAST_HELD_START,
  SEARCH_PAST_HELD_SUCCESS,
  SEARCH_PAST_HELD_ERROR,
  FINISH_START,
  FINISH_SUCCESS,
  FINISH_ERROR,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_ERROR,
  UPLOAD_PRESCRIPTION_START,
  UPLOAD_PRESCRIPTION_SUCCESS,
  UPLOAD_PRESCRIPTION_ERROR,
  GET_FOLLOW_UP_START,
  GET_FOLLOW_UP_SUCCESS,
  GET_FOLLOW_UP_ERROR,
  SET_FOLLOW_UP_START,
  SET_FOLLOW_UP_SUCCESS,
  SET_FOLLOW_UP_ERROR,
  GET_ALL_COUNT_START,
  GET_ALL_COUNT_SUCCESS,
  GET_ALL_COUNT_ERROR,
  GET_ALL_COUNT_PHYSICAL_START,
  GET_ALL_COUNT_PHYSICAL_SUCCESS,
  GET_ALL_COUNT_PHYSICAL_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import config from '../../../config/config';

export const getUpcomingAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_UPCOMING_START });

    const res = await HttpService.post(`/doctor/view-appointments-by-status?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPastHeldAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PAST_HELD_START });

    const res = await HttpService.post(`/doctor/view-appointments-by-status?page=${page}&limit=${limit}`, { status: 'finished' });

    dispatch({
      type: GET_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAppointment = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/doctor/view-appointments-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchUpcomingAppointments = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_UPCOMING_START });

    const res = await HttpService.post('/doctor/search-farmer-appointment', { phone, status: 'waiting' });

    dispatch({
      type: SEARCH_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const searchPastHeldAppointments = (phone) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_PAST_HELD_START });

    const res = await HttpService.post('/doctor/search-farmer-appointment', { phone, status: 'finished' });

    dispatch({
      type: SEARCH_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const finishAppointment = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: FINISH_START });

    const res = await HttpService.post(
      `/doctor/update-appointment-status/${appointmentId}`,
      { status: 'finished' },
    );

    dispatch({
      type: FINISH_SUCCESS,
      payload: res.data,
    });

    toast.success('Appointment finished successfully');
  } catch (error) {
    dispatch({
      type: FINISH_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const rejectAppointment = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: REJECT_START });

    const res = await HttpService.post(
      `/doctor/update-appointment-status/${appointmentId}`,
      { status: 'rejected' },
    );

    dispatch({
      type: REJECT_SUCCESS,
      payload: res.data,
    });
    toast.success('Appointment rejected successfully');
  } catch (error) {
    dispatch({
      type: REJECT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const uploadPrescription = (appointmentId, data) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_PRESCRIPTION_START });

    const reqConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post(
      `/doctor/save-medical/${appointmentId}`,
      data,
      reqConfig,
    );

    dispatch({
      type: UPLOAD_PRESCRIPTION_SUCCESS,
      payload: {
        message: 'Prescription Successfully uploaded -> Press on Finish or Reject Appointment Button',
        data: res.data,
      },
    });
    toast.success('Prescription Successfully uploaded -> Press on Finish or Reject Appointment Button');
  } catch (error) {
    dispatch({
      type: UPLOAD_PRESCRIPTION_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const adminGetUpcomingAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_UPCOMING_START });

    const res = await HttpService.post(`/admin/view-appointments-by-status?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const adminGetPastHeldAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PAST_HELD_START });

    const res = await HttpService.post(`/admin/view-appointments-by-status?page=${page}&limit=${limit}`, { status: 'finished' });

    dispatch({
      type: GET_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const adminGetAppointment = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/admin/view-appointments-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getFollowUpAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_FOLLOW_UP_START });

    const res = await HttpService.get(`/doctor/view-follow-up-appointments/waiting?page=${page}&limit=${limit}`);

    dispatch({
      type: GET_FOLLOW_UP_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FOLLOW_UP_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const followUpAppointment = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: SET_FOLLOW_UP_START });

    const res = await HttpService.patch(`/doctor/upate-follow-up-appointment/${appointmentId}`, { followUpOption: '24' });

    dispatch({
      type: SET_FOLLOW_UP_SUCCESS,
      payload: res.data,
    });

    toast.success('Follow-up set successfully');
  } catch (error) {
    dispatch({
      type: SET_FOLLOW_UP_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const adminGetUpcomingPhysicalAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_UPCOMING_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/appointment/view-physical-appointments-by-status?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_UPCOMING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const adminGetPastHeldPhysicalAppointments = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_PAST_HELD_START });

    const res = await HttpService.post(`${config.doctorApiUrl}/appointment/view-physical-appointments-by-status?page=${page}&limit=${limit}`, { status: 'finished' });

    dispatch({
      type: GET_PAST_HELD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PAST_HELD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllCountDetailsForVirtualAppointments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COUNT_START });
    const response = await HttpService.get('/admin/appointment-requests-summary');
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COUNT_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllCountDetailsForPhysicalAppointments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COUNT_PHYSICAL_START });
    const response = await HttpService.get(`${config?.doctorApiUrl}/appointment/physical-appointments-summary`);
    if (response?.status === 200) {
      dispatch({
        type: GET_ALL_COUNT_PHYSICAL_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNT_PHYSICAL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};
