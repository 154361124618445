/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faFileExcel,
  faChevronRight,
  faChevronLeft,
  faRecycle,
  faDownload,
  faSearch,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faUsers,
  faPaw,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import Search from '../../components/Search';
import LoadingModal from '../../components/LoadingModal';
import {
  getApprovedSales,
  searchApprovedSales,
  exportSales,
} from '../../store/modules/market/actions';
import {
  selectApprovedSales, selectIsLoading, selectIsLoadingExport,
} from '../../store/modules/market/selectors';

import './ApprovedSales.scss';

const ApprovedSales = () => {
  const [query, setQuery] = useState(null);
  const role = localStorage.getItem('role');
  const columns = useMemo(
    () => [
      {
        Header: 'MCC Representative Name',
        accessor: 'mccName',
      },
      {
        Header: 'User Name',
        accessor: 'farmerName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Breed',
        accessor: 'breed',
      },
      {
        Header: 'Cattle Price',
        accessor: 'price',
      },
      {
        Header: 'Unit Code',
        accessor: 'unitCode',
      },
      {
        Header: 'Plant Code',
        accessor: 'plateCode',
      },
      {
        Header: 'Village Name',
        accessor: 'villageName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectApprovedSales(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));

  useEffect(() => {
    dispatch(getApprovedSales());
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: data ? data.paginate.map((item) => (
      {
        ...item,
        mccName: item.Farmer?.mccName,
        farmerName: item.Farmer?.farmerName,
        phone: item.Farmer?.phone.replace('+91', ''),
        gender: item.Farmer?.gender,
        price: `Rs. ${item.price}`,
        unitCode: item.Farmer?.unitCode,
        plateCode: item.Farmer?.plateCode,
        villageName: item.Farmer?.villageName,
      }
    )) : [],
  });

  const nextPage = (page) => {
    dispatch(getApprovedSales(page));
  };

  const previousPage = (page) => {
    dispatch(getApprovedSales(page));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    dispatch(searchApprovedSales(`+91${query}`));
  };

  const exportExcell = () => {
    dispatch(exportSales());
  };

  return (
    <>
      <div className="approved-sales">
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search value={query} onChangeText={handleChange} onSubmit={search} />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              <button type="button" className="button green" onClick={exportExcell}>
                <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
                Export
              </button>
            </div>
          </div>
        </div>
        {data && data?.paginate && data?.paginate?.length ? (
          <div className="table-container">
            <div className="row">
              <div className="col-12">
                <div className="table-footer">
                  <div className="row end">
                    <div className="col-12 col-md-6">
                      <div className="table-footer__buttons">
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => previousPage(1)}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? previousPage(data.Previous.page) : {})}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className="table-footer__button__iclon"
                          />
                        </button>
                        <div className="table-footer__label">
                          {data && data.Next
                            ? data.Next.page - 1
                            : data && data.Previous
                              ? data.Previous.page + 1
                              : 1}
                          {/* /1 */}
                        </div>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.filter((item) => {
                          if (role === 'hnl-admin') {
                            return item?.Header === 'User Name' || item?.Header === 'Mobile' || item?.Header === 'Gender' || item?.Header === 'Age' || item?.Header === 'Breed' || item?.Header === 'Cattle Price';
                          }
                          return item;
                        }).map((column) => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                        <th>All Details</th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row?.cells?.filter((item) => {
                            if (role === 'hnl-admin') {
                              return item?.value !== null;
                            }
                            return item;
                          }).map((cell) => (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                          <td>
                            <Link to={`/admin/sales/${row.original.id}`} className="table__button">View</Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
        {!isLoading && !data && (
        <div className="col-12 col-lg-12 d-flex-col middle">
          <div className="notFound">
            <FontAwesomeIcon icon={faUsers} className="notFound__icon" />
            <span className="notFound__text">No Approved Cattles found</span>
          </div>
        </div>
        )}
      </div>
      <LoadingModal show={isLoading || isLoadingExport} />
    </>
  );
};

export default ApprovedSales;
