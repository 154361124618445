/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import './CreativeHeadingModal.scss';

const CreativeHeadingModal = ({
  show, toggle, onSubmit, creative,
}) => {
  const showHideClassName = show ? 'creative-heading-modal display-block' : 'creative-heading-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  useEffect(() => {
    if (creative) {
      setValue('title', creative.title);
    }
  }, [creative]);

  const submit = ({
    title,
  }) => {
    onSubmit({ title });
    toggle();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="title">
                      <div className="row center">
                        <div className="col-12 mb-4">
                          <span className="form__label">Heading</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Please enter text"
                            {...register('title', {
                              required: {
                                value: true,
                                message: 'Heading is required',
                              },
                            })}
                          />
                          {errors.title && <div className="form__error">{errors.title.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-16">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col end">
                        <button type="button" className="button primary" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreativeHeadingModal;
