/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/modules/auth/selectors';
import StoreService from '../services/Store';

const PublicRoute = ({ children, path, ...rest }) => {
  const isAuthenticated = useSelector((state) => selectIsAuthenticated(state));
  const token = StoreService.getToken();
  const role = localStorage.getItem('role');
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated || token) {
          return role === 'admin' ? (
            <Redirect
              to={{
                pathname: '/admin',
                state: { from: location },
              }}
            />
          ) : role === 'hnl-admin' ? (
            <Redirect
              to={{
                pathname: '/admin',
                state: { from: location },
              }}
            />
          ) : role === 'hfwa_user' ? (
            <Redirect to={{
              pathname: '/admin',
              state: { from: location },
            }}
            />
          ) : role === 'call_center' ? (
            <Redirect to={{
              pathname: '/admin',
              state: { from: location },
            }}
            />
          )
            : role === 'doctor' ? (
              <Redirect
                to={{
                  pathname: '/doctor',
                  state: { from: location },
                }}
              />
            ) : role === 'plant-admin' ? (
              <Redirect
                to={{
                  pathname: '/plant-admin',
                  state: { from: location },
                }}
              />
            ) : role === 'ea_pi' ? (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: { from: location },
                }}
              />
            ) : role === 'regional_fa' ? (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: { from: location },
                }}
              />
            ) : role === 'regional_pi' ? (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: { from: location },
                }}
              />
            )
              : (
                <Redirect
                  to={{
                    pathname: '/403',
                    state: { from: location },
                  }}
                />
              );
        }
        return children;
      }}
    />
  );
};

export default PublicRoute;
