import { createSelector } from 'reselect';

export const selectRaw = (state) => state.auth;

export const selectIsAuthenticated = createSelector(
  [selectRaw],
  (auth) => auth.isAuthenticated,
);

export const selectCurrentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser,
);

export const selectRole = createSelector(
  [selectCurrentUser],
  (currentUser) => (currentUser ? currentUser.UserRole || null : null),
);

export const selectIsLoadingAuth = createSelector(
  [selectRaw],
  (auth) => auth.isLoadingAuth,
);

export const selectErrorMessage = createSelector(
  [selectRaw],
  (auth) => auth.errorMessage,
);

const selectors = {
  selectRaw,
  selectIsAuthenticated,
  selectCurrentUser,
  selectIsLoadingAuth,
  selectRole,
  selectErrorMessage,
};

export default selectors;
