/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import './MccModalForFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllHpcCodesBySearch, getAllPlantCodes } from '../../../../store/modules/newUser/actions';
import { arrayOfRegions } from '../../../../constants/mostUsedArrays';
import {
  selectPlantCode, selectSearchedData,
} from '../../../../store/modules/newUser/selectors';
import Tooltip from '../../../../components/Tooltip';

const ModalForFilter = ({
  show, toggle, onSubmit, values,
}) => {
  const dispatch = useDispatch();
  const currentDate = new Date(); // Get the current date
  const year = currentDate.getFullYear(); // Get the current year
  const startDate = new Date(year, 0, 1);
  const [fromDate, setfromDate] = useState(values ? new Date(values?.fromDate) : startDate.setMonth(startDate.getMonth() + 3));
  const [toDate, setToDate] = useState(values ? new Date(values?.toDate) : new Date());
  const [dateError, setDate] = useState(null);
  const userRole = localStorage?.getItem('role');
  const plantCodeValues = useSelector((state) => selectPlantCode(state));
  const hpcCodeValues = useSelector((state) => selectSearchedData(state));
  const showHideClassName = show ? 'mcc-user-modal display-block' : 'mcc-user-modal display-none';
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  const fetchAllPlantCodes = () => {
    dispatch(getAllPlantCodes());
  };
  const fetchAllHpcCodesForPlant = () => {
    const dataToBepassed = {
      type: 'plant',
    };
    dispatch(getAllHpcCodesBySearch(dataToBepassed));
  };
  useEffect(() => {
    if (userRole === 'admin' || userRole === 'regional_fa' || userRole === 'regional_pi' || userRole === 'ea_pi') {
      fetchAllPlantCodes();
    } else {
      fetchAllHpcCodesForPlant();
    }
  }, []);
  const handleOnPlantCodeSelected = (e) => {
    if (e?.target?.value !== 'Choose here') {
      const dataToBepassed = {
        type: 'admin',
        plantCode: e.target.value,
      };
      dispatch(getAllHpcCodesBySearch(dataToBepassed));
    }
  };
  const submit = ({
    plantCode,
    regionName,
  }) => {
    toggle();
    onSubmit({
      plantCode,
      regionName,
      fromDate,
      toDate,
    });
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="row">
          <div className="col-12 d-flex end">
            <button type="button" className="modal-main__button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="col-12">
            <div className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  {
                    userRole === 'admin' || userRole === 'regional_fa' || userRole === 'regional_pi' || userRole === 'ea_pi' ? (
                      <div className="form__item">
                        <label htmlFor="quantity">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Plant Code</span>
                            </div>
                            <div className="col-12">
                              <select
                                id="plantCode"
                                name="plantCode"
                                onClick={handleOnPlantCodeSelected}
                                className="form__field"
                                {...register('plantCode', { required: { value: false, message: 'plantCode is required' } })}
                              >
                                <option hidden value="">Choose here</option>
                                {
                              plantCodeValues && plantCodeValues.filter((item) => item?.plateCode).map((item, index) => (
                                <option key={index.toString()} value={item?.plateCode}>{item?.plateCode}</option>
                              ))
                             }
                              </select>
                              {errors.plantCode && <div className="form__error">{errors.plantCode.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    ) : null
                  }
                  {/* <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">MCC Code</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="hpcCode"
                            name="hpcCode"
                            className="form__field"
                            {...register('hpcCode', { required: { value: false, message: 'hpcCode is required' } })}
                          >
                            <option hidden value="">Choose here</option>

                            {
                              hpcCodeValues?.map((item, index) => <option key={index.toString()} value={item?.hpcCode}>{item?.hpcCode}</option>)
                            }
                          </select>
                          {errors.hpcCode && <div className="form__error">{errors.hpcCode.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div> */}
                  <div className="form__item">
                    <label htmlFor="quantity">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Region Names</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="regionName"
                            name="regionName"
                            className="form__field"
                            {...register('regionName', {
                              required: {
                                value: false,
                                message: 'Region is required',
                              },
                            })}
                          >
                            <option hidden value="">
                              Choose here
                            </option>

                            {arrayOfRegions?.map((item, index) => (
                              <option
                                key={index.toString()}
                                value={item}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                          {errors.regionName && (
                          <div className="form__error">
                            {errors.regionName.message}
                          </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="durarion">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">
                            From Date
                            {' '}
                            <Tooltip label={<FontAwesomeIcon icon={faCircleInfo} color="#bababa" size="xs" />} hoverText="By default, the date is set at +3 months from the fiscal year." />
                          </span>
                        </div>
                        <div className="col-12">
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setfromDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form__field"
                          />
                          {dateError && <div className="form__error">{dateError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="durarion">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">To Date</span>
                        </div>
                        <div className="col-12">
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form__field"
                          />
                          {dateError && <div className="form__error">{dateError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>

                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="form__button" onClick={handleSubmit(submit)}>Filter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModalForFilter;
