import React, { useState, useEffect } from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Upcoming from '../../pages/Upcoming';
import PastHeld from '../../pages/PastHeld';
import View from '../../pages/View';
import Profile from '../../pages/Profile';
import Error404 from '../../pages/Error404';
import './DoctorDashboard.scss';
import UpdatePassword from '../../pages/UpdatePassword';

const MOBILE_SIZE = 960;

const DashboardLayout = () => {
  const { path } = useRouteMatch();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      setIsSidebarCollapsed(true);
    } else {
      setIsSidebarCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sidebarCollapsedClass = isSidebarCollapsed
    ? 'side-bar-collapsed'
    : '';

  return (
    <div className="layout">
      <div className="layout-wrapper">
        <Sidebar collapsed={isSidebarCollapsed} toggleCollapse={toggleSidebarCollapse} userRole="doctor" />
        <div className={`layout-content ${sidebarCollapsedClass}`}>
          <Header
            isSidebarCollapsed={isSidebarCollapsed}
            toggleSidebarCollapse={toggleSidebarCollapse}
          />
          <main className="main-content" role="main">
            <Switch>
              <Route
                exact
                path="/doctor"
                render={() => <Redirect from="/" to="/doctor/appointments/upcoming" />}
              />
              <Route exact path={`${path}/appointments/upcoming`} component={Upcoming} />
              <Route exact path={`${path}/appointments/past-held`} component={PastHeld} />
              <Route exact path={`${path}/appointments/:id`} component={View} />
              <Route exact path={`${path}/edit-profile`} component={Profile} />
              <Route exact path={`${path}/updatePassword`} component={UpdatePassword} />
              <Route component={Error404} />
            </Switch>
          </main>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardLayout;
