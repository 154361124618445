/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Link, useHistory, useLocation,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faBars, faLock, faPowerOff, faTicket, faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { logout } from '../../store/modules/auth/actions';
import './Header.scss';

const Header = ({ isSidebarCollapsed, toggleSidebarCollapse }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const role = localStorage.getItem('role');
  const adminRoles = ['hfwa_user', 'call_center', 'regional_pi', 'hnl-admin', 'ea_pi', 'regional_fa'];
  const { from } = location.state || { from: { pathname: '/' } };

  const sidebarCollapsedClass = isSidebarCollapsed
    ? ''
    : 'd-none';
  const [showPopover, setshowPopover] = React.useState(false);

  const handlePopover = () => {
    setshowPopover(!showPopover);
  };
  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      toast: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout(() => {
          history.replace(from);
        }));
        Swal.fire(
          {
            title: 'Logout!',
            text: 'You have successfully logged out',
            icon: 'success',
            timer: 2000,
            toast: true,
          },
        );
      }
    });
  };
  const menuActions = [
    {
      id: 0,
      title: 'Tickets',
      icon: <FontAwesomeIcon icon={faTicket} className="popover__icon" />,
      link: adminRoles?.includes(role) ? '/admin/Tickets' : `/${role}/Tickets`,
    },
    {
      id: 1,
      title: 'Change Password',
      icon: <FontAwesomeIcon icon={faLock} className="popover__icon" />,
      link: adminRoles?.includes(role) ? '/admin/updatePassword' : `/${role}/updatePassword`,
    },
    {
      id: 2,
      title: 'Logout',
      icon: <FontAwesomeIcon icon={faPowerOff} className="popover__icon" />,
      action: handleLogout,
    },
  ];
  return (
    <header className="header">
      <nav className="navbar">
        <ul className="navbar__nav">
          <li className={`navbar__item ${sidebarCollapsedClass}`}>
            <button type="button" className="navbar__toggle-btn" onClick={toggleSidebarCollapse}>
              <FontAwesomeIcon icon={faBars} className="navbar__icon" />
            </button>
          </li>
        </ul>
        <div className="popover">
          <FontAwesomeIcon icon={faUserGear} className="navbar__icon" onClick={handlePopover} />
          {
            showPopover
            && (
            <div className="popover__content">
              <ul className="popover__list">
                <li className="popover__item">
                  {
                    menuActions?.filter((item) => {
                      if (item?.title === 'Tickets' && role !== 'call_center') {
                        return false;
                      }
                      return true;
                    }).map((action) => (
                      <Link to={action?.link} key={action.id} onClick={action?.action} className="popover__link">
                        <span>
                          {action?.icon}
                          {action.title}
                        </span>
                      </Link>
                    ))
                  }
                </li>
              </ul>
            </div>
            )
          }
        </div>
        {/* <ul className="navbar__nav">
          <li className="navbar__item">
            <Link to="/notification" className="navbar__link">
              <FontAwesomeIcon icon={faBell} className="navbar__icon" />
            </Link>
          </li>
        </ul> */}
      </nav>
    </header>
  );
};

export default Header;
