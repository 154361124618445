/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faFilter,
  faWarning,
  faUserClock,
  faUsers,
  faUserCheck,
  faUserAltSlash,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import Swal from 'sweetalert2';
import Lottie from 'lottie-react';
import {
  getAllMccFarmersData,
  getAllMccFarmersCount,
  exportExcelForMcc,
} from '../../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectMccData,
  selectDigitalRegistrationCount,
} from '../../../store/modules/newUser/selectors';
import Search from '../../../components/Search';
import AnimatedLoader from '../../../components/LoadingModal';
import ModalForFilter from '../components/MccModal';
import withScreenRefreshDetection from '../../../utils/withScreenRefreshDetection';
import OhSnap from '../../../assets/animations/OhSnap.json';
import CountUp from '../../../components/CountUp';
import './mccFarmerDashboard.scss';

const Tickets = (props) => {
  const role = localStorage.getItem('role');
  const [query, setQuery] = useState(null);
  const [showModalForFilter, setShowModalForFilter] = useState(false);
  const [storePageSizeValue, setStorePageSizeValue] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [storedFilterValues, setStoredFilterValues] = useState(null);
  const columns = useMemo(
    () => [
      {
        Header: 'REG ID',
        accessor: 'id',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'MCC FARMER NAME',
        accessor: 'farmerName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'MOBILE NUMBER',
        accessor: 'phone',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'AADHAR NUMBER',
        accessor: 'aadhaarNumber',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'FARMER DOB',
        accessor: 'dob',
        Cell: ({ value }) => (value !== null ? (
          <p>{moment(value).format('DD-MMM-YYYY')}</p>
        ) : (
          <p>N/A</p>
        )),
      },
      {
        Header: 'REGION NAME',
        accessor: 'regionName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PLANT CODE',
        accessor: 'plantCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PLANT NAME',
        accessor: 'plantName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'MCC REP CODE',
        accessor: 'mccCode',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'FARMER STATUS',
        accessor: 'farmerStatus',
        Cell: ({ value }) => (value !== null ? (
          <p
            style={{
              color: value === 'Inactive' ? 'red' : 'green',
              textTransform: 'uppercase',
            }}
          >
            {value}
          </p>
        ) : (
          <p> Not Available</p>
        )),
      },
      {
        Header: 'REJECTION REASON',
        accessor: 'comment',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'APPROVAL STATUS',
        accessor: 'status',
        Cell: ({ value }) => (value !== null ? (
          <p
            style={{
              color:
                  value === 'pending'
                    ? '#ffb100'
                    : value === 'rejected'
                      ? 'red'
                      : 'green',
              textTransform: 'uppercase',
            }}
          >
            {value}
          </p>
        ) : (
          <p> Not Available</p>
        )),
      },
      {
        Header: 'NOMINEE NAME',
        accessor: 'nomineeName',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'NOMINEE RELATIONSHIP',
        accessor: 'nomineeRelationship',
        Cell: ({ value }) => (value !== null ? <p>{value}</p> : <p>N/A</p>),
      },
      {
        Header: 'PLANT IC SIGN',
        accessor: 'plant_ic_sign',
        Cell: ({ value }) => (value !== null ? (
          <img src={value} alt="sign" className="signatureImg" />
        ) : (
          <p> Not Available</p>
        )),
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdAt',
        Cell: ({ value }) => (value !== null ? (
          <p>{moment(value).format('DD-MMM-YYYY')}</p>
        ) : (
          <p>N/A</p>
        )),
      },
      {
        Header: 'UPDATED ON',
        accessor: 'updatedAt',
        Cell: ({ value }) => (value !== null ? (
          <p>{moment(value).format('DD-MMM-YYYY')}</p>
        ) : (
          <p>N/A</p>
        )),
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectMccData(state));
  const count = useSelector((state) => selectDigitalRegistrationCount(state));

  const isLoading = useSelector((state) => selectIsLoading(state));
  const filterApplied = localStorage.getItem('filterApplied');
  useEffect(() => {
    if (filterApplied) {
      Swal.fire({
        icon: 'info',
        title: 'Filter applied, do you want to continue anyway ?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#229945',
        cancelButtonColor: '#f26868',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Remove Filter',
        toast: true,
      }).then((res) => {
        if (res?.isDismissed) {
          localStorage.removeItem('filterApplied');
          const values = {
            page: pageNum,
            limit: storePageSizeValue,
          };
          dispatch(getAllMccFarmersData(values));
        }
      });
    } else {
      const values = {
        page: pageNum,
        limit: storePageSizeValue,
      };
      dispatch(getAllMccFarmersData(values));
    }
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:
      data && data?.paginate && data?.paginate?.length
        ? data?.paginate?.map((item) => ({
          ...item,
          phone: item?.phone?.replace('+91', ''),
        }))
        : [],
  });

  const nextPage = (page) => {
    setPageNum(page);
    const nextPageVal = {
      page,
      limit: storePageSizeValue,
    };
    dispatch(getAllMccFarmersData(nextPageVal));
  };

  const previousPage = (page) => {
    setPageNum(page);
    const prevPageVal = {
      page,
      limit: storePageSizeValue,
    };
    dispatch(getAllMccFarmersData(prevPageVal));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const search = () => {
    const values = {
      regionName: query,
      page: pageNum,
      limit: 10,
    };
    dispatch(getAllMccFarmersData(values));
  };

  const submitFilter = (e) => {
    const filterValues = {
      page: pageNum,
      limit: storePageSizeValue,
      fromDate: moment(e.fromDate).format('DD/MM/YYYY'),
      plantCode: e?.plantCode === 'Choose here' ? null : e?.plantCode,
      mccCode: e?.hpcCode === 'Choose here' ? null : e?.hpcCode,
      regionName: e?.regionName === 'Choose here' ? null : e?.regionName,
      toDate: moment(e.toDate).format('DD/MM/YYYY'),
    };
    setStoredFilterValues(filterValues);
    const filteredData = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => value !== null),
    );
    dispatch(getAllMccFarmersData(filteredData));
    localStorage.setItem('filterApplied', true);
  };

  const pageSize = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);
  const handleOnPageSizeSelected = (event) => {
    setStorePageSizeValue(event?.target?.value);
    const values = {
      page: pageNum,
      limit: `${event?.target?.value}`,
    };
    dispatch(getAllMccFarmersData(values));
  };
  const handleOnItemSelected = (event) => {
    if (event === 'filter') {
      setShowModalForFilter(!showModalForFilter);
    } else {
      const values = {
        ...storedFilterValues,
        flag: event,
      };
      const filteredExportData = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== null),
      );
      dispatch(exportExcelForMcc(filteredExportData));
    }
  };
  const arrayOfButtons = [
    {
      id: 0,
      name: 'Filter',
      className: 'button primary',
      icon: faFilter,
      onClick: () => handleOnItemSelected('filter'),
    },
    {
      id: 1,
      name: 'Export',
      className: 'button green',
      icon: faFileExcel,
      onClick: () => handleOnItemSelected('export'),
    },
  ];
  const arrayOfStatusCard = [
    {
      id: 0,
      label: 'Pending New MCC Farmers',
      icon: faUserClock,
      count: count?.pending ?? 0,
      className: 'summary-card warning mr-8',
    },
    {
      id: 2,
      label: 'Rejected New MCC Farmers',
      icon: faUserAltSlash,
      count: count?.rejected ?? 0,
      className: 'summary-card danger mr-8',
    },
    {
      id: 2,
      label: 'Approved New MCC Farmers',
      icon: faUserCheck,
      count: count?.approved ?? 0,
      className: 'summary-card green mr-8',
    },
    {
      id: 3,
      label: 'Total New MCC Farmers',
      icon: faUsers,
      count: count?.total ?? 0,
      className: 'summary-card purpleColor mr-8',
    },
  ];
  useEffect(() => {
    if (props?.isRefreshed) {
      localStorage.removeItem('filterApplied');
    }
  }, [props?.isRefreshed]);
  useEffect(() => {
    dispatch(getAllMccFarmersCount());
  }, [dispatch]);

  return (
    <>
      <div className="mcc-farmer-dashboard">
        {filterApplied && (
          <div className="filterAppliedFlag">
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 5 }} />
            In order to dynamically remove the filter that has been applied,
            please refresh the page.
          </div>
        )}
        <div className="row middle">
          {arrayOfStatusCard?.map((item) => (
            <div className="col-12 col-md-3" key={item?.id}>
              <div className={item?.className}>
                <div className="summary-card__left">
                  <FontAwesomeIcon
                    icon={item?.icon}
                    className="summary-card__icon"
                  />
                </div>
                <div className="summary-card__right">
                  <span className="summary-card__label">{item?.label}</span>
                  <span className="summary-card__value">
                    <CountUp start={0} end={item?.count} duration={3} />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row middle">
          <div className="col-12 col-md-10">
            <Search
              value={query}
              label="Search By Region Name"
              onChangeText={handleChange}
              onSubmit={search}
            />
          </div>
          <div className="col-12 col-md-2">
            <div className="buttons">
              {arrayOfButtons
                ?.filter((item) => {
                  if (item?.name === 'Ticket' && role !== 'call_center') {
                    return false;
                  }
                  return true;
                })
                .map((item) => (
                  <button
                    key={item?.id}
                    type="button"
                    className={item?.className}
                    onClick={item?.onClick}
                  >
                    <FontAwesomeIcon
                      icon={item?.icon}
                      className="button__icon"
                    />
                    {item?.name}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className="table-container">
          {data && data?.paginate?.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="table-footer">
                  <div className="row middle">
                    <div className="col-12 col-md-6">
                      <span className="table-footer__title">
                        {data && data.Previous
                          ? (data.Previous.page + 1) * data.Previous.limit
                            - data.Previous.limit
                            + 1
                          : 1}
                        {' '}
                        to
                        {' '}
                        {data && data.Previous
                          ? (data.Previous.page + 1) * data.Previous.limit
                          : storePageSizeValue}
                        {' '}
                        { `of ${pageNum}`}
                        {' '}
                        {''}
                        <span className="form__label">Page Size : </span>
                        <select onChange={handleOnPageSizeSelected} className="dropdown-menu">
                          {pageSize?.map((item, index) => (
                            <option key={index.toString()} value={item} className="dropdown-menu">
                              {item}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="table-footer__buttons">
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => previousPage(1)}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? previousPage(data.Previous.page) : {})}
                          disabled={!data || !data.Previous}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className="table-footer__button__iclon"
                          />
                        </button>
                        <div className="table-footer__label">
                          {data && data.Next
                            ? data.Next.page - 1
                            : data && data.Previous
                              ? data.Previous.page + 1
                              : 1}
                          {/* /1 */}
                        </div>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                        <button
                          type="button"
                          className="table-footer__button"
                          onClick={() => (data ? nextPage(data.Next.page) : {})}
                          disabled={!data || !data.Next}
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleRight}
                            className="table-footer__button__icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup?.headers?.map((column, index) => (
                          <th
                            key={index.toString()}
                            {...column.getHeaderProps()}
                            style={{ fontSize: 12 }}
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row?.cells?.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                              style={{ fontSize: 13, fontWeight: 400 }}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="row middle h-200">
              <div className="d-flex-col center">
                <Lottie animationData={OhSnap} loop className="noUsersIcon" />
                <p className="noRecordsFound">No Records Found</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <AnimatedLoader show={isLoading} />
      {showModalForFilter ? (
        <ModalForFilter
          show={showModalForFilter}
          toggle={() => handleOnItemSelected('filter')}
          onSubmit={submitFilter}
        />
      ) : null}
    </>
  );
};

export default withScreenRefreshDetection(Tickets);
