import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './Search.scss';

function Search({
  value, onChangeText, onSubmit,
  label,
}) {
  const showHideClassName = 'search';
  // const handler = () => {
  //   toggle();
  // };
  const handleSubmit = (evt) => {
    onSubmit(evt);
  };

  return (
    <div className={showHideClassName}>
      <div className="row middle">
        <div className="col-12 col-lg-6">
          <div className="search-form">
            <div className="search-form__item">
              <label htmlFor="search">
                <input
                  className="search-form__field"
                  type="text"
                  id="search"
                  name="search"
                  maxLength={10}
                  placeholder={label ?? 'Search by Phone...'}
                  onChange={onChangeText}
                />
              </label>
              {/* {emailError && <div className="form__error">{emailError}</div>} */}
            </div>
            <div>
              <div className="row">
                <div className="col-12 d-flex-col middle">
                  <button type="button" className="search-form__button" onClick={handleSubmit} disabled={!value}>
                    <FontAwesomeIcon icon={faSearch} className="search-form__button__icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
