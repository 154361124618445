/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';
import moment from 'moment';
import { getApprovedUsers, searchApprovedUsers } from '../../store/modules/user/actions';
import { selectApprovedUsers, selectIsLoading, selectIsLoadingExport } from '../../store/modules/user/selectors';
import EmployeeModal from '../../components/EmployeeModal';
import MccRepModal from '../../components/MccRepModal';
import LoadingModal from '../../components/LoadingModal';

import './AssignUser.scss';

const AssignUser = () => {
  const [query, setQuery] = useState(null);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [mccRep, setMccRep] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [showMccRepModal, setShowMccRepModal] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: 'MCC Representative Name',
        accessor: 'mccName',
      },
      {
        Header: 'Assigned To Employee',
        accessor: 'farmerName',
      },
      {
        Header: 'Mobile',
        accessor: 'phone',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Total Animals',
        accessor: 'totalAnimals',
      },
      {
        Header: 'Unit Code',
        accessor: 'unitCode',
      },
      {
        Header: 'Plant Code',
        accessor: 'plateCode',
      },
      {
        Header: 'Village Name',
        accessor: 'villageName',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectApprovedUsers(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isLoadingExport = useSelector((state) => selectIsLoadingExport(state));

  // useEffect(() => {
  //   dispatch(getApprovedUsers());
  // }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data:
    // data && data.paginate && data.paginate.length
    //   ? data.paginate.map((item) => (
    //     {
    //       ...item,
    //       phone: item.phone.replace('+91', ''),
    //       age: moment(item.age).format('DD/MM/YYYY'),
    //       totalAnimals: item.Cattle.length,
    //     }
    //   ))
    //   :
      [],
  });

  const nextPage = (page) => {
    dispatch(getApprovedUsers(page));
  };

  const previousPage = (page) => {
    dispatch(getApprovedUsers(page));
  };

  const toggleEmployeeModal = () => {
    setShowEmployeeModal(!showEmployeeModal);
  };

  const toggleMccRepModal = () => {
    setShowMccRepModal(!showMccRepModal);
  };

  const submitEmployee = (values) => {
    setEmployee(values);
  };

  const submitMccRep = (values) => {
    setMccRep(values);
  };

  return (
    <>
      <div className="assign-user">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Assign MCC Representative to Employee
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-flex">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item mb-8">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="form__label">Step 1 : Select Employee</span>
                      </div>
                      <div className="col-12 col-md-6 d-flex-col middle">
                        <button type="button" className="form__button" onClick={toggleEmployeeModal}>Select</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="employeeName">
                      <div className="row middle">
                        <div className="col-12 col-md-6">
                          <span className="form__label">Employee Name</span>
                        </div>
                        <div className="col-12 col-md-6">
                          <input
                            className="form__field"
                            type="text"
                            id="employeeName"
                            name="employeeName"
                            placeholder="Employee Name"
                            value="Arjun"
                            disabled
                          />
                          {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="form">
              <div className="row">
                <div className="col-12">
                  <div className="form__item mb-8">
                    <div className="row middle">
                      <div className="col-12 col-md-6">
                        <span className="form__label">Step 2 : Select MCC Representative</span>
                      </div>
                      <div className="col-12 col-md-6 d-flex-col middle">
                        <button type="button" className="form__button" onClick={toggleMccRepModal}>Select</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="mccName">
                      <div className="row middle">
                        <div className="col-12 col-md-6">
                          <span className="form__label">MCC Rep Name</span>
                        </div>
                        <div className="col-12 col-md-6">
                          <input
                            className="form__field"
                            type="text"
                            id="mccName"
                            name="mccName"
                            placeholder="MCC Rep Name"
                            value={mccRep ? mccRep.mccName : ''}
                            disabled
                          />
                          {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <label htmlFor="mccCode">
                      <div className="row middle">
                        <div className="col-12 col-md-6">
                          <span className="form__label">MCC Rep Code</span>
                        </div>
                        <div className="col-12 col-md-6">
                          <input
                            className="form__field"
                            type="text"
                            id="mccCode"
                            name="mccCode"
                            placeholder="MCC Rep Code"
                            value={mccRep ? mccRep.mccCode : ''}
                            disabled
                          />
                          {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="buttons">
              <button type="button" className="button green">Confirm And Assign</button>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              <div className="table-header">
                <div className="row middle mb-8">
                  <div className="col-12">
                    <p className="table-header__title">
                      Assigned MCC Representative
                    </p>
                  </div>
                </div>
                <div className="filter-form">
                  <div className="row center">
                    <div className="col-12 col-md-2">
                      <div className="filter-form__item">
                        <div className="row">
                          <div className="col-auto">
                            <span className="filter-form__label">Filter By:</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="filter-form__item">
                        <label htmlFor="unitCode">
                          <div className="row middle">
                            <div className="col-12 col-md-4">
                              <span className="filter-form__label">Unit Code</span>
                            </div>
                            <div className="col-12 col-md-8">
                              <input
                                className="filter-form__field"
                                type="text"
                                id="unitCode"
                                name="unitCode"
                                placeholder="Unit Code"
                              />
                              {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="filter-form__item">
                        <label htmlFor="plantCode">
                          <div className="row middle">
                            <div className="col-12 col-md-4">
                              <span className="filter-form__label">Plant Code</span>
                            </div>
                            <div className="col-12 col-md-8">
                              <input
                                className="filter-form__field"
                                type="text"
                                id="plantCode"
                                name="plantCode"
                                placeholder="Plant Code"
                              />
                              {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      {/* <div className="form__item"> */}
                      <div className="row">
                        <div className="col-12 d-flex-col middle">
                          <button type="button" className="filter-form__button">Search</button>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-footer">
                <div className="row middle">
                  <div className="col-12 col-md-6">
                    <p className="table-footer__title">
                      {data && data.Previous
                        ? ((data.Previous.page + 1) * data.Previous.limit - data.Previous.limit) + 1
                        : 1}
                      {' '}
                      to
                      {' '}
                      {data && data.Previous
                        ? (data.Previous.page + 1) * data.Previous.limit
                        : 10}
                      {' '}
                      of 1
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="table-footer__buttons">
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => previousPage(1)}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleLeft}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? previousPage(data.Previous.page) : {})}
                        disabled={!data || !data.Previous}
                      >
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          className="table-footer__button__iclon"
                        />
                      </button>
                      <div className="table-footer__label">
                        {data && data.Next
                          ? data.Next.page - 1
                          : data && data.Previous
                            ? data.Previous.page + 1
                            : 1}
                        /1
                      </div>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                      <button
                        type="button"
                        className="table-footer__button"
                        onClick={() => (data ? nextPage(data.Next.page) : {})}
                        disabled={!data || !data.Next}
                      >
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          className="table-footer__button__icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table {...getTableProps()} className="table table-bordered">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                      <th>All Details</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        <td>
                          <button type="button" className="table__button">View</button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EmployeeModal
        show={showEmployeeModal}
        toggle={toggleEmployeeModal}
        onSubmit={submitEmployee}
      />
      <MccRepModal
        show={showMccRepModal}
        toggle={toggleMccRepModal}
        onSubmit={submitMccRep}
      />
      <LoadingModal show={isLoading || isLoadingExport} />
    </>
  );
};

export default AssignUser;
