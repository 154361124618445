import {
  GET_ALL_TEMPORARY_START,
  GET_ALL_TEMPORARY_SUCCESS,
  GET_ALL_TEMPORARY_ERROR,
  GET_ALL_PERMANENT_START,
  GET_ALL_PERMANENT_SUCCESS,
  GET_ALL_PERMANENT_ERROR,
  SEARCH_TEMPORARY_START,
  SEARCH_TEMPORARY_SUCCESS,
  SEARCH_TEMPORARY_ERROR,
  SEARCH_PERMANET_START,
  SEARCH_PERMANET_SUCCESS,
  SEARCH_PERMANET_ERROR,
  FILTER_TEMPORARY_USING_DATE_START,
  FILTER_TEMPORARY_USING_DATE_SUCCESS,
  FILTER_TEMPORARY_USING_DATE_ERROR,
  FILTER_PERMANENT_USING_DATE_START,
  FILTER_PERMANENT_USING_DATE_SUCCESS,
  FILTER_PERMANENT_USING_DATE_ERROR,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  COUNT_START,
  COUNT_SUCCESS,
  COUNT_ERROR,
} from './types';

const initialState = {
  temporaryCertificates: null,
  permanentCertificates: null,
  message: null,
  count: null,
  isLoading: false,
  errorMessage: null,
  isLoadingExport: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_TEMPORARY_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_TEMPORARY_SUCCESS:
      return {
        ...state,
        temporaryCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_TEMPORARY_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case GET_ALL_PERMANENT_START:
      return { ...state, errorMessage: null, isLoading: true };

    case GET_ALL_PERMANENT_SUCCESS:
      return {
        ...state,
        permanentCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case GET_ALL_PERMANENT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case SEARCH_TEMPORARY_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_TEMPORARY_SUCCESS:
      return {
        ...state,
        temporaryCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_TEMPORARY_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case SEARCH_PERMANET_START:
      return { ...state, errorMessage: null, isLoading: true };

    case SEARCH_PERMANET_SUCCESS:
      return {
        ...state,
        permanentCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case SEARCH_PERMANET_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case FILTER_TEMPORARY_USING_DATE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case FILTER_TEMPORARY_USING_DATE_SUCCESS:
      return {
        ...state,
        temporaryCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case FILTER_TEMPORARY_USING_DATE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case FILTER_PERMANENT_USING_DATE_START:
      return { ...state, errorMessage: null, isLoading: true };

    case FILTER_PERMANENT_USING_DATE_SUCCESS:
      return {
        ...state,
        permanentCertificates: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case FILTER_PERMANENT_USING_DATE_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    case EXPORT_START:
      return { ...state, errorMessage: null, isLoadingExport: true };

    case EXPORT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoadingExport: false,
      };

    case EXPORT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoadingExport: false,
      };
    case COUNT_START:
      return { ...state, errorMessage: null, isLoading: true };

    case COUNT_SUCCESS:
      return {
        ...state,
        count: payload || null,
        errorMessage: null,
        isLoading: false,
      };

    case COUNT_ERROR:
      return {
        ...state,
        errorMessage: payload || null,
        isLoading: false,
      };
    default:
      return state;
  }
};
