/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useTable } from 'react-table';
import moment from 'moment';
import LoadingModal from '../../components/LoadingModal';
import { getMilkSlipsReport } from '../../store/modules/user/actions';
import { selectIsLoadingSlips, selectMilkSlipsReport } from '../../store/modules/user/selectors';

import './MilkingReport.scss';

const MilkingReport = () => {
  const [fromDate, setFromDate] = useState(new Date(1990, 0, 1));
  const [toDate, setToDate] = useState(new Date());
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Morning Collection',
        accessor: 'morningCollection',
      },
      {
        Header: 'Morning Revenue',
        accessor: 'morningRevenue',
      },
      {
        Header: 'Evening Collection',
        accessor: 'eveningCollection',
      },
      {
        Header: 'Evening Revenue',
        accessor: 'eveningRevenue',
      },
      {
        Header: 'Total Day\'s Revenue',
        accessor: 'totalDayRevenue',
      },
    ],
    [],
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => selectMilkSlipsReport(state));
  const isLoading = useSelector((state) => selectIsLoadingSlips(state));

  const { id } = useParams();

  const searchReport = () => {
    const filter = {
      from: moment(fromDate).format('YYYY-MM-DD'),
      to: moment(toDate).format('YYYY-MM-DD'),
    };

    dispatch(getMilkSlipsReport(id, filter));
  };

  useEffect(() => {
    searchReport();
  }, []);

  const tableData = data && data.filteredGroupSlips
    && data.filteredGroupSlips.length
    ? data.filteredGroupSlips.map((filteredSlip) => {
      const slips = data.filteredUngroupSlips
        .filter((element) => moment(element.date).isSame(filteredSlip.date));
      const morningSlip = slips.find((slip) => slip.shift === 'morning');
      const eveningSlip = slips.find((slip) => slip.shift === 'evening');
      return {
        date: moment(filteredSlip.date).format('DD/MM/YYYY'),
        morningCollection: slips.length > 0
          ? `${morningSlip ? morningSlip.quantity : 0} ltr`
          : '0',
        morningRevenue: slips.length > 0
          ? `Rs. ${morningSlip ? morningSlip.amount : 0}`
          : '0',
        eveningCollection: slips.length > 0
          ? `${eveningSlip ? eveningSlip.quantity : 0} ltr`
          : '0',
        eveningRevenue: slips.length > 0
          ? `Rs. ${eveningSlip ? eveningSlip.amount : 0}`
          : '0',
        totalDayRevenue: `Rs. ${filteredSlip.totalAmount}`,
      };
    })
    : [];

  const tableFooterData = data && data.filteredGroupSlips
    && data.filteredGroupSlips.length
    ? data.filteredGroupSlips.map((filteredSlip) => {
      const slips = data.filteredUngroupSlips
        .filter((element) => moment(element.date).isSame(filteredSlip.date));
      const morningSlip = slips.find((slip) => slip.shift === 'morning');
      const eveningSlip = slips.find((slip) => slip.shift === 'evening');

      return {
        mQty: slips.length > 0 ? (morningSlip ? morningSlip.quantity : 0) : 0,
        mRevenue: slips.length > 0 ? (morningSlip ? morningSlip.amount : 0) : 0,
        eQty: slips.length > 0 ? (eveningSlip ? eveningSlip.quantity : 0) : 0,
        eRevenue: slips.length > 0 ? (eveningSlip ? eveningSlip.amount : 0) : 0,
      };
    })
    : [];

  const totalAmount = data && data.filteredGroupSlips
      && data.filteredGroupSlips.length
    ? data.filteredGroupSlips.reduce(
      (total, current) => total + +current.totalAmount,
      0,
    )
    : 0;

  const tableFooter = {
    totalMorningCollection: `${tableFooterData.reduce(
      (total, current) => total + current.mQty,
      0,
    )} ltr`,
    totalMorningRevenue: `Rs. ${tableFooterData.reduce(
      (total, current) => total + current.mRevenue,
      0,
    )}`,
    totalEveningCollection: `${tableFooterData.reduce(
      (total, current) => total + current.eQty,
      0,
    )} ltr`,
    totalEveningRevenue: `Rs. ${tableFooterData.reduce(
      (total, current) => total + current.eRevenue,
      0,
    )}`,
    total: `Rs. ${totalAmount}`,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  return (
    <>
      <div className="milking-report">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Farm Detailed Milking Details
              </p>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              <div className="table-header">
                <div className="form">
                  <div className="row center">
                    <div className="col-12 col-md-4">
                      <div className="form__item">
                        <label htmlFor="from">
                          <div className="row middle">
                            <div className="col-12 col-md-4">
                              <span className="form__label">From</span>
                            </div>
                            <div className="col-12 col-md-8">
                              <DatePicker
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form__field"
                              />
                              {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form__item">
                        <label htmlFor="to">
                          <div className="row middle">
                            <div className="col-12 col-md-4">
                              <span className="form__label">To</span>
                            </div>
                            <div className="col-12 col-md-8">
                              <DatePicker
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form__field"
                              />
                              {/* {errors.role && <div className="form__error">{e
                        rrors.role.message}</div>} */}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto">
                      <div className="form__item">
                        <div className="row">
                          <div className="col-12 d-flex-col middle">
                            <button type="button" className="form__button" onClick={searchReport}>Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table {...getTableProps()} className="table table-bordered">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td>
                      {tableFooter.totalMorningCollection}
                    </td>
                    <td>
                      {tableFooter.totalMorningRevenue}
                    </td>
                    <td>
                      {tableFooter.totalEveningCollection}
                    </td>
                    <td>
                      {tableFooter.totalEveningRevenue}
                    </td>
                    <td>
                      {tableFooter.total}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal show={isLoading} />
    </>
  );
};

export default MilkingReport;
