import React from 'react';
import './Spinner.scss';

const Spinner = ({ large }) => {
  const sizeClass = large ? 'large' : '';

  return (
    <div className={`loading ${sizeClass}`}>
      <span className={sizeClass} />
    </div>
  );
};

export default Spinner;
