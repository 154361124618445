/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import ImageSliderModal from '../../components/ImageSliderModal';
import LoadingModal from '../../components/LoadingModal';
import { getSale, updateSale } from '../../store/modules/market/actions';
import { uploadImages } from '../../store/modules/product/actions';
import {
  selectSale, selectIsLoading, selectIsLoadingUpdate,
} from '../../store/modules/market/selectors';
import { selectImages, selectIsUploading } from '../../store/modules/product/selectors';

import 'react-datepicker/dist/react-datepicker.css';
import './EditSale.scss';

const EditSale = () => {
  const [age, setAge] = useState(new Date());
  const [imagesFile, setImagesFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [showVariantsModal, setShowVariantsModal] = useState(false);
  const [showImageSliderModal, setShowImageSliderModal] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => selectSale(state));
  const uploadedImages = useSelector((state) => selectImages(state));
  const [images, setImages] = useState(uploadedImages);
  const isLoading = useSelector((state) => selectIsLoading(state));
  const isUpdating = useSelector((state) => selectIsLoadingUpdate(state));
  const isUploading = useSelector((state) => selectIsUploading(state));
  const { id } = useParams();

  const {
    register, handleSubmit, setValue, watch, formState: { errors },
  } = useForm({
    defaultValues: {
      cattle: 'cow',
      earTag: '',
      category: 'calf',
      breed: 'hf - holstein friesian',
      weight: '',
      currentMilk: '',
      milkCapacity: '',
      fat: '',
      lactationNo: '',
      price: '',
      negotiable: 'false',
      isPregnant: 'false',
      pregnantMonth: '',
      isCalf: 'false',
      description: '',
    },
  });

  const watchCategory = watch('category');
  const watchCattle = watch('cattle');

  useEffect(() => {
    dispatch(getSale(id));
  }, []);

  useEffect(() => {
    if (data) {
      setValue('cattle', data.cattle);
      setValue('earTag', data.earTag);
      setValue('category', data.category);
      setValue('breed', data.breed);
      setValue('weight', `${data.weight}`);
      setAge(new Date(data.age));
      setValue('currentMilk', `${data.currentMilk || ''}`);
      setValue('milkCapacity', `${data.milkCapacity || ''}`);
      setValue('fat', `${data.fat || ''}`);
      setValue('lactationNo', `${data.LactationNumber || ''}`);
      setValue('price', data.price);
      setValue('negotiable', data.negotiable);
      setImages(data.pictures);
      setValue('address', data.address);
      setValue('isPregnant', data.isPregnant);
      setValue('pregnantMonth', `${data.pregnantMonths || ''}`);
      setValue('isCalf', data.isCalf);
      setValue('description', data.description);
    }
  }, [setValue, data]);

  useEffect(() => {
    setImages(uploadedImages);
  }, [uploadedImages]);

  useEffect(() => {
    if (imagesFile && imagesFile.length && !fileError) {
      const formData = new FormData();
      for (let i = 0; i < imagesFile.length; i++) {
        formData.append('images', imagesFile[i]);
      }
      dispatch(uploadImages(formData));
    }
  }, [imagesFile, fileError]);

  const fileReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 700 || img.height > 600) {
          setFileError('Product Image dimensions should not exceed 700 x 600');
        }
      };
      img.onerror = () => {
        setFileError('Invalid image content');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleSelectFile = (event) => {
    setImagesFile(null);
    const { files } = event.target;
    if (!files.length) return;
    setFileError(null);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1e6) {
        setFileError('Product Image size should not be >1MB');
        return;
      }
      fileReader(files[i]);
    }

    setImagesFile(files);
  };

  const toggleVariantsModal = () => {
    setShowVariantsModal(!showVariantsModal);
  };

  const toggleImageSliderModal = () => {
    setShowImageSliderModal(!showImageSliderModal);
  };

  const submit = ({
    cattle,
    earTag,
    category,
    breed,
    weight,
    currentMilk,
    milkCapacity,
    fat,
    lactationNo,
    price,
    negotiable,
    address,
    isPregnant,
    pregnantMonths,
    isCalf,
    description,
  }) => {
    if (!images.length) {
      setFileError('Select at least 1 picture');
      return;
    }

    dispatch(updateSale(id, {
      cattle,
      earTag,
      category,
      breed,
      weight,
      currentMilk,
      milkCapacity,
      fat,
      LactationNumber: lactationNo,
      price,
      negotiable,
      pictures: images,
      address,
      isPregnant,
      pregnantMonths,
      isCalf,
      description,
    }));
  };

  return (
    <>
      <div className="edit-sale">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <p className="heading__title">
                Edit Cattle Details
              </p>
            </div>
          </div>
        </div>
        <div className="row middle">
          <div className="col-12">
            <div className="form-box form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="tabletName">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Catlle</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="cattle"
                            name="cattle"
                            className="form__field"
                            {...register('cattle', { required: { value: true, message: 'Catlle is required' } })}
                          >
                            <option value="cow">Cow</option>
                            <option value="buffalo">Buffalo</option>
                          </select>
                          {errors.cattle && <div className="form__error">{errors.cattle.massage}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="category">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Category</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="category"
                            name="category"
                            className="form__field"
                            {...register('category', { required: { value: true, message: 'Cattle Stage is required' } })}
                          >
                            <option value="calf">Calf</option>
                            <option value="heifer">Heifer</option>
                            <option value="milking">Milking</option>
                            <option value="dry">Dry</option>
                          </select>
                          {errors.category && <div className="form__error">{errors.category.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="breed">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Breed</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="breed"
                            name="breed"
                            className="form__field"
                            {...register('breed', { required: { value: true, message: 'Breed Stage is required' } })}
                          >
                            {watchCattle === 'cow' ? (
                              <>
                                <option value="hf - holstein friesian">Hf - holstein friesian</option>
                                <option value="jersey">Jersey</option>
                                <option value="gir">Gir</option>
                                <option value="sahiwal">Sahiwal</option>
                                <option value="ongole">Ongole</option>
                                <option value="other">Other</option>
                              </>
                            )
                              : (
                                <>
                                  <option value="murrah">Murrah</option>
                                  <option value="jaffrabadi">Jaffrabadi</option>
                                  <option value="surti">Surti</option>
                                  <option value="bhadawari">Bhadawari</option>
                                  <option value="nilli ravi">Nilli ravi</option>
                                  <option value="other">Other</option>
                                </>
                              )}
                          </select>
                          {errors.breed && <div className="form__error">{errors.breed.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="earTag">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Ear tag</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="text"
                            id="earTag"
                            name="earTag"
                            placeholder="Ear tag"
                            {...register('earTag', {
                              minLength: {
                                value: 12,
                                message: 'Ear tag must not be less than 12 digits',
                              },
                              maxLength: {
                                value: 12,
                                message: 'Ear tag must not be greater than 12 digits',
                              },
                              pattern: {
                                value: /\d/,
                                message: 'Ear tag must be digits',
                              },
                            })}
                          />
                          {errors.earTag && <div className="form__error">{errors.earTag.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <div className="row middle">
                      <div className="col-12">
                        <span className="form__label">Cattle Age</span>
                      </div>
                      <div className="col-12">
                        <DatePicker
                          selected={age}
                          onChange={(date) => setAge(date)}
                          dateFormat="dd/MM/yyyy"
                          className="form__field"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <label htmlFor="weight">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Weight</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="number"
                            id="weight"
                            name="weight"
                            placeholder="Weight"
                            min={0}
                            {...register('weight', { required: { value: true, message: 'Weight is required' } })}
                          />
                          {errors.weight && <div className="form__error">{errors.weight.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  {watchCategory === 'milking' || watchCategory === 'dry' ? (
                    <>
                      <div className="form__item">
                        <label htmlFor="currentMilk">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Current Milk (per day)</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="currentMilk"
                                name="currentMilk"
                                placeholder="Current Milk (per day)"
                                min={0}
                                {...register('currentMilk', {
                                  required: {
                                    value: true,
                                    message: 'Current Milk is required',
                                  },
                                })}
                              />
                              {errors.currentMilk && <div className="form__error">{errors.currentMilk.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="milkCapacity">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Milk Capacity (per day)</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="milkCapacity"
                                name="milkCapacity"
                                placeholder="Milk Capacity (per day)"
                                min={0}
                                {...register('milkCapacity', {
                                  required: {
                                    value: true,
                                    message: 'Milk Capacity is required',
                                  },
                                })}
                              />
                              {errors.milkCapacity && <div className="form__error">{errors.milkCapacity.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="fat">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Fat in Milk</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="fat"
                                name="fat"
                                placeholder="Fat in Milk"
                                min={0}
                                {...register('fat', {
                                  required: { value: true, message: 'Fat is required' },
                                  min: {
                                    value: 2,
                                    message: 'Fat must not be less than 2',
                                  },
                                  max: {
                                    value: 20,
                                    message: 'Fat must not be greater than 20',
                                  },
                                })}
                              />
                              {errors.fat && <div className="form__error">{errors.fat.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form__item">
                        <label htmlFor="lactationNo">
                          <div className="row middle">
                            <div className="col-12">
                              <span className="form__label">Lactation No</span>
                            </div>
                            <div className="col-12">
                              <input
                                className="form__field"
                                type="number"
                                id="lactationNo"
                                name="lactationNo"
                                placeholder="Lactation No"
                                min={0}
                                {...register('lactationNo', {
                                  required: { value: true, message: 'Lactation No is required' },
                                  max: {
                                    value: 12,
                                    message: 'Lactation No must not be greater than 12',
                                  },
                                })}
                              />
                              {errors.lactationNo && <div className="form__error">{errors.lactationNo.message}</div>}
                            </div>
                          </div>
                        </label>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__item">
                    <label htmlFor="price">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Rate/Price</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="number"
                            id="price"
                            name="price"
                            placeholder="Rate/Price"
                            min={0}
                            {...register('price', {
                              required: {
                                value: true,
                                message: 'Rate/Price is required',
                              },
                            })}
                          />
                          {errors.price && <div className="form__error">{errors.price.message}</div>}
                        </div>
                      </div>
                    </label>
                    <label htmlFor="negotiable">
                      <input
                        type="checkbox"
                        id="negotiable"
                        name="negotiable"
                        {...register('negotiable')}
                      />
                      Negotiable
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="picture">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Pictures/Videos (At least one)</span>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            {images.length ? images.slice(0, 2).map((img) => (
                              <div className="col">
                                <div
                                  className="picture"
                                  role="button"
                                  tabIndex="0"
                                  onClick={toggleImageSliderModal}
                                >
                                  <img key={img} src={img} alt="product" />
                                </div>
                              </div>
                            ))
                              : null}
                            {images.length > 2
                              ? (
                                <div className="col">
                                  <div className="picture">
                                    <img src={images[2]} alt="product" />
                                    <div
                                      style={{ background: '#00000060' }}
                                      className="picture__placeholder"
                                      role="button"
                                      tabIndex="0"
                                      onClick={toggleImageSliderModal}
                                    >
                                      <span style={{ color: '#FFF', fontWeight: '600', fontSize: 16 }}>
                                        +
                                        {images.length - 2}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            <div className="col">
                              <label htmlFor="image">
                                <div className="picture">
                                  <div style={{ background: fileUrl ? 'transparent' : '#55555520' }} className="picture__placeholder">
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      className="picture__placeholder--icon"
                                    />
                                  </div>
                                </div>
                                <input
                                  className="form__field"
                                  type="file"
                                  name="files[]"
                                  id="image"
                                  multiple
                                  onChange={handleSelectFile}
                                />
                              </label>
                            </div>
                          </div>
                          {fileError && <div className="form__error">{fileError}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="address">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Address</span>
                        </div>
                        <div className="col-12">
                          <input
                            readOnly
                            className="form__field"
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Address"
                            {...register('address', {
                              required: {
                                value: true,
                                message: 'Address is required',
                              },
                            })}
                          />
                          {errors.address && <div className="form__error">{errors.address.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="isPregnant">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Is it pregnant?</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="isPregnant"
                            name="isPregnant"
                            className="form__field"
                            {...register('isPregnant')}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                          {errors.isPregnant && <div className="form__error">{errors.isPregnant.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="pregnantMonth">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">No. of month pregnant</span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__field"
                            type="number"
                            id="pregnantMonth"
                            name="pregnantMonth"
                            placeholder="No. of month pregnant"
                            min={0}
                            {...register('pregnantMonth', {
                              min: {
                                value: 1,
                                message:
                                      'No. of months pregnant must not be less than 1',
                              },
                              max: {
                                value: 9,
                                message:
                                      'No. of months pregnant must not be greater than 9',
                              },
                            })}
                          />
                          {errors.pregnantMonth && <div className="form__error">{errors.pregnantMonth.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="isCalf">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Is there a calf?</span>
                        </div>
                        <div className="col-12">
                          <select
                            id="isCalf"
                            name="isCalf"
                            className="form__field"
                            {...register('isCalf')}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                          {errors.isCalf && <div className="form__error">{errors.isCalf.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form__item">
                    <label htmlFor="description">
                      <div className="row middle">
                        <div className="col-12">
                          <span className="form__label">Description</span>
                        </div>
                        <div className="col-12">
                          <textarea
                            className="form__field"
                            id="description"
                            name="description"
                            placeholder="Description"
                            {...register('description')}
                          />
                          {errors.description && <div className="form__error">{errors.description.message}</div>}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__item">
                    <div className="row">
                      <div className="col-12 d-flex-col middle">
                        <button type="button" className="button primary" onClick={handleSubmit(submit)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImageSliderModal && (
      <ImageSliderModal
        show={showImageSliderModal}
        toggle={toggleImageSliderModal}
        images={images}
      />
      )}
      <LoadingModal show={isLoading || isUpdating || isUploading} />
    </>
  );
};

export default EditSale;
